import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "75%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function PreFacturaServices({ bodega, setBodega, asignados, setAsignados, search, setSearch }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSearch(bodega);
  };

  // const handleTeamClick = (team, remove) => {
  //   setAsignados((prev) => {
  //     const newState = { ...prev };
  //     delete newState[team];
  //     return newState;
  //   });
  // };

  const handleTeamClick = (team) => {
    if (bodega.includes(team)) {
      const updatedBodega = bodega.filter((t) => t !== team);
      const updatedSearch = search.filter((t) => t !== team);
      setBodega(updatedBodega);
      setSearch(updatedSearch);
      setAsignados([...asignados, team]);
    } else {
      const updatedAsignados = asignados.filter((t) => t !== team);
      setAsignados(updatedAsignados);
      setBodega([...bodega, team]);
      setSearch([...search, team]);
    }
  };

  const handleChange = (e) => {
    const filtered = bodega.filter((obj) => obj.nombre.toLowerCase().includes(e.target.value.toLowerCase()));
    setSearch(filtered);
  };

  const handleQuantityChange = (team, e) => {
    setAsignados((prev) => [...prev, { ...team, cantidad: e.target.value, valueCost: 0 }]);
  };

  return (
    <div>
      <Button variant="outlined" onClick={() => setOpen(true)} sx={{ textTransform: "none", color: "white", border: "1px solid white" }} size="small">
        AÑADIR
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(5px)" } } }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex h-[100%] flex-col justify-between">
              <div className="flex flex-col h-[40%]">
                <div className="bg-[#2B2C84]/70 text-white flex justify-between items-center min-h-[15%] mb-1">
                  <div className="pl-20">DISPONIBLES</div>
                  <div className="pr-20 text-black">
                    <input className="pl-2" type="text" placeholder="Buscar" onChange={handleChange} />
                    <SearchIcon sx={{ color: "white", marginLeft: 1 }} />
                  </div>
                </div>
                <div className="h-[85%] overflow-y-auto flex flex-col w-[100%] preFactura-scroll">
                  {search.length > 0 ? (
                    search.map((team) => {
                      const name = `${team.id}-${team.nombre}`;
                      return (
                        <div
                          key={team.nombre} // Agregar una clave única
                          className="bg-[#eef2fc] min-h-[40px] max-h-[40px] mb-1 flex items-center justify-between"
                        >
                          <div className="pl-5 w-full flex justify-between">
                            <div>{team.nombre}</div>
                            <div>
                              Cantidad:
                              <input
                                name={name}
                                type="number"
                                className="bg-[white] w-[40px] border-b-2 mx-3 pl-1 input-number h-9 font-bold"
                                value={team.cantidad}
                                onChange={(e) => handleQuantityChange(team, e)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-[40%]">
                <div className="bg-[#2B2C84]/70 text-white flex justify-center items-center min-h-[15%] mb-1">ASIGNADOS</div>
                <div className="h-[85%] overflow-y-auto flex flex-col w-[100%] preFactura-scroll">
                  {asignados.length > 0 ? (
                    asignados.map((service) => (
                      <div key={service.id} className="bg-[#eef2fc] min-h-[40px] max-h-[40px] mb-1 flex items-center justify-between">
                        <div className="pl-5 w-[85%] flex justify-between">
                          <div>{service.nombre}</div>
                          <div>
                            <span className="mr-2">Cantidad:</span>
                            {service.cantidad}
                          </div>
                        </div>
                        <div className="pr-5">
                          <RemoveIcon onClick={() => handleTeamClick(service, true)} sx={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <Button variant="contained" onClick={handleClose}>
                Guardar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
