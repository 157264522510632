import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";
import dayjs from "dayjs";

const initialState = {
  feriados: [],
  success: false,
  status: "idle",
};

const feriadosSlice = createSlice({
  name: "feriados",
  initialState,
  reducers: {
    setFeriados: (state, action) => {
      state.feriados = action.payload;
      state.status = true;
      state.success = true;
    },
    create: (state, action) => {
      state.feriados.push(action.payload);
      state.success = true;
    },
    remove: (state, action) => {
      const updated = state.feriados.filter((el) => el.id !== action.payload);
      state.feriados = updated;
      state.success = true;
    },
    update: (state, action) => {
      const updated = state.feriados.filter((el) => el.id !== action.payload.id);
      state.feriados = [...updated, action.payload];
      state.success = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getAllFeriados = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/feriados");
    const newData = data.map((el) => ({ ...el, fecha_format: dayjs(el.fecha).add(1, "day").format("DD-MM-YYYY") }));
    dispatch(setFeriados(newData));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createFeriado = (values) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.post("/feriados", values);

    data.fecha_format = dayjs(data.fecha).add(1, "day").format("DD-MM-YYYY");
    dispatch(create(data));
    dispatch(
      setNotification({
        status: "success",
        message: "Feriado creado con exito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear feriado",
        open: true,
      })
    );
  }
};

export const deleteFeriado = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/feriados?id=${id}`);
    dispatch(remove(response.data.id));
    dispatch(
      setNotification({
        status: "success",
        message: "Feriados borrado con exito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al borrar feriado",
        open: true,
      })
    );
  }
};

export const updateFeriado = (values) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.put(`/feriados?id=${values.id}`, values);
    data.fecha_format = dayjs(data.fecha).add(1, "day").format("DD-MM-YYYY");
    dispatch(update(data));
    dispatch(
      setNotification({
        status: "success",
        message: "Feriados editada con exito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar feriados",
        open: true,
      })
    );
  }
};

export const { setFeriados, create, remove, update, setStatus } = feriadosSlice.actions;

export default feriadosSlice.reducer;
