import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import FilterOtAsignadas from "./FilterOtAsignadas";
import { useEffect } from "react";
import { setter } from "../../../redux/slices/informes";

export default function OtAsignadas() {
  const { otAssigned } = useSelector((state) => state.informes);
  const columnStorageRepuestos = [
    { title: "OT", value: "nro_ot" },
    { title: "Cliente", value: "cliente" },
    { title: "Fecha", value: "fecha" },
    { title: "Estado", value: "estado" },
    { title: "OT Asignada", value: "acciones_info_otAsgined" },
    { title: "PDF", value: "pdf_ot" },
  ];

  const [columns] = useDataGrid(columnStorageRepuestos, () => {}, "CARGO");

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "otAssigned", payload: [] }));
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <FilterOtAsignadas />
      <DataGridComponent columns={columns} rows={otAssigned} success={true} size="40px" />
    </div>
  );
}
