import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "",
    color: "#000000",
    padding: 10,
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CobranzasTable({ rows }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" width={"100px"}>
              Fecha
            </StyledTableCell>
            <StyledTableCell align="left" width={"200px"}>
              Usuario
            </StyledTableCell>
            <StyledTableCell align="left" width={"120px"}>
              F. Compromiso
            </StyledTableCell>
            {/* <StyledTableCell align="left" width={"120px"}>
              M. Contacto
            </StyledTableCell> */}
            <StyledTableCell align="left">Observaciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{dayjs(row.f_registro).add(1, "day").format("DD/MM/YY")}</StyledTableCell>
              <StyledTableCell align="left">
                {row.gen_usuario_cobranza_obs_responsableTogen_usuario.nombre}
              </StyledTableCell>
              <StyledTableCell align="left">{dayjs(row.f_compromiso).add(1, "day").format("DD/MM/YY")}</StyledTableCell>
              {/* <StyledTableCell align="left">{row.gen_medio_contacto?.descripcion}</StyledTableCell> */}
              <StyledTableCell align="left">{row.observaciones}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
