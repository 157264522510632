import React from "react";
import DataGridComponent from "../../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../../hooks/useDataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { deleteInvoice, getAllFacturacionesByState, setFacturacion } from "../../redux/slices/facturacion";
import { CircularProgress } from "@mui/material";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Cliente", value: "cliente" },
  { title: "Nro OC", value: "nro_oc" },
  { title: "Nro OC Privada", value: "nro_oc_noMP" },
  { title: "Fecha OC", value: "fecha_oc_parsed" },
  { title: "Nro OT", value: "ot_id" },
  { title: "Monto", value: "monto_sin_iva" },
  { title: "Iva", value: "iva" },
  { title: "Monto Total", value: "monto_total" },
  { title: "Múltiple Facturación", value: "isMultiple" },
  { title: "Equipos", value: "equipos_length" },
  { title: "Repuestos", value: "repuestos_length" },
  { title: "Insumos", value: "articulos_length" },
  { title: "Servicios", value: "servicios_length" },
  { title: "OT & OC", value: "acciones_sin_facturar" },
  { title: "Acciones", value: "acciones_sin_facturar_dos" },
];

export default function Factura() {
  const { facturacion, success } = useSelector((state) => state.facturacion);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    dispatch(deleteInvoice(params.row.id));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Marcas", false, setOpen);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllFacturacionesByState(false)).then(() => setLoading(false));

    return () => dispatch(setFacturacion([]));
  }, [dispatch]);
  console.log(facturacion);
  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Por Facturar</h1>
          <BreadCrumb origin={"Facturacion"} current={"Por Facturar"} />
        </div>
      </div>
      {loading ? (
        <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <DataGridComponent columns={columns} rows={facturacion} success={success} />
      )}
    </div>
  );
}
