import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { setError } from "../../redux/slices/roles";
import { useDispatch } from "react-redux";
import { Chip, TextField } from "@mui/material";
import uploadFiles from "../../utils/UploadFiles";
import FileUploader from "../../utils/FileUploader";
import { registrarPago } from "../../redux/slices/cobranzas";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import dayjs from "dayjs";

export default function InformarPagoModal({ params, name }) {
  const { currentUser } = useSelector((state) => state.auth);
  const [file, setFile] = React.useState({});
  const [monto, setMonto] = React.useState(0);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "auto",
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const [open, setOpen] = React.useState(false);
  const [fecha, setFecha] = React.useState(dayjs());
  const [nuevaFecha, setNuevaFecha] = React.useState(dayjs(params.row.f_vencimiento).add(1, "month"));
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFile({});
    setMonto(0);
    setFecha(dayjs());
    dispatch(setError(false));
  };

  const handleDateChange = (e) => {
    setFecha(e);
  };

  const handleNewDateChange = (e) => {
    setNuevaFecha(e);
  };

  const handleFileChange = (event) => setFile((prevState) => ({ ...prevState, contract: event.target.files[0] }));

  const handleMontoChange = (e) => {
    setMonto(e.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    let estado = true;
    if (params.row.facturacion.pago_parcial === true) {
      if (monto < params.row.saldo_pendiente) {
        estado = false;
      }
    }
    const formToSend = {
      id: params.row.id,
      responsable: currentUser.id,
      observaciones: `Se registra pago con éxito`,
      fecha_pago: fecha,
      monto_pago: params.row.facturacion.pago_parcial === false ? params.row.saldo_pendiente : monto,
      nueva_fecha_pago: params.row.facturacion.pago_parcial === false ? params.row.f_vencimiento : nuevaFecha,
      estado,
    };
    if (file?.contract?.name) {
      const uploaded = await uploadFiles("docs", file.contract);
      formToSend.comprobante = uploaded.data;
    }

    dispatch(registrarPago(formToSend));
    setOpen(false);
    setFile({});
    setMonto(0);
    setFecha(dayjs());
    dispatch(setError(false));
  };

  return (
    <div>
      <Chip color="success" label="Registar" onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={submit}>
            {!params.row?.facturacion?.pago_parcial ? (
              <div>
                <div className="flex justify-center text-xl bg-[#2b2c84]/20 font-semibold p-2 mb-2">REGISTRAR PAGO</div>
                <div className="mb-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={fecha}
                        label={"Fecha de pago"}
                        onChange={handleDateChange}
                        format={"DD/MM/YYYY"}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="flex flex-col min-h-[88px]">
                  <div className="flex flex-col w-full ">
                    <FileUploader
                      value={file}
                      keyword={"contract"}
                      to={"pdf"}
                      handler={handleFileChange}
                      size={"40px"}
                    />
                  </div>
                  {Object.keys(file).length > 0 && (
                    <div className="h-[40px] mt-2 border-[1px] border-[#bdbdbd] text-gray-500 w-full rounded flex justify-between items-center px-3">
                      {file?.contract.name.length < 30
                        ? file?.contract?.name
                        : file.contract?.name.slice(0, 30) + "..."}
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <Typography id="modal-modal-description" sx={{ textAlign: "center", mt: 1, mb: 2 }}>
                    ¿Estas seguro que deseas registrar el pago?
                  </Typography>
                  <div className="flex justify-center">
                    <Button sx={{ marginRight: 2 }} variant="outlined" type="submit" fullWidth>
                      SI
                    </Button>
                    <Button variant="outlined" onClick={handleClose} fullWidth>
                      NO
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-center text-xl bg-[#2b2c84]/20 font-semibold p-2 mb-2">REGISTRAR PAGO</div>
                <div className="mb-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={fecha}
                        label={"Fecha de pago"}
                        onChange={handleDateChange}
                        format={"DD/MM/YYYY"}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="mb-2">
                  <TextField
                    onChange={handleMontoChange}
                    fullWidth
                    size="small"
                    label={"Saldo pendiente"}
                    value={`$ ${params.row.saldo_pendiente}`}
                    disabled
                  />
                </div>
                <div className="">
                  <TextField
                    onChange={handleMontoChange}
                    fullWidth
                    size="small"
                    label={"Monto a pagar"}
                    type="number"
                  />
                </div>
                <div className="mb-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        value={nuevaFecha}
                        label={"Nueva Fecha de pago"}
                        disabled={monto >= params.row.saldo_pendiente ? true : false}
                        onChange={handleNewDateChange}
                        format={"DD/MM/YYYY"}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="flex flex-col min-h-[88px]">
                  <div className="flex flex-col w-full ">
                    <FileUploader
                      value={file}
                      keyword={"contract"}
                      to={"pdf"}
                      handler={handleFileChange}
                      size={"40px"}
                    />
                  </div>
                  {Object.keys(file).length > 0 && (
                    <div className="h-[40px] mt-2 border-[1px] border-[#bdbdbd] text-gray-500 w-full rounded flex justify-between items-center px-3">
                      {file?.contract.name.length < 30
                        ? file?.contract?.name
                        : file.contract?.name.slice(0, 30) + "..."}
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <Typography id="modal-modal-description" sx={{ textAlign: "center", mt: 1, mb: 2 }}>
                    ¿Estas seguro que deseas registrar el pago?
                  </Typography>
                  <div className="flex justify-center">
                    <Button
                      sx={{ marginRight: 2 }}
                      variant="outlined"
                      fullWidth
                      type="submit"
                      disabled={monto <= 0 ? true : false}
                    >
                      SI
                    </Button>
                    <Button variant="outlined" fullWidth onClick={handleClose}>
                      NO
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Box>
      </Modal>
    </div>
  );
}
