import React from "react";
import useConstantRepairParts from "../components/modal/constants/useConstantRepairParts";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import TransitionsModal from "../components/modal/TransitionsModal";
import { deleteBrand, getAllBrands } from "../redux/slices/inventory";
import EditModal from "../components/modal/EditModal";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";
import repairPartSchema from "../resolvers/repairPartSchema.resolver";
import BrandModalCreate from "../components/modal/BrandCreate";
import BrandModalUpdate from "../components/modal/BrandUpdate";
import { deleteServices, getAllServices } from "../redux/slices/services";
import ServicesCreate from "../components/modal/ServicesCreate";
import ServicesUpdate from "../components/modal/ServicesUpdate";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Código", value: "codigo" },
  { title: "Nombre", value: "nombre" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Services() {
  const { services, success } = useSelector((state) => state.services);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteServices(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "SERVICIO", false, setOpen);

  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Servicios</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Servicios"} />
        </div>
        <div>
          <ServicesCreate />
          <ServicesUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={services} success={success} />
    </div>
  );
}
