import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "75%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

export default function PreFacturaEquipament({
  bodega,
  setBodega,
  asignados,
  setAsignados,
  search,
  setSearch,
  setSubTotalEquipos,
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setSearch(bodega);
  };

  const handleTeamClick = (team) => {
    if (bodega.includes(team)) {
      const updatedBodega = bodega.filter((t) => t !== team);
      const updatedSearch = search.filter((t) => t !== team);
      setBodega(updatedBodega);
      setSearch(updatedSearch);
      setAsignados([...asignados, team]);
      setSubTotalEquipos((prevState) => prevState + team.valueCost);
    } else {
      const updatedAsignados = asignados.filter((t) => t !== team);
      setAsignados(updatedAsignados);
      setBodega([...bodega, team]);
      setSearch([...search, team]);
      setSubTotalEquipos((prevState) => prevState - team.valueCost);
    }
  };

  const handleChange = (e) => {
    const filtered = bodega.filter(
      (obj) =>
        obj.descripcion.toLowerCase().includes(e.target.value.toLowerCase()) ||
        obj.modelo.toLowerCase().includes(e.target.value.toLowerCase()) ||
        obj.serie.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearch(filtered);
  };

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        sx={{ textTransform: "none", color: "white", border: "1px solid white" }}
        size="small"
      >
        AÑADIR
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(5px)" } } }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex h-[100%] flex-col justify-between">
              <div className="flex flex-col h-[40%]">
                <div className="bg-[#2b2c84]/70 text-white flex justify-between items-center min-h-[15%] mb-1">
                  <div className="pl-20">DISPONIBLES</div>
                  <div className="pr-20 text-black">
                    <input className="pl-2" type="text" placeholder="Buscar" onChange={handleChange} />
                    <SearchIcon sx={{ color: "white", marginLeft: 1 }} />
                  </div>
                </div>
                <div className="h-[85%] overflow-y-auto flex flex-col w-[100%] preFactura-scroll">
                  {search.length > 0 ? (
                    search.map((team) => (
                      <div className="bg-[#eef2fc] min-h-[40px] max-h-[40px] mb-1 flex items-center justify-between">
                        <div className="w-[90%] flex">
                          <div className="w-[40%]">{team.inv_tipo_equipo?.descripcion}</div>
                          <div className="w-[30%]">{team.modelo}</div>
                          <div className="w-[30%]">{team.serie}</div>
                        </div>
                        <div className="pr-5">
                          <AddIcon onClick={() => handleTeamClick(team)} sx={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-[40%]">
                <div className="bg-[#2b2c84]/70 text-white flex justify-center items-center min-h-[15%] mb-1">
                  ASIGNADOS
                </div>
                <div className="h-[85%] overflow-y-auto flex flex-col w-[100%] preFactura-scroll">
                  {asignados.length > 0 ? (
                    asignados.map((team) => (
                      <div className="bg-[#eef2fc] min-h-[40px] max-h-[40px] mb-1 flex items-center justify-between">
                        <div className="w-[90%] flex">
                          <div className="w-[40%]">{team.inv_tipo_equipo?.descripcion}</div>
                          <div className="w-[30%]">{team.modelo}</div>
                          <div className="w-[30%]">{team.serie}</div>
                        </div>
                        <div className="pr-5">
                          <RemoveIcon onClick={() => handleTeamClick(team)} sx={{ cursor: "pointer" }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <Button variant="contained" onClick={handleClose}>
                Guardar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
