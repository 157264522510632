import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import FilterInvInsumos from "./Filter";
import { setter } from "../../../redux/slices/informes";
import { useEffect } from "react";

export default function RendicionAnual() {
  const { rendicionAnual } = useSelector((state) => state.informes);

  const columnStorageInsumos = [
    { title: "ID", value: "id", hidden: true },
    { title: "Colaborador", value: "colaboradorInforme" },
    { title: "Enero", value: "enero" },
    { title: "Febrero", value: "febrero" },
    { title: "Marzo", value: "marzo" },
    { title: "Abril", value: "abril" },
    { title: "Mayo", value: "mayo" },
    { title: "Junio", value: "junio" },
    { title: "Julio", value: "julio" },
    { title: "Agosto", value: "agosto" },
    { title: "Septiembre", value: "septiembre" },
    { title: "Octubre", value: "octubre" },
    { title: "Noviembre", value: "noviembre" },
    { title: "Diciembre", value: "diciembre" },
    { title: "Total", value: "total" },
  ];

  // console.log(storageInsumos);
  const [columns] = useDataGrid(columnStorageInsumos, () => {}, "CARGO");

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "rendicionAnual", payload: [] }));
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <FilterInvInsumos />
      <DataGridComponent columns={columns} rows={rendicionAnual} success={true} size="40px" />
    </div>
  );
}
