import { useDispatch, useSelector } from "react-redux";
import FilterBodegaEquipos from "./FilterBodegaEquipos";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { setter } from "../../../redux/slices/informes";

export default function BodegaEquipos() {
  const { storageTeam } = useSelector((state) => state.informes);
  const [dataToRender, setDataToRender] = useState([]);
  const columnStorageTeam = [
    { title: "Tipo de Equipo", value: "tipo_equipo_nombre" },
    { title: "Modelo", value: "modelo" },
    { title: "Version", value: "version_nombre" },
    { title: "Cantidad", value: "cantidad" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    function agruparPorModeloTipoYVersion(array) {
      const agrupado = array.reduce((acc, obj) => {
        const key = `${obj.modelo}_${obj.tipo_equipo_nombre}_${obj.inv_version.descripcion}`;
        if (!acc[key]) {
          acc[key] = { ...obj, cantidad: 1 };
        } else {
          acc[key].cantidad++;
        }
        return acc;
      }, {});

      return Object.values(agrupado);
    }

    const arrayAgrupado = agruparPorModeloTipoYVersion(storageTeam);
    setDataToRender(arrayAgrupado);
  }, [storageTeam]);

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "storageTeam", payload: [] }));
    };
  }, []);

  const [columns] = useDataGrid(columnStorageTeam, () => {}, "CARGO");

  return (
    <div className="flex flex-col gap-2">
      <FilterBodegaEquipos />
      <DataGridComponent columns={columns} rows={dataToRender} success={true} size="40px" />
    </div>
  );
}
