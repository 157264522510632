import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { createModel, createTypesOfDocument, getAllTeamTypesSelect } from "../../redux/slices/inventory";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function TypeOfDocumentModalCreate() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const newModel = async (values) => {
    dispatch(createTypesOfDocument(values));
    handleClose();
  };

  return (
    <div>
      <Chip onClick={() => setOpen(true)} variant="contained" sx={{ textTransform: "none" }}  label="Añadir Tipo de Documento" color="primary"/>
        
      
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newModel)}>
              <div className="flex flex-col">
                <div className="w-full mr-5">
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      id={"nombre"}
                      label={"Nombre"}
                      type={"text"}
                      {...register("nombre", { required: true })}
                      error={Boolean(errors["nombre"])}
                      helperText={Boolean(errors["nombre"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-center mt-5">
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Enviar
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
