import React, { useState } from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { getAllTeams, deleteTeams, getAllStorages } from "../redux/slices/inventory";
import TeamModalCreate from "../components/modal/TeamsModalCreate";
import {
  getAllBrandsSelect,
  getAllProvidersSelect,
  getAllTeamTypesSelect,
  getAllModelsSelect,
  getAllStatusSelect,
} from "../redux/slices/inventory";
import TeamModalEdit from "../components/modal/TeamsModal";
import { Chip } from "@mui/material";
import FiltrosEquipos from "../components/Filtros/FiltrosEquipois";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Tipo de equipo", value: "tipoText" },
  { title: "Modelo", value: "modelo" },
  { title: "Version", value: "versionText" },
  { title: "Serie", value: "serie" },
  { title: "Marca", value: "marcaText" },
  { title: "ID Incorporación", value: "id_incorporacion" },
  { title: "Fecha Incorporación", value: "fecha_incorporacion" },
  { title: "Estado", value: "estadoText" },
  { title: "Valor", value: "valueCost" },
  { title: "Cant. de elemento", value: "cantidad" },
  { title: "Elementos", value: "elementosEstado" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Teams({ elementData, elementSetter }) {
  const { teams, success } = useSelector((state) => state.inventory);
  const { currentUser } = useSelector((state) => state.auth);

  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;

    dispatch(deleteTeams(id));
    setOpen({ status: false, data: null });
  };
  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "EQUIPO", false, setOpen);

  const [equipos, setEquipos] = useState([]);

  useEffect(() => {
    dispatch(getAllTeams());
    dispatch(getAllBrandsSelect());
    dispatch(getAllProvidersSelect());
    dispatch(getAllTeamTypesSelect());
    dispatch(getAllModelsSelect());
    dispatch(getAllStatusSelect());
    dispatch(getAllStorages());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (elementData?.status) {
    const filter = teams.filter((team) =>
      team.inv_accesorios.some(
        (acc) => acc.inv_pieza_equipos.inv_tipo_item_equipo.nombre === elementData.element.elemento
      )
    );
    let filteredNew = filter.map((team) => {
      let findCantidad = team.inv_accesorios.find(
        (acc) => acc.inv_pieza_equipos.inv_tipo_item_equipo.nombre === elementData.element.elemento
      );
      return {
        ...team,
        cantidad: findCantidad.cantidad_actual,
      };
    });

    return (
      <div>
        <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded ">
          <div>
            <div className="flex items-center">
              <h1 className="font-semibold text-[#1E293B] text-2xl">
                {elementData?.isInform ? `Equipos con ${elementData.element.elemento}` : "Elementos"}
              </h1>
              <Chip
                onClick={() => elementSetter({ status: false })}
                label="Volver"
                color="primary"
                size="small"
                sx={{ width: "80px", ml: 2 }}
              />
            </div>
            {!elementData.isInform && <BreadCrumb origin={"Inventario"} current={"Elementos"} />}
          </div>
          <div>
            <TeamModalEdit open={open.status} data={open.data} setOpen={setOpen} />
          </div>
        </div>
        <DataGridComponent columns={columns} rows={filteredNew} success={success} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">{"Equipos"}</h1>
          <BreadCrumb origin={"Inventario"} current={"Equipos"} />
        </div>
        <div>
          <TeamModalEdit open={open.status} data={open.data} setOpen={setOpen} permission={currentUser.rol} />
        </div>
        <div className="flex items-center ">
          <FiltrosEquipos rows={teams} setEquipos={setEquipos} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={equipos} success={success} />
    </div>
  );
}
