import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { Button, TextField, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { createOtTypes } from "../../redux/slices/workOrders";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function OtTypeCreateModal() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const newOtType = async (values) => {
    dispatch(createOtTypes(values));
    handleClose();
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Añadir Tipo de OT"
        color="primary"
      />

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newOtType)}>
              <div className="flex flex-col">
                <div className="w-full mr-5 h-[80px]">
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      id={"descripcion"}
                      label={"Descripcion"}
                      type={"text"}
                      {...register("descripcion", { required: true })}
                      error={Boolean(errors["descripcion"])}
                      helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-center ">
                <Button fullWidth type="submit" variant="contained" sx={{ mt: 2 }}>
                  Enviar
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
