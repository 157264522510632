import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import DataGridComponent from "../../components/tables/DataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NewModal from "../../components/newModal";
import { getAllWorkers } from "../../redux/slices/remuneration";
import { IconButton } from "@mui/material";
import { setOpen } from "../../redux/slices/createModal";
import RemunerationForm from "../../components/RemunerationForm/Remuneration";
import FlakyIcon from "@mui/icons-material/Flaky";
import { VacationList } from "./VacationList";

export default function Remunaration() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { users } = useSelector((state) => state.remuneration);
  const { currentUser } = useSelector((state) => state.auth);

  const cols = [
    { headerName: "ID", field: "id", hide: true },
    { headerName: "Nombre", field: "nombre", flex: 1 },
    { headerName: "RUT", field: "rut", flex: 0.7 },
    { headerName: "Cargo", field: "cargo", flex: 0.7, valueGetter: ({ row }) => row.gen_cargo.descripcion },
    {
      flex: 0.3,
      headerName: "Acciones",
      field: "actions",
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center">
            <IconButton
              sx={{ height: 40 }}
              id={params.id}
              color="primary"
              onClick={() => dispatch(setOpen({ open: true, data: params.row, form: <RemunerationForm /> }))}
            >
              <VisibilityIcon />
            </IconButton>
            {currentUser.approver && (
              <IconButton
                sx={{ height: 40 }}
                id={params.id}
                color="success"
                onClick={() =>
                  dispatch(setOpen({ open: true, data: params.row.VacationRequest, form: <VacationList /> }))
                }
              >
                <FlakyIcon />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllWorkers()).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Colaboradores</h1>
          <BreadCrumb origin={"RRHH"} current={"Colaboradores"} />
        </div>
        <div>
          <NewModal />
        </div>
      </div>
      <DataGridComponent columns={cols} rows={users} success={!loading} />
    </div>
  );
}
