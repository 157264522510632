import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import formatDataModal from "../../../utils/formatDataModal";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  success: false,
};

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContact: (state, action) => {
      state.data = action.payload; // listado de contactos que nos da el backend
      state.success = true;
    },
    addContact: (state, action) => {
      state.data = [...state.data, action.payload]; // una copia del estado, y agrega el payload
      state.success = true;
    },
    setUpdateContact: (state, action) => {
      const updated = state.data.filter((client) => {
        return client.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveContact: (state, action) => {
      const updated = state.data.filter((contact) => {
        return contact.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllContacts = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/contacto");

    const filtrado = res.data.filter((contacto) => contacto.estado === true);
    const contacts = filtrado.map((contact) => ({
      ...contact,
      cargo: contact.gen_cargo?.descripcion,
      cliente: contact.cliente_contacto_clienteTocliente?.nombre,
      medio_contacto: contact.gen_medio_contacto?.descripcion,
      pais: res.data.paises?.descripcion,
    }));
    dispatch(setContact(contacts));
  } catch (error) {
    console.log(error);
  }
};

export const createNewContact = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    // const res = await axiosInstance.post("/contacto/register", formatedValues);
    const res = await axiosInstance.post("/contacto/register", data);
    const contact = {
      ...res.data,
      cargo: res.data.gen_cargo?.descripcion,
      cliente: res.data.cliente_contacto_clienteTocliente?.nombre,
      medio_contacto: res.data.gen_medio_contacto?.descripcion,
      pais: res.data.paises?.descripcion,
    };
    dispatch(addContact(contact));
    dispatch(
      setNotification({
        status: "success",
        message: "Contacto creado con éxito",
        open: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear contacto",
        open: true,
      })
    );
    return error;
  }
};

export const updateContact = (data) => async (dispatch) => {
  // const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    // const res = await axiosInstance.put("/contacto/update", formatedValues);
    const res = await axiosInstance.put("/contacto/update", data);

    const contact = {
      ...res.data,
      cargo: res.data.gen_cargo?.descripcion,
      cliente: res.data.cliente_contacto_clienteTocliente?.nombre,
      medio_contacto: res.data.gen_medio_contacto?.descripcion,
      pais: res.data.paises?.descripcion,
    };

    dispatch(setUpdateContact(contact));
    dispatch(
      setNotification({
        status: "success",
        message: "Contacto editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar contacto",
        open: true,
      })
    );
    return console.log(error);
  }
};

export const deleteContact = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/contacto/${id}`);
    dispatch(setRemoveContact(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Contacto eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar contacto",
        open: true,
      })
    );
  }
};

export const { setContact, addContact, setUpdateContact, setRemoveContact, setSuccess } = contactSlice.actions;

export default contactSlice.reducer;
