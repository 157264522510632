import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ValidationDialog = ({ open, setOpen, type }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Todos los campos son requeridos"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Por favor, tenga en cuenta que todos los campos deben ser completados antes de enviar su rendición. Para
          asegurar una presentación adecuada, se requiere la siguiente información
        </DialogContentText>
        {type === "create" && (
          <div className="flex flex-col ">
            <span className="mt-1 bg-[#3b7abd31]  px-2 rounded">- Detalle de rendición </span>
            <span className="bg-[#3b7abd31]  px-2 mt-1 rounded">- Al menos un gasto registrado</span>
            <span className="bg-[#3b7abd31] px-2  mt-1 rounded">- Ambas firmas</span>
          </div>
        )}
        {type === "vb" && (
          <div className="flex flex-col ">
            <span className="mt-1 bg-[#3b7abd31]  px-2 rounded">- Firma Administración</span>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>
          Entendido
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationDialog;
