import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import SignaturePad from "react-signature-canvas";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "400px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const Signature = ({ type, stateCb, disabled, rut, equipment }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const signature = useRef({});

  const trim = () => {
    if (type) {
      dispatch(
        stateCb({
          base64: signature.current.getTrimmedCanvas().toDataURL("image/png"),
          file: dataURLtoFile(signature.current.getTrimmedCanvas().toDataURL("image/png"), type),
        }),
      );
    } else {
      stateCb({
        rut,
        equipment,
        base64: signature.current.getTrimmedCanvas().toDataURL("image/png"),
        file: dataURLtoFile(signature.current.getTrimmedCanvas().toDataURL("image/png"), type),
      });
    }
    setOpen(false);
  };

  const clear = () => {
    signature.current.clear();
  };

  return (
    <div>
      {!!rut ? (
        <Button disabled={disabled} variant="text" color="inherit" onClick={handleOpen}>
          Firmar
        </Button>
      ) : (
        <IconButton
          disabled={type === "cliente" ? disabled.client : type === "tecnico" && disabled.technical}
          variant="contained"
          onClick={handleOpen}
          size="small"
        >
          <BorderColorIcon sx={{ fontSize: 18 }} />
        </IconButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="h-full w-full relative">
            <p className="pointer-events-none selection:bg-none left-2 top-1 absolute text-slate-500">Firma aqui</p>
            <SignaturePad canvasProps={{ className: "w-full h-full rounded" }} ref={signature} />
            <div className="absolute left-3 bottom-2">
              <Button color="success" sx={{ textTransform: "none", p: 0.4 }} variant="contained" onClick={trim}>
                Firmar
              </Button>
            </div>
            <div className="absolute left-20 bottom-2">
              <IconButton sx={{ textTransform: "none", p: 0.4 }} variant="contained" onClick={clear}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Signature;
