import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Chip, Autocomplete } from "@mui/material";
import { useForm } from "react-hook-form";
import { createPieza } from "../../redux/slices/inventory";
import { useEffect, useState } from "react";
import textFieldSelectFormat from "../../utils/textFieldSelectFormat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function PiezasModalCreate() {
  const [open, setOpen] = useState(false);
  const [models, setModales] = useState([]);
  const [versionsByModel, setVersionsByModel] = React.useState([]);
  const [elements, setElements] = React.useState([]);
  const [handledVersion, setHandledVersion] = React.useState({ models: false, versions: false });
  const [equipoSelect, setEquipoSelect] = useState(null);
  const [modelSelect, setModelSelect] = useState(null);
  const [versionSelect, setVersionSelect] = useState(null);
  const [elementSelect, setElementSelect] = useState(null);

  const { teamTypes } = useSelector((state) => state.inventory);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
    setModales([]);
    setEquipoSelect(null);
    setModelSelect(null);
    setVersionSelect(null);
    setVersionsByModel([]);
    setElementSelect(null);
    setElements([]);
    setHandledVersion({ models: false, versions: false });
  };

  const newModel = async (values) => {
    values.inv_tipo_equipo = equipoSelect.id;
    values.inv_modelo = modelSelect.id;
    values.inv_item = elementSelect.id;
    values.version = versionSelect.id;
    dispatch(createPieza(values));
    handleClose();
  };

  useEffect(() => {
    const modelsToRender = [];
    equipoSelect?.modelo?.forEach((el) =>
      modelsToRender.push({
        id: el.id,
        label: el.nombre,
        version: el.inv_version,
        elementos: el.inv_tipo_item_equipo,
      })
    );
    setModales(modelsToRender);
    setModelSelect({ id: 0, label: "" });
    setElementSelect({ id: 0, label: "" });
    setVersionSelect({ id: 0, label: "" });

    setHandledVersion({ models: true });
    setVersionsByModel([]);
    setElements([]);
  }, [equipoSelect]);

  useEffect(() => {
    const versionToRender = [];
    const elementosToRender = [];
    modelSelect?.version?.forEach((el) =>
      versionToRender.push({
        id: el.id,
        label: el.descripcion,
      })
    );
    modelSelect?.elementos?.forEach((el) =>
      elementosToRender.push({
        id: el.id,
        label: el.nombre,
      })
    );
    setElementSelect({ id: 0, label: "" });
    setVersionSelect({ id: 0, label: "" });
    setVersionsByModel(versionToRender);
    setElements(elementosToRender);

    setHandledVersion({ models: true });
  }, [modelSelect]);

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Agregar"
        color="primary"
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newModel)}>
              <div className="flex flex-col">
                <div className="w-full mb-2">
                  <Autocomplete
                    value={equipoSelect || { id: 0, label: "" }}
                    onChange={(option, value) => setEquipoSelect(value)}
                    options={textFieldSelectFormat(teamTypes, "descripcion")}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="EQUIPOS"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full mb-2">
                  <Autocomplete
                    value={modelSelect || { id: 0, label: "" }}
                    onChange={(option, value) => setModelSelect(value)}
                    options={models ? models : []}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="MODELO"
                        disabled={!models ? true : false}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full mb-2">
                  <Autocomplete
                    value={versionSelect || { id: 0, label: "" }}
                    onChange={(option, value) => setVersionSelect(value)}
                    options={versionsByModel ? versionsByModel : []}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="VERSION"
                        disabled={!models ? true : false}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full mb-2">
                  <Autocomplete
                    value={elementSelect || { id: 0, label: "" }}
                    onChange={(option, value) => setElementSelect(value)}
                    options={elements ? elements : []}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ELEMENTOS"
                        disabled={!models ? true : false}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="w-full">
                  <TextField
                    id="cantidad"
                    label="Cantidad"
                    type="text"
                    fullWidth
                    {...register("cantidad", { required: true, pattern: /^[0-9]*$/ })}
                    helperText={
                      errors?.cantidad?.type === "required"
                        ? "El campo es requerido"
                        : errors?.cantidad?.type === "pattern"
                        ? "Es un campo numerico"
                        : " "
                    }
                    error={Boolean(errors["cantidad"])}
                  />
                </div>
              </div>
              <div className="flex items-center w-full justify-center mt-5">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={!equipoSelect || !modelSelect || !versionSelect || !elementSelect}
                >
                  Enviar
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
