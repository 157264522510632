import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { setError } from "../../redux/slices/roles";
import { useDispatch } from "react-redux";

export default function DeleteModal({ handleDelete, params, name, custom }) {
  const { error } = useSelector((state) => state.roles);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "auto",
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    py: 1,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(setError(false));
  };

  return (
    <div>
      <DeleteOutlineOutlinedIcon onClick={handleOpen} sx={{ color: "#f44336" }} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "red", textAlign: "center", fontSize: 25 }}
          >
            ELIMINAR {name || ""}
          </Typography>
          {params.field === "acciones_sin_facturar_dos" && params.row.ot.ot_usuarios.length ? (
            <div className="flex justify-center">
              <Alert severity="error" sx={{ width: "380px" }}>
                Esta OT esta en proceso
              </Alert>
            </div>
          ) : null}
          <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
            {custom
              ? custom
              : `¿Estas seguro que deseas eliminar ${
                  params.row?.nombre ? params.row.nombre : params.row?.descripcion ? params.row?.descripcion : ""
                }?`}
          </Typography>
          <div className="flex justify-center mt-8">
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              onClick={() => {
                handleDelete(params);
                setOpen(false);
              }}
            >
              ELIMINAR
            </Button>
            <Button variant="contained" onClick={handleClose}>
              CANCELAR
            </Button>
          </div>
          {error ? (
            <Alert sx={{ marginTop: "10px" }} severity="error">
              Hay usuarios asignados a ese rol!
            </Alert>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </div>
  );
}
