import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  banks: [],
};

const banks = createSlice({
  name: "Currency",
  initialState,
  reducers: {
    setBanks: (state, action) => {
      state.banks = action.payload;
      state.status = "succeeded";
    },
  },
});

export const getBanks = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/banks");
    dispatch(setBanks(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const { setBanks } = banks.actions;

export default banks.reducer;
