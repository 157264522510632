import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import Chip from "@mui/material/Chip";
import { MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const columns_rp = [
  { title: "Código Loginteg" },
  { title: "Nombre" },
  { title: "N° Modelo" },
  { title: "Serie" },
  { title: "Es Generico" },
  { title: "Cantidad disponible" },
  { title: "Cantidad deseada" },
  { title: "Acciones" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80vh",
  width: {
    xs: "90%", // 0
    sm: "90%", // 600
    md: "90%", // 900
    lg: "80%", // 1200
    xl: "70%", // 1536
  },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 1,
  "@media (min-width: 768px)": {
    p: 2,
  },
};

const columns = [
  { title: "ID", value: "id" },
  { title: "Nombre", value: "descripcion" },
  { title: "Modelo", value: "modelo" },
  { title: "Serie", value: "serie" },
  { title: "Es generico", value: "generico" },
  { title: "Cantidad Disponible", value: "cantidadBase" },
  { title: "Cantidad Deseada", value: "cantidadNew" },
  { title: "Acciones", value: "acciones" },
];

export default function RepairPartOT({ cantidades, setCantidades, idTeam, repuestos, setUpdateValueCost }) {
  const [open, setOpen] = React.useState(false);
  const [repairParts, setRepairParts] = React.useState(repuestos);
  const [models, setModels] = React.useState([]);
  const [repuestosName, setRepuestosName] = React.useState("");
  const [selectedModel, setSelectedModel] = React.useState("");
  const [selectedGeneric, setSelectedGeneric] = React.useState("");

  React.useEffect(() => {
    const modelsName = repuestos.map((repairPart) => repairPart.modelo);

    setModels(modelsName.filter((model, index) => modelsName.indexOf(model) === index));
  }, [repuestos]);

  const handleClose = () => {
    setOpen(false);
    setRepairParts(repuestos);
    setSelectedModel("");
    setSelectedGeneric("");
    setRepuestosName("");
  };

  React.useEffect(() => {
    setRepairParts((prev) => [...prev].sort((a, b) => cantidades[b.id][idTeam] - cantidades[a.id][idTeam]));
  }, [open]);

  const handleFilter = () => {
    setRepairParts(
      repuestos.filter((data) => {
        const dataGeneric = data.generico ? "SI" : "NO";
        return (
          (!selectedModel.length || data.modelo === selectedModel) &&
          (!selectedGeneric.length || dataGeneric === selectedGeneric) &&
          (!repuestosName ||
            data.descripcion.toLowerCase().includes(repuestosName.toLowerCase()) ||
            data.codigo_loginteg?.toLowerCase().includes(repuestosName.toLowerCase()))
        );
      })
    );
  };

  const handleReset = () => {
    setRepairParts(repuestos);
    setSelectedModel("");
    setSelectedGeneric("");
    setRepuestosName("");
  };

  const handleSumPart = (id) => {
    if (cantidades[id].cantidad_total > 0) {
      cantidades[id][idTeam] += 1;
      cantidades[id].cantidad_total -= 1;
      cantidades[id].touched = true;
      setCantidades({ ...cantidades });
      setUpdateValueCost((prev) => !prev);
    }
  };

  const handleRestPart = (id) => {
    if (cantidades[id][idTeam] > 0) {
      cantidades[id][idTeam] -= 1;
      cantidades[id].cantidad_total += 1;
      cantidades[id].touched = true;
      setCantidades({ ...cantidades });
      setUpdateValueCost((prev) => !prev);
    }
  };

  return (
    <div className="w-full">
      <Button
        type="button"
        color="success"
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
        fullWidth
        sx={{ width: "150px" }}
      >
        Asignar repuesto
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="h-full relative flex flex-col items-center">
              <div className="mb-3 text-lg font-semibold">Repuestos Equipo</div>
              <div className="w-full flex mb-2 flex-col lg:flex-row justify-center items-center gap-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Nombre o Código"
                  InputProps={{ style: { fontSize: "14px" } }}
                  onChange={(e) => setRepuestosName(e.target.value)}
                  value={repuestosName}
                />
                <TextField
                  size="small"
                  InputProps={{ style: { fontSize: "14px" } }}
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                  label="Seleccionar Modelo"
                  select
                  fullWidth
                >
                  {models.length &&
                    models.map((model, index) => (
                      <MenuItem key={index} id={index} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  value={selectedGeneric}
                  InputProps={{ style: { fontSize: "14px" } }}
                  onChange={(e) => setSelectedGeneric(e.target.value)}
                  size="small"
                  fullWidth
                  label="Es generico"
                  select
                >
                  <MenuItem value={"SI"}>SI</MenuItem>
                  <MenuItem value={"NO"}>NO</MenuItem>
                </TextField>
                <div className="flex w-full">
                  <Button onClick={handleFilter} variant="contained" size="small" fullWidth sx={{ mr: 1 }}>
                    Filtrar
                  </Button>
                  <Button onClick={handleReset} color="error" variant="outlined" size="small" fullWidth>
                    Borrar Filtros
                  </Button>
                </div>
              </div>
              <div className="flex flex-col w-full overflow-auto  h-[88%] items-center">
                <div className="w-full h-auto max-md:hidden">
                  <TableContainer sx={{ position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          {columns_rp.map((column, idx) => (
                            <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                              {column.title}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {repairParts?.map((row) => {
                          return (
                            <TableRow className="capitalize" key={row.id}>
                              <TableCell size="small" align="center">
                                {row.codigo_loginteg || "S/D"}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {row.descripcion}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {row.modelo}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {row.serie}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {row.generico ? "Si" : "No"}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {cantidades[row.id]?.cantidad_total}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {cantidades[row.id][idTeam]}
                              </TableCell>
                              <TableCell size="small" align="center">
                                <div className="flex">
                                  <Button
                                    sx={{ mr: 1 }}
                                    onClick={() => handleSumPart(row.id)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  >
                                    Agregar
                                  </Button>
                                  <Button
                                    color="error"
                                    onClick={() => handleRestPart(row.id)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  >
                                    Eliminar
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="w-full h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:hidden">
                  {repairParts?.map((row) => {
                    return (
                      <div key={row.id} className="bg-slate-200 rounded p-2 h-auto my-3 mr-2">
                        <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                          <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                          <label className="text-sm">{row.codigo_loginteg || "S/D"}</label>
                        </div>
                        <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                          <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                          <label className="text-sm">{capitalizeFirstLetter(row.descripcion)}</label>
                        </div>
                        <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                          <label className="tracking-wider text-sm font-semibold">N° Modelo:</label>
                          <label className="text-sm">{capitalizeFirstLetter(row.modelo)}</label>
                        </div>
                        <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                          <label className="tracking-wider text-sm font-semibold">Serie:</label>
                          <label className="text-sm">{capitalizeFirstLetter(row.serie)}</label>
                        </div>
                        <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                          <label className="tracking-wider text-sm font-semibold">Es generico:</label>
                          <label className="text-sm">{row.generico ? "Si" : "No"}</label>
                        </div>
                        <div className="flex justify-between p-1 bg-blue-100 rounded mb-2">
                          <label className="tracking-wider  text-sm font-semibold">Cantidad disponible:</label>
                          <label className="text-sm text-blue-800 font-semibold">
                            {cantidades[row.id]?.cantidad_total}
                          </label>
                        </div>
                        <div className="flex justify-between p-1 bg-green-100 rounded mb-2">
                          <label className="tracking-wider text-sm font-semibold">Cantidad deseada:</label>
                          <label className="text-sm text-green-800 font-semibold">{cantidades[row.id][idTeam]}</label>
                        </div>
                        <div className="flex">
                          <Button
                            sx={{ mr: 1 }}
                            onClick={() => handleSumPart(row.id)}
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            Agregar
                          </Button>
                          <Button
                            color="error"
                            onClick={() => handleRestPart(row.id)}
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            Eliminar
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Button
                className=""
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 2 }}
                onClick={(e) => handleClose()}
              >
                Aplicar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
