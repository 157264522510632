import * as React from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import dayjs from "dayjs";

export default function DataPicker({ onChange, action, fecha, size = "medium", toHandler, disabled = false }) {
  const [value, setValue] = React.useState(action === "edit" && fecha ? dayjs(fecha.slice(0, -2)) : null);

  if (action === "edit") {
    onChange(value);
  }

  const handleChange = (value) => {
    setValue(value);
    onChange(value, toHandler);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          sx={{ width: "100%" }}
          value={value}
          onChange={handleChange}
          format={"DD/MM/YYYY"}
          disablePast={disabled}
          slotProps={{ textField: { size: size } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
