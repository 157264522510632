import { Button, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getBodegaSelect, getStorageInsumosFilted } from "../../../../redux/slices/informes";
import { useEffect } from "react";

export default function FilterInvInsumos() {
  const { bodegasSelect } = useSelector((state) => state.informes);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([dispatch(getBodegaSelect())]);
  }, []);

  const submitData = (values) => {
    dispatch(
      getStorageInsumosFilted({
        bodegaId: values.bodegaId,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submitData)} className="flex gap-4">
      <Controller
        id="bodegaId"
        name="bodegaId"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Bodegas"
            error={errors["bodegaId"]}
            placeholder=""
            sx={{
              "& .MuiFormHelperText-root": {
                marginLeft: 0,
              },
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {bodegasSelect?.map(
              (option) =>
                option.id && (
                  <MenuItem key={option.id} dense divider value={option.id}>
                    {option.name}
                  </MenuItem>
                )
            )}
          </TextField>
        )}
      />
      <Button variant="outlined" type="submit">
        Filtrar
      </Button>
    </form>
  );
}
