import React from "react";
import DataGridComponent from "../../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../../hooks/useDataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { getAllFacturacionesByState, setFacturacion } from "../../redux/slices/facturacion";
import {
  getAllCobranzasByState,
  registrarPago,
  setCobranzasFalse,
  setCobranzasFiltered,
} from "../../redux/slices/cobranzas";
import TableInfoFacturacion from "../../components/TableInfoFacturacion/TableInfoFacturacion";
import { CircularProgress } from "@mui/material";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "N° Factura", value: "n_factura", width: 100, maxWidth: 100 },
  { title: "Nombre Cliente", value: "nombre_cliente", width: 350 },
  { title: "N° OC.", value: "nro_oc", width: 200 },
  { title: "N° OC. Privada", value: "nro_oc_privada", width: 200 },
  { title: "F. Facturacion", value: "f_facturacion", width: 120, maxWidth: 120 },
  { title: "F. Vencimiento", value: "fecha_vencimiento", width: 120, maxWidth: 120 },
  { title: "F. Compromiso", value: "fecha_compromiso", width: 120, maxWidth: 120 },
  { title: "Total", value: "monto", width: 150, maxWidth: 150 },
  { title: "Historial", value: "historial_cobranza", width: 80, maxWidth: 80 },
  { title: "Comp. de Pago", value: "compromiso" },
  { title: "Registrar Pago", value: "registrar_pago" },
  { title: "Nota de Crédito", value: "registrar_nota_credito" },
];

export default function Cobranza() {
  const { cobranzasFiltered, cobranzasFalse, success } = useSelector((state) => state.cobranzas);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const formularioToSend = {
      id: params.row.id,
      responsable: currentUser.id,
      f_compromiso: params.row.f_compromiso,
      observaciones: "Se registro pago realizado con éxito",
    };
    dispatch(registrarPago(formularioToSend));
    setOpen(false);
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Marcas", false, setOpen);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCobranzasByState(false)).then(() => setLoading(false));

    return () => {
      dispatch(setCobranzasFalse([]));
    };
  }, [dispatch]);

  return (
    <div>
      <div className="flex items-center max-lg:flex-col bg-[#2b2c84]/20 pr-5 pl-5 pt-5 pb-3 mb-4">
        <div className="w-[20%] max-lg:w-[100%] max-lg:mb-2 max-md:mb-0">
          <h1 className="font-semibold text-[#1E293B] text-2xl">Cobranza</h1>
          <BreadCrumb origin={"Cobranzas"} current={"Cobranza"} />
        </div>
        <div className="flex justify-center w-[80%] max-lg:w-[100%]">
          <TableInfoFacturacion totales={cobranzasFalse} />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <DataGridComponent columns={columns} rows={cobranzasFiltered} success={success} size={"36px"} />
        )}
      </div>
    </div>
  );
}
