export const dataColumns = [
  { value: "rendicion_id", title: "N° Rendición", minWidth: 170 },
  { value: "title", title: "Título", minWidth: 170 },
  { value: "solicitante", title: "Solicitante", minWidth: 170 },
  { value: "fecha_formatted", title: "Fecha Rendición", minWidth: 170 },
  { value: "moneda_text", title: "Tipo de moneda", minWidth: 170 },
  { value: "total", title: "Total acumulado", minWidth: 170 },
  { value: "estado_text", title: "Estado", minWidth: 170 },
  { value: "fecha_aprobacion", title: "Fecha aprobación", minWidth: 170 },
  { value: "fecha_pago", title: "Fecha de pago", minWidth: 170 },
  { value: "pending_actions", title: "Acciones", minWidth: 100 },
];

export const dataColumnsList = [
  { value: "rendicion_id", title: "N° Rendición", minWidth: 170 },
  { value: "title", title: "Título", minWidth: 170 },
  { value: "solicitante", title: "Solicitante", minWidth: 170 },
  { value: "tipo_rendicion", title: "Tipo", minWidth: 170 },
  { value: "fecha_formatted", title: "Fecha Rendición", minWidth: 170 },
  { value: "moneda_text", title: "Tipo de moneda", minWidth: 170 },
  { value: "total", title: "Total acumulado", minWidth: 170 },
  { value: "estado_text", title: "Estado", minWidth: 170 },
  { value: "fecha_aprobacion", title: "Fecha aprobación", minWidth: 170 },
  { value: "fecha_pago", title: "Fecha de pago", minWidth: 170 },
  { value: "pagado_por", title: "Pagado por", minWidth: 170 },
  { value: "pending_actions", title: "Acciones", minWidth: 100 },
];

export const dataColumnsVB = [
  { value: "rendicion_id", title: "N° Rendición", minWidth: 170 },
  { value: "title", title: "Título", minWidth: 170 },
  { value: "solicitante", title: "Solicitante", minWidth: 170 },
  { value: "tipo_rendicion", title: "Tipo", minWidth: 170 },
  { value: "fecha_formatted", title: "Fecha Rendición", minWidth: 170 },
  { value: "moneda_text", title: "Tipo de moneda", minWidth: 170 },
  { value: "total", title: "Total acumulado", minWidth: 170 },
  { value: "estado_text", title: "Estado", minWidth: 170 },
  { value: "vb_actions", title: "Acciones", minWidth: 100 },
];
