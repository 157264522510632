import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logo from "../assets/loginteg.svg";
import background from "../assets/login-bg.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { Alert, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccesToken } from "../redux/slices/auth";
import signUser from "../utils/signUser";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ResetPasswordModal from "../components/modal/resetPasswordModal";
import AlertCustom from "../components/Alert/Alert";

export default function Login() {
  const { accessToken } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [typed, setTyped] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [responseState, setResponseState] = useState({
    success: false,
    error: false,
    message: "",
  });

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, navigate]);

  const loginFields = [
    {
      id: "email",
      label: "Email o Usuario",
      type: "text",
      value: email,
      required: true,
      error: Boolean(errors.email),
      helper: errors.email,
    },
    {
      id: "password",
      label: "Contraseña",
      type: showPassword ? "text" : "password",
      required: true,
      error: Boolean(errors.password),
      helper: errors.password,
      value: password,
      endAdornment: (
        <IconButton onClick={() => setShowPassword((show) => !show)}>
          {showPassword ? <VisibilityOffIcon color="action" /> : <VisibilityIcon color="action" />}
        </IconButton>
      ),
    },
  ];

  const handleChange = (e) => {
    if (e.target.id === "password") {
      setPassword(e.target.value);
      setTyped({ password: true });
    }
    if (e.target.id === "email") {
      setEmail(e.target.value);
      setTyped({ email: true });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (errors.email || errors.password) {
      return;
    }

    setLoading(true);
    const response = await signUser({ email, password });

    if (response.error) {
      setResponseState(response);
      setLoading(false);
      return;
    }

    setLoading(false);
    dispatch(setAccesToken(response));
    navigate("/");
  };

  const handleBlur = (e) => {
    if (e.target.id === "email") {
      const emailRegex = /^[\w.%+-]+@loginteg\.cl$/;

      if (typed.email && !email.length) {
        setErrors({
          ...errors,
          email: "El email es requerido",
        });
        // } else if (typed.email && !email.match(emailRegex)) {
        //   setErrors({
        //     ...errors,
        //     email: "El email no es valido",
        //   });
      } else if (typed.email) {
        setErrors({
          ...errors,
          email: null,
        });
      }
    } else {
      if (typed.password && !password.length) {
        setErrors({
          ...errors,
          password: "La contraseña es requerida",
        });
      } else if (typed.password) {
        setErrors({
          ...errors,
          password: null,
        });
      }
    }
  };

  return (
    <div className="w-full h-screen flex absolute justify-center items-center font-inter">
      <div className="w-full h-full flex justify-center items-center">
        <div className="lg:mx-24 sm:min-w-[320px]">
          <div className="relative w-[150px] -ml-2">
            <img className="object-contain select-none" src={logo} alt="logo" />
          </div>
          <h1 className="text-4xl font-extrabold tracking-tight text-[#1E293B] leading-tight text-start mb-8 mt-4">
            Iniciar sesión
          </h1>
          <form onSubmit={onSubmit}>
            {loginFields &&
              loginFields.map((field) => (
                <div key={field.id} className="my-5">
                  <TextField
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    error={field.error}
                    fullWidth
                    helperText={field.helper}
                    value={field?.value}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: field.endAdornment,
                    }}
                  />
                </div>
              ))}
            <div className="w-full flex justify-start items-center pb-2">
              <p onClick={() => setOpen(true)} className="text-sm text-[#2B2C84] cursor-pointer">
                Olvidaste tu contraseña?
              </p>
            </div>
            <Button
              size="large"
              type="submit"
              style={{
                textTransform: "none",
                margin: "15px 0",
                borderRadius: 24,
                fontWeight: "600",
                padding: 12,
                letterSpacing: 1,
              }}
              variant="contained"
              fullWidth
              disabled={(loading && true) || Boolean(errors.email) || Boolean(errors.password)}
            >
              {loading ? <CircularProgress color="inherit" size={25} /> : "Ingresar"}
            </Button>
            {responseState?.error && <Alert severity="error">{responseState.message}</Alert>}
          </form>
        </div>
      </div>
      <div className="w-full h-screen hidden lg:flex justify-center items-center relative overflow-hidden">
        <img src={background} className="w-full h-full object-cover	pointer-events-none select-none" alt="background" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4">
          <div className="text-5xl font-bold text-gray-100 text-start ">
            <span className="font-light">Entregamos</span> confianza. <br />{" "}
            <span className="font-light">Ofrecemos</span> prestigio.
          </div>
          <div className="mt-6 text-lg tracking-tight leading-7 text-gray-400 text-start ">
            Buscamos, desarrollamos y utilizamos la mejor tecnología a nivel internacional, para adecuarla a las
            necesidades de la población y del país, lo que es un sello de nuestro profesionalismo y calidad.
          </div>
        </div>
      </div>
      <ResetPasswordModal open={open} setOpen={setOpen} setAlert={setSuccessAlert} />
      <AlertCustom open={successAlert} setOpen={setSuccessAlert} />
    </div>
  );
}
