import * as Yup from "yup";

const contactsSchema = Yup.object().shape({
  nombre: Yup.string().required("Nombre del contacto es requerida"),

  cargo: Yup.object()
    .shape({
      label: Yup.string().required("Cargo es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Cargo es requerido"),

  telefono1: Yup.string()
    .max(17, "Máximo 17 caracteres")
    .test("validacion-telefono1", "Por favor ingrese solo números", function (value) {
      if (!value) {
        return true; // Permitimos campos vacíos
      }
      const regex = /^[0-9]+$/;
      return regex.test(value);
    }),
  telefono2: Yup.string()
    .max(17, "Máximo 17 caracteres")
    .test("validacion-telefono2", "Por favor ingrese solo números", function (value) {
      if (!value) {
        return true; // Permitimos campos vacíos
      }
      const regex = /^[0-9]+$/;
      return regex.test(value);
    }),

  correo1: Yup.string()
    // .required("Correo institucional requerido")
    // .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Ingresar un correo valido"),  correo1: Yup.string()
    .test("validar-correo1", "Ingresar un correo valido", function (value) {
      if (!value) {
        return true;
      } else if (value) {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
      }
    }),

  correo2: Yup.string()
    // .required("Correo institucional requerido")
    .test("validar-correo2", "Ingresar un correo válido", function (value) {
      const correo1 = this.parent.correo1;
      if (!value) {
        return true;
      } else if (value) {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
      }
    }),

  principal: Yup.object()
    .shape({
      label: Yup.string().required("Principal es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Principal es requerido"),

  cliente: Yup.object()
    .shape({
      label: Yup.string().required("Cliente es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Cliente es requerido"),

  medio_contacto: Yup.object()
    .shape({
      label: Yup.string().required("Medio de contacto es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Medio de contacto es requerido"),
});

export default contactsSchema;
