import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const RejectDialog = ({ open, setOpen, id, detailState }) => {
  const [commentary, setCommentary] = useState("");
  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleSubmitReject = () => {
    setSubmited(true);

    if (!commentary.length) {
      return;
    }

    setLoading(true);
    const rejected = axiosInstance
      .put(`/fund-accounting/reject/${id}`, { commentary, detailState })
      .then((data) => {
        navigate("/rendicion-de-fondos/vb");
        setError(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"VB - Rechazar"}</DialogTitle>
      <DialogContent sx={{ width: 400, height: "auto" }}>
        <div>
          <TextField
            fullWidth
            placeholder="Un breve comentario explicando las razones por las cuales considera que la rendición no cumple con los requisitos establecidos."
            multiline
            rows={4}
            maxRows={4}
            value={commentary}
            onChange={(event) => setCommentary(event.target.value)}
            error={!commentary.length && submited}
            helperText={!commentary.length && submited ? "El campo es requerido" : " "}
          />
          <div className="w-full flex justify-between">
            {error ? (
              <div className="text-red-500 p-1 bg-red-100 rounded">
                <strong>!Error </strong> al rechazar la rendición
              </div>
            ) : (
              <div></div>
            )}
            <Button
              sx={{ width: 100, maxWidth: 100, alignSelf: "flex-end" }}
              color="error"
              variant="outlined"
              onClick={handleSubmitReject}
              autoFocus
            >
              {loading ? <CircularProgress size={"24px"} color="error" /> : "Rechazar"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RejectDialog;
