import { Button, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

export default function CustomSelectMonthYear({ handleSubmitFunction }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const meses = [
    { id: 1, name: "ENERO" },
    { id: 2, name: "FEBRERO" },
    { id: 3, name: "MARZO" },
    { id: 4, name: "ABRIL" },
    { id: 5, name: "MAYO" },
    { id: 6, name: "JUNIO" },
    { id: 7, name: "JULIO" },
    { id: 8, name: "AGOSTO" },
    { id: 9, name: "SEPTIEMBRE" },
    { id: 10, name: "OCTUBRE" },
    { id: 11, name: "NOVIEMBRE" },
    { id: 12, name: "DICIEMBRE" },
  ];

  const años = [
    { id: 2021, name: "2021" },
    { id: 2022, name: "2022" },
    { id: 2023, name: "2023" },
    { id: 2024, name: "2024" },
  ];

  const submit = (values) => {
    handleSubmitFunction(values);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="flex w-[100%] gap-2">
      <div className="w-full">
        <Controller
          id="month"
          name="month"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              select
              size="small"
              onChange={onChange}
              value={value || ""}
              label="MES"
              error={errors["month"]}
              placeholder=""
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                },
              }}
            >
              {meses?.map(
                (option) =>
                  option.id && (
                    <MenuItem key={option.id} dense divider value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
              )}
            </TextField>
          )}
        />
      </div>
      <div className="w-full">
        <Controller
          id="year"
          name="year"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              select
              size="small"
              onChange={onChange}
              value={value || ""}
              label="AÑO"
              error={errors["year"]}
              placeholder=""
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                },
              }}
            >
              {años?.map(
                (option) =>
                  option.id && (
                    <MenuItem key={option.id} dense divider value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
              )}
            </TextField>
          )}
        />
      </div>
      <Button variant="outlined" type="submit">
        BUSCAR
      </Button>
    </form>
  );
}
