// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { endOt, getAllWorkOrders } from "../redux/slices/workOrders";
// import dayjs from "dayjs";
// import Chip from "@mui/material/Chip";
// import TextField from "@mui/material/TextField";
// import { getAllUsers } from "../redux/slices/users";
// import MenuItem from "@mui/material/MenuItem";
// import Button from "@mui/material/Button";
// import { assignWorkOrderToTechnical } from "../redux/slices/workOrders";
// import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
// import LinearProgress from "@mui/material/LinearProgress";
// import { getOTByClientID } from "../redux/slices/workOrders";
// import { setOpen } from "../redux/slices/editModal";
// import HistoryModal from "../components/modal/HistoryModal";
// import dateFormatter from "../utils/dateFormatter";

// const OtsAssignedAndWithoutAssign = () => {
//   const [expanded, setExpanded] = useState(false);
//   const { data, success } = useSelector((state) => state.workOrders);
//   const { data: users } = useSelector((state) => state.users);
//   const [technical, setTechnical] = useState(users?.filter((user) => user.rol === "Técnico"));
//   const [selectedTechnical, setSelectedTechnical] = useState();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllWorkOrders());

//     if (!users.length) {
//       dispatch(getAllUsers());
//     }

//     if (!technical.length) {
//       let techincalToSelect = users.filter((user) => user.executeOt === true);
//       setTechnical(techincalToSelect);
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [data.length, users, dispatch]);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   const handleTechChange = (e) => {
//     setSelectedTechnical(e.target.value);
//   };

//   const handleSubmit = (e, ot_id) => {
//     e.preventDefault();
//     dispatch(assignWorkOrderToTechnical({ ot_id, technical_id: selectedTechnical }));
//   };

//   const handleCloseOt = (ot_id) => {
//     dispatch(endOt({ id: ot_id }));
//   };

//   const handleHistory = (ot_id) => {
//     dispatch(getOTByClientID(ot_id));
//     dispatch(setOpen({ open: true, id: ot_id }));
//   };

//   return (
//     <div>
//       <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
//         <div>
//           <h1 className="font-semibold text-[#1E293B] text-2xl">Listado de OT pendientes</h1>
//           <BreadCrumb origin={"Servicio tecnico"} current={"OT pendientes"} />
//         </div>
//         <HistoryModal />
//       </div>
//       <div className=" calculated-height-execute overflow-y-auto mt-5 p-2 rounded border-container">
//         {data.length ? (
//           [...data]
//             .sort((a, b) => (a.tecnico === null ? -1 : b.tecnico === null ? 1 : 0))
//             .filter((order) => !order.finalizada && !order.cerrada && (!order.finalizada || !order.cerrada))
//             .map((order) => {
//               const typesCount = {};

//               order?.ot_equipo_ot_equipo_otToot?.forEach((type) => {
//                 if (typesCount.hasOwnProperty(type.inv_equipo.inv_tipo_equipo.descripcion)) {
//                   typesCount[type.inv_equipo.inv_tipo_equipo.descripcion].cantidad++;
//                 } else {
//                   typesCount[type.inv_equipo.inv_tipo_equipo.descripcion] = {
//                     nombre: type.inv_equipo.inv_tipo_equipo.descripcion,
//                     cantidad: 1,
//                   };
//                 }
//               });

//               return (
//                 <Accordion
//                   sx={{ backgroundColor: "eeee", marginBottom: 2 }}
//                   key={order.id}
//                   expanded={expanded === order.id}
//                   onChange={handleChange(order.id)}
//                 >
//                   <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
//                     <div className="flex w-full justify-between items-center">
//                       <div className="sm:flex w-full">
//                         <div className="font-bold sm:min-w-[15%]">OT {order.id}</div>
//                         <div className="text-sm sm:text-regular font-medium text-gray-500">
//                           Cliente {order?.cliente_ot_clienteTocliente?.nombre}
//                         </div>
//                         <div className="ml-10 min-w-[170px]">
//                           {Object.entries(typesCount).map(([key, val], index) => {
//                             return (
//                               <Chip
//                                 key={index}
//                                 size="small"
//                                 className="mr-1"
//                                 label={`${val.nombre} x${val.cantidad}`}
//                                 color="primary"
//                               />
//                             );
//                           })}
//                         </div>
//                       </div>
//                       <div className="sm:mr-10">
//                         <Chip
//                           size="small"
//                           sx={{ minWidth: 91 }}
//                           label={
//                             order.finalizada
//                               ? "Finalizada"
//                               : order.cerrada
//                               ? "Cerrada"
//                               : order.tecnico
//                               ? "Asignada"
//                               : "Sin Asignar"
//                           }
//                           variant="outlined"
//                           color={
//                             order.finalizada
//                               ? "success"
//                               : order.cerrada
//                               ? "primary"
//                               : order.tecnico
//                               ? "warning"
//                               : "error"
//                           }
//                         />
//                       </div>
//                     </div>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <div>
//                       <div className="text-lg font-bold mb-2">Detalle: </div>
//                       <div>
//                         <div className="flex p-1 pl-0 mb-1 bg-slate-100 rounded">
//                           <div className="min-w-[150px] font-semibold mr-3 px-1">Cliente: </div>
//                           <div>{order?.cliente_ot_clienteTocliente?.nombre}</div>
//                           <Chip
//                             size="small"
//                             label={"Historial"}
//                             sx={{ marginLeft: 3, cursor: "pointer" }}
//                             color="success"
//                             onClick={() => handleHistory(order?.cliente_ot_clienteTocliente?.id)}
//                           />
//                         </div>
//                         <div className="flex p-1 pl-0 mb-1 bg-slate-200 rounded">
//                           <div className="min-w-[150px] font-semibold mr-3 px-1">Garantia: </div>
//                           <div>{order?.garantia ? "Si" : "No"}</div>
//                         </div>
//                         <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
//                           <div className="min-w-[150px] font-semibold mr-3 px-1">Fecha de OT: </div>
//                           <div>{dateFormatter(order?.f_ot)}</div>
//                         </div>
//                         <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
//                           <div className="min-w-[150px] font-semibold mr-3 px-1">Fecha de creación: </div>
//                           <div>{dateFormatter(order?.f_creacion)}</div>
//                         </div>
//                         <div className="flex p-1 mb-1 pl-0 bg-slate-200 rounded">
//                           <div className="min-w-[150px] font-semibold mr-3 px-1">Tipo de OT: </div>
//                           <div>{order.gen_tipo_ot?.descripcion}</div>
//                         </div>
//                         <div className="p-1 mb-1 pl-0  bg-slate-100 rounded">
//                           <div className="min-w-[150px] text-lg font-semibold mr-3 px-1">Equipos: </div>
//                           <div>
//                             {order?.ot_equipo_ot_equipo_otToot?.map((equipo) => (
//                               <div key={equipo.id} className="p-1 bg-slate-200 rounded mb-2">
//                                 <div className="flex">
//                                   <strong className="min-w-[150px] mr-1">ID:</strong> {equipo.id}
//                                 </div>
//                                 <div className="capitalize flex">
//                                   <strong className="min-w-[150px] mr-1">Equipo:</strong>
//                                   {equipo.inv_equipo.inv_tipo_equipo.descripcion.toLowerCase()}
//                                 </div>
//                                 <div className="capitalize flex">
//                                   <strong className="min-w-[150px] mr-1">Modelo:</strong>
//                                   {equipo.inv_equipo.modelo.toLowerCase()}
//                                 </div>
//                                 <div className="capitalize flex">
//                                   <strong className="min-w-[150px] mr-1">Nº serie:</strong> {equipo.inv_equipo.serie}
//                                 </div>
//                                 <div className="capitalize flex">
//                                   <strong className="min-w-[150px] mr-1">Instalacion:</strong>
//                                   {dateFormatter(equipo.inv_equipo.f_instalacion)}
//                                 </div>
//                                 <div className="capitalize flex">
//                                   <strong className="min-w-[150px] mr-1">Garantia:</strong>
//                                   {dateFormatter(equipo.inv_equipo.f_garantia)}
//                                 </div>
//                               </div>
//                             ))}
//                           </div>
//                         </div>
//                         {order.ot_observacion_ot_observacion_otToot[0] && (
//                           <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
//                             <div className="min-w-[150px] font-semibold mr-3 px-1">Observacion inicial: </div>
//                             <div>{order.ot_observacion_ot_observacion_otToot[0].descripcion}</div>
//                           </div>
//                         )}

//                         <div
//                           className={`flex p-1 mb-1 pl-0 ${
//                             order.finalizada
//                               ? "bg-green-100"
//                               : order.cerrada
//                               ? "bg-blue-100"
//                               : order.ot_usuarios?.length > 0
//                               ? "bg-orange-100"
//                               : "bg-red-100"
//                           } rounded`}
//                         >
//                           <div className="min-w-[150px] font-semibold mr-3 px-1">Estado: </div>
//                           <div>
//                             {order.finalizada
//                               ? `Finalizada`
//                               : order.cerrada
//                               ? `Cerrada por técnico ${order?.gen_usuario.nombre}`
//                               : order.ot_usuarios?.length > 0
//                               ? `Asignad`
//                               : "Sin Asignar"}
//                           </div>
//                         </div>
//                       </div>
//                       {!order.cerrada && (
//                         <div className="p-1 mt-5">
//                           <div className="text-lg font-bold mb-2">
//                             {order?.tecnico ? "Reasignar técnico:" : "Asignar técnico:"}
//                           </div>
//                           <form onSubmit={(e) => handleSubmit(e, order.id)} className="w-full flex items-center">
//                             <TextField
//                               onChange={handleTechChange}
//                               sx={{ width: "50%", marginRight: 5, marginTop: 1 }}
//                               label="Técnicos"
//                               size="small"
//                               select
//                               required
//                               value={selectedTechnical || ""}
//                             >
//                               {technical &&
//                                 technical.map((tecnico) => (
//                                   <MenuItem key={tecnico.id} value={tecnico.id ?? ""}>
//                                     {tecnico.nombre}
//                                   </MenuItem>
//                                 ))}
//                             </TextField>
//                             <Button
//                               type="submit"
//                               variant="contained"
//                               size="small"
//                               sx={{ height: 39, marginTop: 1, width: 150 }}
//                             >
//                               Enviar
//                             </Button>
//                           </form>
//                         </div>
//                       )}
//                     </div>
//                   </AccordionDetails>
//                 </Accordion>
//               );
//             })
//         ) : !data.length && success ? (
//           <div className="w-full ">
//             <div
//               className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
//               role="alert"
//             >
//               <span className="block sm:inline font-semibold">No hay ordenes de trabajo</span>
//             </div>
//           </div>
//         ) : data.length && !success ? (
//           <div className="w-full">
//             <LinearProgress />
//           </div>
//         ) : (
//           <div className="w-full">
//             <LinearProgress />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default OtsAssignedAndWithoutAssign;

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { endOt, getAllWorkOrders, unassignWorkOrderToTechnical } from "../redux/slices/workOrders";
import dayjs from "dayjs";
import DataPicker from "../components/dataPicker/DataPicker";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { getAllUsers } from "../redux/slices/users";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { assignWorkOrderToTechnical } from "../redux/slices/workOrders";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import LinearProgress from "@mui/material/LinearProgress";
import { getOTByClientID } from "../redux/slices/workOrders";
import { setOpen } from "../redux/slices/editModal";
import HistoryModal from "../components/modal/HistoryModal";
import enviroments from "../utils/enviroments";
import dateFormatter from "../utils/dateFormatter";
import Autocomplete from "@mui/material/Autocomplete";
import WorkOrderEdit from "../components/workOrderEdit/WorkOrderEdit";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ClearIcon from "@mui/icons-material/Clear";
import Pagination from "@mui/material/Pagination";
import PdfModal from "../components/modal/pdfModal";

const OtsAssignedAndWithoutAssign = () => {
  const [expanded, setExpanded] = useState(false);
  const [pdfModal, setPdfModal] = useState({ open: false, data: null });
  const [workOrderEdit, setWorkOrderEdit] = useState({
    open: false,
    order: null,
  });
  const { data, success } = useSelector((state) => state.workOrders);
  const { data: users } = useSelector((state) => state.users);
  const [technical, setTechnical] = useState([]);
  const [selectedTechnical, setSelectedTechnical] = useState([]);
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [client, setClient] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [idSelected, setIdSelected] = useState();
  const [status, setStatus] = useState(["Sin Asignar", "Asignada", "Cerrada", "Finalizada"]);
  const [disableds, setDisableds] = useState({});
  const [date, setDate] = useState([]);
  const [page, setPage] = useState(1);
  const [rendered, setRendered] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWorkOrders());

    if (!users.length) {
      dispatch(getAllUsers());
    }

    if (!technical.length) {
      let techincalToSelect = users.filter((user) => user.executeOt === true);
      setTechnical(techincalToSelect);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, users, dispatch]);

  useEffect(() => {
    const filterClients = data.map((order) => order.cliente_ot_clienteTocliente.nombre);
    const filterIds = data.map((order) => order.id);
    setClients(filterClients.filter((element, index) => filterClients.indexOf(element) === index));
    setOrderIds(filterIds);

    const newOrders = data.map((equipment) => {
      const condicion = equipment.ot_equipo_ot_equipo_otToot.some(
        (equipos) => equipos.inv_equipo.f_instalacion === null || equipos.inv_equipo.f_garantia === null,
      );

      return {
        ...equipment,
        disabled: condicion,
      };
    });

    setOrders(newOrders);

    setRendered(newOrders.sort((a, b) => b.id - a.id).slice(0, 9));
  }, [data]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTechChange = (e) => {
    const verificated = selectedTechnical.some((element) => element.id === e.target.value.id);
    if (verificated === false) {
      setSelectedTechnical((prevState) => [...prevState, e.target.value]);
    }
  };

  const handleDeleteTech = (value) => {
    setSelectedTechnical((prevState) => prevState.filter((tech) => tech.id !== value.id));
  };

  const handleUnassign = (value) => {
    dispatch(unassignWorkOrderToTechnical(value));
  };

  const handleSubmit = async (e, ot, date) => {
    e.preventDefault();
    await dispatch(
      assignWorkOrderToTechnical({
        ot_id: ot.id,
        technical_id: selectedTechnical,
        date,
        cliente: ot.cliente_ot_clienteTocliente.nombre,
        obs: ot?.ot_observacion_ot_observacion_otToot[0]?.descripcion,
      }),
    );
    setSelectedTechnical([]);
  };

  const handleCloseOt = (ot_id) => {
    dispatch(endOt({ id: ot_id }));
  };

  const handleHistory = (ot_id) => {
    dispatch(getOTByClientID(ot_id));
    dispatch(setOpen({ open: true, id: ot_id }));
  };

  const handleFilterSubmit = () => {
    const newOrders = data.map((equipment) => {
      const condicion = equipment.ot_equipo_ot_equipo_otToot.some(
        (equipos) => equipos.inv_equipo.f_instalacion === null || equipos.inv_equipo.f_garantia === null,
      );

      return {
        ...equipment,
        disabled: condicion,
      };
    });
    const filtered = newOrders.filter((data) => {
      const dataStatus = data.finalizada
        ? "Finalizada"
        : data.cerrada
        ? "Cerrada"
        : data.ot_usuarios?.length
        ? "Asignada"
        : "Sin Asignar";

      return (
        (!client?.length || data.cliente_ot_clienteTocliente.nombre === client) &&
        (!statusSelected?.length || dataStatus === statusSelected) &&
        (!idSelected || data.id === idSelected)
      );
    });

    setOrders(filtered);
    if (statusSelected === "" || !statusSelected) {
      setRendered(filtered.slice(0, 9));
    } else {
      setRendered(filtered.slice(0, 9));
    }
    setPage(1);
  };

  const handlePagChange = (event, value) => {
    setPage(value);

    const startIndex = (value - 1) * 9;
    const endIndex = startIndex + 9;
    const dataToShow = orders
      .filter((el) => {
        if (statusSelected === "" || !statusSelected) {
          return el.finalizada !== true;
        } else {
          return el;
        }
      })
      .slice(startIndex, endIndex);

    setRendered(dataToShow);
  };

  const handleButtonDisabled = (order) => {
    const equipos = order.ot_equipo_ot_equipo_otToot.flatMap((orden) => orden.inv_equipo);

    const algunaFechaNulaNoManejada = equipos.some((equipo) => {
      const equipoEnEstado = date.find((obj) => obj.equipo === equipo.id && obj.orden === order.id);

      if (equipo.f_instalacion === null && !equipoEnEstado?.hasOwnProperty("f_instalacion")) {
        return true; // Fecha de instalación nula sin manejar
      }

      if (equipo.f_garantia === null && !equipoEnEstado?.hasOwnProperty("f_garantia")) {
        return true; // Fecha de garantía nula sin manejar
      }

      return false;
    });

    return algunaFechaNulaNoManejada;
  };

  const handleDateChange = (prop_date, data) => {
    let updatedDate = [...date];
    const existingIndex = updatedDate.findIndex((obj) => obj.equipo === data.equipment_id);

    if (existingIndex !== -1) {
      const existingObj = updatedDate[existingIndex];

      if (existingObj.hasOwnProperty(data.action)) {
        existingObj[data.action] = prop_date;
      } else {
        existingObj[data.action] = prop_date;
      }
    } else {
      const newEntry = {
        equipo: data.equipment_id,
        orden: data.ot_id,
        [data.action]: prop_date,
      };
      updatedDate.push(newEntry);
    }

    setDate(updatedDate);
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Listado de OT pendientes</h1>
          <BreadCrumb origin={"Servicio tecnico"} current={"OT pendientes"} />
        </div>
        <HistoryModal />
        <PdfModal open={pdfModal.open} data={pdfModal.data} setOpen={setPdfModal} />
        <WorkOrderEdit
          open={workOrderEdit.open}
          order={workOrderEdit.order}
          setter={setWorkOrderEdit}
          client={workOrderEdit.client}
        />
      </div>
      <div className="calculated-height-execute overflow-y-auto mt-5  p-2 rounded border-container">
        <div className="items-center mb-4 md:flex">
          <form className="md:flex w-full">
            <Autocomplete
              disablePortal
              id="filter-1"
              name="filter-1"
              options={clients.sort()}
              autoComplete="off"
              className="w-full mb-4 md:mb-0 md:mx-1"
              onChange={(e, item, reason) => setClient(item)}
              renderInput={(params) => <TextField {...params} label="Filtrar por cliente" size="small" />}
            />
            <Autocomplete
              disablePortal
              id="filter-2"
              name="filter-2"
              options={status}
              autoComplete="off"
              className="w-full mb-4 md:mb-0 md:mx-1"
              onChange={(e, item, reason) => setStatusSelected(item)}
              renderInput={(params) => <TextField {...params} label="Filtrar por estado" size="small" />}
            />
            <Autocomplete
              disablePortal
              id="filter-3"
              name="filter-3"
              options={orderIds.sort(function (a, b) {
                return a - b;
              })}
              className="w-full mb-3 md:mb-0 md:mx-1"
              getOptionLabel={(option) => `#${option}`}
              onChange={(e, item, reason) => setIdSelected(item)}
              renderInput={(params) => (
                <TextField {...params} autoComplete="off" label="Filtrar por numero de OT" size="small" />
              )}
            />
          </form>
          <Button
            onClick={handleFilterSubmit}
            className="md:min-w-[130px] md:max-w-[130px] w-full mb-4 py-1"
            variant="contained"
          >
            Filtrar OTs
          </Button>
        </div>
        {rendered?.length ? (
          [...rendered]
            .sort((a, b) => (a.tecnico === null ? -1 : b.tecnico === null ? 1 : 0))
            .filter((order) => !order.finalizada && !order.cerrada && (!order.finalizada || !order.cerrada))
            .map((order, index) => {
              const typesCount = {};

              order?.ot_equipo_ot_equipo_otToot?.forEach((type) => {
                if (typesCount.hasOwnProperty(type.inv_equipo.inv_tipo_equipo.descripcion)) {
                  typesCount[type.inv_equipo.inv_tipo_equipo.descripcion].cantidad++;
                } else {
                  typesCount[type.inv_equipo.inv_tipo_equipo.descripcion] = {
                    nombre: type.inv_equipo.inv_tipo_equipo.descripcion,
                    cantidad: 1,
                  };
                }
              });

              return (
                <Accordion
                  sx={{ backgroundColor: "eeee", marginBottom: 2 }}
                  key={order.id}
                  expanded={expanded === order.id}
                  onChange={handleChange(order.id)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <div className="flex w-full justify-between items-center">
                      <div className="sm:flex w-full">
                        <div className="font-bold sm:min-w-[15%]">OT {order.id}</div>
                        <div className="text-sm sm:text-regular font-medium text-gray-500">
                          Cliente {order?.cliente_ot_clienteTocliente?.nombre}
                        </div>
                        <div className="md:ml-10 min-w-[170px]">
                          {Object.entries(typesCount).map(([key, val], index) => {
                            return (
                              <Chip
                                key={index}
                                size="small"
                                className="mr-1"
                                label={`${val.nombre} x${val.cantidad}`}
                                color="primary"
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="sm:mr-10">
                        <Chip
                          size="small"
                          sx={{ minWidth: 91 }}
                          label={
                            order.finalizada
                              ? "Finalizada"
                              : order.cerrada
                              ? "Cerrada"
                              : order.ot_usuarios?.length > 0
                              ? "Asignada"
                              : "Sin Asignar"
                          }
                          variant="outlined"
                          color={
                            order.finalizada
                              ? "success"
                              : order.cerrada
                              ? "primary"
                              : order.ot_usuarios?.length > 0
                              ? "warning"
                              : "error"
                          }
                        />
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className="">
                        <div className="text-lg font-bold ">Detalle:</div>
                        <div className="flex mb-2 gap-2">
                          <Chip
                            size="small"
                            label={"Editar OT"}
                            sx={{ cursor: "pointer" }}
                            color="primary"
                            onClick={() =>
                              setWorkOrderEdit({
                                order: order.id,
                                open: true,
                                client: order?.cliente_ot_clienteTocliente?.id,
                              })
                            }
                          />
                          <Chip
                            size="small"
                            label={"Historial"}
                            sx={{ cursor: "pointer" }}
                            color="success"
                            onClick={() => handleHistory(order?.cliente_ot_clienteTocliente?.id)}
                          />
                          {order?.pdf?.length && (
                            <a
                              href={`${enviroments.img_endpoint}${order.pdf}`}
                              target="_blank"
                              rel="noreferrer noopener"
                              download="proposed_file_name"
                              className="w-[70px] h-[19px] p-3 rounded-[20px] bg-red-500 text-white flex justify-center items-center text-sm"
                            >
                              PDF
                            </a>
                          )}
                          {!order?.skilledPerson?.length ? null : (
                            <Chip
                              size="small"
                              label={"Capacitados"}
                              sx={{ cursor: "pointer" }}
                              color="primary"
                              onClick={() =>
                                setPdfModal({
                                  open: true,
                                  data: { skilled: order.skilledPerson, equipment: order?.ot_equipo_ot_equipo_otToot },
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="flex p-1 pl-0 mb-1 bg-slate-100 rounded">
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Cliente: </div>
                          <div>{order?.cliente_ot_clienteTocliente?.nombre}</div>
                        </div>
                        <div className="flex p-1 pl-0 mb-1 bg-slate-200 rounded">
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Garantia: </div>
                          <div>{order?.garantia ? "Si" : "No"}</div>
                        </div>
                        {order?.fecha_reapertura && (
                          <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
                            <div className="min-w-[150px] font-semibold mr-3 px-1">Reabierta el: </div>
                            <div>{dateFormatter(order?.fecha_reapertura)}</div>
                          </div>
                        )}
                        <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Fecha de OT: </div>
                          <div>{dateFormatter(order?.f_ot)}</div>
                        </div>
                        <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Fecha de creación: </div>
                          <div>{dateFormatter(order?.f_creacion)}</div>
                        </div>
                        <div className="flex p-1 mb-1 pl-0 bg-slate-200 rounded">
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Tipo de OT: </div>
                          <div>{order.gen_tipo_ot?.descripcion}</div>
                        </div>
                        <div className="p-1 mb-1 pl-0  bg-slate-100 rounded">
                          <div className="min-w-[150px] text-lg font-semibold mr-3 px-1">Equipos: </div>
                          <div>
                            {order?.ot_equipo_ot_equipo_otToot?.map((equipo) => {
                              return (
                                <div key={equipo.id} className="p-1 bg-slate-200 rounded mb-2">
                                  <div className="flex">
                                    <strong className="min-w-[150px] mr-1">ID:</strong> {equipo.equipo}
                                  </div>
                                  <div className="capitalize flex">
                                    <strong className="min-w-[150px] mr-1">Equipo:</strong>
                                    {equipo.inv_equipo.inv_tipo_equipo.descripcion.toLowerCase()}
                                  </div>
                                  <div className="capitalize flex">
                                    <strong className="min-w-[150px] mr-1">Modelo:</strong>
                                    {equipo.inv_equipo.modelo.toLowerCase()}
                                  </div>
                                  <div className="capitalize flex">
                                    <strong className="min-w-[150px] mr-1">Nº serie:</strong> {equipo.inv_equipo.serie}
                                  </div>
                                  <div className="capitalize flex items-center">
                                    <strong className="min-w-[150px] mr-1">Instalacion:</strong>
                                    {equipo.inv_equipo?.f_instalacion ? (
                                      dateFormatter(equipo.inv_equipo?.f_instalacion)
                                    ) : (
                                      <DataPicker
                                        onChange={handleDateChange}
                                        toHandler={{
                                          ot_id: equipo.ot,
                                          equipment_id: equipo.equipo,
                                          action: "f_instalacion",
                                        }}
                                        size="small"
                                      />
                                    )}
                                  </div>
                                  <div className="capitalize flex items-center">
                                    <strong className="min-w-[150px] mr-1">Garantia:</strong>
                                    {equipo.inv_equipo?.f_garantia ? (
                                      dateFormatter(equipo.inv_equipo?.f_garantia)
                                    ) : (
                                      <DataPicker
                                        onChange={handleDateChange}
                                        toHandler={{
                                          ot_id: equipo.ot,
                                          equipment_id: equipo.equipo,
                                          action: "f_garantia",
                                        }}
                                        size="small"
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {order.ot_observacion_ot_observacion_otToot[0] && (
                          <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
                            <div className="min-w-[150px] font-semibold mr-3 px-1">Observacion inicial: </div>
                            <div>{order.ot_observacion_ot_observacion_otToot[0].descripcion}</div>
                          </div>
                        )}
                        <div
                          className={`flex p-1 mb-1 pl-0 ${
                            order.finalizada
                              ? "bg-green-100"
                              : order.cerrada
                              ? "bg-blue-100"
                              : order.ot_usuarios?.length > 0
                              ? "bg-orange-100"
                              : "bg-red-100"
                          } rounded`}
                        >
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Estado: </div>
                          <div className="w-full">
                            {order.finalizada ? (
                              `Finalizada`
                            ) : order.cerrada ? (
                              <div>
                                <div>Cerrada por técnico {order?.img_tecnico}</div>
                              </div>
                            ) : order.ot_usuarios?.length > 0 ? (
                              // `Asignado a técnico ${order?.gen_usuario?.nombre}`
                              <div>
                                <p className="font-semibold mb-2">ASIGNADO A:</p>
                                {order.ot_usuarios?.map((tech) => (
                                  <div key={tech.id} className="flex p-2 border-solid border-2 mb-2 w-[50%]">
                                    <div className={"w-full"}>
                                      {" "}
                                      <EngineeringIcon
                                        sx={{
                                          marginRight: 2,
                                          color: "#2B2C84",
                                        }}
                                      />
                                      {tech.gen_usuario.nombre}
                                    </div>
                                    <div>
                                      <ClearIcon onClick={() => handleUnassign(tech)} />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              "Sin Asignar"
                            )}
                          </div>
                        </div>
                      </div>
                      {!order.finalizada && order.cerrada && (
                        <div className="flex p-1 mb-1 pl-0 bg-slate-200 rounded">
                          <div className="min-w-[150px] font-semibold mr-3 px-1">Asignado a: </div>
                          <div>
                            {order?.ot_usuarios?.length > 0 &&
                              order.ot_usuarios.map((user) => <div>{user.gen_usuario.nombre}</div>)}
                          </div>
                        </div>
                      )}

                      {!order.finalizada && order.cerrada && (
                        <Button
                          onClick={() => handleCloseOt(order.id)}
                          color="success"
                          size="small"
                          sx={{ mt: 2 }}
                          variant="contained"
                        >
                          Finalizar Ot
                        </Button>
                      )}
                      {!order.cerrada && (
                        <div className="p-1 mt-5">
                          <div className="text-lg font-bold mb-2">
                            {order?.tecnico ? "Reasignar técnico:" : "Asignar técnico:"}
                          </div>
                          <form onSubmit={(e) => handleSubmit(e, order, date)} className="w-full flex flex-col">
                            <TextField
                              onChange={handleTechChange}
                              sx={{
                                width: "50%",
                                marginRight: 5,
                                marginTop: 1,
                              }}
                              label="Técnicos"
                              size="small"
                              select
                              required
                              value={selectedTechnical || ""}
                            >
                              {technical.map((tecnico) => (
                                <MenuItem
                                  key={tecnico.id}
                                  value={tecnico ?? ""}
                                  disabled={order.ot_usuarios?.some((user) => user.tecnico === tecnico.id)}
                                >
                                  {tecnico.nombre}
                                </MenuItem>
                              ))}
                            </TextField>
                            <div className={"mt-2"}>
                              <div className="text-lg font-bold mb-2">Técnicos: </div>
                              <div>
                                {selectedTechnical.length
                                  ? selectedTechnical.map((tech) => (
                                      <div key={tech.id} className="flex p-2 border-solid border-2 mb-2 w-[50%]">
                                        <div className={"w-full"}>
                                          {" "}
                                          <EngineeringIcon
                                            sx={{
                                              marginRight: 2,
                                              color: "#2B2C84",
                                            }}
                                          />
                                          {tech.nombre}
                                        </div>
                                        <div>
                                          <ClearIcon onClick={() => handleDeleteTech(tech)} />
                                        </div>
                                      </div>
                                    ))
                                  : "No se han seleccionado técnicos"}
                              </div>
                              <Button
                                type="submit"
                                variant="contained"
                                size="small"
                                sx={{ height: 39, marginTop: 1, width: 150 }}
                                disabled={handleButtonDisabled(order)}
                              >
                                Enviar
                              </Button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })
        ) : !data.length && success ? (
          <div className="w-full ">
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline font-semibold">No hay ordenes de trabajo</span>
            </div>
          </div>
        ) : data.length && !success ? (
          <div className="w-full">
            <LinearProgress />
          </div>
        ) : (
          <div className="w-full">
            <LinearProgress />
          </div>
        )}
        <div className="w-full flex mt-10 justify-center items-center">
          <Pagination
            color="primary"
            shape="rounded"
            count={Math.round(orders.length / 9)}
            variant="outlined"
            page={page}
            onChange={handlePagChange}
          />
        </div>
      </div>
    </div>
  );
};

export default OtsAssignedAndWithoutAssign;
