import React from "react";
import TransitionsModal from "../components/modal/TransitionsModal";
import BusinessNameSchema from "../resolvers/businessName.resolver";
import { createNewBusinessName, deleteBusinessName } from "../redux/slices/businessName";
import useConnstantBussinesName from "../components/modal/constants/useConstantBusinessName";
import { getAllBusinessName } from "../redux/slices/businessName";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import EditModal from "../components/modal/EditModal";
import { updateBusinessName } from "../redux/slices/businessName";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Fecha de creacion", value: "f_creacion" },
  { title: "Acciones", value: "acciones" },
];

export default function BusinessName() {
  const { data, success } = useSelector((state) => state.businessName);
  const { id } = useSelector((state) => state.editModal);
  const [fields, element] = useConnstantBussinesName(data);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteBusinessName(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "TIPO DE RAZON SOCIAL");

  const newBusinessName = async (value) => {
    dispatch(createNewBusinessName(value));
    dispatch(setOpen(false));
  };

  const handleUpdate = async (values) => {
    dispatch(updateBusinessName({ ...values, id }));
    dispatch(setOpenEdit({ open: false }));
  };

  useEffect(() => {
    if (!data.length) {
      dispatch(getAllBusinessName());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Tipo de Razon Social</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Tipo razon social"} />
        </div>
        <div>
          <TransitionsModal
            size="500px"
            data={element}
            fields={fields}
            schema={BusinessNameSchema}
            submit={newBusinessName}
            type={"create"}
          />
          <EditModal fields={fields} schema={BusinessNameSchema} submit={handleUpdate} type={"edit"} size="500px" />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={success} />
    </div>
  );
}
