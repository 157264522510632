import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  riesgo: [],
  success: false,
  status: "idle",
};

const riesgoSlice = createSlice({
  name: "riesgo",
  initialState,
  reducers: {
    setRiesgo: (state, action) => {
      state.riesgo = action.payload;
      state.status = true;
      state.success = true;
    },
    create: (state, action) => {
      state.riesgo.push(action.payload);
      state.success = true;
    },
    remove: (state, action) => {
      const updated = state.riesgo.filter((el) => el.id !== action.payload);
      state.riesgo = updated;
      state.success = true;
    },
    update: (state, action) => {
      const updated = state.riesgo.filter((el) => el.id !== action.payload.id);
      state.riesgo = [...updated, action.payload];
      state.success = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getAllRiesgo = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/riesgo");
    dispatch(setRiesgo(response.data));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createRiesgo = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/riesgo", values);
    dispatch(create(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Riesgo creada con exito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear riesgo",
        open: true,
      })
    );
  }
};

export const deleteRiesgo = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/riesgo?id=${id}`);
    dispatch(remove(response.data.id));
    dispatch(
      setNotification({
        status: "success",
        message: "Riesgo borrada con exito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al borrar riesgo",
        open: true,
      })
    );
  }
};

export const updateRiesgo = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/riesgo?id=${values.id}`, values);
    dispatch(update(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Riesgo editado con exito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar riesgo",
        open: true,
      })
    );
  }
};

export const { setRiesgo, create, remove, update, setStatus } = riesgoSlice.actions;

export default riesgoSlice.reducer;
