import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { deleteArea, getAllAreas } from "../redux/slices/area";
import AreaCreate from "../components/modal/AreaCreate";
import AreaUpdate from "../components/modal/AreaUpdate";
import { deleteCharge, getAllCharges } from "../redux/slices/charges";
import PositionCreate from "../components/modal/PositionCreate";
import PositionUpdate from "../components/modal/PositionUpdate";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Tipo de cargo", value: "tipo" },
  { title: "Fecha de creacion", value: "f_creacion" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Position() {
  const { data, success } = useSelector((state) => state.charges);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteCharge(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "AREA", false, setOpen);

  useEffect(() => {
    dispatch(getAllCharges());
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Cargos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Cargos"} />
        </div>
        <div>
          <PositionCreate />
          <PositionUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={success} />
    </div>
  );
}
