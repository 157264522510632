import { Button, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getBodegaSelect,
  getModelSelect,
  getStorageTemsFilted,
  getTeamTypeSelect,
  getVersionSelect,
} from "../../../../redux/slices/informes";
import { useEffect } from "react";

export default function FilterBodegaEquipos() {
  const { bodegasSelect, teamTypeSelect, modelSelect, versionSelect } = useSelector((state) => state.informes);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(getBodegaSelect()),
      dispatch(getTeamTypeSelect()),
      dispatch(getModelSelect()),
      dispatch(getVersionSelect()),
    ]);
  }, []);

  const submitData = (values) => {
    dispatch(
      getStorageTemsFilted({
        bodegaId: values.bodegaId,
        teamId: values.tipoEquipoId,
        model: values.model,
        versionId: values.versionId,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submitData)} className="flex gap-4">
      <Controller
        id="bodegaId"
        name="bodegaId"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Bodegas"
            error={errors["bodegaId"]}
            placeholder=""
            sx={{
              "& .MuiFormHelperText-root": {
                marginLeft: 0,
              },
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {bodegasSelect?.map(
              (option) =>
                option.id && (
                  <MenuItem key={option.id} dense divider value={option.id}>
                    {option.name}
                  </MenuItem>
                )
            )}
          </TextField>
        )}
      />
      <Controller
        id="tipoEquipoId"
        name="tipoEquipoId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Tipo de equipo"
            placeholder=""
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {teamTypeSelect?.map(
              (option) =>
                option.id && (
                  <MenuItem key={option.id} dense divider value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                )
            )}
          </TextField>
        )}
      />
      <Controller
        id="model"
        name="model"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Modelo"
            placeholder=""
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {modelSelect?.map(
              (option) =>
                option.id && (
                  <MenuItem key={option.id} dense divider value={option.nombre}>
                    {option.nombre}
                  </MenuItem>
                )
            )}
          </TextField>
        )}
      />
      <Controller
        id="versionId"
        name="versionId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Version"
            placeholder=""
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {versionSelect?.map(
              (option) =>
                option.id && (
                  <MenuItem key={option.id} dense divider value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                )
            )}
          </TextField>
        )}
      />
      <Button variant="outlined" type="submit">
        Filtrar
      </Button>
    </form>
  );
}
