import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { deleteArea, getAllAreas } from "../redux/slices/area";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import {
  createCategoriesDetail,
  deleteCategoriesDetail,
  getCategoriesDetail,
  updateCategoriesDetail,
} from "../redux/slices/fundAccounting";

const permitedRenderDataColumn = [
  { title: "Nombre", value: "nombre" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function ReimbursementCategory() {
  const { detailCategories } = useSelector((state) => state.fundAccounting);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteCategoriesDetail(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "CATEGORIA", false, setOpen);

  useEffect(() => {
    dispatch(getCategoriesDetail());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Categorías Rendición</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Categorías Rendición"} />
        </div>
        <div>
          <Button variant="outlined" onClick={() => setOpen({ status: true, data: null })}>
            Crear Categoria
          </Button>
          <CategoryModal open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={detailCategories} success={true} />
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function CategoryModal({ open, data, setOpen }) {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset({ nombre: "" });
  };

  const handle_submit = async (value) => {
    if (!!data) {
      value.id = data.id;
      dispatch(updateCategoriesDetail(value));
    } else {
      dispatch(createCategoriesDetail(value));
    }
    handleClose();
  };

  useEffect(() => {
    if (data?.id) {
      reset({
        nombre: data.nombre,
      });
    }
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(handle_submit)}>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">
                {!!data ? "EDITAR CATEGORIA" : "CREAR CATEGORIA"}
              </div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="nombre"
                  name="nombre"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Nombre"
                      onChange={onChange}
                      size="small"
                      value={value || ""}
                      error={errors.nombre}
                      helperText={errors.nombre?.message}
                    />
                  )}
                />
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="outlined" fullWidth>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
