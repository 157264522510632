import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  currency: [],
};

const currency = createSlice({
  name: "Currency",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
      state.status = "succeeded";
    },
  },
});

export const getCurrencies = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/currency");
    dispatch(setCurrency(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const { setCurrency } = currency.actions;

export default currency.reducer;
