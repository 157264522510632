import React from "react";
import { getAllRoles, getAllPermissions, createNewRol, updateRole } from "../redux/slices/roles";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import RolesModal from "../components/modal/RolesModal";
import useConstantRoles from "../components/modal/constants/useConstantRoles";
import EditRoleModal from "../components/modal/EditRoleModal";
import formatPermissions from "../utils/formatPermissions";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";
import { deleteRole } from "../redux/slices/roles";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Fecha de creacion", value: "f_creacion" },
  { title: "Acciones", value: "acciones" },
];

export default function Roles() {
  const { data, permissions, success } = useSelector((state) => state.roles);
  const { id } = useSelector((state) => state.editModal);
  const { currentUser } = useSelector((state) => state.auth);
  const [fields, activedPermissions] = useConstantRoles();
  const dispatch = useDispatch();

  const handleDelete = (params) => {
    dispatch(deleteRole(params.id));
  };
  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete);

  const handleSubmit = (values) => {
    const result = formatPermissions(values, permissions);
    dispatch(createNewRol({ role_name: values.role_name, permissions: result }));
    dispatch(setOpen(false));
  };

  const handleUpdate = (values) => {
    const result = formatPermissions(values, permissions);
    dispatch(updateRole({ role_name: values.role_name, permissions: result, id }));
    if (id === currentUser.rol) {
      window.location.reload();
    }
    dispatch(setOpenEdit({ open: false }));
  };

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllPermissions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Roles</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Roles"} />
        </div>
        <div>
          <RolesModal submit={handleSubmit} fields={fields} permissions={permissions} type={"create"} />
          <EditRoleModal
            submit={handleUpdate}
            fields={fields}
            permissions={permissions}
            type={"edit"}
            activedPermissions={activedPermissions}
          />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={success} />
    </div>
  );
}
