import { getFacturacionById, setFacturaId, updateFacturacion } from "../../../redux/slices/facturacion";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FacturaEquipamentTable from "../../../components/tables/Facturacion/FacturaEquipamentTable";
import FacturaRepuestoTable from "../../../components/tables/Facturacion/FacturaRepuestoTable";
import FacturaItemTable from "../../../components/tables/Facturacion/FacturaItemTable";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import uploadFiles from "../../../utils/UploadFiles";
import enviroments from "../../../utils/enviroments";
import xmljs from "xml-js";
import dayjs from "dayjs";
import FacturaServicioTable from "../../../components/tables/Facturacion/FacturaServicioTable";

export default function FacturaId() {
  const [equipos, setEquipos] = useState([]);
  const [items, setItems] = useState([]);
  const [repuestos, setRepuestos] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { facturaId } = useSelector((state) => state.facturacion);
  const { currentUser } = useSelector((state) => state.auth);

  const [xml, setXml] = useState({});
  const [montoCobranza, setMontoCobranza] = useState({});
  const [xmlData, setXmlData] = useState(null);
  const [PDF, setPDF] = useState({});
  const [fecha, setFecha] = useState(dayjs());
  const [fechaPago, setFechaPago] = useState(dayjs().add(30, "days"));
  const [monto, setMonto] = useState("");
  const [numero, setNumero] = useState("");

  useEffect(() => {
    setEquipos(facturaId.facturacion_equipo);
    setRepuestos(facturaId.facturacion_repuestos);
    setItems(facturaId.facturacion_articulos);
    let monto = 0;
    if (facturaId?.cobranza?.length) {
      facturaId.cobranza.forEach((el) => {
        monto += el.monto;
      });
    }
    setMontoCobranza(monto);
    setServicios(facturaId.facturacion_servicios);
  }, [facturaId]);

  useEffect(() => {
    dispatch(getFacturacionById(id));

    return () => dispatch(setFacturaId({}));
  }, []);

  const handleChangeFileXml = (e) => {
    setXml(e.target.files[0]);
  };

  const handleChangeFilePDF = (e) => {
    setPDF(e.target.files[0]);
  };

  const handleChangeFecha = (date) => {
    setFecha(date);
    setFechaPago(dayjs(date).add(30, "days"));
  };

  const handleChangeFechaPago = (date) => {
    setFechaPago(date);
  };

  const handleChangeMonto = (e) => {
    setMonto(e.target.value);
  };

  const handleChangeNumero = (e) => {
    setNumero(e.target.value);
  };

  const handleFileRead = (event) => {
    setXml(event.target.files[0]);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const xmlString = reader.result;
      const jsonData = xmljs.xml2json(xmlString, { compact: true, spaces: 4 });
      setXmlData(JSON.parse(jsonData));
    };

    reader.readAsText(file);
  };

  const handleSubmit = async (e) => {
    setDisabledBtn(true);
    e.preventDefault();
    let estado = false;
    if (parseInt(monto) + parseInt(montoCobranza) >= parseInt(facturaId?.monto)) {
      estado = true;
    }
    const uploadedxml = await uploadFiles("docs", xml);

    let pdfData = {};
    if (PDF) {
      pdfData = await uploadFiles("docs", PDF);
    }

    const res = await dispatch(
      updateFacturacion({
        id,
        file_xml: uploadedxml.data,
        ...(pdfData?.data && { file_pdf: pdfData?.data }),
        f_facturacion: fecha,
        monto,
        n_factura: numero,
        f_pago: fechaPago,
        estado,
        cliente: facturaId.cliente,
        servicios: facturaId.facturacion_servicios,
        equipos: facturaId.facturacion_equipo,
        articulos: facturaId.facturacion_articulos,
        repuestos: facturaId.facturacion_repuestos,
        responsable: currentUser.id,
      })
    );
    if (res.status === 201) {
      navigate("/por-facturar");
    }
  };

  useEffect(() => {
    if (xmlData) {
      const values = xmlData.EnvioDTE.SetDTE.DTE.Documento.Encabezado.IdDoc;
      const monto = xmlData.EnvioDTE.SetDTE.DTE.Documento.Encabezado.Totales.MntTotal._text;
      setMonto(monto);
      setNumero(values.Folio._text);
      setFecha(dayjs(values.FchEmis._text));
      setFechaPago(dayjs(values.FchEmis._text).add(1, "M"));
      // setFechaPago(dayjs(values.MntPagos.FchPago._text));
    }
  }, [xmlData]);

  return (
    <div>
      {facturaId && Object.keys(facturaId).length > 0 ? (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="flex mb-10">
            <div className="w-[10%] flex items-center">
              <Chip
                label="Volver"
                sx={{ minWidth: "100px" }}
                color="primary"
                onClick={() => navigate("/por-facturar")}
              />
            </div>
          </div>
          <div className="flex justify-between mb-5">
            <div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold">NOMBRE: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.nombre?.toUpperCase()}</div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">DIRECCION: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.direccion?.toUpperCase()}</div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">RUT: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.rut} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">MAIL CONTACTO: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.mail_contacto?.toUpperCase()} </div>
              </div>
              {facturaId?.nro_oc && (
                <div className="flex">
                  <div className="min-w-[180px] font-semibold ">NRO OC DE MERCADO: </div>
                  <div>{facturaId?.nro_oc ? facturaId?.nro_oc : "S/D"} </div>
                </div>
              )}
              {facturaId?.nroLicitacion && (
                <div className="flex">
                  <div className="min-w-[180px] font-semibold ">NRO LICITACIÓN: </div>
                  <div>{facturaId?.nroLicitacion ? facturaId?.nroLicitacion : "S/D"} </div>
                </div>
              )}
              {facturaId?.nro_oc_noMP && (
                <div className="flex">
                  <div className="min-w-[180px] font-semibold ">NRO OC: </div>
                  <div>{facturaId?.nro_oc_noMP ? facturaId?.nro_oc_noMP : "S/D"} </div>
                </div>
              )}
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">FECHA OC: </div>
                <div>{facturaId?.fecha_oc ? dayjs(facturaId?.fecha_oc).format("DD/MM/YYYY") : "S/D"} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">METODO DE PAGO: </div>
                <div>{facturaId?.pago_parcial ? "PARCIAL" : "TOTAL"} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">MONTO OC: </div>
                <div>{`$ ${(facturaId?.monto).toLocaleString()}`} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">PORC. DESCUENTO: </div>
                <div>{`% ${facturaId?.descuento}`} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">MONTO TOTAL: </div>
                <div>
                  {`$ ${(facturaId?.monto - (facturaId?.descuento / 100) * facturaId?.monto).toLocaleString()}`}{" "}
                </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">MONTO FACTURADO: </div>
                <div>{`$ ${montoCobranza.toLocaleString()}`} </div>
              </div>
            </div>
            <div className="text-4xl flex pr-10">FACTURACIÓN</div>
          </div>
          {facturaId.facturacion_equipo.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">EQUIPOS</div>
              </div>
              <FacturaEquipamentTable rows={equipos} />
            </div>
          ) : (
            <></>
          )}
          {facturaId.facturacion_repuestos.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">REPUESTOS</div>
              </div>
              <FacturaRepuestoTable rows={repuestos} />
            </div>
          ) : (
            <></>
          )}
          {facturaId.facturacion_articulos.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">INSUMOS</div>
              </div>
              <FacturaItemTable rows={items} />
            </div>
          ) : (
            <></>
          )}
          {facturaId.facturacion_servicios.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">SERVICIOS</div>
              </div>
              <FacturaServicioTable rows={servicios} />
            </div>
          ) : (
            <></>
          )}
          {facturaId?.observacion ? (
            <div className="mb-4">
              <div className="flex bg-[#2b2c84]/70 h-[40px] items-center justify-center mb-2">
                <div className="text-white font-semibold flex">OBSERVACION</div>
              </div>
              <div>{facturaId?.observacion}</div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-col">
            <div className="flex flex-col mb-5">
              {facturaId?.file_oc && (
                <div className="flex mb-5">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <label className="font-semibold mr-5">ARCHIVO ADJUNTO: </label>
                  <a
                    href={`${enviroments.img_endpoint}${facturaId?.file_oc}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline"
                  >
                    <p>{facturaId?.file_oc}</p>
                  </a>
                </div>
              )}
              <div className="flex h-[30px] mb-5">
                <div className="mr-5">
                  <div>
                    <label
                      htmlFor="file-pdf"
                      className={`custom-file-upload border 2 border-solid ${
                        !PDF.name ? "border-red-500 text-red-500" : "border-green-700 text-green-700"
                      }`}
                    >
                      SUBIR PDF
                    </label>
                    <input id="file-pdf" type="file" onChange={handleChangeFilePDF} placeholder="archivo" />
                  </div>
                  {/* <Button variant="contained" component="label" size="small">
                    Subir pdf
                    <input
                      hidden
                      accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                      type="file"
                      onChange={handleChangeFilePDF}
                    />
                  </Button> */}
                </div>
                <div className="flex items-end w-[85%]">
                  {PDF.name && (
                    <div>
                      {PDF.name} <CheckCircleOutlineIcon sx={{ color: "green", marginLeft: 2 }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex h-[30px]">
                <div className=" mr-5">
                  {/* <Button variant="contained" component="label" size="small">
                    Subir xml
                    <input
                      hidden
                      accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                      type="file"
                      onChange={handleChangeFileXml}
                    />
                  </Button> */}
                  <div>
                    <label
                      htmlFor="file-xml"
                      className={`custom-file-upload border 2 border-solid ${
                        !xml.name ? "border-red-500 text-red-500" : "border-green-700 text-green-700"
                      }`}
                    >
                      SUBIR XML
                    </label>
                    <input
                      id="file-xml"
                      type="file"
                      onChange={handleFileRead}
                      placeholder="archivo"
                      accept="application/xml,.xml"
                    />
                  </div>
                </div>
                <div className="flex items-end ">
                  {xml.name && (
                    <div>
                      {xml.name} <CheckCircleOutlineIcon sx={{ color: "green", marginLeft: 2 }} />
                    </div>
                  )}
                </div>
              </div>
              {numero && fecha && fechaPago && monto && (
                <div className="mt-5">
                  <div className="flex mb-2">
                    <label className="w-[180px] font-semibold">- Número de Factura:</label>
                    <p>{numero}</p>
                  </div>
                  <div className="flex mb-2">
                    <label className="w-[180px] font-semibold">- Monto Factura:</label>
                    <p>$ {parseFloat(monto).toLocaleString()}</p>
                  </div>
                  <div className="flex mb-2">
                    <label className="w-[180px] font-semibold">- Fecha de Facturación:</label>
                    <p>{dayjs(fecha).format("DD/MM/YYYY")}</p>
                  </div>
                  <div className="flex mb-2">
                    <label className="w-[180px] font-semibold">- Fecha de Pago:</label>
                    <p>{dayjs(fechaPago).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="flex">
              <div className="w-[50%] flex items-center">
                <div className="mr-5 w-[200px]">FECHA DE FACTURACION: </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        defaultValue={fecha}
                        onChange={handleChangeFecha}
                        sx={{
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                            paddingY: "8.5px",
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="w-[50%] flex items-center">
                <div className="mr-5 w-[200px]">FECHA DE PAGO: </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker format="DD/MM/YYYY" value={fechaPago} onChange={handleChangeFechaPago} />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-[50%] flex items-center mt-3">
                <div className="mr-5 w-[200px]">N° FACTURA: </div>
                <TextField sx={{ width: "246px" }} onChange={handleChangeNumero} size="small" />
              </div>
              <div className="w-[50%] flex items-center">
                <div className="mr-5 w-[200px]">MONTO: </div>
                <TextField sx={{ width: "246px" }} onChange={handleChangeMonto} size="small" />
              </div>
            </div> */}
          </div>
          <Button
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            sx={{ marginTop: 5 }}
            disabled={xml.name && monto && numero && disabledBtn === false ? false : true}
          >
            ENVIAR
          </Button>
        </form>
      ) : (
        <div className="w-[100%] h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
