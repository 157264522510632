import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import ReadCSV from "../../components/ReadCSV/ReadCSV";
import {
  getAllDocumentsRecived,
  getProyects,
  getTipoGastos,
  uploadDocuemntsreceived,
} from "../../redux/slices/documents";
import FacturasRecibidasTable from "../../components/tables/FacturasRecibidasTable/FacturasRecibidasTable";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Button, MenuItem, TextField } from "@mui/material";

export default function FactrurasRecibidas() {
  const { currentUser } = useSelector((state) => state.auth);
  const { documentsRecived, success } = useSelector((state) => state.document);
  const [docFiltered, setDocFiltered] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(0);
  const [selectedTipoGasto, setSelectedTipoGasto] = useState(0);
  const [selectedProyecto, setSelectedProyecto] = useState(0);
  const { proyects, tipoGastos } = useSelector((state) => state.document);

  useEffect(() => {
    filterDocuments();
  }, [selectedCliente, selectedTipoGasto, selectedProyecto, documentsRecived]);

  const handleCliente = (e) => {
    setSelectedCliente(e.target.value);
  };

  const handleTipoGasto = (e) => {
    setSelectedTipoGasto(e.target.value);
  };

  const handleProyecto = (e) => {
    setSelectedProyecto(e.target.value);
  };

  const filterDocuments = () => {
    let filteredDocs = [...documentsRecived];

    // Filtrar por Cliente
    if (selectedCliente !== 0) {
      filteredDocs = filteredDocs.filter((el) => el.razon_social === selectedCliente);
    }

    // Filtrar por Tipo de Gasto
    if (selectedTipoGasto !== 0) {
      filteredDocs = filteredDocs.filter((el) => el.tipo_gasto === selectedTipoGasto);
    }

    // Filtrar por Proyecto
    if (selectedProyecto !== 0) {
      filteredDocs = filteredDocs.filter((el) => el.proyecto === selectedProyecto);
    }

    setDocFiltered(filteredDocs);
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const handleSumbit = (e) => {
    e.forEach((element) => {
      delete element.nro;
      element.responsable_carga = currentUser.id;
    });
    dispatch(uploadDocuemntsreceived(e));
  };

  useEffect(() => {
    dispatch(getProyects());
    dispatch(getTipoGastos());
  }, []);

  const handle_filter = async (values) => {
    const f_inicio = dayjs(values.f_inicio).format("DD-MM-YYYY");
    const f_fin = dayjs(values.f_fin).format("DD-MM-YYYY");
    await dispatch(getAllDocumentsRecived(f_inicio, f_fin));
  };

  useEffect(() => {
    if (documentsRecived) {
      setDocFiltered(documentsRecived);
    }
  }, [documentsRecived]);

  useEffect(() => {
    const cliente = [];
    if (documentsRecived) {
      documentsRecived.forEach((el) => {
        let findCliente = cliente.find((element) => element === el.razon_social);
        if (!findCliente) {
          cliente.push(el.razon_social);
        }
      });
    }
    setCliente(cliente);
  }, [documentsRecived]);

  // const handleCliente = (e) => {
  //   if (e === 0) {
  //     setDocFiltered(documentsRecived);
  //   } else {
  //     const filter = documentsRecived.filter((el) => el.rut_proveedor === e);
  //     setDocFiltered(filter);
  //   }
  // };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">SII</h1>
          <BreadCrumb origin={"SII"} current={"Facturas Recibidas"} />
        </div>
        <div>
          <ReadCSV uploader={handleSumbit} />
        </div>
      </div>
      <form onSubmit={handleSubmit(handle_filter)} className="flex gap-5 my-5">
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              rules={{ required: true }}
              name={"f_inicio"}
              defaultValue={dayjs()}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  label={"Fecha Inicio"}
                  onChange={onChange}
                  value={value || null}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              rules={{ required: true }}
              name={"f_fin"}
              defaultValue={dayjs()}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  label={"Fecha Fin"}
                  onChange={onChange}
                  value={value || null}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <Button variant="outlined" type="submit">
          BUSCAR
        </Button>
        <div className="w-[300px]">
          <TextField
            size="small"
            className="w-[80%] md:w-[90%]"
            label="Clientes"
            select
            value={selectedCliente}
            onChange={handleCliente}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem key={0} value={0}>
              TODOS
            </MenuItem>
            {cliente?.map((cli) => (
              <MenuItem key={cli} value={cli}>
                {cli}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="w-[300px]">
          <TextField
            size="small"
            className="w-[80%] md:w-[90%]"
            label="Tipo Gasto"
            select
            value={selectedTipoGasto}
            onChange={handleTipoGasto}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem key={0} value={0}>
              TODOS
            </MenuItem>
            {tipoGastos?.map((tipo) => (
              <MenuItem key={tipo.id} value={tipo.nombre}>
                {tipo.nombre}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="w-[300px]">
          <TextField
            size="small"
            className="w-[80%] md:w-[90%]"
            label="Proyecto"
            select
            value={selectedProyecto}
            onChange={handleProyecto}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem key={0} value={0}>
              TODOS
            </MenuItem>
            {proyects?.map((pro) => (
              <MenuItem key={pro.id} value={pro.nombre}>
                {pro.nombre}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </form>
      <div>
        <FacturasRecibidasTable rows={docFiltered} />
      </div>
    </div>
  );
}
