import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { deleteArea, getAllAreas } from "../redux/slices/area";
import AreaCreate from "../components/modal/AreaCreate";
import AreaUpdate from "../components/modal/AreaUpdate";

const permitedRenderDataColumn = [
  { title: "Area", value: "name" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Area() {
  const { area, success } = useSelector((state) => state.area);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteArea(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "AREA", false, setOpen);

  useEffect(() => {
    dispatch(getAllAreas());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Area</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Area"} />
        </div>
        <div>
          <AreaCreate />
          <AreaUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={area} success={success} />
    </div>
  );
}
