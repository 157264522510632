import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton, TextField } from "@mui/material";

const GenericDataGrid = ({ columns, rows, type }) => {
  const [open, setOpen] = useState({ state: false, data: null });

  return (
    <TableContainer sx={{ maxHeight: 290, position: "relative" }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
      {type === "self" && <div className="font-medium text-xl mb-2">Empresa</div>}
      {type === "contact" && <div className="font-medium text-xl mb-2">Contactos del cliente</div>}
      {type === "children" && <div className=" font-medium text-xl mb-2">Instituciones Asociadas al cliente</div>}
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        {/* <caption>A basic table example with a caption</caption> */}
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={{ fontWeight: 600 }} key={column.value}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {type === "self" &&
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.tipo_razon_social}</TableCell>
                <TableCell>{row.rut}</TableCell>
                <TableCell>{row.hijoRender}</TableCell>
                <TableCell>{row.tipo_empresa}</TableCell>
              </TableRow>
            ))}
          {type === "contact" &&
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>{row.gen_cargo.descripcion}</TableCell>
                <TableCell>{row.correo1}</TableCell>
                <TableCell>{row.telefono1}</TableCell>
                <TableCell>{row.principal ? "Si" : "No"}</TableCell>
              </TableRow>
            ))}
          {type === "children" &&
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.nombre}</TableCell>
                <TableCell>{row.gen_tipo_razon_social.descripcion}</TableCell>
                <TableCell>{row.rut}</TableCell>
                <TableCell>{row.hijo ? "Hija" : "Padre"}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => setOpen({ state: true, data: row })}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <ClientDetail open={open.state} setOpen={setOpen} data={open.data} />
    </TableContainer>
  );
};

const ClientDetail = ({ open, setOpen, data }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ width: 500, height: "auto" }}>
        <div className="w-full">
          <div className="py-2 bg-blue-200 rounded text-center font-semibold mb-2">Detalles de institucion</div>
          <div className="w-full flex gap-4 mb-2">
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Nombre"
              fullWidth
              value={data?.nombre || ""}
            />
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Razon Social"
              fullWidth
              value={data?.gen_tipo_razon_social?.descripcion || ""}
            />
          </div>
          <div className="w-full flex gap-4 mb-2">
            <TextField sx={{ pointerEvents: "none" }} size="small" label="Rut" fullWidth value={data?.rut || ""} />
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Región"
              fullWidth
              value={data?.gen_region.descripcion || ""}
            />
          </div>
          <div className="w-full flex gap-4 mb-2">
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Comuna"
              fullWidth
              value={data?.gen_comuna.descripcion || ""}
            />
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Dirección"
              fullWidth
              value={data?.direccion || ""}
            />
          </div>
          <div className="w-full flex gap-4 mb-2">
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Correo de Contacto"
              fullWidth
              value={data?.mail_contacto || ""}
            />
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Encargado de pago"
              fullWidth
              value={data?.nombre_pago || ""}
            />
          </div>
          <div className="w-full flex gap-4 mb-2">
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Correo encargado de pago"
              fullWidth
              value={data?.correo_pago || ""}
            />
            <TextField
              sx={{ pointerEvents: "none" }}
              size="small"
              label="Tipo de empresa"
              fullWidth
              value={data?.tipo_empresa || "Pública"}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GenericDataGrid;
