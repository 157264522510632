import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: true,
  accessRoutes: {
    clientsLinks: [],
    maintainersLinks: [],
  },
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    handleSideBar: (state, action) => {
      state.open = action.payload || !state.open;
    },
    closeSideBar: (state, action) => {
      state.open = false;
    },
    setAccessRoutes: (state, action) => {
      state.accessRoutes = action.payload;
    },
  },
});

export const { handleSideBar, setAccessRoutes, closeSideBar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
