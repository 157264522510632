import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  id: null,
};

const editModalSlice = createSlice({
  name: "editModal",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload.open;
      state.id = action.payload.id;
    },
  },
});

export const { setOpen } = editModalSlice.actions;

export default editModalSlice.reducer;
