import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { updateFeriado } from "../../redux/slices/feriados";
import "dayjs/locale/es";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function FeriadosUpdate({ open, data, setOpen }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const handle_submit = async (value) => {
    dispatch(updateFeriado({ ...value, id: data.id }));
    reset();
    setOpen({ status: false, data: null });
  };

  React.useEffect(() => {
    if (data?.id) {
      reset({
        name: data.name,
        fecha: dayjs(data.fecha).add(1, "day"),
        anio: data.anio,
      });
    }
  }, [data]);

  const años = [
    {
      id: 2023,
      nombre: 2023,
    },
    {
      id: 2024,
      nombre: 2024,
    },
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(handle_submit)}>
                <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR FERIADO</div>
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="name"
                    name="name"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Nombre"
                        onChange={onChange}
                        size="small"
                        value={value || ""}
                        error={errors.name}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </div>
                <div className="min-h-[65px] mb-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <Controller
                      id={"fecha"}
                      control={control}
                      rules={{ required: true }}
                      name={"fecha"}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="Fecha"
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                              error: !!errors["fecha"],
                            },
                          }}
                          onChange={onChange}
                          value={value || null}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="anio"
                    name="anio"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Año"
                        onChange={onChange}
                        size="small"
                        value={value || ""}
                        error={errors.anio}
                        select
                        helperText={errors.anio?.message}
                      >
                        {años?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </div>
                <div className="flex items-center w-full justify-between">
                  <Button type="submit" variant="outlined" fullWidth>
                    Enviar formulario
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
