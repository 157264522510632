import React from "react";
import DataGridComponent from "../../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../../hooks/useDataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { getAllClients } from "../../redux/slices/clients";
import { CircularProgress } from "@mui/material";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "nombre" },
  { title: "Direccion", value: "direccion" },
  { title: "Comuna", value: "comuna" },
  { title: "Mail contacto", value: "mail_contacto" },
  { title: "Acciones", value: "acciones_pre_factura" },
];

export default function PreFactura() {
  const { data, success } = useSelector((state) => state.clients);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async () => {};

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Marcas", false, setOpen);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllClients()).then(() => setLoading(false));
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Orden de Compra</h1>
          <BreadCrumb origin={"Facturacion"} current={"Orden de Compra"} />
        </div>
      </div>
      {loading ? (
        <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <DataGridComponent columns={columns} rows={data} success={success} />
      )}
    </div>
  );
}
