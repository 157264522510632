import React from "react";
import useConstantDepartament from "../components/modal/constants/useConstantDepartament";
import TransitionsModal from "../components/modal/TransitionsModal";
import departamentSchema from "../resolvers/departament.resolver";
import { getAllDepartments, createNewDepartment, deleteDepartment } from "../redux/slices/deparments";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import EditModal from "../components/modal/EditModal";
import { updateDepartment } from "../redux/slices/deparments";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Fecha de creacion", value: "f_creacion" },
  { title: "Acciones", value: "acciones" },
];

export default function Departments() {
  const { data, success } = useSelector((state) => state.department);
  const { id } = useSelector((state) => state.editModal);
  const [fields, element] = useConstantDepartament(data);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteDepartment(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "DEPARTAMENTO");

  const newDepartament = async (values) => {
    dispatch(createNewDepartment(values));
    dispatch(setOpen(false));
  };

  const handleUpdate = async (values) => {
    dispatch(updateDepartment({ ...values, id }));
    dispatch(setOpenEdit({ open: false }));
  };

  useEffect(() => {
    dispatch(getAllDepartments());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Departamentos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Departamentos"} />
        </div>
        <div>
          <TransitionsModal
            data={element}
            fields={fields}
            schema={departamentSchema}
            submit={newDepartament}
            type={"create"}
          />
          <EditModal fields={fields} schema={departamentSchema} submit={handleUpdate} type={"edit"} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={success} />
    </div>
  );
}
