import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

export default function AccordionOtRepairParts({ otFacturacionRepuesto, otRepuesto }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <span className="font-bold uppercase tracking-wider	text-sm">Repuestos:</span>
      </AccordionSummary>
      <AccordionDetails>
        <section className="w-full">
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 ">
            {otRepuesto?.map((row) => {
              return (
                row.cantidad !== 0 && (
                  <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                      <label className="text-sm">{row.inv_repuesto.descripcion}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                      <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.modelo)}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Serie:</label>
                      <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.serie)}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                      <label className="text-sm">{row.cantidad}</label>
                    </div>
                  </div>
                )
              );
            })}
            {otFacturacionRepuesto?.map((row) => {
              return (
                row.cantidad !== 0 && (
                  <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                    <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                      <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                      <label className="text-sm">{row.inv_repuesto.descripcion}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                      <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.modelo)}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Serie:</label>
                      <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.serie)}</label>
                    </div>
                    <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                      <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                      <label className="text-sm">{row.cantidad}</label>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </section>
      </AccordionDetails>
    </Accordion>
  );
}
