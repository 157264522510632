import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, esES, gridClasses } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { styled, alpha } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import exportToExcel from "../../utils/exportToExcel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .status-entransito": {
    backgroundColor: "#FFF7B3",
    "&:hover": {
      backgroundColor: "#dfd89d  ",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFF7B3",
      "&:hover": {
        backgroundColor: "#FFF7B3",
      },
    },
  },
  "& .status-reservado": {
    backgroundColor: "#aee6b1",
    "&:hover": {
      backgroundColor: "#c0fac4",
    },
    "&.Mui-selected": {
      backgroundColor: "#aee6b1",
      "&:hover": {
        backgroundColor: "#c0fac4",
      },
    },
  },
  "& .status-vigente": {
    backgroundColor: "#B3D1FF",
    "&:hover": {
      backgroundColor: "#8ba4ca",
    },
    "&.Mui-selected": {
      backgroundColor: "#B3D1FF",
      "&:hover": {
        backgroundColor: "#B3D1FF",
      },
    },
  },
  "& .status-novigente": {
    backgroundColor: "#D3D3D3 ",
    "&:hover": {
      backgroundColor: "#c0bcbc ",
    },
    "&.Mui-selected": {
      backgroundColor: "#D3D3D3 ",
      "&:hover": {
        backgroundColor: "#D3D3D3 ",
      },
    },
  },
  "& .status-cliente": {
    backgroundColor: "#dafadf ",
    "&:hover": {
      backgroundColor: "#dafadf",
    },
    "&.Mui-selected": {
      backgroundColor: "#dafadf",
      "&:hover": {
        backgroundColor: "#dafadf",
      },
    },
  },
  "& .status-bodega": {
    backgroundColor: "#FFDAB9  ",
    "&:hover": {
      backgroundColor: "#FFDAB9",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFDAB9",
      "&:hover": {
        backgroundColor: "#FFDAB9",
      },
    },
  },
  "& .status-baja": {
    backgroundColor: "#FFC0CB  ",
    "&:hover": {
      backgroundColor: "#FFC0CB",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFC0CB",
      "&:hover": {
        backgroundColor: "#FFC0CB",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));

export default function ControlledDataGridComponent({
  columns,
  rows,
  success,
  dateSort,
  filename = "TablaExportada",
  size = "0px",
  setPaginationModel,
  paginationModel,
}) {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <div
          className="text-[#2B2C84] font-medium flex items-center gap-1 text-[13px] cursor-pointer hover:bg-slate-50 py-1"
          onClick={() => exportToExcel(rows, columns, filename)}
        >
          <SaveAltIcon sx={{ height: "22px", width: "22px" }} /> <span>EXPORTAR A EXCEL</span>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ height: `calc(100vh - 65px - 32px - 80px - 24px - ${size})` }}>
      <StyledDataGrid
        className="transition-all"
        sx={{ p: 2, overflowX: "scroll" }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
        slotProps={{}}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }],
          },
        }}
        getRowClassName={(params) =>
          params.row?.estadoText
            ? `status-${params.row.estadoText.replace(/\s/g, "").toLowerCase()}`
            : params.indexRelativeToCurrentPage % 2 === 0
            ? "even"
            : "odd"
        }
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        // pageSizeOptions={[50, 1]}
        loading={!success ? true : false}
      />
    </Box>
  );
}
