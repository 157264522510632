import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import {
  deleteArticle,
  deleteArticleMold,
  getAllArticles,
  getAllArticlesMold,
  getAllProvidersSelect,
} from "../redux/slices/inventory";
import ArticleCreate from "../components/modal/ArticleCreate";
import ArticleUpdate from "../components/modal/ArticleUpdate";
import ArticleMoldCreate from "../components/modal/ArticlesMoldCreate";
import ArticleMoldUpdate from "../components/modal/ArticleMoldUpdate";

const permitedRenderDataColumn = [
  { value: "codigo_loginteg", title: "Código Loginteg", minWidth: 170 },
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "codigo_proveedor", title: "Código Proveedor", minWidth: 170 },
  {
    value: "acciones_inv",
    title: "Acciones",
    minWidth: 170,
    align: "center",
  },
];

export default function ArticlesMold() {
  const dispatch = useDispatch();
  const { articlesMold, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(deleteArticleMold(id));
    setOpen({ status: false, data: null });
  };
  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Insumos", false, setOpen);

  useEffect(() => {
    dispatch(getAllArticlesMold());
    dispatch(getAllProvidersSelect());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Insumos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Insumos"} />
        </div>
        <div>
          <ArticleMoldCreate arr={articlesMold} />
          <ArticleMoldUpdate arr={articlesMold} open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={articlesMold} success={success} />
    </div>
  );
}
