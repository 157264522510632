import React from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import useDataGrid from "../../hooks/useDataGrid";
import DataGridComponent from "../../components/tables/DataGrid";
import { getAllCobranzasByState, registrarPago, setCobranzasTrue } from "../../redux/slices/cobranzas";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

const permitedRenderDataColumn = [
  { title: "N° Factura", value: "n_factura", width: 50, maxWidth: 150 },
  { title: "Nombre Cliente", value: "nombre_cliente", width: 400 },
  { title: "Nota de Crédito", value: "numero_nota_credito" },
  { title: "Fecha Facturacion", value: "f_facturacion", width: 140, maxWidth: 200 },
  { title: "Fecha de Pago", value: "fecha_pago", width: 140, maxWidth: 200 },
  { title: "Monto", value: "monto_withOut_iva", width: 100, maxWidth: 150 },
  { title: "Iva", value: "iva", width: 100, maxWidth: 150 },
  { title: "Total", value: "monto", width: 100, maxWidth: 150 },
  { title: "Acciones", value: "facturas_pagadas" },
];

export default function FacturasPagadas() {
  const { cobranzasTrue, success } = useSelector((state) => state.cobranzas);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const formularioToSend = {
      id: params.row.id,
      responsable: currentUser.id,
      f_compromiso: params.row.f_compromiso,
      observaciones: "Se registro pago realizado con éxito",
    };
    dispatch(registrarPago(formularioToSend));
    setOpen(false);
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Marcas", false, setOpen);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCobranzasByState(true)).then(() => setLoading(false));

    return async () => {
      await dispatch(setCobranzasTrue([]));
    };
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div className="">
          <h1 className="font-semibold text-[#1E293B] text-2xl">Facturas Pagadas</h1>
          <BreadCrumb origin={"Cobranzas"} current={"Facturas Pagadas"} />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <DataGridComponent columns={columns} rows={cobranzasTrue} success={success} />
        )}
      </div>
    </div>
  );
}
