import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { deleteTypesOfDocument, getAllTypesOfDocuments } from "../redux/slices/inventory";
import TypeOfDocumentModalCreate from "../components/modal/TypeOfDocumentModalCreate";
import TypeOfDocumentModalUpdate from "../components/modal/TypeOfDocumentModalUpdate";

export default function TypesOfDocument() {
  const permitedRenderDataColumn = [
    { title: "ID", value: "id", hide: true },
    { title: "Descripcion", value: "nombre" },
    { title: "Acciones", value: "acciones_inv" },
  ];
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const { typeOfDocument, success } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteTypesOfDocument(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Modelo", false, setOpen);

  useEffect(() => {
    dispatch(getAllTypesOfDocuments());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Tipos de Documento</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Tipos de Documento"} />
        </div>
        <div>
          <TypeOfDocumentModalCreate />
          <TypeOfDocumentModalUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={typeOfDocument} success={success} />
    </div>
  );
}
