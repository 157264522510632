import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getCobranzaById, setCobranzaId, updateRegistroCobranza } from "../../../redux/slices/cobranzas";
import { TextField } from "@mui/material";
import "dayjs/locale/es";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import CobranzasTable from "../../../components/tables/CobranzasTable/CobranzasTable";
import enviroments from "../../../utils/enviroments";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import * as utc from "dayjs/plugin/utc";
import CobranzasPago from "../../../components/tables/CobranzasTable/CobranzasPago";
import FacturaEquipamentTable from "../../../components/tables/Facturacion/FacturaEquipamentTable";
import FacturaRepuestoTable from "../../../components/tables/Facturacion/FacturaRepuestoTable";
import FacturaItemTable from "../../../components/tables/Facturacion/FacturaItemTable";
import FacturaServicioTable from "../../../components/tables/Facturacion/FacturaServicioTable";
dayjs.extend(utc);

export default function FacturasPagadasId() {
  const { cobranzaId } = useSelector((state) => state.cobranzas);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCobranzaById(id));

    return () => dispatch(setCobranzaId({}));
  }, []);

  console.log(cobranzaId);
  const principal =
    cobranzaId.facturacion?.cliente_facturacion_clienteTocliente.contacto_contacto_clienteTocliente.filter(
      (cliente) => cliente.principal === true && cliente.estado === true
    );

  return (
    <div>
      {Object.keys(cobranzaId).length > 0 ? (
        <div>
          <div className="mt-5 bg-[#2b2c84] text-white px-5 py-2 rounded flex justify-center mb-5 font-semibold">
            FACTURA
          </div>
          <div className="pl-5 grid xl:grid-cols-2 md:grid-cols-1 gap-x-5">
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">RUT: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                cobranzaId
                value={cobranzaId?.facturacion?.cliente_facturacion_clienteTocliente.rut}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>

            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Nombre: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={cobranzaId?.facturacion?.cliente_facturacion_clienteTocliente.nombre}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>

            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Número de Factura: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={cobranzaId?.n_factura}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Monto: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={cobranzaId?.monto}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Contactos: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={principal.length > 0 ? `${principal[0]?.telefono1} - ${principal[0]?.telefono2}` : "Sin asignar"}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Medio de contacto: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={principal.length > 0 ? principal[0]?.gen_medio_contacto?.descripcion : "Sin asignar"}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Nro OT: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={cobranzaId.facturacion.ot.id}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Estado OT: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={
                  cobranzaId.facturacion.ot.finalizada
                    ? "Finalizada"
                    : cobranzaId.facturacion.ot.cerrada
                    ? "Cerrada"
                    : cobranzaId.facturacion.ot.ot_usuarios?.length
                    ? "Asignada"
                    : "Sin Asignar"
                }
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "325px",
                }}
              />
            </div>
          </div>

          <div>
            <div className="mt-5 bg-[#2b2c84] text-white px-5 py-2 rounded flex justify-center mb-5 font-semibold">
              DETALLE FACTURACIÓN
            </div>
            {cobranzaId?.facturacion?.facturacion_equipo?.length ? (
              <div className="mb-4">
                <div className="flex bg-[#2b2c84] text-white h-[40px] items-center justify-center">
                  <div className="font-semibold flex ">EQUIPOS</div>
                </div>
                <FacturaEquipamentTable rows={cobranzaId?.facturacion?.facturacion_equipo} />
              </div>
            ) : (
              ""
            )}
            {cobranzaId?.facturacion?.facturacion_repuestos?.length ? (
              <div className="mb-4">
                <div className="flex bg-[#2b2c84] text-white h-[40px] items-center justify-center">
                  <div className="font-semibold flex ">REPUESTOS</div>
                </div>
                <FacturaRepuestoTable rows={cobranzaId?.facturacion?.facturacion_repuestos} />
              </div>
            ) : (
              ""
            )}
            {cobranzaId?.facturacion?.facturacion_articulos?.length ? (
              <div className="mb-4">
                <div className="flex bg-[#2b2c84] text-white h-[40px] items-center justify-center">
                  <div className="font-semibold flex ">INSUMOS</div>
                </div>
                <FacturaItemTable rows={cobranzaId?.facturacion?.facturacion_articulos} />
              </div>
            ) : (
              ""
            )}
            {cobranzaId?.facturacion?.facturacion_servicios?.length ? (
              <div className="mb-4">
                <div className="flex bg-[#2b2c84] text-white h-[40px] items-center justify-center">
                  <div className="font-semibold flex ">SERVICIOS</div>
                </div>
                <FacturaServicioTable rows={cobranzaId?.facturacion?.facturacion_servicios} />
              </div>
            ) : (
              ""
            )}
            {cobranzaId?.facturacion?.observacion ? (
              <div className="mb-4">
                <div className="flex bg-[#2b2c84] text-white h-[40px] items-center justify-center mb-2">
                  <div className="font-semibold flex ">OBSERVACION</div>
                </div>
                <div>{cobranzaId?.facturacion?.observacion}</div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div>
            <div className="mt-5 bg-[#2b2c84] text-white px-5 py-2 rounded flex justify-center mb-5 font-semibold">
              ARCHIVOS ADJUNTOS
            </div>
            <div className="flex flex-col mb-5">
              <div className="ml-5 flex mb-2">
                <AttachFileIcon sx={{ marginRight: 1 }} />
                <p className="font-semibold">XML:</p>
                <a
                  href={`${enviroments.img_endpoint}${cobranzaId?.file_xml}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="hover:underline ml-5"
                >
                  <p>{cobranzaId?.file_xml}</p>
                </a>
              </div>
              <div className="ml-5 flex mb-2">
                <AttachFileIcon sx={{ marginRight: 1 }} />
                <p className="font-semibold">PDF:</p>
                <a
                  href={`${enviroments.img_endpoint}${cobranzaId?.file_pdf}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="hover:underline  ml-5"
                >
                  <p>{cobranzaId?.file_pdf}</p>
                </a>
              </div>
              {cobranzaId.facturacion.file_oc && (
                <div className="ml-5 flex mb-2">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <p className="font-semibold">ORDEN DE COMPRA :</p>
                  <a
                    href={`${enviroments.img_endpoint}${cobranzaId?.facturacion.file_oc}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline  ml-5"
                  >
                    <p>{cobranzaId?.facturacion.file_oc}</p>
                  </a>
                </div>
              )}
              {cobranzaId?.numero_nota_credito && (
                <div className="ml-5 flex mb-2">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <p className="font-semibold">NOTA DE CREDITO :</p>
                  <a
                    href={`${enviroments.img_endpoint}${cobranzaId?.cobranza_pagos[0].comprobante}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline  ml-5"
                  >
                    <p>{cobranzaId?.cobranza_pagos[0].comprobante}</p>
                  </a>
                </div>
              )}
              {/* {cobranzaId.archivo_nota_credito && (
                <div className="ml-5 flex">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <p className="font-semibold">NOTA DE CREDITO</p>:
                  <a
                    href={`${enviroments.img_endpoint}${cobranzaId?.archivo_nota_credito}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline  ml-5"
                  >
                    <p>{cobranzaId?.archivo_nota_credito}</p>
                  </a>
                </div>
              )}
              {cobranzaId.archivo_comprobante_pago && (
                <div className="ml-5 flex">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <p className="font-semibold">COMPROBANTE DE PAGO</p>:
                  <a
                    href={`${enviroments.img_endpoint}${cobranzaId?.archivo_comprobante_pago}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline  ml-5"
                  >
                    <p>{cobranzaId?.archivo_comprobante_pago}</p>
                  </a>
                </div>
              )} */}
            </div>
          </div>
          {!cobranzaId.numero_nota_credito && (
            <div>
              <div className="bg-[#2b2c84] text-white px-5 py-2 rounded mt-5 flex justify-center font-semibold">
                <label className="">PAGOS </label>
              </div>
              <CobranzasPago rows={cobranzaId ? cobranzaId : []} />
            </div>
          )}
          {cobranzaId.numero_nota_credito && (
            <div>
              <div className="mt-5 bg-[#2b2c84] text-white px-5 py-2 rounded flex justify-center mb-5 font-semibold">
                OBSERVACIÓN NOTA DE CREDITO
              </div>
              <div>{cobranzaId?.obs_nota_credito}</div>
            </div>
          )}
          <div>
            <div className="bg-[#2b2c84] text-white px-5 py-2 rounded mt-5 flex justify-center font-semibold">
              <label className="">HISTORIAL </label>
            </div>
            <CobranzasTable rows={cobranzaId?.cobranza_obs} />
          </div>
        </div>
      ) : (
        <div className="w-[100%] h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
