import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import { useEffect } from "react";
import { getContactsDetail } from "../../../redux/slices/informes";

export default function Contactos() {
  const { contactsDetail } = useSelector((state) => state.informes);
  const columnStorageInsumos = [
    { title: "Nombre", value: "nombre" },
    { title: "Cargo", value: "cargo_nombre" },
    { title: "Area", value: "area_nombre" },
    { title: "Pais", value: "pais_nombre" },
    { title: "Fecha de Nacimiento", value: "fecha_nacimiento" },
    { title: "Medio de contacto", value: "medio_contacto_nombre" },
    { title: "Principal", value: "is_principal" },
    { title: "Correo 1", value: "correo1" },
    { title: "Correo 2", value: "correo2" },
    { title: "telefono 1", value: "telefono1" },
    { title: "telefono 2", value: "telefono2" },
    { title: "Instagram", value: "instagram" },
    { title: "LinkedIn", value: "linkedin" },
    { title: "Observaciones", value: "observaciones" },
  ];

  const [columns] = useDataGrid(columnStorageInsumos, () => {}, "CARGO");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactsDetail());
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <DataGridComponent columns={columns} rows={contactsDetail} success={true} size="40px" />
    </div>
  );
}
