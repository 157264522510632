import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  openClient: false,
  client: null,
  success: true,
};

const programmingSlice = createSlice({
  name: "programming",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.openClient = action.payload.openClient;
      state.client = action.payload.client;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setNewMaintenance: (state, action) => {
      const filtered = state.client.cliente_equipo_cliente_equipo_clienteTocliente.filter((equipo) => {
        return equipo.equipo === action.payload.equipo;
      });
      filtered[0].inv_equipo.ot_programacion.push(action.payload);
      state.success = true;
      state.client.cliente_equipo_cliente_equipo_clienteTocliente = filtered;
    },
    setRemoveMaintenance: (state, action) => {
      const filtered = state.client.cliente_equipo_cliente_equipo_clienteTocliente.filter((equipo) => {
        return equipo.equipo === action.payload.equipo;
      });
      const maintenanceFiltered = filtered[0].inv_equipo.ot_programacion.filter((equipo) => {
        return equipo.id !== action.payload.id;
      });
      filtered[0].inv_equipo.ot_programacion = maintenanceFiltered;

      state.client.cliente_equipo_cliente_equipo_clienteTocliente = filtered;
    },
  },
});

export const createMaintenance = (new_date) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const created = await axiosInstance.post(`/equipo/mantencion`, new_date);
    dispatch(setNewMaintenance(created.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Mantencion creada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear mantencion",
        open: true,
      })
    );
    return console.log(error);
  }
};

export const removeMaintenance = (id) => async (dispatch) => {
  try {
    const removed = await axiosInstance.delete(`equipo/mantencion/${id}`);
    dispatch(setRemoveMaintenance(removed.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Mantencion eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar mantencion",
        open: true,
      })
    );
    return console.log(error);
  }
};

export const { setOpen, setSuccess, setNewMaintenance, setRemoveMaintenance } = programmingSlice.actions;

export default programmingSlice.reducer;
