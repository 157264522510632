import { useParams, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../utils/axiosInstance";
import { Alert } from "@mui/material";

const ResetPassword = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState();
  const [res, setRes] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // validar si el token es valido
    const verify = async () => {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const res = await axiosInstance.get("/login/forgot/verify");
      if (res.status === 200) {
        setValid(true);
      } else {
        setValid(false);
      }
    };

    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const res = await axiosInstance.post("/login/forgot/reset", { password });
    setLoading(false);
    setRes({ message: res.data.message });
  };

  const handleBlur = (e) => {
    if (e.target.id === "password") {
      if (!password.length) {
        setErrors({
          ...errors,
          password: "La contraseña es requerida",
        });
      } else if (password.length < 8) {
        setErrors({
          ...errors,
          password: "Debe tener un minimo de 8 caracteres",
        });
      } else if (repeatPassword.length && password !== repeatPassword) {
        setErrors({
          ...errors,
          password: "Las contraseñas no coinciden",
        });
      } else {
        setErrors({
          ...errors,
          password: null,
        });
      }
    } else {
      if (!repeatPassword.length) {
        setErrors({
          ...errors,
          repeatPassword: "Repetir la contraseña es requerido",
        });
      } else if (repeatPassword.length < 8) {
        setErrors({
          ...errors,
          password: "Debe tener un minimo de 8 caracteres",
        });
      } else if (repeatPassword !== password) {
        setErrors({
          ...errors,
          repeatPassword: "Las contraseñas no coinciden",
        });
      } else {
        setErrors({
          ...errors,
          repeatPassword: null,
        });
      }
    }
  };

  return (
    <div className="w-full h-screen flex justify-center  items-center">
      <div className="bg-img"></div>
      <form className="flex flex-col fixed bg-white  shadow-lg p-20 rounded z-10" onSubmit={handleSubmit}>
        {valid ? (
          <div className="flex flex-col">
            <div className="text-2xl font-semibold mb-5">Ingresa nueva contraseña</div>
            <TextField
              id={"password"}
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              error={Boolean(errors.password)}
              helperText={errors.password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={handleBlur}
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword((show) => !show)}>
                    {showPassword ? <VisibilityOffIcon color="action" /> : <VisibilityIcon color="action" />}
                  </IconButton>
                ),
              }}
            />
            <TextField
              id="repeatPassword"
              type={showRepeatPassword ? "text" : "password"}
              label="Repetir Contraseña"
              error={Boolean(errors.repeatPassword)}
              helperText={errors.repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowRepeatPassword((show) => !show)}>
                    {showRepeatPassword ? <VisibilityOffIcon color="action" /> : <VisibilityIcon color="action" />}
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              disabled={Boolean(errors.password || errors.repeatPassword) || loading}
              variant="contained"
              sx={{ textTransform: "none", my: 2 }}
            >
              Cambiar Contraseña
            </Button>
            {res?.message && <Alert severity="info">{res.message}</Alert>}
            {res?.message && (
              <Link className="text-sm text-center mt-1 text-slate-600" to={"/login"}>
                Volver al Login
              </Link>
            )}
          </div>
        ) : (
          <div className="text-center">
            <div className="text-2xl font-semibold mb-5">El token expiro</div>
            <div className="text-xl font-semibold ">Solicite un nuevo enlace de recuperacion</div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
