import Navbar from "../components/NavBar/Navbar";
import SideBar from "../pages/SideBar";
import { useSelector } from "react-redux";
import Notification from "../components/Alert/Notification";

const Wrapper = ({ children }) => {
  const { open } = useSelector((state) => state.sidebar);

  return (
    <div className="w-full flex mt-[65px]">
      <Navbar />
      <SideBar />
      <div className={`${open ? "mainactive" : "maincontent"}`}>{children}</div>
      <Notification />
    </div>
  );
};

export default Wrapper;
