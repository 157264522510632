import { Button, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getBodegaSelect,
  getModelSelect,
  getOtAssigned,
  getStorageInsumosFilted,
  getStorageRepuestosFiltered,
  getStorageTemsFilted,
  getTeamTypeSelect,
  getTechnicalSelect,
  getVersionSelect,
} from "../../../../redux/slices/informes";
import { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function FilterOtAsignadas() {
  const { technicalSelect } = useSelector((state) => state.informes);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([dispatch(getTechnicalSelect())]);
  }, []);

  const submitData = (values) => {
    dispatch(
      getOtAssigned({
        initial_date: values.initial_date,
        final_date: values.final_date,
        technical: values.technical,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submitData)} className="flex gap-4">
      <div className="w-full flex">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Controller
            id={"initial_date"}
            control={control}
            rules={{ required: true }}
            name={"initial_date"}
            // defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    sx: { mr: "20px" },
                    error: !!errors["initial_date"],
                    // helperText: errors['fecha_ingreso'] && ListOfErrors[errors['fecha_ingreso'].type],
                  },
                }}
                onChange={onChange}
                value={value || null}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full flex">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Controller
            id={"final_date"}
            control={control}
            rules={{ required: true }}
            name={"final_date"}
            // defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    sx: { mr: "20px" },
                    error: !!errors["final_date"],
                    // helperText: errors['fecha_ingreso'] && ListOfErrors[errors['fecha_ingreso'].type],
                  },
                }}
                onChange={onChange}
                value={value || null}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <Controller
        id="technical"
        name="technical"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Técnico"
            error={errors["technical"]}
            placeholder=""
            sx={{
              "& .MuiFormHelperText-root": {
                marginLeft: 0,
              },
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {technicalSelect?.map(
              (option) =>
                option.id && (
                  <MenuItem key={option.id} dense divider value={option.id}>
                    {option.nombre}
                  </MenuItem>
                )
            )}
          </TextField>
        )}
      />
      <Button variant="outlined" type="submit">
        Filtrar
      </Button>
    </form>
  );
}
