import { createSlice, current } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

const initialState = {
  users: [],
  portal: {},
};

const slice = createSlice({
  name: "remuneration",
  initialState,
  reducers: {
    set: (state, { payload: { data, name } }) => {
      state[name] = data;
      state.success = true;
    },
    update: (state, { payload: { data, name } }) => {
      state[name] = [...state[name].filter((worker) => worker.id !== data.id), data];
      state.success = true;
    },
    updatePortalVacationRequest: (state, action) => {
      state.portal.VacationRequest = [...state.portal.VacationRequest, action.payload.data];
    },
    replacePortalVacationRequest: (state, action) => {
      state.portal.VacationRequest = [
        ...state.portal.VacationRequest.filter((e) => e.id !== action.payload.data.id),
        action.payload.data,
      ];
    },
    updateSettlement: (state, action) => {
      state.portal.Settlement = [...state.portal.Settlement.filter((e) => e.id !== action.payload.id), action.payload];
    },
  },
});

export const getAllWorkers = () => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.get("/remuneration");
    dispatchEvent(set({ data, name: "users" }));
  } catch (error) {
    console.log(error);
  }
};

export const updateWorkerVacation = (values) => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.put("/remuneration/vacation", values);
    dispatchEvent(update({ data, name: "users" }));
  } catch (error) {
    console.log(error);
  }
};

export const uploadProof = (values) => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.put("/remuneration/proof", values);
    dispatchEvent(update({ data: data, name: "users" }));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProof = (id) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.delete(`/remuneration/proof?id=${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSettlement = (id) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.delete(`/remuneration/settlement?id=${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadSettlement = (values) => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.put("/remuneration/settlement", values);
    dispatchEvent(update({ data: data, name: "users" }));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPortalData = () => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.get("/remuneration/portal");
    dispatchEvent(set({ data: data, name: "portal" }));
  } catch (error) {
    console.log(error);
  }
};

export const vacationRequest = (val) => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.post("/remuneration/vacation", val);
    dispatchEvent(updatePortalVacationRequest({ data: data, name: "portal" }));
  } catch (error) {
    console.log(error);
  }
};

export const acceptRejectVacationRequest = (val) => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.put("/remuneration/vacation-state", val);
    dispatchEvent(update({ name: "users", data: data.user }));
    return data.vacation || data;
  } catch (error) {
    console.log(error);
  }
};

export const signVacation = (val) => async (dispatchEvent) => {
  try {
    const { data } = await axiosInstance.put("/remuneration/vacation-accept", val);
    dispatchEvent(replacePortalVacationRequest({ name: "portal", data: data }));
    return data.vacation;
  } catch (error) {
    console.log(error);
  }
};

export const handleCodeSuccess = (val) => async (dispatchEvent) => {
  const { data } = await axiosInstance.put("/remuneration/settlement-signed", val);
  dispatchEvent(updateSettlement(data));
  return data;
};

export const { set, update, updatePortalVacationRequest, updateSettlement, replacePortalVacationRequest } =
  slice.actions;

export default slice.reducer;
