import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import { setter } from "../../../redux/slices/informes";
import { useEffect } from "react";
import ListadoRendicionFilter from "./ListadoRendicionFilter";
import { getAllUsers } from "../../../redux/slices/users";

export default function InformeListadoRendicion() {
  const { listadoRendicion } = useSelector((state) => state.informes);

  const columnStorageInsumos = [
    { title: "Nro Rendicion", value: "id", hidden: true },
    { title: "Nro Rendicion", value: "rendicion_id" },
    { title: "Solicitada por", value: "solicitada_por" },
    { title: "Fecha", value: "fecha_rendicion" },
    { title: "Moneda", value: "moneda" },
    { title: "Total", value: "monto" },
    { title: "Tipo", value: "tipo" },
    { title: "Estado", value: "estado" },
    { title: "Pagado por", value: "pagado_por" },
    { title: "Fecha de pago", value: "fecha_pago" },
    { title: "Acciones", value: "pending_actions" },
  ];

  console.log(listadoRendicion);
  // console.log(storageInsumos);
  const [columns] = useDataGrid(columnStorageInsumos, () => {}, "CARGO");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
    return () => {
      dispatch(setter({ setter: "storageInsumos", payload: [] }));
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <ListadoRendicionFilter />
      <DataGridComponent columns={columns} rows={listadoRendicion} success={true} size="40px" />
    </div>
  );
}
