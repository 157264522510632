import { useSelector } from "react-redux";

export default function useFieldsReadOnly() {
  const { client } = useSelector((state) => state.clients);

  const fields = [
    {
      id: "nombre",
      label: "Nombre",
      value: client?.nombre || "",
      type: "text",
    },
    {
      id: "direccion",
      label: "Direccion",
      type: "text",
      value: client?.direccion || "",
    },
    {
      id: "rut",
      label: "Rut empresa",
      type: "text",
      value: client?.rut || "",
    },
    {
      id: "mail",
      label: "Email contacto",
      type: "text",
      value: client?.mail_contacto || "",
    },
    {
      id: "region",
      label: "Region",
      type: "text",
      value: client?.gen_region?.descripcion || "",
    },
    {
      id: "comuna",
      label: "Comuna",
      type: "text",
      value: client?.gen_comuna?.descripcion || "",
    },
  ];

  return client.id ? fields : null;
}
