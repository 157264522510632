import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import FeriadosCreate from "../components/modal/FeriadosCreate";
import { deleteFeriado, getAllFeriados } from "../redux/slices/feriados";
import FeriadosUpdate from "../components/modal/FeriadosUpdate";

const permitedRenderDataColumn = [
  { title: "Feriado", value: "name" },
  { title: "Fecha", value: "fecha_format" },
  { title: "Año", value: "anio" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Feriados() {
  const { feriados, success } = useSelector((state) => state.feriados);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteFeriado(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "FERIADO", false, setOpen);

  useEffect(() => {
    dispatch(getAllFeriados());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Feriados</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Feriados"} />
        </div>
        <div>
          <FeriadosCreate />
          <FeriadosUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent
        columns={columns}
        rows={[...feriados]?.sort(function (o1, o2) {
          return o2.fecha ? -1 : o1.fecha ? 1 : 0;
        })}
        success={success}
      />
    </div>
  );
}
