import React, { useState } from "react";
import VersionSchema from "../resolvers/version.resolver";
import useConstantVersion from "../components/modal/constants/useConstantVersion";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import EditModal from "../components/modal/EditModal";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";
import { deleteVersion, createVersion, updateVersion, getAllVersions } from "../redux/slices/inventory";
import VersionCreate from "../components/modal/VersionCreate";
import VersionEdit from "../components/modal/VersionEdit";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Modelo", value: "modelo_nombre" },
  { title: "Equipo", value: "equipo_nombre" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Version() {
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const { version, success } = useSelector((state) => state.inventory);
  const { id } = useSelector((state) => state.editModal);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;

    dispatch(deleteVersion(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "VERSION", false, setOpen);

  useEffect(() => {
    dispatch(getAllVersions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Versión</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Versión"} />
        </div>
        <div>
          <VersionCreate />
          <VersionEdit open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={version} success={success} />
    </div>
  );
}
