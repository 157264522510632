import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import authReducer from "./slices/auth";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import sidebarReducer from "./slices/sidebar";
import contactReducer from "./slices/contact";
import businessNameReducer from "./slices/businessName";
import chargesReducer from "./slices/charges";
import clientsReducer from "./slices/clients";
import communeReducer from "./slices/commune";
import contactMeansReducer from "./slices/contactMeans";
import departmentsReducer from "./slices/deparments";
import regionsReducer from "./slices/regions";
import rolesReducer from "./slices/roles";
import usersReducer from "./slices/users";
import editModalReducer from "./slices/editModal";
import createModalReducer from "./slices/createModal";
import invProductsReducer from "./slices/invProducts";
import workOrdersReducer from "./slices/workOrders";
import programmingReducer from "./slices/programming";
import notificationReducer from "./slices/notification";
import inventoryReducer from "./slices/inventory";
import facturacionReducer from "./slices/facturacion";
import cobranzasReducer from "./slices/cobranzas";
import documentsReducer from "./slices/documents";
import servicesReducer from "./slices/services";
import feriadosReducer from "./slices/feriados";
import remuneration from "./slices/remuneration";
import fundAccounting from "./slices/fundAccounting";
import backdrop from "./slices/backdrop";
import banks from "./slices/banco";
import currency from "./slices/moneda";
import gananciasReducer from "./slices/ganancias";
import riesgoReducer from "./slices/riesgo";
import areaReducer from "./slices/area";
import paisesReducer from "./slices/paises";
import informesReducer from "./slices/informes";
import checklist from "./slices/checklist";
import gestionEnvios from "./slices/gestionEnvios";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistAuthConfig = {
  key: "auth",
  storage,
  whitelist: ["accessToken", "isLogged"],
};

const store = configureStore({
  reducer: {
    auth: persistReducer(persistAuthConfig, authReducer),
    sidebar: sidebarReducer,
    contact: contactReducer,
    users: usersReducer,
    clients: clientsReducer,
    charges: chargesReducer,
    businessName: businessNameReducer,
    commune: communeReducer,
    contactMeans: contactMeansReducer,
    department: departmentsReducer,
    regions: regionsReducer,
    roles: rolesReducer,
    editModal: editModalReducer,
    createModal: createModalReducer,
    invProducts: invProductsReducer,
    workOrders: workOrdersReducer,
    programming: programmingReducer,
    notification: notificationReducer,
    inventory: inventoryReducer,
    facturacion: facturacionReducer,
    cobranzas: cobranzasReducer,
    document: documentsReducer,
    services: servicesReducer,
    feriados: feriadosReducer,
    remuneration,
    fundAccounting,
    backdrop,
    currency,
    banks,
    ganancias: gananciasReducer,
    riesgo: riesgoReducer,
    area: areaReducer,
    paises: paisesReducer,
    informes: informesReducer,
    checkMenu: checklist,
    gestionEnvios: gestionEnvios,
  },
  middleware: (defaultMiddleware) =>
    defaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
