import textFieldSelectFormat from "../utils/textFieldSelectFormat";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../redux/slices/clients";
import { getCobranzaCliente } from "../redux/slices/cobranzas";

export default function CustomSelectClient({ setLoading }) {
  const { data } = useSelector((state) => state.clients);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const submit = async () => {
    setLoading(true);
    await dispatch(getCobranzaCliente(selected.id)).then(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)} className="flex w-[100%] gap-2">
      <div className="w-full">
        <Autocomplete
          value={selected || { id: 0, label: "" }}
          onChange={(option, value) => setSelected(value)}
          options={textFieldSelectFormat(data, "withCondition")}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccionar Cliente"
              size="small"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                },
              }}
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
            />
          )}
        />
        {/* <Controller
          id="client"
          name="client"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              select
              size="small"
              onChange={onChange}
              value={value || ""}
              label="Cliente"
              error={errors["client"]}
              placeholder=""
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                },
              }}
            >
              {data?.map(
                (option) =>
                  option.id && (
                    <MenuItem key={option.id} dense divider value={option.id}>
                      {option.nombre}
                    </MenuItem>
                  )
              )}
            </TextField>
          )}
        /> */}
      </div>
      <Button variant="outlined" type="submit">
        BUSCAR
      </Button>
    </form>
  );
}
