import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import StorageIcon from "@mui/icons-material/Storage";
import { getStorageInsumosByCode, setter } from "../../redux/slices/informes";
import dayjs from "dayjs";
import { Divider } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function InformeInsumosModal({ codigo_loginteg }) {
  const [open, setOpen] = React.useState(false);
  const { InsumosDetail } = useSelector((state) => state.informes);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(setter({ setter: "InsumosDetail", payload: [] }));
  };

  return (
    <div>
      <StorageIcon
        onClick={() => {
          setOpen(true);
          dispatch(getStorageInsumosByCode(codigo_loginteg));
        }}
        className="cursor-pointer"
        color="primary"
      />

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">
              Detalle {codigo_loginteg}
            </div>
            {InsumosDetail.length && (
              <div className="bg-slate-100 rounded p-3 shadow-md max-h-[400px] overflow-y-auto">
                <div className="grid grid-cols-3 font-semibold p-1">
                  <div>Lote</div>
                  <div>F. Vencimiento</div>
                  <div>Cantidades</div>
                </div>
                <Divider />
                {InsumosDetail.map((el) => (
                  <>
                    <div key={el.id} className="grid grid-cols-3 p-1">
                      <div className="">{el.lotes}</div>
                      <div className="">{dayjs(el.fecha_vencimiento).format("DD-MM-YYYY")}</div>
                      <div className="">{el.cantidad}</div>
                    </div>
                    <Divider />
                  </>
                ))}
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
