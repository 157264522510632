// import React from "react";
// import DataGridComponent from "../components/tables/DataGrid";
// import { useSelector, useDispatch } from "react-redux";
// import { useEffect, useState } from "react";
// import useDataGrid from "../hooks/useDataGrid";
// import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
// import FeriadosCreate from "../components/modal/FeriadosCreate";
// import { deleteFeriado, getAllFeriados } from "../redux/slices/feriados";
// import FeriadosUpdate from "../components/modal/FeriadosUpdate";
// import { deleteArea, getAllAreas } from "../redux/slices/area";
// import AreaCreate from "../components/modal/AreaCreate";
// import AreaUpdate from "../components/modal/AreaUpdate";
// import ContactCreate from "../components/modal/ContactCreate";

// const permitedRenderDataColumn = [
//   { title: "Area", value: "name" },
//   { title: "Acciones", value: "acciones_inv" },
// ];

// export default function Contact() {
//   const { area, success } = useSelector((state) => state.area);
//   const [open, setOpen] = useState({
//     status: false,
//     data: null,
//   });
//   const dispatch = useDispatch();

//   const handleDelete = async (params) => {
//     const id = params.id;
//     await dispatch(deleteArea(id));
//     setOpen({ status: false, data: null });
//   };

//   const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "AREA", false, setOpen);

//   useEffect(() => {
//     dispatch(getAllAreas());
//   }, [dispatch]);

//   return (
//     <div>
//       <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
//         <div>
//           <h1 className="font-semibold text-[#1E293B] text-2xl">Contact</h1>
//           <BreadCrumb origin={"Mantenedores"} current={"Contactos"} />
//         </div>
//         <div>
//           <ContactCreate />
//           <AreaUpdate open={open.status} data={open.data} setOpen={setOpen} />
//         </div>
//       </div>
//       <DataGridComponent columns={columns} rows={area} success={success} />
//     </div>
//   );
// }

import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { deleteArea } from "../redux/slices/area";
import AreaUpdate from "../components/modal/AreaUpdate";
import { deleteContact, getAllContacts } from "../redux/slices/contact";
import ContactCreate from "../components/modal/ContactCreate";
import ContactUpdate from "../components/modal/ContactUpdata";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "nombre" },
  { title: "Cliente", value: "cliente" },
  { title: "Cargo", value: "cargo" },
  { title: "Telefono 1", value: "telefono1" },
  { title: "Correo 1", value: "correo1" },
  { title: "Acciones", value: "acciones_contacto" },
];

export default function Area() {
  const { currentUser } = useSelector((state) => state.auth);
  const isViewMode = currentUser?.action_mode === "view";
  const { data, success } = useSelector((state) => state.contact);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteContact(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "CONTACTO", false, setOpen);

  useEffect(() => {
    if (!data.length) {
      dispatch(getAllContacts());
    }
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Contacto</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Contacto"} />
        </div>
        <div>
          {!isViewMode && <ContactCreate />}
          <ContactUpdate isViewMode={isViewMode} open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={success} />
    </div>
  );
}
