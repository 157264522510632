import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { getAllElements } from "../redux/slices/inventory";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import Teams from "./Teams";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Elemento", value: "elemento" },
  { title: "Cantidad", value: "cantidad" },
  { title: "Acciones", value: "acciones_elements" },
];

export const Elements = ({ isInform }) => {
  const { elements, success } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  const [handleElementClick, setHandleElementClick] = useState({ status: false });

  const handleDelete = () => {};
  const [columns] = useDataGrid(
    permitedRenderDataColumn,
    handleDelete,
    "Listado de elementos",
    false,
    setHandleElementClick,
    isInform
  );

  useEffect(() => {
    dispatch(getAllElements());
  }, [dispatch]);

  if (handleElementClick.status) {
    return <Teams elementSetter={setHandleElementClick} elementData={handleElementClick} />;
  }

  return (
    <div>
      {!isInform && (
        <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
          <div>
            <h1 className="font-semibold text-[#1E293B] text-2xl">Listado de elementos</h1>
            <BreadCrumb origin={"Inventario"} current={"Listado de elementos"} />
          </div>
          <div>{/* <ArticleUpdate open={open.status} data={open.data} setOpen={setOpen} /> */}</div>
        </div>
      )}
      <DataGridComponent columns={columns} rows={elements} success={success} />
    </div>
  );
};
