import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const CommentaryDialog = ({ open, setOpen, id, detailState, setDetailState }) => {
  const [commentary, setCommentary] = useState("");
  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmitReject = () => {
    setSubmited(true);

    if (!commentary.length) {
      return;
    }

    setDetailState((prev) => {
      const updatedState = { ...prev };

      if (updatedState[id]) {
        updatedState[id].commentary = commentary;
      }

      return updatedState;
    });
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ width: 400, height: "auto" }}>
        <div>
          <TextField
            fullWidth
            label="Comentario"
            multiline
            rows={4}
            maxRows={4}
            value={commentary}
            onChange={(event) => setCommentary(event.target.value)}
            error={!commentary.length && submited}
            helperText={!commentary.length && submited ? "El campo es requerido" : " "}
          />
          <div className="w-full flex justify-between">
            <Button fullWidth color="primary" variant="outlined" onClick={handleSubmitReject} autoFocus>
              {loading ? <CircularProgress size={"24px"} color="primary" /> : "Guardar Comentario"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CommentaryDialog;
