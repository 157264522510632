import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  services: [],
  servicesSelect: [],
  success: false,
  status: "idle",
};

const servicesSlice = createSlice({
  name: "bussinesUnit",
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload;
      state.status = true;
      state.success = true;
    },
    setServicesSelect: (state, action) => {
      state.servicesSelect = action.payload;
    },
    create: (state, action) => {
      state.services.push(action.payload);
      state.success = true;
    },
    remove: (state, action) => {
      const updated = state.services.filter((el) => el.id !== action.payload);
      state.services = updated;
      state.success = true;
    },
    update: (state, action) => {
      const updated = state.services.filter((el) => el.id !== action.payload.id);
      state.services = [...updated, action.payload];
      state.success = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getAllServices = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/services");
    dispatch(setServices(response.data.sort((a,b) => a.codigo - b.codigo)));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getAllServicesSelect = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/services/select");
    dispatch(setServicesSelect(response.data));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createServices = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/services", values);
    dispatch(create(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Servicio creado con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear servicio",
        open: true,
      }),
    );
  }
};

export const deleteServices = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/services?id=${id}`);
    dispatch(remove(response.data.id));
    dispatch(
      setNotification({
        status: "success",
        message: "Servicio borrado con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al borrar servicio",
        open: true,
      }),
    );
  }
};

export const updateServices = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/services?id=${values.id}`, values);
    dispatch(update(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Servicio editada con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar servicio",
        open: true,
      }),
    );
  }
};

export const { setServices, setServicesSelect, create, remove, update, setStatus } = servicesSlice.actions;

export default servicesSlice.reducer;
