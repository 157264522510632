import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import { getOTByClientID } from "../../redux/slices/workOrders";
import { setOpen } from "../../redux/slices/editModal";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import HistoryModal from "../modal/HistoryModal";
import dateFormatter from "../../utils/dateFormatter";
import RepairPartOT from "../modal/RepairPartOT";
import Button from "@mui/material/Button";
import axiosInstance from "../../utils/axiosInstance";
import { setNotification } from "../../redux/slices/notification";

const columns = [
  { title: "Equipo", value: "equipo" },
  { title: "Modelo", value: "modelo" },
  { title: "Nº serie", value: "serie" },
  { title: "Fecha de instalacion", value: "instalacion" },
  { title: "Fecha de garantia", value: "garantia" },
  { title: "Acciones", value: "acciones" },
];

export default function AccordionOtProd({ props }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const [cantidades, setCantidades] = useState({});

  const handleHistory = (ot_id, e) => {
    e.preventDefault();
    dispatch(getOTByClientID(ot_id));
    dispatch(setOpen({ open: true, id: ot_id }));
  };

  const handleSubmit = async () => {
    const res = await axiosInstance.put("/ot/update-repair-parts", { cantidades });
    if (res.status === 200) {
      dispatch(
        setNotification({
          status: "success",
          message: "Repuestos actualizados con exito",
          open: true,
        })
      );
    } else {
      dispatch(
        setNotification({
          status: "error",
          message: "Error al actualizar repuesto",
          open: true,
        })
      );
    }
  };

  const equipo = props.ot_equipo_ot_equipo_otToot;

  const repairPartsOT = props.ot_repuesto_ot_repuesto_otToot;

  useEffect(() => {
    if (equipo.length) {
      equipo.forEach((equipment) => {
        const repairParts = equipment.inv_equipo.inv_tipo_equipo.inv_repuesto;
        const equipmentID = equipment.inv_equipo.id;

        repairParts.forEach((repairPart) => {
          const repairPartOT = repairPartsOT.find(
            (part) => part.equipo === equipmentID && part.repuesto === repairPart.id
          );

          const cantidad = repairPartOT ? repairPartOT.cantidad : 0;

          if (cantidades.hasOwnProperty(repairPart.id)) {
            cantidades[repairPart.id] = {
              ...cantidades[repairPart.id],
              [equipmentID]: cantidad,
              ot_id: props.id,
              resp: currentUser.id,
            };
          } else {
            cantidades[repairPart.id] = {
              cantidad_total: repairPart.cantidad,
              [equipmentID]: cantidad,
              ot_id: props.id,
              resp: currentUser.id,
            };
          }
        });

        setCantidades({ ...cantidades });
      });
    }
    // eslint-disable-next-line
  }, [equipo]);

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-bold uppercase tracking-wider	text-sm">Equipos:</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-[full] flex flex-col items-center justify-center md:flex-row lg:justify-start flex-wrap gap-4">
            {equipo?.map((row) => (
              <div
                className="flex flex-col justify-center items-center p-5 w-[100%] h-[300px] sm:w-[350px] bg-slate-100 mb-5"
                key={row.inv_equipo.id}
              >
                <ul>
                  <li>
                    <div className="flex flex-row items-center gap-5">
                      <h6 className="font-semibold text-gray-600">Equipo</h6>
                      <Chip label={row.inv_equipo.id} sx={{ width: "50px" }} />
                    </div>
                  </li>
                  <li>
                    <div className="flex flex-row items-center gap-5">
                      <h6 className="font-semibold text-gray-600">Modelo</h6>
                      <h7>{row.inv_equipo.modelo.toLowerCase()}</h7>
                    </div>
                  </li>
                  <li>
                    <div className="flex flex-row items-center gap-5">
                      <h6 className="font-semibold text-gray-600">N° Serie</h6>
                      <h7>{row.inv_equipo.serie}</h7>
                    </div>
                  </li>
                  <li>
                    <div className="flex flex-row items-center gap-5">
                      <h6 className="font-semibold text-gray-600">Fecha de instalación</h6>
                      <h7>{dateFormatter(row.inv_equipo.f_instalacion)}</h7>
                    </div>
                  </li>
                  <li>
                    <div className="flex flex-row items-center gap-5">
                      <h6 className="font-semibold text-gray-600">Fecha de garantía</h6>
                      <h7>{dateFormatter(row.inv_equipo.f_garantia)}</h7>
                    </div>
                  </li>
                </ul>
                <div className="flex flex-col w-[80%] gap-2 mt-2">
                  {Object.keys(cantidades)?.length && !props.cerrada && (
                    <RepairPartOT
                      idTeam={row.inv_equipo.id}
                      cantidades={cantidades}
                      setCantidades={setCantidades}
                      repuestos={row?.inv_equipo?.inv_tipo_equipo?.inv_repuesto || []}
                    />
                  )}

                  <Button
                    type="button"
                    variant="contained"
                    onClick={(e) => handleHistory(props?.cliente_ot_clienteTocliente?.id, e)}
                    fullWidth
                  >
                    Historial
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button type="submit" variant="contained" onClick={handleSubmit} disabled={props.cerrada} fullWidth>
            Actualizar Repuestos
          </Button>
        </AccordionDetails>
      </Accordion>
      <HistoryModal />
    </div>
  );
}
