import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({
  options,
  name,
  label,
  onChange,
  Form,
  renderOption = "descripcion",
  value,
  setValue,
  size = "small",
  error,
  disabled = false,
}) {
  const [open, toggleOpen] = React.useState(false);
  const handleClose = () => {
    setDialogValue({
      descripcion: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    descripcion: "",
  });

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        disabled={disabled}
        onChange={(_, val) => onChange(val, toggleOpen, setDialogValue)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              [renderOption]: `Agregar "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id={name}
        size={size}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option[renderOption];
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option, idx) => (
          <li {...props} key={idx.index}>
            {option[renderOption]}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} error={error} helperText={error && "* Campo Requerido"} label={label} />
        )}
      />
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogContent>
          {<Form dialogValue={dialogValue} setter={setValue} open={open} handleClose={handleClose} />}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
