import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { TextField, Button, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateObservation } from "../../redux/slices/workOrders";
import Chip from "@mui/material/Chip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import dateFormatter from "../../utils/dateFormatter";

const columns = [
  { title: "Fecha", value: "fecha" },
  { title: "Usuario", value: "usuario" },
  { title: "Observación", value: "observacion" },
];

export default function AccordionOtObs({ props, responsible, otId }) {
  const [observations, setObservations] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleNewObservation = () => {
    setObservations([
      ...observations,
      {
        descripcion: "",
        responsable: responsible,
        ot: parseInt(otId),
      },
    ]);
  };

  const handleChange = (e, index) => {
    let newObs = [...observations];
    observations[index].descripcion = e.target.value;
    setObservations(newObs);
  };

  const handleSubmit = async (e) => {
    setDisabled(true);
    e.preventDefault();
    await dispatch(updateObservation(observations));
    setDisabled(false);
    setObservations([]);
  };

  const handleDelete = (index) => {
    let newObs = [...observations];
    newObs.splice(index, 1);
    setObservations(newObs);
  };
  const obs = props.ot_observacion_ot_observacion_otToot;
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-bold uppercase tracking-wider	text-sm ">Observaciones</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full">
            <div className="flex w-full justify-end mr-2 mb-2">
              <Chip
                label="Añadir"
                disabled={props.cerrada}
                color="primary"
                sx={{ width: "80px" }}
                variant="contained"
                onClick={handleNewObservation}
              />
            </div>
            <div>
              {obs?.map((row, index) => {
                return (
                  <div className="capitalize bg-slate-100 mb-2 p-2" key={index}>
                    <div className="flex gap-3 items-center justify-start p-1  mb-2">
                      <h6 className="font-semibold text-gray-600">Fecha de creacion</h6>
                      <Chip label={dateFormatter(row.f_creaci_n)} />
                    </div>
                    <div className="flex gap-3 items-center justify-start p-1  mb-2">
                      <h6 className="font-semibold text-gray-600">Usuario</h6>
                      <h7>{row.gen_usuario.nombre}</h7>
                    </div>
                    <div className="flex flex-col gap-3 justify-start p-1  mb-2">
                      <h6 className="font-semibold text-gray-600">Observacion</h6>
                      <h7 className="bg-slate-200 w-fit p-2 rounded">{row.descripcion}</h7>
                    </div>
                  </div>
                );
              })}
            </div>
            {observations &&
              observations.map((obs, index) => (
                <div key={index} className="mt-5">
                  <div className="flex relative">
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      multiline
                      size="small"
                      rows={2}
                      value={obs.descripcion}
                      placeholder="Observaciones"
                      onChange={(e) => handleChange(e, index)}
                    />
                    <div className="absolute top-3 right-5">
                      <IconButton onClick={(e) => handleDelete(index)}>
                        <RemoveCircleIcon sx={{ color: "#c22047" }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            {observations.length > 0 && (
              <div className="mt-5 w-full flex justify-center">
                <Button variant="contained" fullWidth onClick={handleSubmit} disabled={props.cerrada || disabled}>
                  ENVIAR
                </Button>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
