import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  area: [],
  success: false,
  status: "idle",
};

const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    setArea: (state, action) => {
      state.area = action.payload;
      state.status = true;
      state.success = true;
    },
    create: (state, action) => {
      state.area.push(action.payload);
      state.success = true;
    },
    remove: (state, action) => {
      const updated = state.area.filter((el) => el.id !== action.payload);
      state.area = updated;
      state.success = true;
    },
    update: (state, action) => {
      const updated = state.area.filter((el) => el.id !== action.payload.id);
      state.area = [...updated, action.payload];
      state.success = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getAllAreas = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/area");
    dispatch(setArea(response.data));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createArea = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/area", values);
    dispatch(create(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Area creada con exito",
        open: true,
      }),
    );

    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear area",
        open: true,
      }),
    );
  }
};

export const deleteArea = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/area/delete?id=${id}`);
    dispatch(remove(response.data.id));
    dispatch(
      setNotification({
        status: "success",
        message: "Area borrada con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al borrar area",
        open: true,
      }),
    );
  }
};

export const updateArea = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/area/update?id=${values.id}`, values);
    dispatch(update(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Area editada con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar area",
        open: true,
      }),
    );
  }
};

export const { setArea, create, remove, update, setStatus } = areaSlice.actions;

export default areaSlice.reducer;
