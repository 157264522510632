import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useState } from "react";
import thousandSeparator from "../../utils/thousandSeparator";
import removeNonNumeric from "../../utils/removeNaN";
import InputAdornment from "@mui/material/InputAdornment";

const HandledTextField = ({ props }) => {
  const {
    label,
    field,
    select,
    list = null,
    date = null,
    control,
    error,
    pattern,
    separator = false,
    account = false,
    disabledByType,
    disabled,
  } = props;

  return (
    <div>
      <div className="items-center font-grandview gap-x-2 mb-2 relative max-lg:hidden">
        <div className="flex items-center font-grandview gap-x-2 w-full">
          {field !== "observacion_general" && (
            <div className="text-gray-600 min-w-[150px] font-bold text-xs  bg-gray-200  h-[40px]  flex items-center pl-2 rounded">
              {label} :
            </div>
          )}
          {!select && !list && !date ? (
            <div className="w-full">
              <Controller
                id={field}
                control={control}
                name={field}
                rules={{ required: true, pattern: pattern }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    rows={field === "observacion_general" && 4}
                    multiline
                    placeholder={field === "observacion_general" && label}
                    fullWidth
                    disabled={field === "monto_rendir" ? true : disabledByType}
                    onChange={(event) =>
                      !separator
                        ? onChange(event.target.value)
                        : onChange(thousandSeparator(removeNonNumeric(event.target.value)))
                    }
                    value={separator && value ? thousandSeparator(value) : value || ""}
                    error={Boolean(error)}
                    InputProps={
                      separator || account
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">{separator ? "$" : account && "N.º"}</InputAdornment>
                            ),
                          }
                        : {}
                    }
                  />
                )}
              />
            </div>
          ) : select ? (
            list.length && (
              <div className="w-full">
                <Controller
                  id={field}
                  control={control}
                  name={field}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      disabled={disabledByType}
                      onChange={onChange}
                      value={value || ""}
                      size="small"
                      fullWidth
                      error={!!Boolean(error)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      {list.map((data, index) => (
                        <MenuItem key={index} value={data.id} dense divider>
                          {data.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>
            )
          ) : (
            date && (
              <div className="w-full">
                <Controller
                  id={field}
                  control={control}
                  name={field}
                  rules={{ required: true }}
                  defaultValue={dayjs()}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"} localeText={esES}>
                      <DatePicker
                        disabled={disabledByType}
                        value={value || ""}
                        onChange={(date) => onChange(date)}
                        slotProps={{ textField: { size: "small", fullWidth: true } }}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            )
          )}
        </div>
        <span className="text-xs absolute text-red-500 top-3 right-7 pointer-events-none">
          {error === "required" ? "El campo es requerido" : error === "pattern" && "El campo es númerico"}
        </span>
      </div>
      <div className="items-center font-grandview gap-x-2 mb-2 relative lg:hidden">
        <div className="flex items-center font-grandview w-full">
          {!select && !list && !date ? (
            <div className="w-full">
              <Controller
                id={field}
                control={control}
                name={field}
                rules={{ required: true, pattern: pattern }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    label={label}
                    fullWidth
                    disabled={disabledByType}
                    onChange={(event) =>
                      !separator
                        ? onChange(event.target.value)
                        : onChange(thousandSeparator(removeNonNumeric(event.target.value)))
                    }
                    value={separator && value ? thousandSeparator(value) : value || ""}
                    error={Boolean(error)}
                    InputProps={
                      separator || account
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">{separator ? "$" : account && "N.º"}</InputAdornment>
                            ),
                          }
                        : {}
                    }
                  />
                )}
              />
            </div>
          ) : select ? (
            list.length && (
              <div className="w-full">
                <Controller
                  id={field}
                  control={control}
                  name={field}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label={label}
                      disabled={disabledByType}
                      onChange={onChange}
                      value={value || ""}
                      size="small"
                      fullWidth
                      error={!!Boolean(error)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      {list.map((data, index) => (
                        <MenuItem key={index} value={data.id} dense divider>
                          {data.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>
            )
          ) : (
            date && (
              <div className="w-full">
                <Controller
                  id={field}
                  control={control}
                  name={field}
                  rules={{ required: true }}
                  defaultValue={dayjs()}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"} localeText={esES}>
                      <DatePicker
                        disabled={disabledByType}
                        value={value || ""}
                        onChange={(date) => onChange(date)}
                        slotProps={{ textField: { size: "small", fullWidth: true } }}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            )
          )}
        </div>
        <span className="text-xs absolute text-red-500 top-3 right-7 pointer-events-none">
          {error === "required" ? "El campo es requerido" : error === "pattern" && "El campo es númerico"}
        </span>
      </div>
    </div>
  );
};

export default HandledTextField;
