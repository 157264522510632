import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: "10px",
    width: "33%",
    borderBottom: "3px solid black",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
    width: "33%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    borderBottom: "1px solid #8c8c8c",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
}));
export default function PreFacturaServicesTable({ rows, setAsignados }) {
  const handleValueCostChange = (id, value) => {
    const updatedRows = rows.map((row) => (row.id === id ? { ...row, valueCost: value === "" ? 0 : parseInt(value) } : row));
    setAsignados(updatedRows);
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>DETALLE</StyledTableCell>
            <StyledTableCell align="right">CANTIDAD</StyledTableCell>
            <StyledTableCell align="right">TOTAL</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.nombre}
                </StyledTableCell>
                <StyledTableCell align="right">{row.cantidad}</StyledTableCell>
                <StyledTableCell align="right">
                  <TextField
                    sx={{ width: 150 }}
                    value={row?.valueCost}
                    onChange={(e) => handleValueCostChange(row.id, e.target.value)}
                    type="number"
                    variant="outlined"
                    size="small"
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
