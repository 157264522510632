function formatDataModal(obj) {
  if (obj) {
    const valueTransforms = {
      number: (value) => parseInt(value),
      true: (value) => true,
      false: (value) => false,
      date: (value) => value,
      default: (value) => value,
      obj: (value) => value.value,
    };

    const newValues = Object.entries(obj).reduce((acc, [key, value]) => {
      const type =
        typeof value === "object" && key !== "imagen" && value !== null && key !== "f_nacimiento"
          ? "obj"
          : value instanceof Date
          ? "date"
          : value === "true"
          ? true
          : value === "false"
          ? false
          : key === "rut" ||
            key === "clave" ||
            key === "confirmar_clave" ||
            key === "imagen" ||
            key === "garantia" ||
            key === "telefono" ||
            key === "telefono1" ||
            key === "telefono2" ||
            key === "generico" ||
            key === "envio_mail"
          ? "default"
          : isNaN(value)
          ? "default"
          : "number";
      acc[key] = valueTransforms[type](value);
      return acc;
    }, {});
    return newValues;
  }
}

export default formatDataModal;
