import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { updatePieza } from "../../redux/slices/inventory";
import { useState } from "react";
import textFieldSelectFormat from "../../utils/textFieldSelectFormat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function PiezasModalUpdate({ open, data, setOpen }) {
  const { teamTypes, itemTypeSelect } = useSelector((state) => state.inventory);
  const [models, setModels] = React.useState([]);
  const [versionsByModel, setVersionsByModel] = React.useState([]);
  const [elements, setElements] = React.useState([]);
  const [handledVersion, setHandledVersion] = React.useState({ models: false, versions: false });
  const [equipoSelect, setEquipoSelect] = useState(null);
  const [modelSelect, setModelSelect] = useState(null);
  const [versionSelect, setVersionSelect] = useState(null);
  const [elementSelect, setElementSelect] = useState(null);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
    setModels([]);
    setVersionsByModel([]);
    setElements([]);
    setHandledVersion({ models: false, versions: false });
  };

  React.useEffect(() => {
    if (teamTypes && data?.id) {
      const teamType = teamTypes.filter((teamType) => teamType.id === data.inv_tipo_equipo);
      const model = teamType[0].inv_modelo.filter((model) => model.id === data.inv_modelo);
      setEquipoSelect({ id: teamType[0].id, label: teamType[0].descripcion });
      setModelSelect({
        id: model[0].id,
        label: model[0].nombre,
      });
      setVersionSelect({ id: model[0].inv_version[0].id, label: model[0].inv_version[0].descripcion });
      setElementSelect({ id: model[0].inv_tipo_item_equipo[0].id, label: model[0].inv_tipo_item_equipo[0].nombre });
      const modelsToRender = [];
      teamType[0].inv_modelo?.forEach((el) =>
        modelsToRender.push({
          id: el.id,
          label: el.nombre,
          version: el.inv_version,
          elementos: el.inv_tipo_item_equipo,
        })
      );
      setModels(modelsToRender);

      const versionToRender = [];
      const elementosToRender = [];
      model[0]?.inv_version?.forEach((el) =>
        versionToRender.push({
          id: el.id,
          label: el.descripcion,
        })
      );
      model[0]?.inv_tipo_item_equipo?.forEach((el) =>
        elementosToRender.push({
          id: el.id,
          label: el.nombre,
        })
      );
      setVersionsByModel(versionToRender);
      setElements(elementosToRender);
    }
  }, [data, teamTypes]);

  const updateNewModel = async (value) => {
    value.inv_tipo_equipo = equipoSelect.id;
    value.inv_modelo = modelSelect.id;
    value.inv_item = elementSelect.id;
    value.version = versionSelect.id;
    dispatch(updatePieza({ ...value, id: data.id }));
    setOpen({ status: false, data: null });
    handleClose();
  };

  const handleEquiposChanges = (equipos) => {
    setEquipoSelect(equipos);
    setModels(
      equipos.modelo.map((el) => ({
        id: el.id,
        label: el.nombre,
        version: el.inv_version,
        elementos: el.inv_tipo_item_equipo,
      }))
    );
    setModelSelect({ id: 0, label: "" });
    setElementSelect({ id: 0, label: "" });
    setVersionSelect({ id: 0, label: "" });
  };

  const handleModeloChange = (modelo) => {
    setModelSelect(modelo);
    setElementSelect({ id: 0, label: "" });
    setVersionSelect({ id: 0, label: "" });
    setElements(modelo.elementos.map((el) => ({ id: el.id, label: el.nombre })));
    setVersionsByModel(modelo.version.map((el) => ({ id: el.id, label: el.descripcion })));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(updateNewModel)}>
                <div className="flex flex-col">
                  <div className="w-full mb-2">
                    <Autocomplete
                      value={equipoSelect || { id: 0, label: "" }}
                      onChange={(option, value) => handleEquiposChanges(value)}
                      options={textFieldSelectFormat(teamTypes, "descripcion")}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="EQUIPOS"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <Autocomplete
                      value={modelSelect || { id: 0, label: "" }}
                      onChange={(option, value) => handleModeloChange(value)}
                      options={models ? models : []}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="MODELO"
                          disabled={!models ? true : false}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <Autocomplete
                      value={versionSelect || { id: 0, label: "" }}
                      onChange={(option, value) => setVersionSelect(value)}
                      options={versionsByModel ? versionsByModel : []}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="VERSION"
                          disabled={!models ? true : false}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <Autocomplete
                      value={elementSelect || { id: 0, label: "" }}
                      onChange={(option, value) => setElementSelect(value)}
                      options={elements ? elements : []}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ELEMENTOS"
                          disabled={!models ? true : false}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      id="cantidad"
                      label="Cantidad"
                      type="text"
                      fullWidth
                      {...register("cantidad", { required: true, pattern: /^[0-9]*$/ })}
                      helperText={
                        errors?.cantidad?.type === "required"
                          ? "El campo es requerido"
                          : errors?.cantidad?.type === "pattern"
                          ? "Es un campo numerico"
                          : " "
                      }
                      error={Boolean(errors["cantidad"])}
                      defaultValue={data?.cantidad || ""}
                    />
                  </div>
                </div>
                <div className="flex items-center w-full justify-center mt-5">
                  <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Enviar
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
