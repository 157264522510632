import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, Button, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editContact } from "../../redux/slices/workOrders";
import { useState } from "react";
import { validateRUT } from "validar-rut";
import { getAllContactMeans } from "../../redux/slices/contactMeans";
import dayjs from "dayjs";

const validarRut = (value, isRutValidationRequired) => {
  if (isRutValidationRequired) {
    return validateRUT(value);
  } else {
    return true;
  }
};

export default function AccordionOtDetail({ props, otId, setCurrentClient }) {
  const cliente = props.cliente_ot_clienteTocliente;
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  // eslint-disable-next-line
  const [isRutValidationRequired, setIsRutValidationRequired] = useState(true);
  const { data: contactMeans } = useSelector((state) => state.contactMeans);
  const { data: clientData } = useSelector((state) => state.clients);
  let contactoPrincipal = {};

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  React.useEffect(() => {
    dispatch(getAllContactMeans());
  }, [dispatch]);

  // const clientWatcher = watch("resp_cliente");

  // React.useEffect(() => {
  //   const clientFillData = clientData.find((el) => el.id === clientWatcher);
  //   if (clientFillData) {
  //     setValue("rut_cliente", clientFillData.rut);
  //     setValue("resp_correo", clientFillData.mail_contacto);
  //   }
  // }, [clientWatcher]);

  cliente?.contacto_contacto_clienteTocliente.forEach((contacto) => {
    if (contacto.principal === true) {
      contactoPrincipal = contacto;
    }
  });

  const handleClick = () => {
    setValue("id", otId);
  };

  const submit = async (value) => {
    if (value?.garantia) value.garantia = Boolean(value.garantia);
    if (value?.garantia_act) value.garantia_act = Boolean(value.garantia_act);
    if (value?.medio_contacto) value.medio_contacto = parseInt(value.medio_contacto);

    setDisabled(true);
    await dispatch(editContact(value));
    setCurrentClient(value.resp_cliente);
    setDisabled(false);
  };

  return (
    <div>
      <Accordion className="font-roboto">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-bold uppercase tracking-wider	text-sm">Información del cliente:</span>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit(submit)}>
            <div className="md:flex pl-10 mb-2 bg-slate-200 rounded capitalize p-2">
              <div className="md:w-[50%] w-[100%]">
                <span className="font-bold uppercase tracking-wider	text-sm min-w-[186px]">cliente:</span>
                <div className=" text-sm">{cliente?.nombre}</div>
              </div>
              <div className="">
                <div className="font-bold uppercase tracking-wider text-sm">Rut:</div>{" "}
                <div className="text-sm">{cliente?.rut}</div>
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 bg-slate-100 rounded capitalize p-2">
              <div className="md:w-[50%] w-[100%]">
                <span className="font-bold uppercase tracking-wider	text-sm">Direccion:</span>{" "}
                <div className=" text-sm">
                  {cliente?.direccion || <span className="text-red-500">No ingresado</span>}
                </div>
              </div>
              <div>
                <span className="font-bold uppercase tracking-wider	text-sm">Telefono:</span>
                <div className=" text-sm">
                  {contactoPrincipal.telefono2 || <span className="text-red-500">No ingresado</span>}
                </div>
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 bg-slate-200 rounded capitalize p-2">
              <div className="md:w-[50%] w-[100%]">
                <span className="font-bold uppercase tracking-wider	text-sm">Comuna:</span>{" "}
                <div className=" text-sm">
                  {cliente?.gen_comuna.descripcion || <span className="text-red-500">No ingresado</span>}
                </div>
              </div>
              <div>
                <span className="font-bold uppercase tracking-wider	text-sm">
                  <span className="font-bold uppercase tracking-wider	text-sm">Email:</span>
                </span>
                <div className="text-sm">
                  {contactoPrincipal?.correo1 || <span className="text-red-500">No ingresado</span>}
                </div>
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 bg-slate-100 rounded capitalize p-2">
              <div className="md:w-[50%] w-[100%]">
                <span className="font-bold uppercase tracking-wider	text-sm">Contacto:</span>{" "}
                <div className="text-sm">{"Principal" || <span className="text-red-500">No ingresado</span>}</div>
              </div>
              <div>
                <span className="font-bold uppercase tracking-wider	text-sm">Celular:</span>{" "}
                <div className="text-sm">
                  {contactoPrincipal.telefono1 || <span className="text-red-500">No ingresado</span>}
                </div>
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 capitalize mt-3 rounded p-1 bg-slate-200">
              <div className="md:w-[50%] w-[100%] flex flex-col">
                <span className="font-bold uppercase tracking-wider	text-sm">Garantia:</span>
                <div className="text-sm mt-1">{props?.garantia ? "SI" : "NO"}</div>
              </div>
              <div className="md:w-[50%] w-[100%] flex flex-col">
                <span className="font-bold uppercase tracking-wider	text-sm">Validacion Garantia:</span>
                <TextField
                  size="small"
                  required
                  className="w-[80%] md:w-[90%]"
                  defaultValue={props?.garantia_act}
                  {...register("garantia_act")}
                  variant="standard"
                  select
                  disabled={props.cerrada}
                >
                  <MenuItem value={true}>SI</MenuItem>
                  <MenuItem value={false}>NO</MenuItem>
                </TextField>
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 mt-3 rounded p-1 bg-slate-100">
              <div className="flex flex-col w-[100%] capitalize md:w-[50%]">
                <label className="">
                  <span className="font-bold uppercase tracking-wider	text-sm">Cliente responsable:</span>
                </label>
                <TextField
                  id="filled-disabled"
                  defaultValue={props?.contacto?.id}
                  select
                  size="small"
                  className="w-[80%] md:w-[90%]"
                  variant="standard"
                  required
                  disabled={props.cerrada}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#707070",
                    },
                  }}
                  {...register("resp_cliente")}
                >
                  {props.cliente_ot_clienteTocliente.contacto_contacto_clienteTocliente
                    ?.filter((el) => el.estado === true)
                    ?.filter((el) => el.id !== cliente.id)
                    .map((contactMean) => (
                      <MenuItem key={contactMean.id} value={contactMean.id}>
                        {contactMean.nombre}
                      </MenuItem>
                    ))}
                </TextField>
                {/* <TextField
                  size="small"
                  className="w-[80%] md:w-[90%]"
                  defaultValue={props?.resp_cliente}
                  {...register("resp_cliente")}
                  variant="standard"
                  disabled={props.cerrada}
                /> */}
              </div>
              <div className="flex flex-col w-[100%] capitalize md:w-[50%]">
                <label>
                  <span className="font-bold uppercase tracking-wider	text-sm">Email responsable:</span>
                </label>
                <TextField
                  size="small"
                  className="w-[80%] md:w-[90%]"
                  defaultValue={props?.resp_correo}
                  {...register("resp_correo")}
                  variant="standard"
                  disabled={props.cerrada}
                />
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 mt-3 rounded p-1 bg-slate-200">
              <div className="flex flex-col w-[100%] capitalize md:w-[50%]">
                <span className="font-bold uppercase tracking-wider	text-sm">Medio de contacto:</span>
                <TextField
                  id="filled-disabled"
                  defaultValue={props?.medio_contacto}
                  select
                  size="small"
                  className="w-[80%] md:w-[90%]"
                  variant="standard"
                  required
                  disabled={props.cerrada}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#707070",
                    },
                  }}
                  {...register("medio_contacto")}
                >
                  {contactMeans?.map((contactMean) => (
                    <MenuItem key={contactMean.id} value={contactMean.id}>
                      {contactMean.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="flex flex-col w-[100%] capitalize md:w-[50%]">
                <span className="font-bold uppercase tracking-wider	text-sm">Horario de atención:</span>
                <div className="mt-2 text-sm">
                  {props?.horario_atencion ? (
                    dayjs(props.horario_atencion).format("DD/MM/YYYY HH:mm")
                  ) : (
                    <span className="text-red-500">No ingresado</span>
                  )}
                </div>
              </div>
            </div>
            <div className="md:flex pl-10 mb-2 mt-3 rounded p-1 bg-slate-100">
              <div className="flex flex-col w-[100%] capitalize md:w-[50%]">
                <span className="font-bold uppercase tracking-wider	text-sm">Rut del cliente:</span>
                <TextField
                  id="filled-disabled"
                  defaultValue={props?.rut_cliente || ""}
                  className="w-[80%] md:w-[90%]"
                  size="small"
                  variant="standard"
                  helperText={errors.rut_cliente?.type === "validarRut" && "El RUT es invalido"}
                  required
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#707070",
                    },
                  }}
                  disabled={props.cerrada}
                  {...register("rut_cliente", {
                    required: true,
                    validate: {
                      validarRut: (val) => {
                        return validarRut(val, isRutValidationRequired);
                      },
                    },
                  })}
                />
              </div>
              <div className="flex flex-col w-[100%] capitalize md:w-[50%]"></div>
            </div>

            <div className="mt-[50px] flex justify-center">
              <Button
                fullWidth
                type="submit"
                variant="contained"
                onClick={handleClick}
                disabled={props.cerrada || disabled}
              >
                ENVIAR
              </Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
