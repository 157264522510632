import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/createModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "750px",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 1,
};

export default function NewModal({ children }) {
  const { open, form } = useSelector((state) => state.createModal);
  const dispatchEvent = useDispatch();

  return (
    <Modal
      open={open}
      onClose={() => dispatchEvent(setOpen(false))}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>{form}</Box>
      </Fade>
    </Modal>
  );
}
