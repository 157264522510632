import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import { useEffect } from "react";
import { getContactsDetail, getTeamsAssigned } from "../../../redux/slices/informes";

export default function EquiposAsignados() {
  const { teamsAssigned } = useSelector((state) => state.informes);
  const columnStorageInsumos = [
    { title: "Nombre", value: "nombre" },
    { title: "Rut", value: "rut" },
    { title: "Pais", value: "pais" },
    { title: "Comuna", value: "comuna" },
    { title: "Region", value: "region" },
    { title: "Direccion", value: "direccion" },
    { title: "Razon Social", value: "razon_social" },
    { title: "Tipo de Empresa", value: "tipo_empresa" },
    { title: "Equipo", value: "tipo_equipo" },
    { title: "Marca", value: "marca" },
    { title: "Modelo", value: "modelo" },
    { title: "Version", value: "version" },
    { title: "Serie", value: "serie" },
  ];

  const [columns] = useDataGrid(columnStorageInsumos, () => {}, "CARGO");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamsAssigned());
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <DataGridComponent columns={columns} rows={teamsAssigned} success={true} size="40px" />
    </div>
  );
}
