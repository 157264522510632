import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import GenericDataGrid from "../tables/GenericDataGrid";
import useFieldsReadOnly from "./constant/useFieldsReadOnly";
import { getClientById, resetClientById } from "../../redux/slices/clients";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useParams } from "react-router-dom";
import enviroments from "../../utils/enviroments";

// data self
const selfColumn = [
  { title: "Razon Social", value: "tipo_razon_social" },
  { title: "Rut", value: "rut" },
  { title: "Jerarquía", value: "hijoRender" },
  { title: "Tipo Empresa", value: "tipo_empresa" },
];

// contact_contacto_clienteToCliente
const contactsColumns = [
  { title: "Nombre", value: "nombre" },
  { title: "Cargo", value: "cargo" },
  { title: "Correo Institucional", value: "correo" },
  { title: "Telefono", value: "telefono1" },
  { title: "Es contacto principal", value: "principal" },
];

//other_cliente
const childrenColumns = [
  { title: "Nombre", value: "nombre" },
  { title: "Razon social", value: "tipo_razon_social" },
  { title: "Rut", value: "rut" },
  { title: "Tipo Empresa", value: "principal" },
  { title: "Visualizar", value: "visualizate" },
];

export const ClientDetail = () => {
  const { id } = useParams();
  const fields = useFieldsReadOnly();
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getClientById(id));
    } else {
      navigate("/clients");
    }

    return () => dispatch(resetClientById());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  return client.id ? (
    <div className="calculated-height flex p-2">
      <div className="w-64 max-w-64 bg-slate-100 rounded items-start justify-center flex flex-col h-full px-4">
        <h1 className="font-semibold text-[#1E293B] mb-5 text-2xl">Detalle de cliente</h1>
        <div className="mb-5 w-52 h-52">
          <Avatar
            alt="Remy Sharp"
            src={client.img && `${enviroments.img_endpoint}${client.img}`}
            sx={{ width: 190, height: 190 }}
            variant="rounded"
          />
        </div>
        <div className="w-full flex-wrap">
          {fields &&
            fields.map((field, index) => (
              <div key={index} className="mb-5">
                <label className="text-xs text-slate-400">{field.label}</label>
                {field.label === "Email contacto" ? (
                  <p className="text-lg break-words w-full">{field.value.toLowerCase()}</p>
                ) : (
                  <p className="text-lg capitalize flex flex-wrap max-w-full">{field.value.toLowerCase()}</p>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className="w-skeleton px-5">
        <GenericDataGrid columns={selfColumn} rows={[client]} type={"self"} />
        {!client?.contacto_contacto_clienteTocliente.filter((cliente) => cliente.estado)?.length ? null : (
          <GenericDataGrid
            columns={contactsColumns}
            rows={client?.contacto_contacto_clienteTocliente.filter((cliente) => cliente.estado)}
            type={"contact"}
          />
        )}
        {!client?.other_cliente.filter((children) => children.estado === true)?.length ? null : (
          <GenericDataGrid
            columns={childrenColumns}
            rows={client?.other_cliente.filter((children) => children.estado === true)}
            type={"children"}
          />
        )}
      </div>
    </div>
  ) : (
    <Box sx={{ width: "95%", position: "absolute" }}>
      <LinearProgress />
    </Box>
  );
};

export default ClientDetail;
