import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import { Button, Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { editProduct, removeOtProgramation, removeProduct, addProduct } from "../../redux/slices/invProducts";
import { useState } from "react";
import AssignmentModal from "../modal/AssignmentModal";
import CircularProgress from "@mui/material/CircularProgress";
import dateFormatter from "../../utils/dateFormatter";

export default function AccordionEquipaments({ equipo, idCliente, idx, setSelected, selected }) {
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleRemove = async (equipo, id) => {
    setDisabled(true);
    await dispatch(removeProduct(id));
    await dispatch(removeOtProgramation(equipo));
    await dispatch(
      editProduct({
        id: equipo,
        estado: 4,
        f_garantia: null,
        f_contrato: null,
        f_instalacion: null,
        f_actualizacion: dayjs(),
      }),
    );
    window.location.reload();
  };

  const assignEquipment = async (idCliente, idProduct) => {
    setDisabled(true);
    await dispatch(addProduct(idProduct, parseInt(idCliente)));
    await dispatch(editProduct({ id: idProduct, estado: 5, f_garantia: null, f_contrato: null, f_instalacion: null }));

    window.location.reload();
  };

  return equipo.inv_estado.id === 1 || equipo.inv_estado.id === 4 ? (
    <div key={idx} className="mb-2 flex items-center">
      <Checkbox
        checked={selected.find((el) => el === equipo.id)}
        onClick={(e) => {
          if (e.target.checked) {
            setSelected((prev) => [...prev, equipo.id]);
          } else {
            setSelected((prev) => [...prev.filter((el) => el !== equipo.id)]);
          }
        }}
        size="small"
      />
      <Accordion
        sx={{
          borderRadius: "5px",
        }}
      >
        <AccordionSummary
          sx={{ backgroundColor: "rgb(226 232 240)", borderRadius: "5px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="lg:flex w-[100%] justify-between">
            <div className="lg:grid grid-cols-5  lg:min-w-[700px] items-start">
              <div className=" capitalize font-semibold">{equipo.inv_tipo_equipo.descripcion.toLowerCase()}</div>
              <div className="lg:ml-10 text-sm lg:text-regular font-medium text-gray-500 capitalize">
                Marca: {equipo.inv_marca.descripcion}
              </div>
              <div className="text-sm lg:ml-10 lg:text-regular lg:min-w-[190px] font-medium text-gray-500 capitalize">
                Modelo: {equipo.modelo}
              </div>
              <div className="lg:ml-10 text-sm lg:text-regular font-medium text-gray-500 capitalize">
                Proveedor: {equipo.inv_proveedor.descripcion}
              </div>
              <div className="lg:ml-10 lg:min-w-[120px] text-sm lg:text-regular font-medium text-gray-500 capitalize">
                Serie: {equipo.serie}
              </div>
            </div>
            <div className="lg:ml-5">
              <Chip
                size="small"
                label={equipo.inv_estado.descripcion}
                sx={{ textTransform: "capitalize", marginRight: "20px", minWidth: "85px" }}
                color="success"
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-2">
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Modelo:</b> {equipo.modelo}
            </div>
            <div className="bg-slate-200 p-1 mb-1 pl-0">
              <b>Serie:</b> {equipo.serie}
            </div>
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Marca:</b> {equipo.inv_marca.descripcion}
            </div>
            <div className="bg-slate-200 p-1 mb-1 pl-0">
              <b>Proveedor:</b> {equipo.inv_proveedor.descripcion}
            </div>
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Fecha de ingreso inventario:</b> {dateFormatter(equipo.f_creacion)}
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <Button variant="contained" onClick={() => assignEquipment(idCliente, equipo.id)}>
              Añadir
            </Button>
            {/* <AssignmentModal idProduct={equipo.id} idCliente={idCliente} action={"add"} /> */}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <div className="mb-2">
      <Accordion
        sx={{
          marginBottom: 1,
          borderRadius: "5px",
        }}
      >
        <AccordionSummary
          sx={{ backgroundColor: "rgb(226 232 240)", borderRadius: "5px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="lg:flex w-[100%]  justify-between">
            <div className="grid w-full grid-cols-5 lg:min-w-[700px] items-start">
              <div className=" capitalize font-semibold">{equipo.inv_tipo_equipo.descripcion.toLowerCase()}</div>
              <div className="lg:ml-10 text-sm lg:text-regular font-medium text-gray-500 capitalize">
                Marca: {equipo.inv_marca.descripcion}
              </div>
              <div className="text-sm lg:ml-10 lg:text-regular lg:min-w-[190px] font-medium text-gray-500 capitalize">
                Modelo: {equipo.modelo}
              </div>
              <div className="lg:ml-10 text-sm lg:text-regular lg:min-w-[190px] font-medium text-gray-500 capitalize">
                Proveedor: {equipo.inv_proveedor.descripcion}
              </div>
              <div className="lg:ml-10 lg:min-w-[120px] text-sm lg:text-regular font-medium text-gray-500 capitalize">
                Serie: {equipo.serie}
              </div>
            </div>
            <div className="lg:ml-5">
              <Chip
                size="small"
                label={"ASIGNADO"}
                sx={{
                  marginRight: "20px",
                  textTransform: "capitalize",
                  backgroundColor: "#c44d58",
                  color: "white",
                }}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-2">
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Modelo:</b> {equipo.modelo}
            </div>
            <div className="bg-slate-200 p-1 mb-1 pl-0">
              <b>Serie:</b> {equipo.serie}
            </div>
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Marca:</b> {equipo.inv_marca.descripcion}
            </div>
            <div className="bg-slate-200 p-1 mb-1 pl-0">
              <b>Proveedor:</b> {equipo.inv_proveedor.descripcion}
            </div>
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Fecha de ingreso inventario:</b> {dateFormatter(equipo.f_creacion)}
            </div>
            <div className="bg-slate-200 p-1 mb-1 pl-0">
              <b>Fecha de instalacion:</b> {dateFormatter(equipo.f_instalacion)}
            </div>
            <div className="bg-slate-100 p-1 mb-1 pl-0">
              <b>Fecha de Garantia:</b> {dateFormatter(equipo.f_garantia)}
            </div>
            <div className="bg-slate-200 p-1 mb-1 pl-0">
              <b>Fecha de Contrato:</b>{" "}
              {equipo.f_contrato ? dateFormatter(equipo.f_contrato) : "No posee fecha de contrato"}
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <Button
              variant="contained"
              sx={{
                width: "100px",
                height: "35px",
                backgroundColor: "#c44d58",
                color: "white",
                borderRadius: 2,
                marginRight: 5,
              }}
              disabled={disabled}
              onClick={() => handleRemove(equipo.id, equipo.cliente_equipo[0].id)}
            >
              QUITAR
              {disabled === true && (
                <div className="w-[15px] h-[100%] flex items-center ml-2">
                  <CircularProgress style={{ width: "15px", height: "15px" }} />
                </div>
              )}
            </Button>
            <AssignmentModal
              idProduct={equipo.id}
              idCliente={idCliente}
              action={"edit"}
              f_garantia={equipo.f_garantia}
              f_contrato={equipo.f_contrato}
              f_instalacion={equipo.f_instalacion}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
