import { createSlice } from "@reduxjs/toolkit";
import formatDataModal from "../../../utils/formatDataModal";
import axiosInstance from "../../../utils/axiosInstance";
import { setCurrentUser } from "../auth";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  success: false,
};

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    addUser: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateUser: (state, action) => {
      const updated = state.data.filter((user) => {
        return user.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveUser: (state, action) => {
      const updated = state.data.filter((user) => {
        return user.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/usuario");

    const users = res.data.map((user) => ({
      ...user,
      cargo: user.gen_cargo.descripcion,
      departamento: user.gen_departamento.descripcion,
      rol: user.gen_rol.descripcion,
    }));
    dispatch(setUsers(users));
  } catch (error) {
    console.log(error);
  }
};
export const getAllDeletedUsers = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/usuario/deleted");

    const users = res.data.map((user) => ({
      ...user,
      cargo: user.gen_cargo.descripcion,
      departamento: user.gen_departamento.descripcion,
      rol: user.gen_rol.descripcion,
    }));
    dispatch(setUsers(users));
  } catch (error) {
    console.log(error);
  }
};

export const createNewUser = (data) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/usuario/register", data);
    const user = {
      ...res.data,
      cargo: res.data.gen_cargo.descripcion,
      departamento: res.data.gen_departamento.descripcion,
      rol: res.data.gen_rol.descripcion,
    };
    dispatch(addUser(user));
    dispatch(
      setNotification({
        status: "success",
        message: "Usuario creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al crear usuario",
        open: true,
      })
    );
    console.log(error);
  }
};

export const updateUser = (data, profile) => async (dispatch) => {
  if (profile) data.profile = true;

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/usuario/update", data);
    if (profile) {
      dispatch(setCurrentUser(res.data));
    }

    const user = {
      ...res.data,
      cargo: res.data.gen_cargo.descripcion,
      departamento: res.data.gen_departamento.descripcion,
      rol: res.data.gen_rol.descripcion,
    };

    dispatch(setUpdateUser(user));
    dispatch(
      setNotification({
        status: "success",
        message: "Usuario editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al editar usuario",
        open: true,
      })
    );
  }
};

export const updateUserProfile = (data, profile) => async (dispatch) => {
  if (profile) data.profile = true;

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/usuario/updateProfile", data);
    if (profile) {
      dispatch(setCurrentUser(res.data));
    }

    dispatch(setUpdateUser(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Usuario editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al editar usuario",
        open: true,
      })
    );
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/usuario/${id}`);
    dispatch(setRemoveUser(deleted.data.deleted));
    dispatch(
      setNotification({
        status: "success",
        message: "Usuario eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al eliminar usuario",
        open: true,
      })
    );
  }
};
export const recoverUser = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const response = await axiosInstance.put(`/usuario/recover/${id}`);
    dispatch(setRemoveUser(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Usuario recuperado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al recuperar usuario",
        open: true,
      })
    );
  }
};

export const { addUser, setUsers, setUpdateUser, setRemoveUser, setSuccess } = usersSlice.actions;

export default usersSlice.reducer;
