import DataGridComponent from "../../components/tables/DataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { dataColumnsList } from "../../hooks/fundAccounting";
import useDataGrid from "../../hooks/useDataGrid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFundAccounting, getAllFundAccountingsRegisters } from "../../redux/slices/fundAccounting";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function ListadoRendiciones() {
  const dispatch = useDispatch();
  const { fundAccounting } = useSelector((state) => state.fundAccounting);
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = async (param) => {
    const response = await dispatch(deleteFundAccounting(param.id));
    return response;
  };

  useEffect(() => {
    dispatch(getAllFundAccountingsRegisters());
  }, [dispatch]);

  const [column] = useDataGrid(dataColumnsList, handleDelete, "", false);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Listado Rendiciones</h1>
          <BreadCrumb origin={"Rendicion de gastos"} current={"Listado Rendiciones"} />
        </div>
      </div>
      <DataGridComponent
        columns={column}
        rows={[...fundAccounting].sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        })}
        success={true}
      />
    </div>
  );
}
