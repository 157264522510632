import dayjs from "dayjs";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import dateFormatter from "../../utils/dateFormatter";

const AccordionHistoryAssign = ({ order }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      sx={{ backgroundColor: "eeee", marginBottom: 2 }}
      key={order.id}
      expanded={expanded === order.id}
      onChange={handleChange(order.id)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <div className="flex w-full justify-between items-center">
          <div className="sm:flex w-full">
            <div className="font-bold sm:min-w-[15%]">OT {order.id}</div>
            <div className="text-sm sm:text-regular font-medium text-gray-500">
              Cliente {order?.cliente_ot_clienteTocliente?.nombre}
            </div>
            <div className="ml-10 min-w-[170px]">
              {/* {order?.ot_equipo_ot_equipo_otToot?.map((equipo, index) => (
                <Chip
                  key={index}
                  size="small"
                  label={equipo.inv_equipo?.descripcion}
                  sx={{ marginLeft: 1 }}
                  color="primary"
                />
              ))} */}
            </div>
          </div>
          <div className="sm:mr-10">
            <Chip
              size="small"
              sx={{ minWidth: 91 }}
              label={
                order.finalizada ? "Finalizada" : order.cerrada ? "Cerrada" : order.tecnico ? "Asignada" : "Sin Asignar"
              }
              variant="outlined"
              color={order.finalizada ? "success" : order.cerrada ? "primary" : order.tecnico ? "warning" : "error"}
            />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="text-lg font-bold mb-2">Detalle: </div>
        <div>
          <div className="flex p-1 pl-0 mb-1 bg-slate-200 rounded">
            <div className="min-w-[110px] font-semibold mr-3 px-1">Cliente: </div>
            <div>{order?.cliente_ot_clienteTocliente?.nombre}</div>
          </div>
          <div className="flex p-1 pl-0 mb-1 bg-slate-100 rounded">
            <div className="min-w-[110px] font-semibold mr-3 px-1">Garantia: </div>
            <div>{order?.garantia ? "Si" : "No"}</div>
          </div>
          <div className="flex p-1 mb-1 pl-0 bg-slate-200 rounded">
            <div className="min-w-[110px] font-semibold mr-3 px-1">Fecha de OT: </div>
            <div>{dateFormatter(order?.f_ot)}</div>
          </div>
          <div className="p-1 mb-1 pl-0  bg-slate-100 rounded">
            <div className="min-w-[110px] text-lg font-semibold mr-3 px-1">Equipos: </div>
            <div>
              {order?.ot_equipo_ot_equipo_otToot?.map((equipo) => (
                <div key={equipo.id} className="p-1 bg-slate-200 rounded mb-2">
                  <div className="flex">
                    <strong className="min-w-[110px] mr-1">ID:</strong> {equipo.id}
                  </div>
                  <div className="capitalize flex">
                    <strong className="min-w-[110px] mr-1">Equipo:</strong>
                    {equipo.inv_equipo.inv_tipo_equipo.descripcion.toLowerCase()}
                  </div>
                  <div className="capitalize flex">
                    <strong className="min-w-[110px] mr-1">Modelo:</strong>
                    {equipo.inv_equipo.modelo.toLowerCase()}
                  </div>
                  <div className="capitalize flex">
                    <strong className="min-w-[110px] mr-1">Nº serie:</strong> {equipo.inv_equipo.serie}
                  </div>
                  <div className="capitalize flex">
                    <strong className="min-w-[110px] mr-1">Instalacion:</strong>
                    {dateFormatter(equipo.inv_equipo.f_instalacion)}
                  </div>
                  <div className="capitalize flex">
                    <strong className="min-w-[110px] mr-1">Garantia:</strong>
                    {dateFormatter(equipo.inv_equipo.f_garantia)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {order.ot_observacion_ot_observacion_otToot[0] && (
            <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
              <div className="min-w-[110px] font-semibold mr-3 px-1">Observacion inicial: </div>
              <div>{order.ot_observacion_ot_observacion_otToot[0].descripcion}</div>
            </div>
          )}

          <div
            className={`flex p-1 mb-1 pl-0 ${
              order.finalizada
                ? "bg-green-100"
                : order.cerrada
                ? "bg-blue-100"
                : order.tecnico
                ? "bg-orange-100"
                : "bg-red-100"
            } rounded`}
          >
            <div className="min-w-[110px] font-semibold mr-3 px-1">Estado: </div>
            <div>
              {order.finalizada
                ? `Finalizada`
                : order.cerrada
                ? `Cerrada por técnico ${order?.img_tecnico}`
                : order.tecnico
                ? `Asignado a técnico ${order?.img_tecnico}`
                : "Sin Asignar"}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionHistoryAssign;
