import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, MenuItem, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { createVersion, getAllTeamTypes } from "../../redux/slices/inventory";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function VersionCreate() {
  const [open, setOpen] = React.useState(false);
  const { teamTypes } = useSelector((state) => state.inventory);
  const [models, setModales] = useState([]);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const handleClose = () => {
    setModales([]);
    setOpen(false);
    reset();
    setError(false);
  };

  const submitVersion = async (values) => {
    if (typeof values.modelo !== "number") {
      setError(true);
      return;
    } else {
      setError(false);
    }

    dispatch(createVersion(values));
    handleClose();
  };

  const handleChangeTeam = (team) => {
    setModales(team.inv_modelo);
  };

  useEffect(() => {
    dispatch(getAllTeamTypes());
  }, []);

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Crear Versión"
        color="primary"
      />

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(submitVersion)}>
              <div className="flex flex-col">
                <div className="w-full mb-5">
                  <TextField
                    fullWidth
                    id={"descripcion"}
                    label={"Descripcion"}
                    type={"text"}
                    {...register("descripcion")}
                    error={Boolean(errors["descripcion"])}
                    helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                    defaultValue={""}
                  />
                </div>
                <div className="w-full mb-5">
                  <TextField fullWidth id="tipo_equipo" label="Equipo" select defaultValue={""}>
                    {teamTypes?.map((team) => (
                      <MenuItem key={team.id} value={team.id} onClick={() => handleChangeTeam(team)}>
                        {team.descripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="w-full mb-5">
                  <TextField
                    fullWidth
                    id="modelo"
                    label="Modelo"
                    select
                    defaultValue={""}
                    {...register("modelo")}
                    error={error}
                    helperText={error && "El campo es requerido"}
                  >
                    {models?.map((model) => (
                      <MenuItem key={model.id} value={model.id}>
                        {model.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="flex mb-5 w-full justify-between">
                  <div className="w-[48%]">
                    <TextField
                      fullWidth
                      id={"altura"}
                      label={"Altura"}
                      type={"text"}
                      {...register("altura")}
                      defaultValue={""}
                    />
                  </div>
                  <div className="w-[48%]">
                    <TextField
                      fullWidth
                      id={"ancho"}
                      label={"Ancho"}
                      type={"text"}
                      {...register("ancho")}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="flex mb-5 w-full justify-between">
                  <div className="w-[48%]">
                    <TextField
                      fullWidth
                      id={"profundidad"}
                      label={"Profundidad"}
                      type={"text"}
                      {...register("profundidad")}
                      defaultValue={""}
                    />
                  </div>
                  <div className="w-[48%]">
                    <TextField
                      fullWidth
                      id={"observacion"}
                      label={"Observación"}
                      type={"text"}
                      {...register("configuracion")}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
