import * as Yup from "yup";

const repairPartSchema = Yup.object().shape({
  descripcion: Yup.string().required("El campo es requerido"),
  marca: Yup.object()
    .shape({
      label: Yup.string().required("El campo es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Cargo es requerido"),
  modelo: Yup.string().required("El campo es requerido"),
  tipo_equipo: Yup.object()
    .shape({
      label: Yup.string().required("El campo es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Cargo es requerido"),
  estado: Yup.object()
    .shape({
      label: Yup.string().required("Cargo es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Cargo es requerido"),
  cantidad: Yup.string().test("is-number", "Solo se admiten numeros", function (value) {
    if (!value || value.trim() === "") {
      return true;
    }
    return /[0-9]/.test(value);
  }),
});

export default repairPartSchema;
