import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Chip, MenuItem, FormControlLabel, Radio, RadioGroup, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { createNewClient, updateClient } from "../../redux/slices/clients";
import { getAllPaises } from "../../redux/slices/paises";
import { createNewStorage, updateStorage } from "../../redux/slices/inventory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function StorageForm({ clients, open_edit, data_edit, close_edit, bodegas }) {
  const [open, setOpen] = React.useState(false);
  const { paises } = useSelector((state) => state.paises);
  const [type, setType] = React.useState(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    if (data_edit) {
      close_edit({ status: false, data: null });
    }
    setType(false);
    setOpen(false);
    reset({ nombre: "", paisId: "", adress: "" });
  };

  const handle_submit = async (values) => {
    if (!!!data_edit) {
      await dispatch(createNewStorage({ ...values, type }));
    } else {
      values.id = data_edit.id;
      await dispatch(updateStorage({ ...values, type, id: data_edit.id }));
      close_edit({ status: false, data: null });
    }

    handleClose();
    reset();
  };

  useEffect(() => {
    dispatch(getAllPaises());
  }, [open_edit]);

  useEffect(() => {
    if (!!data_edit) {
      setType(data_edit.main);
      reset({ nombre: data_edit.name, adress: data_edit.adress, paisId: data_edit.paisesId });
    }
  }, [data_edit, open_edit]);

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Añadir Bodega"
        color="primary"
      />

      <Modal
        open={open || open_edit}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open || open_edit}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(handle_submit)}>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-4 items-center justify-between flex">
                {data_edit ? "EDITAR" : "AÑADIR"} BODEGA
                {!bodegas.filter((el) => el?.id !== data_edit?.id).some((el) => el.main) && (
                  <div className="flex justify-end items-center">
                    <Checkbox onChange={(e) => setType(e.target.checked)} checked={type} />
                    <span>Bodega principal</span>
                  </div>
                )}
              </div>
              <div className="max-xl:max-h-[520px] max-xl:overflow-y-auto max-xl:w-[350px] w-[800px]">
                <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                  <div className="min-h-[65px] mb-1">
                    <Controller
                      id="nombre"
                      name="nombre"
                      control={control}
                      rules={{ required: "* Campo requerido" }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          label="Nombre"
                          onChange={onChange}
                          size="small"
                          value={value || ""}
                          error={errors.nombre}
                          helperText={errors.nombre?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="min-h-[65px] mb-1">
                    <Controller
                      id="adress"
                      name="adress"
                      control={control}
                      rules={{ required: "* Campo requerido" }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          label="Dirección"
                          onChange={onChange}
                          size="small"
                          value={value || ""}
                          error={errors.adress}
                          helperText={errors.adress?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="paisId"
                    name="paisId"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        onChange={onChange}
                        value={value || ""}
                        label="País"
                        error={errors.paisId}
                        helperText={errors.paisId?.message}
                        placeholder=""
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          },
                        }}
                      >
                        {paises?.map(
                          (option) =>
                            option.id && (
                              <MenuItem key={option.id} dense divider value={option.id}>
                                {option.name}
                              </MenuItem>
                            ),
                        )}
                      </TextField>
                    )}
                  />
                </div>
              </div>

              <div className="flex items-center w-full justify-between max-xl:mt-5">
                <Button type="submit" variant="outlined" fullWidth>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
