import * as Yup from "yup";

const chargesSchema = Yup.object().shape({
  descripcion: Yup.string().required("Nombre requerido"),
  tipo: Yup.object()
    .shape({
      label: Yup.string().required("Tipo es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Tipo requerido"),
});

export default chargesSchema;
