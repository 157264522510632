import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import { deleteProvider, getAllProviders } from "../redux/slices/inventory";
import ProvidersCreate from "../components/modal/ProvidersCreate";
import ProvidersUpdate from "../components/modal/ProvidersUpdate";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { value: "descripcion", title: "Nombre", minWidth: 120 },
  { value: "contacto", title: "Contacto", minWidth: 100 },
  { value: "correo", title: "Correo", minWidth: 100 },
  { value: "rut", title: "Rut", minWidth: 100 },
  { value: "nacionalText", title: "Nacional", minWidth: 100 },
  {
    value: "acciones_inv",
    title: "Acciones",
    minWidth: 170,
    align: "center",
  },
];

export default function Providers() {
  const dispatch = useDispatch();
  const { providers, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(deleteProvider(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Proveedores", false, setOpen);

  useEffect(() => {
    dispatch(getAllProviders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Proveedores</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Proveedores"} />
        </div>
        <div>
          <ProvidersCreate />
          <ProvidersUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={providers} success={success} />
    </div>
  );
}
