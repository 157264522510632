import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import "dayjs/locale/es";
import dayjs from "dayjs";

export default function ControlledDatePicker({
  id,
  label,
  control,
  rules,
  name,
  disabled,
  errors,
  size = "small",
  defaultValue,
  minH = true,
  minDate,
  maxDate,
  disableWeekends,
}) {
  const ListOfErrors = {
    required: "* Campo requerido",
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Controller
        id={id}
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            shouldDisableDate={disableWeekends && disableWeekendsFn}
            disabled={disabled}
            format="DD/MM/YYYY"
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{
              textField: {
                size: size,
                fullWidth: true,
                error: !!errors[name],
                sx: {
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#606060",
                  },
                },
              },
            }}
            label={label}
            onChange={onChange}
            value={value || null}
          />
        )}
      />
    </LocalizationProvider>
  );
}

function disableWeekendsFn(date) {
  return dayjs(date).day() === 0 || dayjs(date).day() === 6;
}
