import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, TextField, Dialog, Checkbox, MenuItem, Divider } from "@mui/material";
import { useState } from "react";
import { validateRUT } from "validar-rut";
import Signature from "../Signature/Signature";
import { useDispatch, useSelector } from "react-redux";
import { getAllChecks } from "../../redux/slices/checklist";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import axiosInstance from "../../utils/axiosInstance";
import uploadFiles from "../../utils/UploadFiles";
import enviroments from "../../utils/enviroments";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CapacitacionAccordion({ handleSubmit, ot, setLength }) {
  const teams = ot.ot_equipo_ot_equipo_otToot;
  const isClosed = ot.cerrada;

  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [rutError, setRutError] = useState(false);
  const [skilled, setSkilled] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [equipment, setEquipment] = useState("");
  const [open, setOpen] = useState({ status: false, data: null });

  const { checkMenu } = useSelector((state) => state.checkMenu);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAllChecks());
  }, []);

  React.useEffect(() => {
    setLength(skilled.length);
  }, [skilled.length]);

  React.useEffect(() => {
    if (ot.skilledPerson?.length) {
      setSkilled([...ot.skilledPerson.map((p) => ({ ...p, sign: p.signature }))]);

      const skilledObj = {};

      ot.skilledPerson.forEach((person) => {
        const checkList = person.checkListPerson;

        skilledObj[`${person.rut}&${person.equipment}`] = [];
        checkList.forEach(({ checkListId }) => {
          const finded = checkMenu.find((el) => el.checkList.some((check) => check.id === checkListId));
          if (!skilledObj[`${person.rut}&${person.equipment}`].some((skObj) => skObj?.id === finded?.id)) {
            skilledObj[`${person.rut}&${person.equipment}`].push(finded);
          }
        });
      });

      setSelectedMenu(skilledObj);
    }
  }, [ot, checkMenu]);

  const addSkilled = () => {
    if (name !== "" && rut !== "" && !rutError && equipment !== "") {
      setSkilled((prev) => [...prev, { name, rut, sign: null, equipment, checkList: null }]);
      setName("");
      setRut("");
      setEquipment("");
    }
  };

  const onClose = () => {
    setOpen({ status: false, data: null });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-bold uppercase tracking-wider	text-sm ">CAPACITACIÓN</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-cols-4 max-sm:grid-cols-1 gap-3 mb-3">
            <TextField
              disabled={isClosed}
              label="Nombre Capacitado"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              disabled={isClosed}
              label="RUT Capacitado"
              value={rut}
              size="small"
              onChange={(e) => setRut(e.target.value)}
              onBlur={(e) => {
                if (e.target.value) {
                  const error = !validateRUT(e.target.value);
                  setRutError(error ? "El RUT ingresado es invalido" : false);
                }
              }}
              error={!!rutError}
              helperText={rutError}
            />
            <TextField
              label="Equipo Capacitado"
              size="small"
              value={equipment}
              onChange={(e) => setEquipment(e.target.value)}
              select
              disabled={rut === ""}
            >
              {teams?.map((team, idx) => {
                const inv = team?.inv_equipo;

                return (
                  <MenuItem
                    key={idx}
                    disabled={skilled.some((el) => el.equipment === inv.id && rut === el.rut)}
                    value={inv.id}
                    dense
                  >
                    <div
                      className={
                        skilled.some((el) => el.equipment === inv.id && rut === el.rut)
                          ? "line-through text-red-600"
                          : "text-green-500 font-semibold"
                      }
                    >
                      {inv.descripcion} - {inv.modelo}
                    </div>
                  </MenuItem>
                );
              })}
            </TextField>
            <div className="max-sm:w-full max-sm:flex max-sm:justify-end" onClick={addSkilled}>
              <Button disabled={isClosed} variant="outlined">
                Agregar
              </Button>
            </div>
          </div>
          <div className="flex flex-wrap max-sm:w-full gap-3 py-2">
            {skilled.map(({ name, rut, sign, equipment }, idx) => {
              const equip = teams?.find((el) => el.inv_equipo.id === equipment)?.inv_equipo;
              return (
                <div key={idx} className="max-sm:w-full">
                  <div className="flex">
                    <div className="text center border-gray p-3 rounded max-sm:w-full max-sm:flex max-sm:justify-center max-sm:items-center max-sm:flex-col">
                      <div className="flex justify-bet=een items-center bg-slate-200 rounded p-2 max-sm:w-full">
                        <div>
                          <div className="text-sm font-semibold line-clamp-1 max-w-[120px]">{name}</div>
                          <div className="text-sm">{rut}</div>
                          <div className="text-sm">
                            {equip?.descripcion} - {equip?.modelo}
                          </div>
                        </div>
                      </div>
                      <div className="p-2 max-w-52 mt-5 max-h-52 h-52 w-52 mb-3">
                        {sign?.base64 ? (
                          <img className="max-w-40 max-h-40 object-contain" src={sign?.base64} alt="firma" />
                        ) : (
                          !!sign && (
                            <img
                              className="max-w-40 max-h-40 object-contain"
                              src={`${enviroments.img_endpoint}${sign}`}
                              alt="firma"
                            />
                          )
                        )}
                      </div>
                      <div className="w-full flex justify-end">
                        <Button
                          onClick={() => setOpen({ status: true, data: { name, rut, equipment, sign } })}
                          variant="outlined"
                        >
                          Temario
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <FullScreenDialog
            checkMenu={checkMenu}
            teams={teams}
            open={open.status}
            onClose={onClose}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            data={open.data}
            skilled={skilled}
            setSkilled={setSkilled}
            otID={ot?.id}
            isClosed={isClosed}
          />
        </AccordionDetails>
      </Accordion>
    </form>
  );
}

function FullScreenDialog({
  open,
  onClose,
  checkMenu,
  teams,
  selectedMenu,
  setSelectedMenu,
  data,
  setSkilled,
  skilled,
  otID,
  isClosed,
}) {
  const [checked, setChecked] = useState({});
  const [signed, setSigned] = useState(false);

  const equip = teams?.find((el) => el.inv_equipo.id === data?.equipment)?.inv_equipo;

  React.useEffect(() => {
    const sk = skilled.find((el) => el?.rut === data?.rut && el.equipment === data.equipment);
    if (!!sk?.checkList) {
      setChecked(sk?.checkList);
    } else if (!!sk?.checkListPerson) {
      const obj = {};
      sk.checkListPerson.map((list) => {
        obj[list.checkList.id] = {
          ...list,
          item: list.checkList.item,
          subItem: list.checkList.subItem,
          checked: list.checked,
        };
      });

      setChecked(obj);
    }
  }, [skilled, data, open]);

  const save = async (val) => {
    let res;

    if (val?.file) {
      res = await uploadFiles("docs", val.file);
    }

    const find = {
      ...skilled.find((el) => el.rut === data.rut && el.equipment === data.equipment),
      checkList: checked,
      ...(res && { sign: { base64: val.base64, file: res.data } }),
    };

    const { data: idRes } = await axiosInstance.put("/check-menu/save", { ...find, otID });

    setSkilled((prev) => {
      const index = prev.findIndex((el) => el.rut === data.rut && el.equipment === data.equipment);

      if (index !== -1) {
        const updatedArray = [...prev];
        updatedArray[index] = { ...find, id: idRes };
        return updatedArray;
      }

      return prev;
    });
    handleClose();
  };

  const handleCheck = (e, id) => {
    setChecked((prev) => ({ ...prev, [id]: { ...prev[id], checked: e.target.checked } }));
  };

  const handleClose = () => {
    onClose();
    setChecked({});
    setSigned(false);
  };
  // <Signature disabled={!!sign} />

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <div className="flex gap-2 max-sm:flex-col max-sm:gap-0 max-sm:py-2">
                <div className="max-sm:text-sm">{data?.name}</div>
                <div className="max-sm:text-sm">{data?.rut}</div>
                <div className="max-sm:text-sm">{equip?.descripcion}</div>
              </div>
            </Typography>

            {!!!data?.sign && !signed ? (
              <Signature rut={data?.rut} equipment={data?.equipment} stateCb={save} />
            ) : (
              <Button disabled={isClosed} autoFocus color="inherit" onClick={save}>
                guardar
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <div className="p-4 w-[100vw]">
          <div className="font-semibold text-xl max-sm:text-base">Temario</div>
          <div className="">
            <div className="py-2">
              <TextField
                onChange={(e) =>
                  setSelectedMenu((prev) => ({
                    ...prev,
                    [`${data?.rut}&${data?.equipment}`]: !!prev[`${data?.rut}&${data?.equipment}`]
                      ? [...prev[`${data?.rut}&${data?.equipment}`], e.target.value]
                      : [e.target.value],
                  }))
                }
                fullWidth
                label="Seleccionar Menú"
                disabled={isClosed}
                select
                size="small"
              >
                {checkMenu
                  .filter((el) => teams.some((t) => t.inv_equipo.modelo === el.modelName))
                  .map((el, idx) => {
                    const f = Object.entries(selectedMenu).find(
                      ([key, val]) => key === `${data?.rut}&${data?.equipment}`,
                    );
                    let disabled = false;
                    if (!!f) {
                      disabled = f[1].some((selected) => selected.id === el.id);
                    }

                    return (
                      <MenuItem key={idx} disabled={disabled} dense value={el}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
            <Divider />
            <div className="mt-3">
              <div className="w-full grid grid-cols-4 gap-10 mb-2 font max-sm:hidden">
                <div className="font-semibold">ITEM</div>
                <div className="col-span-2 font-semibold">SUBITEM</div>
                <div className="font-semibold">ESTADO</div>
              </div>
              {Object.entries(selectedMenu).map(([key, el]) => {
                if (key === `${data?.rut}&${data?.equipment}`) {
                  return el.map((selected) => {
                    const checkList = selected.checkList;

                    return checkList?.map((check, idx) => (
                      <div key={idx} className="w-full rounded border-b mb-2 py-2">
                        <div className="grid grid-cols-4 gap-10 max-sm:gap-4 items-center px-2 w-full max-sm:grid-cols-1">
                          <div className="font-semibold">{check.item}</div>
                          <div className="col-span-2 max-sm:col-span-1 max-sm:leading-snug">{check.subItem}</div>
                          <div className="flex gap-2 items-center">
                            <div className="sm:hidden font-semibold">Estado</div>
                            <Checkbox
                              key={check?.id}
                              checked={checked[check.id]?.checked ? true : false}
                              onChange={(e) => handleCheck(e, check.id)}
                              disabled={isClosed}
                            />
                          </div>
                        </div>
                      </div>
                    ));
                  });
                }
              })}
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
