import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

const initialState = {
  data: [],
  comunas: [],
  success: false,
};

const regionsSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    setRegions: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setCommunaByRegion: (state, action) => {
      const comuna = state.data
        .filter((region) => region.id === action.payload)
        .flatMap((region) => region.gen_provincia)
        .filter((provincia) => provincia.gen_comuna)
        .flatMap((provincia) => provincia.gen_comuna)
        .sort((a, b) => a.descripcion.localeCompare(b.descripcion));

      state.comunas = comuna;
    },
  },
});

export const getAllRegions = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/region");
    dispatch(setRegions(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getCommuneByRegion = (region_id) => async (dispatch) => {
  dispatch(setCommunaByRegion(region_id));
};

export const { setRegions, setSuccess, setCommunaByRegion } = regionsSlice.actions;

export default regionsSlice.reducer;
