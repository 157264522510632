import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../../../hooks/useDataGrid";
import { getAllArticles } from "../../../redux/slices/inventory";
import DataComponent from "./DataComponent";
import TeamModalCreate from "../../modal/TeamsModalCreate";

const createArr = [
  { title: "Tipo de equipo", value: "tipoText" },
  { title: "Modelo", value: "modeloText" },
  { title: "Version", value: "versionText" },
  { title: "Serie", value: "serie" },
  { title: "Marca", value: "marcaText" },
  { title: "Valor", value: "valueCost" },
  { title: "Estado", value: "estadoText" },
  { title: "Eliminar", value: "deleteTeam" },
];

const editArr = [
  { title: "Tipo de equipo", value: "inv_tipo_equipo" },
  { title: "Modelo", value: "modelo" },
  { title: "Version", value: "inv_version" },
  { title: "Serie", value: "serie" },
  { title: "Marca", value: "inv_marca" },
  { title: "Estado", value: "inv_estado" },
];

export default function EquipmentAccordion({ equipos, setEquipos, edit }) {
  const [id, setId] = useState(1);

  const permitedRenderDataColumn = edit ? editArr : createArr;

  const dispatch = useDispatch();
  const { articles, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    setEquipos(equipos.filter((equipo) => equipo.id !== params));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Articulos", false, setOpen);

  useEffect(() => {
    dispatch(getAllArticles());
  }, [dispatch]);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          sx={{ bgcolor: "#e4dadab8", borderRadius: 1 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="flex justify-center font-bold uppercase tracking-wider text-sm mr-5">
            Recepción de Equipos
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#fdf9f9", borderBottomLeftRadius: 1, borderBottomRightRadius: 1 }}>
          <div>
            <div className={`flex justify-end mb-5 ${edit && "hidden"}`}>
              <div>
                <TeamModalCreate id={id} setId={setId} setEquipos={setEquipos} />
              </div>
            </div>
            <DataComponent columns={columns} rows={equipos} success={success} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
