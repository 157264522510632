import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import { getAllCobranzasByState, setCobranzasFalse } from "../redux/slices/cobranzas";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import thousandSeparator from "../utils/thousandSeparator";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

export default function Morosos() {
  const { cobranzasFalse, success } = useSelector((state) => state.cobranzas);
  const [accordionRender, setAccordionRender] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCobranzasByState(false));

    return () => {
      dispatch(setCobranzasFalse([]));
    };
  }, [dispatch]);

  useEffect(() => {
    const parseResult = {};
    if (cobranzasFalse.length) {
      cobranzasFalse.forEach((el) => {
        if (!parseResult[el.nombre_cliente]) {
          parseResult[el.nombre_cliente] = {
            nombre_cliente: el.nombre_cliente,
            facturas: [
              {
                nro_factura: el.n_factura,
                monto: el.monto,
                neto: `$ ${Math.round(parseInt(el.monto.split("$")[1].replaceAll(".", "")) / 1.19).toLocaleString()}`,
                iva: `$ ${Math.round(
                  (parseInt(el.monto.split("$")[1].replaceAll(".", "")) / 1.19) * 0.19,
                ).toLocaleString()}`,
                fecha_vencimiento: el.fecha_vencimiento,
                f_facturacion: el.f_facturacion,
                id: el.id,
              },
            ],
          };
        } else {
          parseResult[el.nombre_cliente].facturas.push({
            nro_factura: el.n_factura,
            monto: el.monto,
            neto: `$ ${Math.round(parseInt(el.monto.split("$")[1].replaceAll(".", "")) / 1.19).toLocaleString()}`,
            iva: `$ ${Math.round(
              (parseInt(el.monto.split("$")[1].replaceAll(".", "")) / 1.19) * 0.19,
            ).toLocaleString()}`,
            fecha_vencimiento: el.fecha_vencimiento,
            f_facturacion: el.f_facturacion,
            id: el.id,
          });
        }
      });
    }

    const sortedParseResult = Object.keys(parseResult)
      .sort((a, b) => {
        if (a === "nombre_cliente") return -1;
        if (b === "nombre_cliente") return 1;
        return a.localeCompare(b);
      })
      .reduce((acc, key) => {
        acc[key] = parseResult[key];
        return acc;
      }, {});

    const finalResult = Object.values(sortedParseResult);
    setAccordionRender(finalResult);
  }, [cobranzasFalse]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded mb-5">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Morosos</h1>
          <BreadCrumb origin={"Informe Cobranzas"} current={"Morosos"} />
        </div>
      </div>
      <div>
        {accordionRender?.map((el) => (
          <Accordion sx={{ marginBottom: 1, backgroundColor: "rgb(248 250 252)" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>{el.nombre_cliente}</Typography>
            </AccordionSummary>
            <div className="grid grid-cols-7 gap-1 mb-3">
              <div className="pl-4">Nro Factura</div>
              <div>F. Facturación</div>
              <div>F. Vencimiento</div>
              <div>Neto</div>
              <div>IVA</div>
              <div>Monto</div>
              <div>Detalle</div>
            </div>
            {el.facturas?.map((factura, index) => (
              <div className={`grid grid-cols-7 gap-1 py-2 ${index % 2 === 0 ? "bg-slate-100" : "bg-slate-50"}`}>
                <div className="pl-4">{factura.nro_factura}</div>
                <div>{factura.f_facturacion}</div>
                <div>{factura.fecha_vencimiento}</div>
                <div>{factura.neto}</div>
                <div>{factura.iva}</div>
                <div>{factura.monto}</div>
                <div>
                  <Link to={`/facturas-pagadas/${factura.id}`}>
                    <IconButton color="success" sx={{ mt: -2 }}>
                      <InfoIcon />
                    </IconButton>
                  </Link>
                </div>
              </div>
            ))}
          </Accordion>
        ))}
      </div>
    </div>
  );
}
