import TextField from "@mui/material/TextField";
import { useState } from "react";
import logo from "../../assets/loginteg.svg";
import Button from "@mui/material/Button";
import axiosInstance from "../../utils/axiosInstance";
import Alert from "@mui/material/Alert";

export const ForgotPassword = ({ setOpen, setAlert }) => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [resError, setResError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleBlur = (e) => {
    const emailRegex = /^[\w.%+-]+@loginteg\.cl$/;

    if (!email.length) {
      setErrors({
        ...errors,
        email: "El email es requerido",
      });
    } else if (!email.match(emailRegex)) {
      setErrors({
        ...errors,
        email: "El email no es valido",
      });
    } else {
      setErrors({
        ...errors,
        email: null,
      });
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axiosInstance.post("/login/forgot", { email });
      setLoading(false);
      setResError("");
      setOpen(false);
      setAlert(true);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        setResError("Usuario no encontrado");
      } else {
        setResError("Ocurrio un error inesperado");
      }
    }
  };

  return (
    <div className="flex flex-col py-3 pb-7 px-10">
      <div className="mb-3 -ml-3">
        <img width={180} src={logo} alt="logo" />
      </div>
      <label className="text-slate-500 mb-5">
        Si olvidate tu contraseña puedes restablecerla mediante tu correo corporativo Loginteg
      </label>
      <TextField
        error={Boolean(errors.email)}
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        onBlur={handleBlur}
        label="Correo Loginteg"
        helperText={errors?.email}
        fullWidth
        type="email"
        placeholder="user@loginteg.cl"
      />
      <Button disabled={loading} onClick={onSubmit} sx={{ my: 2 }} fullWidth variant="contained">
        Enviar
      </Button>
      {Boolean(resError) && <Alert severity="error">{resError}</Alert>}
    </div>
  );
};

export default ForgotPassword;
