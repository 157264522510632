import axiosInstance from "./axiosInstance";

const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

const uploadFiles = async (filetype, files) => {
  try {
    let formData = new FormData();

    formData.append("file", files);

    const upload = await axiosInstance.post(`/upload`, formData, config);

    return { status: 200, data: upload.data };
  } catch (error) {
    console.log(error);
    return { status: 400, message: error.response.data };
  }
};

export default uploadFiles;
