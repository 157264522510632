import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { updateUser, updateUserProfile } from "../redux/slices/users";
import profileSchema from "../resolvers/profile.resolver";
import axios from "axios";
import enviroments from "../utils/enviroments";

export default function Profile() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [submited, setSubmited] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [url, setUrl] = useState();
  const [image, setImage] = useState();

  const data = useSelector((state) => state.auth.currentUser);
  const { success } = useSelector((state) => state.users);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(profileSchema) });

  const handlePasswords = (e) => {
    if (e.target.id === "clave") {
      setPassword(e.target.value);
    }
    if (e.target.id === "confirmar_clave") {
      setPasswordConfirm(e.target.value);
    }
  };

  const onSubmit = async (values) => {
    setSubmited(true);
    if (values.clave === "" || values.confirmar_clave === "") {
      delete values.clave;
      delete values.confirmar_clave;
    }

    values.profile = true;

    if (image) {
      const formData = new FormData();
      formData.append("image", image);
      const res = await axios.post(`${enviroments.base_url}/images`, formData);
      values.img = res.data;
    }

    dispatch(updateUserProfile({ ...values, id: data.id }, true));
  };

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  return data.id ? (
    <div className="calculated-height relative flex p-2 justify-center">
      {!success && submited && (
        <Box sx={{ width: "100%", position: "absolute" }}>
          <LinearProgress />
        </Box>
      )}
      <div className=" h-max w-full max-w-[50%] mt-5">
        <div className="flex items-center mt-20 mb-20 ml-5">
          <Avatar
            alt="Remy Sharp"
            src={(url && url) || (data.img && `${enviroments.img_endpoint}${data.img}`)}
            sx={{ width: 180, height: 180 }}
          />
          <label htmlFor="file-upload" className={`custom-file-upload mx-10`}>
            <FileUploadIcon sx={{ mr: 1 }} />
            Cambiar Avatar
          </label>
          <input id="file-upload" type="file" accept="image/*" {...register("imagen")} onChange={uploadImage} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="flex">
            <TextField
              id="nombre"
              {...register("nombre")}
              helperText={errors["nombre"]?.message}
              error={Boolean(errors["nombre"]?.message)}
              defaultValue={data?.nombre}
              label={"Nombre"}
              fullWidth
              sx={{ mb: 4, marginX: 2 }}
            />
            <TextField
              id="direccion"
              helperText={errors["direccion"]?.message}
              error={Boolean(errors["direccion"]?.message)}
              defaultValue={data?.direccion}
              {...register("direccion")}
              label={"Direccion"}
              fullWidth
              sx={{ mb: 4, marginX: 2 }}
            />
          </div>
          <div className="flex">
            <TextField
              id="telefono"
              helperText={errors["telefono"]?.message}
              error={Boolean(errors["telefono"]?.message)}
              {...register("telefono")}
              defaultValue={data?.telefono}
              label={"Telefono"}
              fullWidth
              sx={{ mb: 4, marginX: 2 }}
            />
            <TextField
              id="mail_personal"
              {...register("mail_personal")}
              helperText={errors["mail_personal"]?.message}
              error={Boolean(errors["mail_personal"]?.message)}
              defaultValue={data?.mail_personal}
              label={"Correo Personal"}
              fullWidth
              sx={{ mb: 4, marginX: 2 }}
            />
          </div>
          <div className="flex">
            <TextField
              id={"clave"}
              {...register("clave")}
              label={"Contraseña"}
              helperText={errors["clave"]?.message}
              error={Boolean(errors["clave"]?.message)}
              fullWidth
              sx={{ mb: 4, marginX: 2 }}
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={handlePasswords}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword((show) => !show)}>
                    {showPassword ? <VisibilityOffIcon color="action" /> : <VisibilityIcon color="action" />}
                  </IconButton>
                ),
              }}
            />
            <TextField
              id={"confirmar_clave"}
              {...register("confirmar_clave")}
              helperText={errors["confirmar_clave"]?.message}
              error={Boolean(errors["confirmar_clave"]?.message)}
              value={passwordConfirm}
              type={showPasswordConfirm ? "text" : "password"}
              onChange={handlePasswords}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPasswordConfirm((show) => !show)}>
                    {showPasswordConfirm ? <VisibilityOffIcon color="action" /> : <VisibilityIcon color="action" />}
                  </IconButton>
                ),
              }}
              label={"Confirmar Contraseña"}
              fullWidth
              sx={{ mb: 4, marginX: 2 }}
            />
          </div>
          <Button sx={{ mb: 4, marginX: 2, textTransform: "none" }} variant="contained" type="submit">
            Guardar Cambios
          </Button>
        </form>
      </div>
    </div>
  ) : (
    <Box sx={{ width: "95%", position: "absolute" }}>
      <LinearProgress />
    </Box>
  );
}
