import { Button, MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getBodegaSelect, getRendicionAnual, getStorageInsumosFilted } from "../../../../redux/slices/informes";
import { useEffect } from "react";

export default function FilterInvInsumos() {
  const { bodegasSelect } = useSelector((state) => state.informes);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([dispatch(getBodegaSelect())]);
  }, []);

  const submitData = (values) => {
    dispatch(getRendicionAnual(values.year));
  };

  function getYearRange() {
    const currentYear = new Date().getFullYear();
    const yearsBack = Array.from({ length: 11 }, (_, index) => currentYear - 10 + index);
    const yearsForward = Array.from({ length: 11 }, (_, index) => currentYear + index);

    const allYears = [...new Set(yearsBack.concat(yearsForward))];
    return allYears.map(String);
  }

  const years = getYearRange();
  const date = new Date().getFullYear();

  return (
    <form onSubmit={handleSubmit(submitData)} className="flex gap-4">
      <Controller
        id="year"
        name="year"
        control={control}
        rules={{ required: true }}
        defaultValue={date}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            select
            size="small"
            onChange={onChange}
            value={value || ""}
            label="Seleccionar Año"
            error={errors["year"]}
            placeholder=""
            sx={{
              "& .MuiFormHelperText-root": {
                marginLeft: 0,
              },
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            {years?.map((option, idx) => (
              <MenuItem key={idx} dense divider value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Button variant="outlined" type="submit">
        Filtrar
      </Button>
    </form>
  );
}
