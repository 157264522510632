import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersToAprove } from "../../redux/slices/gestionEnvios";
import { Button, MenuItem, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function RendicionModal({ open, setOpen, handleSubmit }) {
  const { users } = useSelector((state) => state.gestionEnvios);
  const [mail, setMail] = React.useState("");
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAllUsersToAprove());
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col">
            <p className="mb-5 text-lg bg-[#2B2C84] py-1 text-white text-center rounded-sm">
              Seleccionar el encargado de realizar el pago
            </p>
            <TextField fullWidth select id="filled-disabled" label="Usuario" size="small">
              {users?.envioMailUsuario?.map((el) => (
                <MenuItem key={el.gen_usuario.id} value={el.gen_usuario.id} onClick={() => setMail(el.gen_usuario.id)}>
                  {el?.gen_usuario?.nombre}
                </MenuItem>
              ))}
            </TextField>
            <Button fullWidth variant="contained" sx={{ marginTop: 3 }} onClick={() => handleSubmit(mail)}>
              Aprobar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
