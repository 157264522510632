import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import FilterOtGeneral from "./FilterOtGeneral";
import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { setter } from "../../../redux/slices/informes";

export default function OtGeneral() {
  const { otGeneral } = useSelector((state) => state.informes);
  const [status, setStatus] = useState("Todos");
  const [ots, setOts] = useState([]);
  const columnStorageRepuestos = [
    { title: "F. Ejecución", value: "f_ejecucion" },
    { title: "OT", value: "ot_id" },
    { title: "Establecimiento", value: "cliente" },
    { title: "Dirección", value: "direccion" },
    { title: "Tipo", value: "tipo" },
    { title: "Fecha", value: "fecha" },
    { title: "Personal Asigando", value: "personal" },
    { title: "Estado", value: "estado" },
    { title: "OT Asignada", value: "acciones_info_otGeneral" },
    { title: "PDF", value: "pdf_ot" },
  ];

  const estados = ["Todos", "Asignada", "Cerrada", "Finalizada", "Sin Asignar"];

  useEffect(() => {
    setOts(otGeneral);
  }, [otGeneral]);

  const changeStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value === "Todos") {
      setOts(otGeneral);
    } else {
      setOts(otGeneral.filter((el) => el.estado === e.target.value));
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "otGeneral", payload: [] }));
    };
  }, []);

  const [columns] = useDataGrid(columnStorageRepuestos, () => {}, "CARGO");
  return (
    <div className="flex flex-col gap-2">
      <FilterOtGeneral />
      {otGeneral.length ? (
        <TextField
          fullWidth
          select
          size="small"
          onChange={changeStatus}
          value={status || ""}
          label="Estado"
          placeholder=""
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            },
          }}
        >
          {estados?.map((option) => (
            <MenuItem key={option} dense divider value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <></>
      )}
      <DataGridComponent columns={columns} rows={ots} success={true} size="80px" />
    </div>
  );
}
