import { Button, Chip, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { acceptRejectVacationRequest } from "../../redux/slices/remuneration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import CommentIcon from "@mui/icons-material/Comment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axiosInstance from "../../utils/axiosInstance";
import uploadFiles from "../../utils/UploadFiles";
import enviroments from "../../utils/enviroments";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";

export const VacationList = () => {
  const [arr, setArr] = useState([]);
  const { data } = useSelector((state) => state.createModal);
  const dispatch = useDispatch();
  const [commentaryMode, setCommentaryMode] = useState(false);
  const [viewCommentary, setViewCommentary] = useState(false);
  const [selected, setSelected] = useState({ id: null, state: null });
  const [file, setFile] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ mode: "onChange" });

  const handler = (id, state) => {
    setCommentaryMode(true);
    setSelected({ id, state });
  };

  const submit = async ({ approverObservation }) => {
    const submitData = { id: selected.id, state: selected.state, approverObservation };

    if (file) {
      const res = await uploadFiles("docs", file);
      submitData.file = res.data;
    }

    const data = await dispatch(acceptRejectVacationRequest(submitData));

    setCommentaryMode(false);
    setArr((prev) => [...prev.filter((vac) => vac.id !== selected.id), data]);
  };

  const handleUserCommentary = (data) => {
    setViewCommentary(true);
    setValue("userCommentary", data);
  };

  useEffect(() => {
    if (data) {
      setArr(data);
    }
  }, [data]);

  if (commentaryMode) {
    return (
      <form onSubmit={handleSubmit(submit)} className="p-2">
        <div>
          {selected.state === "ACEPTADA" && (
            <div className="mb-3">
              <Button
                color={!!file ? "success" : "primary"}
                variant={!!file ? "contained" : "outlined"}
                fullWidth
                startIcon={<AttachFileIcon />}
                size="small"
                component="label"
              >
                Subir Documento
                <input
                  hidden
                  accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Button>
            </div>
          )}
        </div>
        <ControlledTextField
          id="approverObservation"
          rules={{ required: true }}
          name="approverObservation"
          control={control}
          label="Observación"
          disabled={false}
          errors={errors}
          rows={3}
        />
        <Button type="submit" sx={{ mt: 2 }} fullWidth variant="contained">
          Guardar
        </Button>
      </form>
    );
  }

  if (viewCommentary) {
    return (
      <form className="p-2">
        <ControlledTextField
          id="userCommentary"
          rules={{ required: true }}
          name="userCommentary"
          control={control}
          label="Observación del solicitante"
          disabled={true}
          errors={errors}
          rows={3}
        />
        <Button onClick={() => setViewCommentary(false)} sx={{ mt: 2 }} fullWidth variant="contained">
          Cerrar
        </Button>
      </form>
    );
  }

  return (
    <div
      className={`max-h-[400px] flex ${
        ![1].length ? "justify-center" : undefined
      }  items-center flex-col w-full min-h-[400px] rounded overflow-auto my-1 pr-2`}
    >
      <div className="p-2 bg-neutral-300 rounded w-full text-center mb-2">Solicitudes de vacaciones</div>
      {arr.length ? (
        [...arr]
          .sort((a, b) => a.id - b.id)
          .map((file, index) => (
            <div
              key={index}
              className={`mb-2 rounded p-2 pr-0 border-[1px] items-center w-full flex justify-between border-neutral-300`}
            >
              <div className="uppercase  line-clamp-1 w-full text-neutral-700 text-sm ml-1 tracking-wider">
                {dayjs(file.initialDate).format("DD/MM/YYYY")} a {dayjs(file.finalDate).format("DD/MM/YYYY")}
              </div>
              <div className="uppercase w-full text-neutral-700 text-sm text-center tracking-wider">
                {file.totalDays} Días
              </div>
              {file.state === "ACEPTADA" && file.signedFile && file.finalApprove && (
                <div className="uppercase w-full text-neutral-700  text-sm flex gap-2 justify-center tracking-wider">
                  <Chip name="ACEPTADA" label="Aceptada" size="small" color="success" variant="outlined" />
                </div>
              )}
              {file.state === "ACEPTADA" &&
                !file.signedFile &&
                !file.finalApprove &&
                typeof file.finalApprove !== "undefined" && (
                  <div className="uppercase w-full text-neutral-700  text-sm flex gap-2 justify-center tracking-wider">
                    <Chip
                      name="PENDIENTE DE VALIDACIÓN"
                      label="documento pendiente"
                      size="small"
                      color="warning"
                      variant="outlined"
                    />
                  </div>
                )}
              {file.state === "ACEPTADA" &&
                file.signedFile &&
                !file.finalApprove &&
                typeof file.finalApprove !== "undefined" && (
                  <div className="uppercase w-full text-neutral-700  text-sm flex gap-2 justify-center tracking-wider">
                    <Chip
                      name="PENDIENTE DE VALIDACIÓN"
                      label="validación faltante"
                      size="small"
                      color="info"
                      variant="outlined"
                    />
                  </div>
                )}
              {file.state === "RECHAZADA" && (
                <div className="uppercase w-full text-neutral-700  text-sm flex gap-2 justify-center tracking-wider">
                  <Chip name="rechazada" label="Rechazada" size="small" color="error" variant="outlined" />
                </div>
              )}
              {file.state === "PENDIENTE" && (
                <div className="uppercase w-full text-neutral-700  text-sm flex gap-2 justify-center tracking-wider">
                  <Chip
                    onClick={() => handler(file.id, "ACEPTADA")}
                    name="ACEPTADA"
                    label="Aceptar"
                    size="small"
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    onClick={() => handler(file.id, "RECHAZADA")}
                    name="RECHAZADA"
                    label="Rechazar"
                    size="small"
                    color="error"
                    variant="outlined"
                  />
                </div>
              )}
              <IconButton
                onClick={() => handleUserCommentary(file.userObservation)}
                sx={{ mr: 1 }}
                handleUserCommentary
                disabled={!file.userObservation}
                color={file.userObservation ? "info" : "default"}
              >
                <CommentIcon />
              </IconButton>
              <IconButton color="primary" disabled={!file.signedFile}>
                <a
                  className="-ml-3 -mt-2"
                  href={`${enviroments.img_endpoint}${file.signedFile}`}
                  target="_blank"
                  download
                >
                  <AssignmentReturnedIcon />
                </a>
              </IconButton>
            </div>
          ))
      ) : (
        <span className="w-full flex justify-center items-center h-[320px] text-neutral-500">
          No se encontraron solicitudes
        </span>
      )}
    </div>
  );
};
