import { useSelector, useDispatch } from "react-redux";
import { getAllClients } from "../redux/slices/clients";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGrid from "../components/tables/DataGrid";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { removeMaintenance, setOpen } from "../redux/slices/programming";
import dayjs from "dayjs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createMaintenance } from "../redux/slices/programming";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import dateFormatter from "../utils/dateFormatter";

const programmingColumns = [
  { value: "descripcion", title: "Descripcion", minWidth: 170 },
  { value: "serie", title: "Serie", minWidth: 100 },
  { value: "f_programacion", title: "Fecha de mantencion", minWidth: 100 },
  { value: "action", title: "Acciones", minWidth: 100 },
];

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "direccion", title: "Direccion", minWidth: 100 },
  {
    value: "comuna",
    title: "Comuna",
    minWidth: 170,
  },
  {
    value: "mail_contacto",
    title: "Mail contacto",
    minWidth: 170,
  },
  {
    value: "programacion",
    title: "Accion",
    minWidth: 170,
    align: "center",
  },
];

export default function ProgrammingTeam() {
  const dispatch = useDispatch();
  const { data, success } = useSelector((state) => state.clients);
  const { openClient, client, success: successProgramming } = useSelector((state) => state.programming);
  const [expanded, setExpanded] = useState(false);
  const [date, setDate] = useState({
    date: "",
    equipo: "",
  });
  const [dateError, setDateError] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = (id) => {
    dispatch(removeMaintenance(id));
  };

  const handeCreate = () => {
    if (dayjs(date.date).add(1, "day").isBefore(dayjs())) {
      return setDateError(true);
    }
    if (!date.date && !date.equipo) {
      return setDateError(true);
    }
    dispatch(createMaintenance(date));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Crear OT", false);

  useEffect(() => {
    if (!data.length) {
      dispatch(getAllClients());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  const handleDate = (data) => {
    setDateError(false);
    setDate(data);
  };

  const handleBack = () => {
    dispatch(setOpen({ openClient: false, client: null }));
    dispatch(getAllClients());
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Programa Mantención</h1>
          <BreadCrumb origin={"Servicio técnico"} current={"Programa Mantención"} />
        </div>
      </div>
      {!openClient ? (
        <div>
          <DataGrid columns={columns} rows={data} success={success} />
        </div>
      ) : (
        <div className="mt-5">
          <div className="flex mb-5">
            <Chip
              color="primary"
              onClick={handleBack}
              sx={{ textTransform: "none", mr: 1 }}
              variant="contained"
              label="Volver a la lista"
            />
            <Chip
              avatar={<Avatar src={client.imagen && `data:image/png;base64,${client.imagen}`} />}
              sx={{ textTransform: "none" }}
              label={`Cliente: ${client.nombre}`}
            />
          </div>
          <div className="border-container-programming">
            <div className=" mb-2 bg-slate-200 text-lg p-2 rounded font-semibold">Equipos asignados</div>
            {[...client.cliente_equipo_cliente_equipo_clienteTocliente]
              .filter((equipo) => dayjs(equipo.inv_equipo.f_garantia?.slice(0, -2)).isAfter(dayjs()))
              .map((equipo) => (
                <Accordion
                  sx={{
                    marginBottom: 1,
                    borderRadius: "5px",
                  }}
                  key={equipo.id}
                  expanded={expanded === equipo.id}
                  onChange={handleChange(equipo.id)}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "rgb(226 232 240)", borderRadius: "5px" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="flex w-full justify-between items-center">
                      <div className="sm:grid grid-cols-3 w-full">
                        <div className="font-bold sm:min-w-[15%]">{equipo.inv_equipo?.inv_tipo_equipo.descripcion}</div>
                        <div className="text-sm sm:text-regular font-medium text-gray-500 capitalize">
                          {equipo.inv_equipo.modelo}
                        </div>
                        <div className="sm:ml-10 text-sm sm:text-regular font-medium text-gray-500 capitalize">
                          {equipo.inv_equipo.serie}
                        </div>
                        {/*        <div className="sm:ml-10 text-sm sm:text-regular font-medium text-gray-500 capitalize">
                          Garantia: {dateFormatter(equipo.inv_equipo.f_garantia)}
                        </div>
                        <div className="sm:ml-10 text-sm sm:text-regular font-medium text-gray-500 capitalize">
                          Contrato: {dateFormatter(equipo.inv_equipo.f_contrato)}
                        </div> */}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="text-lg font-bold mb-2">Detalle: </div>
                    <div className="bg-slate-100 p-2">
                      <div className="flex p-1 pl-0 mb-1 rounded">
                        <div className="min-w-[155px] font-semibold mr-3 px-1">Modelo:</div>
                        <div>{equipo.inv_equipo.modelo}</div>
                      </div>
                      <div className="flex p-1 pl-0 mb-1 bg-slate-100 rounded">
                        <div className="min-w-[155px] font-semibold mr-3 px-1">Serie:</div>
                        <div>{equipo.inv_equipo.serie}</div>
                      </div>
                      {/*   <div className="flex p-1 pl-0 mb-1 rounded">
                        <div className="min-w-[155px] font-semibold mr-3 px-1">Fecha de Garantia:</div>
                        <div>{dateFormatter(equipo.inv_equipo.f_garantia)}</div>
                      </div>
                      <div className="flex p-1 mb-1 pl-0 bg-slate-100 rounded">
                        <div className="min-w-[110px] font-semibold mr-3 px-1">Fecha de Contrato: </div>
                        <div>{dateFormatter(equipo.inv_equipo.f_contrato)}</div>
                      </div> */}
                    </div>
                    <div className="text-lg font-bold mb-2 mt-2">Programaciones: </div>
                    <div className="my-3 flex items-start">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={date?.date || undefined}
                          format="DD-MM-YYYY"
                          onChange={(newDate) => handleDate({ date: newDate, equipo: equipo.equipo })}
                          slotProps={{
                            textField: {
                              size: "small",
                              helperText: dateError ? "La fecha es invalida" : "Programar nueva mantencion",
                              error: dateError,
                            },
                          }}
                          label="Fecha de mantencion"
                        />
                      </LocalizationProvider>
                      {date && (
                        <Button
                          disabled={!successProgramming}
                          onClick={handeCreate}
                          sx={{ ml: 1, textTransform: "none" }}
                          variant="contained"
                          size="medium"
                        >
                          {!successProgramming ? (
                            <CircularProgress style={{ width: "25px", height: "25px" }} color="inherit" />
                          ) : (
                            "Programar"
                          )}
                        </Button>
                      )}
                    </div>
                    {equipo.inv_equipo.ot_programacion && (
                      <TableContainer
                        sx={{ maxHeight: 300, position: "relative" }}
                        className="mb-5 bg-slate-100 rounded p-2"
                      >
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="caption table">
                          <TableHead>
                            <TableRow>
                              {programmingColumns.map((column) => (
                                <TableCell key={column.value}>{column.title}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {equipo.inv_equipo.ot_programacion.map((programacion) => (
                              <TableRow key={programacion.id}>
                                <TableCell>{programacion.inv_equipo?.inv_tipo_equipo?.descripcion}</TableCell>
                                <TableCell>{programacion.inv_equipo.serie}</TableCell>
                                <TableCell>{dateFormatter(programacion.f_programacion)}</TableCell>
                                <TableCell>
                                  <button onClick={() => handleDelete(programacion.id)}>
                                    <DeleteIcon sx={{ color: "red" }} />
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
