import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useEffect } from "react";
import { getAllRepairPartsBodega } from "../../../redux/slices/inventory";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreFacturaRepuestos from "../../modal/PreFacturaRepuestos";
import PreFacturaRepuestoTable from "../../tables/PreFacturaTable/PreFacturaRepuestoTable";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)();

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#ffffff" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: "start",
  paddingLeft: "20px",
  paddingRight: "20px",
  fontSize: "15px",
}));

export default function PreFacturaAccordionRepuestos({
  repuestosAsignados,
  setRepuestosAsignados,
  subTotalRepuestos,
  setSubTotalRepuestos,
  ganRepuestos,
  setTotalesRepuestos,
  totalesRepuestos,
}) {
  const [expanded, setExpanded] = useState(false);
  const [repuestosBack, setRepuestosBack] = useState([]);
  const [search, setSearch] = useState([]);
  const { repairPartsBodega } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(getAllRepairPartsBodega());
  }, []);

  useEffect(() => {
    setRepuestosBack(repairPartsBodega);
    setSearch(repairPartsBodega);
  }, [repairPartsBodega]);

  useEffect(() => {
    let total = 0;
    repuestosAsignados.forEach((el) => {
      total += parseInt(el.valueCost);
    });
    setSubTotalRepuestos(total);
  }, [repuestosAsignados]);

  return (
    <div>
      <div className="mb-5">
        <div className="flex justify-end bg-[#2b2c84]/70 h-[40px]">
          <div className="text-white flex items-center justify-center w-[80%]">REPUESTOS</div>
          <div className="w-[20%] flex justify-end pr-5 items-center">
            <PreFacturaRepuestos
              bodega={repuestosBack}
              setBodega={setRepuestosBack}
              asignados={repuestosAsignados}
              setAsignados={setRepuestosAsignados}
              setSubTotalRepuestos={setSubTotalRepuestos}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <div>
          <PreFacturaRepuestoTable rows={repuestosAsignados} setAsignados={setRepuestosAsignados} />
        </div>
        <div className="flex justify-end mt-5 pr-2 font-semibold text-xl">
          Total: ${subTotalRepuestos?.toLocaleString()}
        </div>
      </div>
    </div>
  );
}
