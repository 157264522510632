import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDispatch } from "react-redux";
import { WorkerOrderActualySituation } from "../../redux/slices/workOrders";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import dayjs from "dayjs";
import dateFormatter from "../../utils/dateFormatter";
import enviroments from "../../utils/enviroments";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

export default function AccordionAdicional({ otId, responsible, imgs, closed }) {
  const prevImgs = imgs.filter((img) => img.posicion === "act" && img.type === "img");
  const prevDocs = imgs.filter((img) => img.posicion === "act" && img.type !== "img");
  const disptach = useDispatch();
  const [imagenes, setImagenes] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const idOt = parseInt(otId);
  const [disabled, setDisabled] = useState(false);

  const uploadImage = (file) => {
    if (file.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onload = function (event) {
        if (file.target.files[0].type.includes("image")) {
          setImagenes([
            ...imagenes,
            {
              img: URL.createObjectURL(file.target.files[0]),
              rawImage: file.target.files[0],
              descripcion: "",
              ot: parseInt(idOt),
              responsable: responsible,
              estado: true,
              posicion: "act",
            },
          ]);
        } else {
          setDocumentos([
            ...documentos,
            {
              rawDoc: file.target.files[0],
              descripcion: "",
              ot: parseInt(idOt),
              responsable: responsible,
              estado: true,
              posicion: "act",
            },
          ]);
        }
      };
    }
  };

  const handleDelete = (index) => {
    let newImg = [...imagenes];
    newImg.splice(index, 1);
    setImagenes(newImg);
  };
  const handleDeleteDocs = (index) => {
    let newDoc = [...documentos];
    newDoc.splice(index, 1);
    setDocumentos(newDoc);
  };

  const handleChange = (e, index) => {
    const newImagenes = [...imagenes];
    newImagenes[index].descripcion = e.target.value;
    setImagenes(newImagenes);
  };
  const handleChangeDocs = (e, index) => {
    const newDocuments = [...documentos];
    newDocuments[index].descripcion = e.target.value;
    setDocumentos(newDocuments);
  };

  const handleSubmit = async (e) => {
    setDisabled(true);
    e.preventDefault();
    await disptach(WorkerOrderActualySituation(imagenes, documentos));
    setDisabled(false);
    setImagenes([]);
    setDocumentos([]);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-bold uppercase tracking-wider	text-sm ">INFORMACIÓN ADICIONAL</span>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {!closed && (
              <div className="mb-10 flex justify-center md:flex md:justify-start">
                <label htmlFor="file-act" className="custom-file-upload">
                  <FileUploadIcon sx={{ mr: 1 }} />
                  SUBIR ARCHIVO
                </label>
                <input id="file-act" type="file" onChange={(e) => uploadImage(e)} accept="*" />
              </div>
            )}
            {prevImgs.length > 0 &&
              prevImgs.map((prev, index) => (
                <div className="flex flex-col items-center mb-5 md:flex md:flex-row" key={index}>
                  <div className="md:min-w-[250px] w-full md:w-[250px] h-[300px] md:h-[200px] border-gray rounded p-2 mb-5 md:mb-0">
                    <img
                      src={`${enviroments.img_endpoint}${prev.img}`}
                      alt={"detalle"}
                      className="w-full h-full object-cover rounded"
                    />
                  </div>
                  <div className="flex flex-col border-gray md:ml-5 min-h-[200px] justify-between rounded w-full md:items-start">
                    <div className="mx-4 mt-4 capitalize break-all pr-5">
                      <div className="">
                        <div className="uppercase font-bold pr-2 min-w-max">Observación:</div>
                        <div>{prev.descripcion}</div>
                      </div>
                      <div className="">
                        <div className=" mr-4 ">
                          <div className="uppercase font-bold pr-2">Fecha: </div>
                          {dateFormatter(prev.f_creacion)}
                        </div>
                        <div className="">
                          <div className="uppercase font-bold min-w-max pr-2">Técnico:</div>
                          <div className="line-clamp-3">{prev.gen_usuario.nombre}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {prevDocs.length > 0 &&
              prevDocs.map((prev, index) => (
                <div className="flex flex-col items-center mb-5 md:flex md:flex-row" key={index}>
                  <div className="flex flex-col justify-center items-center w-full md:min-w-[250px] md:w-[250px] h-[300px] md:h-[200px] border-gray rounded p-2 mb-5 md:mb-0">
                    <InsertDriveFileOutlinedIcon sx={{ width: "100px", height: "100px" }} />
                    <a href={`${enviroments.img_endpoint}${prev.img}`}>
                      {prev.img.length > 15 ? `${prev.img.slice(0, 20)}...` : prev.img}
                    </a>
                  </div>
                  <div className="flex flex-col border-gray items-center ml-5 h-[200px] justify-between rounded w-full md:items-start">
                    <div className="mx-4 mt-4 capitalize flex flex-wrap break-all pr-5">
                      <div className="uppercase font-bold pr-2">Observación:</div> {prev.descripcion}
                    </div>
                    <div className="mx-4 mb-4 flex ">
                      <div className="flex mr-4">
                        <div className="uppercase font-bold pr-2">Fecha: </div>
                        {dateFormatter(prev.f_creacion)}
                      </div>
                      <div className="flex">
                        <div className="uppercase font-bold pr-2">Técnico:</div> {prev.gen_usuario.nombre}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {imagenes.length > 0 &&
              imagenes.map((imagen, index) => (
                <div className="flex flex-col items-center mb-5 md:flex md:flex-row" key={index}>
                  <div className="md:min-w-[250px] w-full md:w-[250px] h-[300px] md:h-[200px] border-gray rounded p-2 mb-5 md:mb-0">
                    <img src={imagen.img} alt="observacion" className="w-full h-full object-cover rounded" />
                  </div>
                  <div className="md:ml-5 flex justify-center items-center w-full relative">
                    <TextField
                      fullWidth
                      required
                      id="outlined-multiline-static"
                      value={imagen.descripcion}
                      multiline
                      rows={7}
                      placeholder="Observaciones"
                      onChange={(e) => handleChange(e, index)}
                    />
                    <div className="absolute right-5 top-3 self-start">
                      <RemoveCircleIcon sx={{ color: "#c22047" }} onClick={(e) => handleDelete(index)} />
                    </div>
                  </div>
                </div>
              ))}
            {documentos.length > 0 &&
              documentos?.map((doc, index) => (
                <div className="flex flex-col items-center mb-5 md:flex md:flex-row" key={index}>
                  <div className="flex flex-col justify-center items-center min-w-[250px] w-[250px] h-[200px] border-gray rounded p-2 mb-5 md:mb-0">
                    <InsertDriveFileOutlinedIcon sx={{ width: "100px", height: "100px" }} />
                    <span>
                      {doc.rawDoc?.name?.length > 15 ? `${doc.rawDoc?.name?.slice(0, 20)}...` : doc.rawDoc?.name}
                    </span>
                  </div>
                  <div className="md:ml-5 flex justify-center items-center w-full relative">
                    <TextField
                      fullWidth
                      required
                      id="outlined-multiline-static"
                      value={doc.descripcion}
                      multiline
                      rows={7}
                      placeholder="Observaciones"
                      onChange={(e) => handleChangeDocs(e, index)}
                    />
                    <div className="absolute right-5 top-3 self-start">
                      <RemoveCircleIcon sx={{ color: "#c22047" }} onClick={(e) => handleDeleteDocs(index)} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {(imagenes.length > 0 || documentos.length > 0) && (
            <div className="flex w-full justify-center mb-5">
              <Button
                type="submit"
                variant="contained"
                sx={{ px: 5 }}
                onClick={handleSubmit}
                disabled={disabled}
                fullWidth
              >
                ENVIAR
              </Button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </form>
  );
}
