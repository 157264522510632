import CSVReader from "react-csv-reader";

export default function ReadCSV({ uploader }) {
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="bg-[#2B2C84] p-2 rounded text-white">
        <CSVReader
          label="Importar CSV"
          parserOptions={papaparseOptions}
          onFileLoaded={(data, fileInfo, originalFile) => uploader(data)}
        />
      </div>
    </div>
  );
}
