import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDispatch } from "react-redux";
import { WorkerOrderActualySituation } from "../../redux/slices/workOrders";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import dayjs from "dayjs";
import dateFormatter from "../../utils/dateFormatter";
import enviroments from "../../utils/enviroments";

export default function AccordionOtSit({ otId, responsible, imgs, closed }) {
  const prevImgs = imgs.filter((img) => img.posicion === "sit");
  const disptach = useDispatch();
  const [imagenes, setImagenes] = useState([]);
  const idOt = parseInt(otId);
  const [disabled, setDisabled] = useState(false);

  const uploadImage = (img) => {
    const reader = new FileReader();
    reader.readAsDataURL(img.target.files[0]);

    reader.onload = function (event) {
      setImagenes([
        ...imagenes,
        {
          img: URL.createObjectURL(img.target.files[0]),
          rawImage: img.target.files[0],
          descripcion: "",
          ot: parseInt(idOt),
          responsable: responsible,
          estado: true,
          posicion: "sit",
        },
      ]);
    };
  };

  const handleDelete = (index) => {
    let newImg = [...imagenes];
    newImg.splice(index, 1);
    setImagenes(newImg);
  };

  const handleChange = (e, index) => {
    const newImagenes = [...imagenes];
    newImagenes[index].descripcion = e.target.value;
    setImagenes(newImagenes);
  };

  const handleSubmit = async (e) => {
    setDisabled(true);
    e.preventDefault();
    await disptach(WorkerOrderActualySituation(imagenes));
    setDisabled(false);
    setImagenes([]);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-bold uppercase tracking-wider	text-sm ">INFORME DE SITUACION</span>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {!closed && (
              <div className="mb-10 flex justify-center md:flex md:justify-start">
                <label htmlFor="file-sit" className="custom-file-upload">
                  <FileUploadIcon sx={{ mr: 1 }} />
                  SUBIR IMÁGEN
                </label>
                <input id="file-sit" type="file" onChange={(e) => uploadImage(e)} accept="image/*" />
              </div>
            )}
            {prevImgs.length > 0 &&
              prevImgs.map((prev, index) => (
                <div className="flex flex-col items-center mb-5 md:flex md:flex-row" key={index}>
                  <div className="md:min-w-[250px] w-full md:w-[250px] h-[300px] md:h-[200px] border-gray rounded p-2 mb-5 md:mb-0">
                    <img
                      src={`${enviroments.img_endpoint}${prev.img}`}
                      alt={"detalle"}
                      className="w-full h-full object-cover rounded"
                    />
                  </div>
                  <div className="flex flex-col border-gray  md:ml-5 min-h-[200px] justify-between rounded w-full md:items-start">
                    <div className="mx-4 mt-4 capitalize break-all pr-5">
                      <div className="">
                        <div className="uppercase font-bold pr-2 min-w-max">Observación:</div>
                        <div>{prev.descripcion}</div>
                      </div>
                      <div className="">
                        <div className=" mr-4 ">
                          <div className="uppercase font-bold pr-2">Fecha: </div>
                          {dateFormatter(prev.f_creacion)}
                        </div>
                        <div className="">
                          <div className="uppercase font-bold min-w-max pr-2">Técnico:</div>
                          <div className="line-clamp-3">{prev.gen_usuario.nombre}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {imagenes &&
              imagenes.map((imagen, index) => (
                <div className="flex flex-col items-center mb-5 md:flex md:flex-row" key={index}>
                  <div className="md:min-w-[250px] w-full md:w-[250px] h-[300px] md:h-[200px] border-gray rounded p-2 mb-5 md:mb-0">
                    <img src={imagen.img} alt="observacion" className="w-full h-full object-cover rounded" />
                  </div>
                  <div className="md:ml-5 flex justify-center items-center w-full relative">
                    <TextField
                      fullWidth
                      required
                      id="outlined-multiline-static"
                      value={imagen.descripcion}
                      multiline
                      rows={7}
                      placeholder="Observaciones"
                      onChange={(e) => handleChange(e, index)}
                    />
                    <div className="absolute right-5 top-3 self-start">
                      <RemoveCircleIcon sx={{ color: "#c22047" }} onClick={(e) => handleDelete(index)} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {imagenes.length > 0 && (
            <div className="flex w-full justify-center mb-5">
              <Button
                type="submit"
                variant="contained"
                sx={{ px: 5 }}
                onClick={handleSubmit}
                disabled={disabled}
                fullWidth
              >
                ENVIAR
              </Button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </form>
  );
}
