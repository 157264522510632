import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PreFacturaEquipament from "../../modal/PreFacturaEquipament";
import { useEffect } from "react";
import { getAllArticles, getAllItemTypes } from "../../../redux/slices/inventory";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreFacturaTable from "../../tables/PreFacturaTable/PreFacturaEquipamentTable";
import PreFacturaItemTable from "../../tables/PreFacturaTable/PreFacturaItemTable";
import PreFacturaItem from "../../modal/PreFacturaItem";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)();

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#ffffff" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: "start",
  paddingLeft: "20px",
  paddingRight: "20px",
  fontSize: "15px",
}));

export default function PreFacturaAccordionItems({
  itemsAsignados,
  setItemsAsignados,
  subTotalItems,
  setSubTotalItems,
  ganArticulos,
  setTotalesInsumos,
  totalesInsumos,
}) {
  const [expanded, setExpanded] = useState(false);
  const [itemsBack, setItemsBack] = useState([]);
  const [search, setSearch] = useState([]);
  const { articles } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(getAllArticles());
  }, []);

  useEffect(() => {
    setItemsBack(articles);
    setSearch(articles);
  }, [articles]);

  useEffect(() => {
    let total = 0;
    itemsAsignados.forEach((el) => {
      total += parseInt(el.valueCost);
    });
    setSubTotalItems(total);
  }, [itemsAsignados]);

  return (
    <div>
      <div className="mb-5">
        <div className="flex justify-end bg-[#2b2c84]/70 h-[40px]">
          <div className="text-white flex items-center justify-center w-[80%]">INSUMOS</div>
          <div className="w-[20%] flex justify-end pr-5 items-center">
            <PreFacturaItem
              bodega={itemsBack}
              setBodega={setItemsBack}
              asignados={itemsAsignados}
              setAsignados={setItemsAsignados}
              setSubTotalItems={setSubTotalItems}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <div>
          <PreFacturaItemTable rows={itemsAsignados} setAsignados={setItemsAsignados} />
        </div>
        <div className="flex justify-end mt-5 font-semibold text-xl pr-2">
          Total: ${subTotalItems?.toLocaleString()}
        </div>
      </div>
    </div>
  );
}
