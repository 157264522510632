import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function CustomMultipleField({
  id,
  name,
  control,
  value,
  label,
  disabled = false,
  errors,
  rules,
  options,
  onChange,
  isCustom,
  size = "small",
  width = "22%",
}) {
  const theme = useTheme();

  const optionsIds = options.map((e) => e.id);
  const optionsMap = {};
  options.forEach((opt) => {
    optionsMap[opt.id] = `${opt.nombre}`;
  });

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div className="w-full">
      <Box
        sx={{
          display: "flex",
        }}
      >
        <div className="gap-x-2 flex w-full">
          {isCustom && (
            <div className="text-gray-600 min-w-[150px] font-bold text-xs max-lg:hidden bg-gray-200  h-[40px]  flex items-center pl-2 rounded">
              {label} :
            </div>
          )}
          <Autocomplete
            multiple
            fullWidth
            id={id}
            value={value || []}
            disabled={disabled}
            options={optionsIds}
            onChange={(event, newValue) => handleChange(newValue)}
            getOptionLabel={(option) => optionsMap[option]}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size={size} label={isCustom ? "" : label} />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Tooltip key={index} title={optionsMap[option]}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    size={"small"}
                    sx={{ width: width }}
                    label={optionsMap[option]}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ))
            }
          />
        </div>
      </Box>
    </div>
  );
}
