import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { deleteOt } from "../../redux/slices/workOrders";
import { Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function DeleteOtModal({ id, status }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNavigate = () => navigate("/por-facturar");

  const handleDelete = async (id) => {
    const res = await dispatch(deleteOt(id));
    if (res === "eliminado") {
      if (status === false) {
        window.location.reload();
      } else {
        navigate("/por-facturar");
      }
    }
  };

  return (
    <div>
      <Chip
        label="Eliminar"
        sx={{ minWidth: "100px" }}
        color="error"
        onClick={handleOpen}
        disabled={!status ? false : true}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-center text-xl mb-5">¿Estas seguro de eliminar?</div>
          <div className="flex justify-around">
            <Button variant="outlined" onClick={() => handleDelete(id)}>
              SI
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              NO
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
