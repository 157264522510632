import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { createNewBrand } from "../../redux/slices/inventory";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function BrandModalCreate() {
  const [open, setOpen] = React.useState(false);
  const [equipo, setEquipo] = React.useState(false);
  const [repuesto, setRepuesto] = React.useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const newTeam = async (value) => {
    value.equipo = equipo;
    value.repuesto = repuesto;

    dispatch(createNewBrand(value));
    reset();
    setOpen(false);
  };

  const handleEquipo = () => {
    setEquipo(!equipo);
  };

  const handleRepuesto = () => {
    setRepuesto(!repuesto);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Registrar nueva marca"
        color="primary"
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newTeam)}>
              <div className="flex">
                <div className="w-full mr-5">
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      id={"nombre"}
                      label={"Nombre"}
                      type={"text"}
                      {...register("descripcion", { required: true })}
                      error={Boolean(errors["descripcion"])}
                      helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Enviar formulario
                </Button>
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={equipo} onChange={handleEquipo} />}
                    label="Equipo"
                    sx={{ mt: 2 }}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={repuesto} onChange={handleRepuesto} />}
                    label="Repuesto"
                    sx={{ mt: 2 }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
