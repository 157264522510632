import { useSelector } from "react-redux";
import enviroments from "../../utils/enviroments";
import React, { useState } from "react";
import { Divider, IconButton } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const PortalProof = () => {
  const { portal } = useSelector((state) => state.remuneration);
  const files = portal.ProofOfPayment;
  const [selectedFile, setSelectedFile] = useState({ id: null, fileName: null });

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePDF = (id, fileName) => {
    setSelectedFile({ id, fileName });
    setPageNumber(1);
  };

  return (
    <div className="flex gap-5">
      <div className=" pr-2 rounded w-[20%] max-h-[80vh] overflow-auto relative">
        {files.length ? (
          files.map((file) => {
            return (
              <div key={file.id}>
                <div
                  id={file.id}
                  onClick={() => handlePDF(file.id, file.fileName)}
                  className={`${
                    selectedFile.id === file.id ? "bg-blue-200" : undefined
                  } rounded transition-all flex cursor-pointer hover:bg-neutral-100 p-2 border-l-[3px] bg-neutral-100/50 border-l-blue-950 items-center w-full justify-between px-2`}
                >
                  <div>
                    <div className="uppercase pointer-events-none line-clamp-1 w-full text-neutral-700 text-sm ml-1 tracking-wider">
                      Comprobante
                    </div>
                    <div className="uppercase pointer-events-none w-full text-neutral-700 text-sm ml-1 tracking-wider">{`${
                      months[file.month].label
                    } - ${file.year}`}</div>
                  </div>
                </div>
                <Divider sx={{ my: 1 }} />
              </div>
            );
          })
        ) : (
          <div className="text-neutral-500 w-full text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            No se encontraron comprobantes
          </div>
        )}
      </div>
      <div className="w-[80%] h-[80vh]">
        {selectedFile?.fileName ? (
          <div className="relative z-20 min-h-[80vh] max-h-[80vh] py-5 w-full flex justify-center items-center bg-[#58545c] rounded">
            <Document
              loading="Cargando"
              noData="Cargando.."
              onLoadSuccess={onDocumentLoadSuccess}
              className={"max-h-[78vh] overflow-y-auto overflow-x-hidden rounded"}
              file={`${enviroments.img_endpoint}${selectedFile.fileName}`}
            >
              <Page
                width={600}
                noData="No hay datos"
                loading={"Cargando.."}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={pageNumber}
              />
            </Document>
            <div className="absolute bottom-5 bg-[#58545c] rounded left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex text-white items-center gap-5 px-2">
                <IconButton
                  color="inherit"
                  disabled={pageNumber <= 1}
                  onClick={() => {
                    setPageNumber((prev) => prev - 1);
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <span>{pageNumber}</span>
                <IconButton
                  color="inherit"
                  disabled={pageNumber >= numPages}
                  onClick={() => {
                    setPageNumber((prev) => prev + 1);
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </div>
            <a
              href={`${enviroments.img_endpoint}${selectedFile.fileName}`}
              target="_blank"
              rel="noreferrer noopener"
              download="proposed_file_name"
              className="cursor-pointer absolute top-4 right-12 px-4 bg-red-400 rounded-full text-white py-2"
            >
              Descargar PDF
            </a>
          </div>
        ) : (
          <div className="bg-[#58545c] text-white flex justify-center items-center text-xl min-h-[80vh] w-full h-full rounded">
            <div>Seleccionar Comprobante</div>
          </div>
        )}
      </div>
    </div>
  );
};

const months = [
  { id: 0, label: "Enero" },
  { id: 1, label: "Febrero" },
  { id: 2, label: "Marzo" },
  { id: 3, label: "Abril" },
  { id: 4, label: "Mayo" },
  { id: 5, label: "Junio" },
  { id: 6, label: "Julio" },
  { id: 7, label: "Agosto" },
  { id: 8, label: "Septiembre" },
  { id: 9, label: "Octubre" },
  { id: 10, label: "Noviembre" },
  { id: 11, label: "Diciembre" },
];

export default PortalProof;
