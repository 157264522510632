import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";
import dayjs from "dayjs";

const initialState = {
  cobranzas: [],
  cobranzasTrue: [],
  cobranzasFalse: [],
  cobranzasFiltered: [],
  cobranzasClientes: [],
  cobranzasAnuladas: [],
  cobranzaId: {},
  open: false,
  success: true,
};

const cobranzasSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setCobranzas: (state, action) => {
      state.cobranzas = action.payload;
      state.success = true;
    },
    setCobranzasClientes: (state, action) => {
      state.cobranzasClientes = action.payload;
      state.success = true;
    },
    setCobranzasAnuladas: (state, action) => {
      state.cobranzasAnuladas = action.payload;
      state.success = true;
    },
    setCobranzasTrue: (state, action) => {
      state.cobranzasTrue = action.payload;
      state.success = true;
    },
    setCobranzasFalse: (state, action) => {
      state.cobranzasFalse = action.payload;
      state.success = true;
    },
    setCobranzasFiltered: (state, action) => {
      state.cobranzasFiltered = action.payload;
      state.success = true;
    },
    setCobranzaId: (state, action) => {
      state.cobranzaId = action.payload;
      state.succes = true;
    },
    setRegistroCobranza: (state, action) => {
      const updated = state.cobranzasFiltered.filter((registro) => {
        return registro.id !== action.payload.id;
      });
      state.cobranzasFiltered = updated;
      state.success = true;
    },
    setUpdateRegistroCobranza: (state, action) => {
      const updated = state.cobranzasFiltered.filter((registro) => {
        return registro.id !== action.payload.id;
      });
      state.cobranzasFiltered = [...updated, action.payload];
      state.success = true;
    },
  },
});
export const getAllCobranzas = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/cobranzas/all");
    const newData = data.map((el) => ({
      ...el,
      id: el.id,
      numero_nota_credito: el.numero_nota_credito || "S/D",
      nro_oc: el.facturacion.nro_oc,
      nro_oc_noMP: el.facturacion.nro_oc_noMP,
      fecha_oc: dayjs(el.facturacion.fecha_oc).add(1, "day").format("DD/MM/YYYY"),
      cliente_facutra: el.facturacion.cliente_facturacion_clienteTocliente.nombre,
      estado_factura: el.estado ? "Pagado" : "Pendiente",
      iva: "$ " + Math.round(el.monto - el.monto / 1.19).toLocaleString(),
      monto_withOut_iva: "$ " + Math.round(el.monto / 1.19).toLocaleString(),
      monto: `$ ${el.monto.toLocaleString()}`,
      nro_factura: el.n_factura,
      f_facturacion: dayjs(el.f_facturacion).add(1, "day").format("DD/MM/YYYY"),
      p_parcial: el.facturacion.pago_parcial ? "SI" : "NO",
      p_multiple: el.facturacion.multiple ? "SI" : "NO",
      nro_ot: el.facturacion.ot_id,
      estado_ot: `${
        el.facturacion?.ot?.finalizada
          ? "Finalizada"
          : el.facturacion?.ot?.cerrada
          ? "Cerrada"
          : el.facturacion?.ot?.ot_usuarios.length
          ? "Asignada"
          : !el.facturacion?.ot?.ot_usuarios.length
          ? "Sin Asignar"
          : "Sin OT Asignada"
      }`,
    }));
    console.log(newData);
    dispatch(setCobranzas(newData));
  } catch (error) {
    console.log(error);
  }
};

export const getAllCobranzasByState = (estado) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/cobranzas?status=${estado}`);
    const newData = res.data.map((cob) => {
      let saldo_pendiente = 0;
      if (cob.cobranza_pagos.length) {
        saldo_pendiente = cob.cobranza_pagos.reduce((acumulador, currentValue) => acumulador + currentValue.monto, 0);
      }
      return {
        ...cob,
        numero_nota_credito: cob.numero_nota_credito || "S/D",
        nro_oc: cob.facturacion.nro_oc,
        nro_oc_privada: cob.facturacion.nro_oc_noMP,
        iva: "$ " + Math.round(cob.monto - cob.monto / 1.19).toLocaleString(),
        monto_withOut_iva: "$ " + Math.round(cob.monto / 1.19).toLocaleString(),
        monto: `$ ${cob.monto.toLocaleString()}`,
        nombre_cliente: cob.facturacion.cliente_facturacion_clienteTocliente.nombre,
        razon_social: cob.facturacion.cliente_facturacion_clienteTocliente.gen_tipo_razon_social.descripcion,
        f_facturacion: dayjs(cob.f_facturacion).add(1, "day").format("DD/MM/YYYY"),
        fecha_vencimiento: dayjs(cob.f_vencimiento).add(1, "day").format("DD/MM/YYYY"),
        fecha_compromiso: dayjs(cob.f_compromiso).add(1, "day").format("DD/MM/YYYY"),
        fecha_pago: dayjs(cob.cobranza_pagos[cob.cobranza_pagos.length - 1]?.f_pago)
          .add(1, "day")
          .format("DD/MM/YYYY"),
        saldo_pendiente: cob.monto - saldo_pendiente,
      };
    });

    if (estado === true) {
      await dispatch(setCobranzasTrue(newData));
    } else {
      await dispatch(setCobranzasFiltered(newData));
      await dispatch(setCobranzasFalse(newData));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllCobranzasAnuladas = (estado) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/cobranzas/anuladas`);
    const newData = res.data.map((cob) => {
      let saldo_pendiente = 0;
      if (cob.cobranza_pagos.length) {
        saldo_pendiente = cob.cobranza_pagos.reduce((acumulador, currentValue) => acumulador + currentValue.monto, 0);
      }
      return {
        ...cob,
        iva: "$ " + Math.round(cob.monto - cob.monto / 1.19).toLocaleString(),
        monto_withOut_iva: "$ " + Math.round(cob.monto / 1.19).toLocaleString(),
        monto: `$ ${cob.monto.toLocaleString()}`,
        nombre_cliente: cob.facturacion.cliente_facturacion_clienteTocliente.nombre,
        razon_social: cob.facturacion.cliente_facturacion_clienteTocliente.gen_tipo_razon_social.descripcion,
        f_facturacion: dayjs(cob.facturacion.f_facturacion).add(1, "day").format("DD/MM/YYYY"),
        fecha_vencimiento: dayjs(cob.f_vencimiento).add(1, "day").format("DD/MM/YYYY"),
        fecha_compromiso: dayjs(cob.f_compromiso).add(1, "day").format("DD/MM/YYYY"),
        fecha_pago: dayjs(cob.fecha_pago).add(1, "day").format("DD/MM/YYYY"),
        saldo_pendiente: cob.monto - saldo_pendiente,
      };
    });

    await dispatch(setCobranzasAnuladas(newData));
  } catch (error) {
    console.log(error);
  }
};

export const registrarPago = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/cobranzas/registro", values);
    if (res.data.estado === true) {
      dispatch(setRegistroCobranza(res.data));
    } else {
      let saldo_pendiente = 0;
      if (res.data.cobranza_pagos.length) {
        saldo_pendiente = res.data.cobranza_pagos.reduce((acumulador, currentValue) => acumulador + currentValue.monto, 0);
      }
      const newData = {
        ...res.data,
        iva: "$ " + Math.round(res.data.monto - res.data.monto / 1.19).toLocaleString(),
        monto_withOut_iva: "$ " + Math.round(res.data.monto / 1.19).toLocaleString(),
        monto: `$ ${res.data.monto.toLocaleString()}`,
        n_factura: res.data.facturacion.n_factura,
        nombre_cliente: res.data.facturacion.cliente_facturacion_clienteTocliente.nombre,
        razon_social: res.data.facturacion.cliente_facturacion_clienteTocliente.gen_tipo_razon_social.descripcion,
        f_facturacion: dayjs(res.data.facturacion.f_facturacion).add(1, "day").format("DD/MM/YYYY"),
        fecha_vencimiento: dayjs(res.data.f_vencimiento).add(1, "day").format("DD/MM/YYYY"),
        fecha_compromiso: dayjs(res.data.f_compromiso).add(1, "day").format("DD/MM/YYYY"),
        fecha_pago: dayjs(res.data.fecha_pago).add(1, "day").format("DD/MM/YYYY"),
        saldo_pendiente: res.data.monto - saldo_pendiente,
      };
      dispatch(setUpdateRegistroCobranza(newData));
    }
    dispatch(
      setNotification({
        status: "success",
        message: "Pago registrado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al registrar pago",
        open: true,
      })
    );
  }
};

export const getCobranzaById = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/cobranzas/byId/${id}`);
    let newData = { ...res.data, monto: `$ ${res.data.monto?.toLocaleString()}` };
    dispatch(setCobranzaId(newData));
  } catch (error) {
    console.log(error);
  }
};

export const updateRegistroCobranza = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/cobranzas", values);
    dispatch(setCobranzaId(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Registrado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al registrar",
        open: true,
      })
    );
  }
};

export const getCobranzaCliente = (id) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/cobranzas/cliente?id=${id}`);
    await dispatch(setCobranzasClientes(data));
  } catch (error) {
    console.log(error);
  }
};

export const {
  setCobranzas,
  setCobranzasClientes,
  setCobranzasTrue,
  setCobranzasFalse,
  setCobranzaId,
  setUpdateRegistroCobranza,
  setRegistroCobranza,
  setCobranzasFiltered,
  setCobranzasAnuladas,
} = cobranzasSlice.actions;

export default cobranzasSlice.reducer;
