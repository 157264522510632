// import { Box, Tab, Tabs, Typography } from "@mui/material";
// import { useState } from "react";
// import RegistrarAnticipo from "./RegistrarAnticipo";
// import AnticiposList from "./AnticipoList";

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//       className="min-h-[450px] max-h-[450px]"
//     >
//       {value === index && (
//         <Box sx={{ py: 2, px: 1 }}>
//           <div>{children}</div>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// export default function Anticipos() {
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <div>
//       <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//         <Tabs value={value} onChange={handleChange} centered>
//           <Tab sx={{ width: "50%" }} label="Registrar Fondo por rendir" {...a11yProps(0)} />
//           <Tab sx={{ width: "50%" }} label="Listado de Fondos por rendir" {...a11yProps(1)} />
//         </Tabs>
//       </Box>
//       <CustomTabPanel value={value} index={0}>
//         <RegistrarAnticipo />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={1}>
//         <AnticiposList />
//       </CustomTabPanel>
//     </div>
//   );
// }

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import RegistrarAnticipo from "./RegistrarAnticipo";
import AnticiposList from "./AnticipoList";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="min-h-[450px] max-h-[450px]"
    >
      {value === index && (
        <Box sx={{ py: 2, px: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Anticipos() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Fondos por Rendir</h1>
          <BreadCrumb origin={"Rendicion de gastos"} current={"Fondos por Rendir"} />
        </div>
      </div>
      <AnticiposList />
    </div>
  );
}
