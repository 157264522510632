import { Button, TextField, Chip, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { createArea } from "../../redux/slices/area";

export default function AreaDialog({ open, dialogValue, handleClose, setter }) {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("name", dialogValue.name);
  }, [open]);

  const handle_submit = async (value) => {
    const area = await dispatch(createArea(value));
    setter(area);
    reset();
    handleClose();
  };

  return (
    <form>
      <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR AREA</div>
      <div className="min-h-[65px] mb-1">
        <Controller
          id="name"
          name="name"
          control={control}
          rules={{ required: "* Campo requerido" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label="Nombre"
              onChange={onChange}
              size="small"
              value={value || ""}
              error={errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
      </div>
      <div className="flex items-center w-full justify-between">
        <Button onClick={handleSubmit(handle_submit)} variant="outlined" fullWidth>
          Enviar formulario
        </Button>
      </div>
    </form>
  );
}
