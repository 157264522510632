import React from "react";
import {
  getAllRepairParts,
  getAllBrandsSelect,
  getAllTeamTypesSelect,
  getAllStatusSelect,
  deleteRepairPart,
  getAllStorages,
  getAllProvidersSelect,
} from "../redux/slices/inventory";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import RepairPartCreate from "../components/modal/RepairPartCreate";
import RepairPartUpdate from "../components/modal/RepairPartUpdate";

const permitedRenderDataColumn = [
  { title: "id", value: "id" },
  { title: "Codigo Loginteng", value: "codigo_loginteg" },
  { title: "Descripcion", value: "descripcion" },
  { title: "Marca", value: "marcaText" },
  { title: "Modelo", value: "modelo" },
  { title: "Código Proveedor", value: "serie" },
  { title: "Compatibilidad", value: "equipoText" },
  { title: "Estado", value: "estadoText" },
  { title: "Cantidad", value: "cantidad" },
  { title: "Valor", value: "valueCost" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function RepairParts() {
  const { repairParts, success } = useSelector((state) => state.inventory);
  const [sorted, setSorted] = useState([]);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteRepairPart(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Repuestos", false, setOpen);

  useEffect(() => {
    dispatch(getAllRepairParts());
    dispatch(getAllBrandsSelect());
    dispatch(getAllTeamTypesSelect());
    dispatch(getAllStatusSelect());
    dispatch(getAllStorages());
    dispatch(getAllProvidersSelect());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Repuestos</h1>
          <BreadCrumb origin={"Inventario"} current={"Repuestos"} />
        </div>
        <div>
          {/* <RepairPartCreate /> */}
          <RepairPartUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={[...repairParts].sort((a, b) => b.id - a.id)} success={success} />
    </div>
  );
}
