import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import { deleteArticle, getAllArticles, getAllProviders, getAllStatusSelect } from "../redux/slices/inventory";
import ArticleCreate from "../components/modal/ArticleCreate";
import ArticleUpdate from "../components/modal/ArticleUpdate";
import { getAllStorages } from "../redux/slices/inventory";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { value: "codigo_loginteg", title: "Código Loginteg", minWidth: 170 },
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "lotes", title: "Lote", minWidth: 100 },
  { value: "codigo_proveedor", title: "Código Proveedor", minWidth: 170 },
  { value: "fecha_vencimientoFormat", title: "Fecha de vencimiento", minWidth: 100 },
  { value: "cantidad", title: "Cantidad", minWidth: 100 },
  { title: "Valor", value: "valueCost" },
  { title: "Estado", value: "estadoText" },
  {
    value: "acciones_inv",
    title: "Acciones",
    minWidth: 170,
    align: "center",
  },
];

export default function Articles() {
  const dispatch = useDispatch();
  const { articles, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(deleteArticle(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Insumos", false, setOpen);

  useEffect(() => {
    dispatch(getAllArticles());
    dispatch(getAllStorages());
    dispatch(getAllStatusSelect());
    dispatch(getAllProviders());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Insumos</h1>
          <BreadCrumb origin={"Inventario"} current={"Insumos"} />
        </div>
        <div>
          {/* <ArticleCreate /> */}
          <ArticleUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={articles} success={success} />
    </div>
  );
}
