import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import enviroments from "../../../utils/enviroments";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "",
    color: "#000000",
    padding: 10,
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CobranzasPago({ rows }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" width={"100px"}>
              Fecha de Pago
            </StyledTableCell>
            <StyledTableCell align="left" width={"200px"}>
              Monto
            </StyledTableCell>
            <StyledTableCell align="left" width={"200px"}>
              Comprobante
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <a
                    href={`${enviroments.img_endpoint}${cobranzaId?.archivo_comprobante_pago}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline  ml-5"
                  >
                    <p>{cobranzaId?.archivo_comprobante_pago}</p>
                  </a> */}
          {rows.cobranza_pagos.map((row) => (
            <StyledTableRow key={rows.id}>
              <StyledTableCell>{dayjs(row.f_pago).add(1, "day").format("DD/MM/YY")}</StyledTableCell>
              <StyledTableCell align="left">{`$ ${row.monto.toLocaleString()}`}</StyledTableCell>
              <StyledTableCell align="left">
                {row.comprobante ? (
                  <a
                    href={`${enviroments.img_endpoint}${row.comprobante}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline"
                  >
                    <p>{row.comprobante}</p>
                  </a>
                ) : (
                  "S/D"
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
