import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import RolesForm from "../form/RolesForm";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/createModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

export default function RolesModal({ submit, fields, permissions, type }) {
  const { open } = useSelector((state) => state.createModal);
  const dispatch = useDispatch();
  const handleOpen = () => dispatch(setOpen(true));
  const handleClose = () => dispatch(setOpen(false));
  return (
    <div>
      <Chip variant="contained" sx={{ textTransform: "none" }} onClick={handleOpen} label="Crear nuevo Rol" color="primary" />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>{permissions && <RolesForm submit={submit} fields={fields} permissions={permissions} />}</Box>
        </Fade>
      </Modal>
    </div>
  );
}
