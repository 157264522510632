import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, MenuItem } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";

import Chip from "@mui/material/Chip";
import {
  createProyectBilling,
  createTypesExpensesBilling,
  updateProyectBilling,
  updateTypesExpensesBilling,
} from "../../redux/slices/facturacion";
import { getCommuneByRegion } from "../../redux/slices/regions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function ProyectBilling({ open, data, setOpen, type, subtype }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const openByType = type === "create" ? openModal : open;
  const { paises } = useSelector((state) => state.paises);
  const { data: regionData, comunas } = useSelector((state) => state.regions);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    type === "create" ? setOpenModal(false) : setOpen({ status: false });
    reset();
  };

  const countryWatcher = watch("pais_id");
  const regionWatcher = watch("region");

  const isChilean = paises.find((el) => el.id === countryWatcher)?.name === "Chile";

  React.useEffect(() => {
    if (data?.id) {
      reset({
        nombre: data.nombre,
        pais_id: data.paises?.id,
        region: data.gen_region?.id,
        comuna: data.gen_comuna?.id,
      });
    }
  }, [data]);

  const newItemType = async (value) => {
    if (!subtype) {
      if (type === "create") {
        dispatch(createProyectBilling(value));
      } else {
        dispatch(updateProyectBilling(value, data.id));
      }
    } else {
      if (type === "create") {
        dispatch(createTypesExpensesBilling(value));
      } else {
        dispatch(updateTypesExpensesBilling(value, data.id));
      }
    }
    reset();
    handleClose();
  };

  React.useEffect(() => {
    let condition = paises.find((el) => el.id === countryWatcher)?.name !== "Chile";
    if (condition) {
      setValue("region", null);
      setValue("comuna", null);
    }
  }, [countryWatcher]);

  React.useEffect(() => {
    if (regionWatcher) {
      dispatch(getCommuneByRegion(regionWatcher));
    }
  }, [regionWatcher]);

  if (type === "create" || (type === "edit" && data?.id)) {
    return (
      <div>
        {type === "create" && (
          <Chip
            onClick={() => setOpenModal(true)}
            variant="contained"
            sx={{ textTransform: "none" }}
            label={subtype === "expenses" ? "Registrar tipo de gasto" : `Registrar nuevo Proyecto`}
            color="primary"
          />
        )}
        <Modal
          open={openByType}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openByType}>
            <Box sx={style}>
              {
                <form onSubmit={handleSubmit(newItemType)}>
                  <div className="min-h-[65px] mb-1">
                    <Controller
                      id={"nombre"}
                      control={control}
                      name={"nombre"}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          label="Nombre"
                          onChange={onChange}
                          value={value || ""}
                          error={Boolean(errors?.nombre)}
                          helperText={errors?.nombre && "El campo es requerido"}
                        />
                      )}
                    />
                  </div>
                  <div className="min-h-[65px] mb-1">
                    <Controller
                      id="pais_id"
                      name="pais_id"
                      control={control}
                      rules={{ required: "* Campo requerido" }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          select
                          size="small"
                          onChange={onChange}
                          value={value || ""}
                          label="Pais"
                          error={errors.pais_id}
                          helperText={errors.pais_id?.message}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 300,
                                },
                              },
                            },
                          }}
                        >
                          {paises?.map(
                            (option) =>
                              option.id && (
                                <MenuItem key={option.id} dense divider value={option.id}>
                                  {option.name}
                                </MenuItem>
                              )
                          )}
                        </TextField>
                      )}
                    />
                  </div>
                  {isChilean && (
                    <div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="region"
                          name="region"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Región"
                              error={errors.region}
                              helperText={errors.region?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {regionData?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.descripcion}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="comuna"
                          name="comuna"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Comunas"
                              error={errors.comuna}
                              helperText={errors.comuna?.message}
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {comunas?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.descripcion}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                  )}

                  <Button type="submit" fullWidth sx={{ mt: 1 }} variant="contained">
                    Guardar
                  </Button>
                </form>
              }
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}
