import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import NewForm from "../form/NewForm";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/createModal";
import { Button, Chip } from "@mui/material";

export default function TransitionsModal({
  data,
  fields,
  schema,
  submit,
  type,
  img = false,
  image,
  size = "70%",
  setParentRuts,
}) {
  const { open } = useSelector((state) => state.createModal);
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: size,
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 5,
  };

  return (
    <div>
      <Chip
        sx={{ textTransform: "none" }}
        variant="contained"
        onClick={() => dispatch(setOpen(true))}
        label={data.button}
        color="primary"
      />
      <Modal
        open={open}
        onClose={() => {
          if (setParentRuts) {
            setParentRuts(null);
          }
          dispatch(setOpen(false));
          if (image) {
            image(undefined);
          }
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <NewForm
              fields={fields}
              schema={schema}
              submit={submit}
              type={type}
              img={img}
              image={image}
              setParentRuts={setParentRuts}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
