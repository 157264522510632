import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  ganancias: [],
  success: false,
  status: "idle",
};

const gananciaSlice = createSlice({
  name: "ganancias",
  initialState,
  reducers: {
    setGanancias: (state, action) => {
      state.ganancias = action.payload;
      state.status = true;
      state.success = true;
    },
    create: (state, action) => {
      state.services.push(action.payload);
      state.success = true;
    },
    remove: (state, action) => {
      const updated = state.ganancias.filter((el) => el.id !== action.payload);
      state.ganancias = updated;
      state.success = true;
    },
    update: (state, action) => {
      const updated = state.ganancias.filter((el) => el.id !== action.payload.id);
      state.ganancias = [...updated, action.payload];
      state.success = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getAllGanancias = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/ganancias");
    dispatch(setGanancias(response.data));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const createGanancia = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/ganancias", values);
    dispatch(create(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Ganancia creada con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear ganancia",
        open: true,
      }),
    );
  }
};

export const deleteGanancia = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/ganancias?id=${id}`);
    dispatch(remove(response.data.id));
    dispatch(
      setNotification({
        status: "success",
        message: "Ganancia borrada con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al borrar ganancia",
        open: true,
      }),
    );
  }
};

export const updateGanancias = (values) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/ganancias?id=${values.id}`, values);
    dispatch(update(response.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Ganancia editada con exito",
        open: true,
      }),
    );
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar ganancia",
        open: true,
      }),
    );
  }
};

export const { setGanancias, create, remove, update, setStatus } = gananciaSlice.actions;

export default gananciaSlice.reducer;
