import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { getAllGanancias, deleteGanancia } from "../redux/slices/ganancias";
import GananciaModal from "../components/modal/GananciaModal";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "name" },
  { title: "Porcentaje", value: "porcentaje" },
  { title: "Acciones", value: "acciones_inv" },
];

export default function Ganancias() {
  const { ganancias, success } = useSelector((state) => state.ganancias);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteGanancia(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "SERVICIO", false, setOpen);

  useEffect(() => {
    dispatch(getAllGanancias());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Ganancias</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Ganancias"} />
        </div>
        <div>
          <GananciaModal open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={ganancias} success={success} />
    </div>
  );
}
