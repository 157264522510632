import "./App.css";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoute";
import ClientDetail from "./components/clientDetails/ClientDetail";
import NotFound from "./components/NotFound/NotFound";
import FormOt from "./components/createOtForm/FormOt";
import EquipamentList from "./components/equipmentList/EquipamentList";
import WorkOrderEdit from "./components/workOrderEdit/WorkOrderEdit";
import {
  Home,
  BusinessName,
  Charges,
  Client,
  ContactMeans,
  Contacts,
  Departments,
  Roles,
  Users,
  WorkOrder,
  Login,
  Profile,
  CreateOt,
  AssignOT,
  DetailOT,
  ExecuteOT,
  ProgrammingTeam,
  Assignment,
  Maintenance,
  ResetPassword,
  TeamTypes,
  Version,
  Teams,
  RepairParts,
  Brands,
  OtsAssignedAndWithoutAssign,
  Articles,
  ItemType,
  Providers,
  InventoryIncorporation,
  Model,
  TypesOfDocument,
  Piezas,
  Elements,
} from "./pages";
import PreFactura from "./pages/PreFactura/PreFactura";
import Factura from "./pages/Factura/Factura";
import PreFacturaId from "./pages/PreFactura/PreFacturaId/PreFacturaId";
import FacturaId from "./pages/Factura/FacturaId/FacturaId";
import Facturado from "./pages/Facturado/Facturado";
import FacturadoId from "./pages/Facturado/FacturadoId/FacturadoId";
import Cobranzas from "./pages/Cobranzas/Cobranza";
import CobranzaId from "./pages/Cobranzas/CobranzaId/CobranzaId";
import FacturasPagadas from "./pages/FacturasPagadas/FacturasPagadas";
import FacturasPagadasId from "./pages/FacturasPagadas/FacurasPagadasId/FacturasPagadasId";
import OtType from "./pages/OtType";
import FacturacionTipoGastos from "./pages/FacturacionTipoGastos";
import FacturacionProyectos from "./pages/FacturacionProyectos";
import FactrurasEmitidas from "./pages/FactrurasEmitidas/FactrurasEmitidas";
import FactrurasRecibidas from "./pages/FacturasRecibidas/FacturasRecibidas";
import Services from "./pages/Services";
import Morosos from "./pages/Morosos";
import InventoryIncorporationList from "./pages/InventoryIncorporationList";
import WorkOrderView from "./components/workOrderView";
import InformeFacturacion from "./pages/InformeFacturacion";
import RepairPartMaintainer from "./pages/RepairPartMaintainer";
import Facturas from "./pages/Facturas";
import FacturacionCliente from "./pages/FacturacionCliente";
import ArticlesMold from "./pages/ArticlesMold";
import FacturasAnuladas from "./pages/FacturasAnuladas";
import Feriados from "./pages/Feriados";
import Remuneartion from "./pages/Remuneration";
import Portal from "./pages/Portal";
import RDFList from "./pages/Rendicion/RDFList";
import Rendicion from "./pages/Rendicion";
import VBList from "./pages/Rendicion/VBList";
import PruchOrderEdit from "./pages/Factura/PruchOrderEdit";
import Ganancias from "./pages/Ganancias";
import Riesgo from "./pages/Riesgo";
import Area from "./pages/Area";
import Contact from "./pages/Contact";
import Position from "./pages/Position";
import PendingVacationList from "./pages/Remuneration/PendingVacationList";
import ReimbursementCategory from "./pages/ReimbursmentCategory";
import Bodegas from "./pages/Bodegas";
import Anticipos from "./pages/Rendicion/Anticipos";
import PagoRendicion from "./pages/Rendicion/PagoRendicion";
import ReabrirOT from "./pages/ReabrirOT";
import Informes from "./pages/Informes";
import MenuMantencion from "./pages/MenuMantencion";
import WorkOrderViewInform from "./components/Informes/OtGeneral/WorkOrderViewInform";
import GestionDeEnvios from "./pages/GestionDeEnvios";
import PagoGeneral from "./pages/Rendicion/PagoGeneral";
import ListadoRendiciones from "./pages/Rendicion/ListadoRendiciones";

function App() {
  return (
    <div className="App font-roboto">
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/password-reset/:token" element={<ResetPassword />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/social-reason-types" element={<BusinessName />} />
          {/* <Route exact path="/positions" element={<Charges />} /> */}
          <Route exact path="/positions" element={<Position />} />
          <Route exact path="/clients" element={<Client />} />
          <Route exact path="/contact-means" element={<ContactMeans />} />
          {/* <Route exact path="/contacts" element={<Contacts />} /> */}
          <Route exact path="/contacts" element={<Contact />} />
          <Route exact path="/departments" element={<Departments />} />
          <Route exact path="/roles" element={<Roles />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/workOrder" element={<WorkOrder />} />
          <Route exact path="/clients/:id" element={<ClientDetail />} />
          <Route exact path="/create-ot" element={<CreateOt />} />
          <Route exact path="/create-ot/:id" element={<FormOt />} />
          <Route exact path="/assign-ot" element={<AssignOT />} />
          <Route exact path="/execute-ot" element={<ExecuteOT />} />
          <Route exact path="/execute-ot/:otId" element={<DetailOT />} />
          <Route exact path="/execute-ot/edit" element={<WorkOrderEdit />} />
          <Route exact path="/assign-team" element={<Assignment />} />
          <Route exact path="/assign-team/:id" element={<EquipamentList />} />
          <Route exact path="/programming-team" element={<ProgrammingTeam />} />
          <Route exact path="/maintenance-team" element={<Maintenance />} />
          <Route exact path="/pending-ots" element={<OtsAssignedAndWithoutAssign />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/teams-types" element={<ItemType />} />
          <Route path="/version" element={<Version />} />
          <Route path="/create-team" element={<Teams />} />
          <Route path="/repair-parts" element={<RepairParts />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/models" element={<Model />} />
          <Route path="/types-of-document" element={<TypesOfDocument />} />
          <Route path="/piezas" element={<Piezas />} />
          <Route path="/insumos" element={<Articles />} />
          <Route path="/item-type" element={<TeamTypes />} />
          <Route path="/providers" element={<Providers />} />
          <Route path="/elements" element={<Elements />} />
          <Route path="/inventory-incorporation" element={<InventoryIncorporationList />} />
          <Route path="/inventory-incorporation/create" element={<InventoryIncorporation />} />
          <Route path="/orden-de-compra" element={<PreFactura />} />
          <Route path="/orden-de-compra/:id" element={<PreFacturaId />} />
          <Route path="/orden-de-compra-edit/:id" element={<PruchOrderEdit />} />
          <Route path="/por-facturar" element={<Factura />} />
          <Route path="/ot-view/:id" element={<WorkOrderView />} />
          <Route path="/por-facturar/:id" element={<FacturaId />} />
          <Route path="/ordenes-facturadas" element={<Facturado />} />
          <Route path="/ordenes-facturadas/:id" element={<FacturadoId />} />
          <Route path="/cobranza" element={<Cobranzas />} />
          <Route path="/cobranza/:id" element={<CobranzaId />} />
          <Route path="/facturas-pagadas" element={<FacturasPagadas />} />
          <Route path="/facturas-pagadas/:id" element={<FacturasPagadasId />} />
          <Route path="/proyectos" element={<FacturacionProyectos />} />
          <Route path="/facturacion-tipo-gastos" element={<FacturacionTipoGastos />} />
          <Route path="/ot-types" element={<OtType />} />
          <Route path="/facturas-emitidas" element={<FactrurasEmitidas />} />
          <Route path="/facturas-recibidas" element={<FactrurasRecibidas />} />
          <Route path="/services" element={<Services />} />
          <Route path="/morosos" element={<Morosos />} />
          <Route path="/facturacion" element={<InformeFacturacion />} />
          <Route path="/maintainer-repair-part" element={<RepairPartMaintainer />} />
          <Route path="/facturas-generadas" element={<Facturas />} />
          <Route path="/facturacion-clientes" element={<FacturacionCliente />} />
          <Route path="/maintainer-supplies" element={<ArticlesMold />} />
          <Route path="/facturas-anuladas" element={<FacturasAnuladas />} />
          <Route path="/feriados" element={<Feriados />} />
          <Route path="/colaboradores" element={<Remuneartion />} />
          <Route path="/ganancias" element={<Ganancias />} />
          <Route path="/riesgo" element={<Riesgo />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/pendientes-de-validacion" element={<PendingVacationList />} />
          <Route path="/rendicion-de-fondos" element={<RDFList />} />
          <Route path="/listado-rendiciones" element={<ListadoRendiciones />} />
          <Route exact path="/rendicion-de-fondos/create" element={<Rendicion type="create" />} />
          <Route exact path="/rendicion-de-fondos/:id" element={<Rendicion type="view" />} />
          <Route exact path="/rendicion-de-fondos/pending/:id" element={<Rendicion type="pending" />} />
          <Route exact path="/rendicion-de-fondos/vb" element={<VBList type="vb" />} />
          <Route exact path="/rendicion-de-fondos/vb/:id" element={<Rendicion type="vb" />} />
          <Route path="/area" element={<Area />} />
          <Route path="/bodegas" element={<Bodegas />} />
          <Route path="/fondos-por-rendir" element={<Anticipos />} />
          <Route path="/pago-general" element={<PagoGeneral />} />
          <Route path="/pago-rendicion" element={<PagoRendicion />} />
          <Route path="/categorias-rendicion" element={<ReimbursementCategory />} />
          <Route path="/reabrir-orden" element={<ReabrirOT />} />
          <Route path="/informes" element={<Informes />} />
          <Route path="/checklist-capacitacion" element={<MenuMantencion />} />
          <Route path="/ot-view-inform/:id" element={<WorkOrderViewInform />} />
          <Route path="/gestion-de-envios" element={<GestionDeEnvios />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
