import { useSelector, useDispatch } from "react-redux";
import { getAllClients } from "../redux/slices/clients";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGrid from "../components/tables/DataGrid";
import HistoryModal from "../components/modal/HistoryModal";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "direccion", title: "Direccion", minWidth: 100 },
  {
    value: "comuna",
    title: "Comuna",
    minWidth: 170,
  },
  {
    value: "mail_contacto",
    title: "Mail contacto",
    minWidth: 170,
  },
  {
    value: "accion",
    title: "Crear OT",
    minWidth: 170,
    align: "center",
  },
];

export default function CreateOt() {
  const dispatch = useDispatch();
  const { data, success } = useSelector((state) => state.clients);
  const { currentUser } = useSelector((state) => state.auth);

  const handleDelete = () => {};

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Crear OT", false, currentUser?.rol);

  useEffect(() => {
    if (!data.length) {
      dispatch(getAllClients());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Crea OT</h1>
          <BreadCrumb origin={"Servicio técnico"} current={"Crea OT"} />
        </div>
        <HistoryModal />
      </div>
      <DataGrid columns={columns} rows={data} success={success} />
    </div>
  );
}
