import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../../../hooks/useDataGrid";
import {
  deleteArticle,
  getAllArticles,
  getAllStatusSelect,
  getAllStorages,
  setArticles,
} from "../../../redux/slices/inventory";
import ArticleCreate from "../../modal/ArticleCreate";
import ArticleUpdate from "../../modal/ArticleUpdate";
import DataComponent from "./DataComponent";
import dayjs from "dayjs";

const createArr = [
  { value: "codigo_loginteg", title: "Código Loginteg", minWidth: 170 },
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "cantidad", title: "Cantidad", minWidth: 100 },
  { value: "lotes", title: "Lotes", minWidth: 100 },
  { value: "fecha_vencimiento", title: "Fecha de vencimiento", minWidth: 100 },
  { title: "Valor", value: "valueCost" },
  { title: "Estado", value: "estadoText" },
  { title: "Eliminar", value: "deleteTeam" },
];

const editArr = [
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "cantidad", title: "Cantidad", minWidth: 100 },
  { value: "lotes", title: "Lotes", minWidth: 100 },
  { value: "fecha_vencimiento", title: "Fecha de vencimiento", minWidth: 100 },
  { title: "Estado", value: "estadoText" },
];

export default function ArticlesAccordion({ articulos, setArticulos, edit }) {
  const [id, setId] = useState(1);

  const permitedRenderDataColumn = edit ? editArr : createArr;
  const dispatch = useDispatch();
  const [articlesToRender, setArticlesToRender] = useState([]);
  const { articles, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    setArticulos(articulos.filter((rep) => rep.id !== params));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Insumos", false, setOpen);

  useEffect(() => {
    dispatch(getAllArticles());
    dispatch(getAllStorages());
    dispatch(getAllStatusSelect());
  }, [dispatch]);

  useEffect(() => {
    const newArticles = articulos.map((articulo) => ({
      ...articulo,
      fecha_vencimiento: dayjs(articulo.fecha_vencimiento).format("DD/MM/YYYY"),
      estadoText: articulo?.estadoText
        ? articulo.estadoText
        : articulo.bodegaId
        ? articulo?.bodega?.name
        : articulo?.inv_estado?.descripcion,
    }));

    setArticlesToRender(newArticles);
  }, [articulos]);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          sx={{ bgcolor: "#e4dadab8", borderRadius: 1 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="flex justify-center font-bold uppercase tracking-wider text-sm">Recepción de Insumos</div>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#fdf9f9", borderBottomLeftRadius: 1, borderBottomRightRadius: 1 }}>
          <div>
            <div className={`flex justify-end mb-5 ${edit && "hidden"}`}>
              <div>
                <ArticleCreate id={id} setId={setId} articulos={articulos} setArticulos={setArticulos} />
              </div>
            </div>
            <DataComponent columns={columns} rows={articlesToRender} success={success} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
