import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DataGridComponent from "../../components/tables/DataGrid";
import useDataGrid from "../../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnticipos, updatePaymentMethod, updatePaymentMethodLoginteg } from "../../redux/slices/fundAccounting";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import uploadFiles from "../../utils/UploadFiles";
import enviroments from "../../utils/enviroments";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function PagoLogintegModal({ params }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [paymentMethod, setPaymentMethod] = useState(params?.metodo_loginteg || "");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const vals = { metodo: paymentMethod, id: params.id, file };
    if (file) {
      vals.file = await uploadFiles("docs", file);
    } else {
      vals.file = null;
    }

    await dispatch(updatePaymentMethodLoginteg(vals));
    handleClose();
  };

  return (
    <div>
      <LocalAtmIcon color="success" onClick={handleOpen} className="cursor-pointer" />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
            {"Pago Loginteg"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                label="Método utilizado"
                onChange={(e) => setPaymentMethod(e.target.value)}
                sx={{ mt: 2 }}
                fullWidth
                select
                value={paymentMethod}
                disabled={params?.file_loginteg}
              >
                <MenuItem value="Transferencia">Transferencia</MenuItem>
                <MenuItem value="Efectivo">Efectivo</MenuItem>
              </TextField>
            </DialogContentText>

            {params?.file_loginteg ? (
              <div className="mt-2">
                <Button component="label" variant="contained" fullWidth>
                  <a href={`${enviroments.img_endpoint}${params?.file_loginteg}`} target="_blank" download>
                    Descargar Comprobante
                  </a>
                </Button>
              </div>
            ) : (
              <div className="mt-2 w-full">
                <div>
                  <Button
                    disabled={params?.file_loginteg}
                    color={file ? "success" : "primary"}
                    component="label"
                    variant="contained"
                    fullWidth
                  >
                    Subir Archivo
                    <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} accept="application/pdf" />
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
            {!params?.file_loginteg && (
              <Button onClick={handleSubmit} autoFocus>
                Confirmar
              </Button>
            )}
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
}
