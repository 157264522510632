import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const backdrop = createSlice({
  name: "backdrop",
  initialState,
  reducers: {
    setOpenBackdrop: (state, action) => {
      state.backdrop = action.payload;
      state.status = "succeeded";
    },
  },
});

export const { setOpenBackdrop } = backdrop.actions;

export default backdrop.reducer;
