import DataGridComponent from "../../components/tables/DataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import useDataGrid from "../../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPagoRendicion, resetPagoRendicion, updatePagoRendicion } from "../../redux/slices/fundAccounting";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from "@mui/material";
import uploadFiles from "../../utils/UploadFiles";
import enviroments from "../../utils/enviroments";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export const dataColumns = [
  { value: "rendicion_id", title: "N° Rendición", minWidth: 170 },
  { value: "responsable", title: "Solicitante", minWidth: 170 },
  { value: "title", title: "Título", minWidth: 170 },
  { value: "fecha_formatted", title: "Fecha Rendición", minWidth: 170 },
  { value: "moneda_text", title: "Tipo de moneda", minWidth: 170 },
  { value: "total", title: "Total acumulado", minWidth: 170 },
  { value: "estado_text", title: "Estado", minWidth: 170 },
  { value: "responsable_pago", title: "Encargado pago", minWidth: 170 },
  { value: "rendicion_pago_semaforo", title: "Color", minWidth: 170 },
  { value: "rendicion_pago_actions", title: "Acciones", minWidth: 100 },
];

export default function PagoGeneral() {
  const [alreadyAproved, setAlreadyAproved] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [render, setRender] = useState([]);
  const [filterType, setFilterType] = useState(localStorage.getItem("pago-rendicion") === "true" ? true : false);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState({
    open: false,
    data: null,
  });

  const onClose = () => {
    setOpen({ open: false, data: null });
    setAlreadyAproved(false);
    setFile(null);
  };

  const dispatch = useDispatch();
  const { pagoRendicion } = useSelector((state) => state.fundAccounting);
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (open.data) {
      setAlreadyAproved(open.data.pagada);
    }
  }, [open]);

  useEffect(() => {
    dispatch(getPagoRendicion());
  }, [dispatch, currentUser.id]);

  useEffect(() => {
    const filtered = pagoRendicion.filter((el) => el.pagada === filterType);
    setRender(filtered);
  }, [pagoRendicion, filterType]);

  const [column] = useDataGrid(dataColumns, null, "", false, setOpen);

  const handleSubmit = async () => {
    const vals = { id: open.data.id };
    vals.currentUser = currentUser.id;
    vals.fecha_pago = date;
    if (file) {
      vals.file = await uploadFiles("docs", file);
    } else {
      vals.file = null;
    }

    await dispatch(updatePagoRendicion(vals));
    onClose();
  };

  const handleFilter = (e) => {
    setFilterType(e.target.value);
    localStorage.setItem("pago-rendicion", e.target.value);
  };

  const handleReset = async () => {
    const vals = { id: open.data.id };

    await dispatch(resetPagoRendicion(vals));
    onClose();
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Pago General</h1>
          <BreadCrumb origin={"Rendicion de gastos"} current={"Pago General"} />
        </div>
        <div className="w-[200px]">
          <TextField onChange={handleFilter} value={filterType} fullWidth size="small" label="Estado" select>
            <MenuItem value={false}>No Pagadas</MenuItem>
            <MenuItem value={true}>Pagadas</MenuItem>
          </TextField>
        </div>
      </div>
      <DataGridComponent
        columns={column}
        rows={[...render].sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        })}
        success={true}
      />
      <Dialog open={open.open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Procedimiento para marcar como pagada una rendición"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              {!alreadyAproved && "Antes de marcar como pagada la rendición puedes ver los detalles de la misma."}
              {alreadyAproved && "La rendición fue marcada como pagada. "}
            </span>
          </DialogContentText>
          {!alreadyAproved && (
            <div className="mt-2 w-full">
              <div className="mb-5">
                <Button color={file ? "success" : "primary"} component="label" variant="contained" fullWidth>
                  Subir Comprobante
                  <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} accept="application/pdf" />
                </Button>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha de pago"
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      sx: { mr: "20px" },
                      // helperText: errors['fecha_ingreso'] && ListOfErrors[errors['fecha_ingreso'].type],
                    },
                  }}
                  onChange={(e) => setDate(e)}
                  value={date}
                />
              </LocalizationProvider>
            </div>
          )}
          {alreadyAproved && !!open.data?.archivo_pagada && (
            <div className="mt-2">
              <Button component="label" variant="contained" fullWidth>
                <a href={`${enviroments.img_endpoint}${open.data?.archivo_pagada}`} target="_blank" download>
                  Descargar Comprobante
                </a>
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cerrar</Button>
          {!alreadyAproved ? (
            <Button onClick={handleSubmit} autoFocus disabled={!file}>
              Aprobar
            </Button>
          ) : (
            <Button color="error" onClick={handleReset} autoFocus>
              Volver a estado inicial
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
