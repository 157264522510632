import { Button, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createNewDepartment } from "../../redux/slices/deparments";
import { createNewRol, getAllPermissions } from "../../redux/slices/roles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import formatPermissions from "../../utils/formatPermissions";

export default function RolesDialog({ open, dialogValue, handleClose, setter }) {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.roles);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    register,
  } = useForm();

  useEffect(() => {
    dispatch(getAllPermissions());

    setValue("role_name", dialogValue.descripcion);
  }, [open]);

  const handle_submit = async (values) => {
    const result = formatPermissions(values, permissions);
    const role = await dispatch(createNewRol({ role_name: values.role_name, permissions: result }));
    setter(role);
    reset();
    handleClose();
  };

  return (
    <form className="flex flex-col justify-center items-center h-full">
      <div className="w-full ">
        <TextField
          size="small"
          required
          key={"role_name"}
          id={"role_name"}
          label={"Nombre del rol"}
          fullWidth
          type={"text"}
          {...register("role_name")}
          helperText={errors["role_name"]?.message}
          error={Boolean(errors["role_name"]?.message)}
          sx={{ marginBottom: 2 }}
        />
      </div>
      <div className="w-full mb-1 text-lg">Permisos del Rol</div>
      <div className="flex w-full h-auto justify-center">
        <TableContainer sx={{ width: "100%", height: 400 }}>
          <Table stickyHeader size="small" aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Nombre</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Descripcion</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions &&
                [...permissions]
                  .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                  .map((permission, index) => (
                    <TableRow key={index}>
                      <TableCell className="capitalize">{permission.descripcion.replace(/_/g, " ")}</TableCell>
                      <TableCell>{permission?.detalle || "S/D"}</TableCell>
                      <TableCell>
                        <Controller
                          control={control}
                          name={permission.descripcion}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              size="small"
                              checked={value}
                              onChange={(event) => onChange(event.target.checked)}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="flex w-full ml-3 mt-5">
        <Button onClick={handleSubmit(handle_submit)} sx={{ width: 200 }} variant="contained">
          Enviar
        </Button>
      </div>
    </form>
  );
}
