import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import uploadFiles from "../../utils/UploadFiles";
import { deleteSettlement, uploadSettlement } from "../../redux/slices/remuneration";
import enviroments from "../../utils/enviroments";
import axiosInstance from "../../utils/axiosInstance";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Settlements({ year, setYear, month, setMonth }) {
  // const [year, setYear] = useState(dayjs().year());
  // const [month, setMonth] = useState(dayjs().subtract(1, "month").month());
  const [arr, setArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data } = useSelector((state) => state.createModal);
  const [refresh, setRefresh] = useState(false);
  const years = generateYearArray();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setArr(data?.Settlement);
    }
  }, [data]);

  const uploadImage = async (file) => {
    if (file.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onload = async function (event) {
        setLoading(true);
        const res = await uploadFiles("docs", file.target.files[0]);
        const newObj = await dispatch(
          uploadSettlement({ id: data.id, year, month, fileName: res.data, personalEmail: data?.mail_personal })
        );
        // const newID = newObj.Settlement.sort((a, b) => b.id - a.id)[0];
        setArr((prev) => [...prev, { id: newObj.id, year, month, fileName: res.data }]);
        setLoading(false);
      };
    }
  };

  const handleDelete = async (file) => {
    const deleted = await dispatch(deleteSettlement(file.id));
    setArr((prev) => [...prev.filter((el) => el.id !== deleted.id)]);
  };

  const handleFilter = async () => {
    const res = await axiosInstance.get(`/remuneration/settlement?id=${data.id}&month=${month}&year=${year}`);
    setArr(res.data);
  };

  useEffect(() => {
    handleFilter();
  }, [month, year]);

  return (
    <form>
      <div className="w-full flex">
        <div className="w-full flex gap-3">
          <TextField
            fullWidth
            size="small"
            label="Mes"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
            select
          >
            {months.map(({ id, label }) => (
              <MenuItem key={id} value={id} dense divider>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            size="small"
            label="Año"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
            select
          >
            {years.map(({ id, label }) => (
              <MenuItem key={id} value={id} dense divider>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div
        className={`max-h-[295px] flex ${
          !arr.length ? "justify-center" : undefined
        }  items-center flex-col w-full min-h-[295px] overflow-auto my-4 pr-2`}
      >
        {arr.length ? (
          arr.map((file, index) => (
            <div
              key={index}
              className={`bg-neutral-100 mb-2 rounded p-1 border-[1px] items-center w-full flex justify-between border-neutral-300`}
            >
              <div className="uppercase line-clamp-1 w-full text-neutral-700 text-sm ml-1 tracking-wider">
                {`${file.fileName.split("-")[0]}.PDF`}
              </div>
              <div className="uppercase w-full text-neutral-700 text-sm ml-1 tracking-wider">{`${
                months[file.month].label
              } - ${file.year}`}</div>
              <a
                href={`${enviroments.img_endpoint}${file.fileName}`}
                target="_blank"
                rel="noreferrer noopener"
                download="proposed_file_name"
                className="cursor-pointer"
              >
                <PictureAsPdfIcon color="error" />
              </a>
              <IconButton onClick={() => handleDelete(file)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))
        ) : (
          <span className="-mt-5 text-neutral-500">No se encontraron liquidaciones</span>
        )}
      </div>
      <Button disabled={loading} fullWidth variant="outlined" component="label">
        Subir Liquidación de {months[month].label} - {year}
        <input type="file" hidden onChange={(e) => uploadImage(e)} accept="application/pdf" />
      </Button>
      <div className="text-sm w-full text-center mt-1 text-neutral-500">
        Para subir una liquidación con otra fecha se deben modificar los selectores
      </div>
    </form>
  );
}

const months = [
  { id: 0, label: "Enero" },
  { id: 1, label: "Febrero" },
  { id: 2, label: "Marzo" },
  { id: 3, label: "Abril" },
  { id: 4, label: "Mayo" },
  { id: 5, label: "Junio" },
  { id: 6, label: "Julio" },
  { id: 7, label: "Agosto" },
  { id: 8, label: "Septiembre" },
  { id: 9, label: "Octubre" },
  { id: 10, label: "Noviembre" },
  { id: 11, label: "Diciembre" },
];

const generateYearArray = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear - 10; i <= currentYear + 10; i++) {
    years.push({ id: i, label: i });
  }

  return years;
};
