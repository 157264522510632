import React, { useEffect } from "react";
import ControlledAccordions from "../components/accordion/ControlledAccordions";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import Slide from "@mui/material/Slide";
import { closeSideBar } from "../redux/slices/sidebar";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function SideBar() {
  const { open } = useSelector((state) => state.sidebar);
  const [color, setColor] = React.useState();
  const { accessRoutes } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        dispatch(closeSideBar(false));
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const handleClick = (index, name) => {
    setColor({ index: index, name, style: "text-[#2b2c84] bg-[#2b2c84]/20" });
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AnimatePresence>
      <Slide direction="right" in={open}>
        <div className={`sidebar is-active shadow relative`}>
          <div className="sidebar-scroll">
            {accessRoutes.managmentLinks.length ? (
              <ControlledAccordions
                handleColor={handleClick}
                color={color}
                name={"Gestión Web"}
                panel={"panel0"}
                expanded={expanded}
                handleChange={handleChange}
                links={accessRoutes.managmentLinks}
              />
            ) : null}
            {accessRoutes.clientsLinks.length ? (
              <ControlledAccordions
                handleColor={handleClick}
                color={color}
                name={"Clientes"}
                panel={"panel1"}
                expanded={expanded}
                handleChange={handleChange}
                links={accessRoutes.clientsLinks}
              />
            ) : null}
            {accessRoutes.maintainersLinks.length ? (
              <ControlledAccordions
                handleColor={handleClick}
                color={color}
                name={"Mantenedores"}
                panel={"panel2"}
                expanded={expanded}
                handleChange={handleChange}
                links={accessRoutes.maintainersLinks}
              />
            ) : null}
            {accessRoutes.servicioTecnico.length ? (
              <ControlledAccordions
                handleColor={handleClick}
                color={color}
                name={"Servicio Tecnico"}
                panel={"panel3"}
                expanded={expanded}
                handleChange={handleChange}
                links={accessRoutes.servicioTecnico}
              />
            ) : null}
            {accessRoutes.inventoryLinks.length ? (
              <ControlledAccordions
                handleColor={handleClick}
                color={color}
                name={"Inventario"}
                panel={"panel4"}
                expanded={expanded}
                handleChange={handleChange}
                links={accessRoutes.inventoryLinks}
              />
            ) : null}
            {accessRoutes.billingLinks.length ? (
              <ControlledAccordions
                name={"Facturacion"}
                links={accessRoutes.billingLinks}
                color={color}
                panel={"panel5"}
                expanded={expanded}
                handleChange={handleChange}
                handleColor={handleClick}
              />
            ) : null}
            {accessRoutes.cobranzaLinks.length ? (
              <ControlledAccordions
                name={"Cobranzas"}
                links={accessRoutes.cobranzaLinks}
                color={color}
                panel={"panel6"}
                expanded={expanded}
                handleChange={handleChange}
                handleColor={handleClick}
              />
            ) : null}
            {accessRoutes.informesCobranzasLinks.length ? (
              <ControlledAccordions
                name={"Informes Cobranzas"}
                links={accessRoutes.informesCobranzasLinks}
                color={color}
                panel={"panel7"}
                expanded={expanded}
                handleChange={handleChange}
                handleColor={handleClick}
              />
            ) : null}
            {accessRoutes.siiLinks.length ? (
              <ControlledAccordions
                name={"SII"}
                links={accessRoutes.siiLinks}
                color={color}
                panel={"panel8"}
                expanded={expanded}
                handleChange={handleChange}
                handleColor={handleClick}
              />
            ) : null}
            {accessRoutes.rrhhLinks.length ? (
              <ControlledAccordions
                name={"RRHH"}
                links={accessRoutes.rrhhLinks}
                color={color}
                panel={"panel9"}
                expanded={expanded}
                handleChange={handleChange}
                handleColor={handleClick}
              />
            ) : null}
            {accessRoutes.rendicionLinks.length ? (
              <ControlledAccordions
                name={"Rendición de gastos"}
                links={accessRoutes.rendicionLinks}
                color={color}
                panel={"panel10"}
                expanded={expanded}
                handleChange={handleChange}
                handleColor={handleClick}
              />
            ) : null}
            {accessRoutes.informesLinks.length ? (
              <Link key={0} to="/informes" onClick={() => handleClick(0, "informes")} sx={{ display: "flex" }}>
                <div
                  className={`flex items-center py-2 mb-1 hover:bg-gray-200 rounded transition hover:duration-150 duration-150 pl-[15px] min-h-[48px] ${
                    color?.index === 0 && color?.name === "informes" ? color.style : null
                  }`}
                >
                  <KeyboardArrowRightIcon sx={{ fontSize: "20px", mr: 0.2, color: "#2b2c84" }} />
                  <div className="font-medium tracking-wider text-xs uppercase text-[#3a3a3a]">INFORMES</div>
                </div>
              </Link>
            ) : null}
            <Chip className=" text-center font-medium text-sm w-[95%]" label={"Versión 3.2.2"} />
          </div>
        </div>
      </Slide>
    </AnimatePresence>
  );
}
