import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import FilterInvInsumos from "./FilterInvInsumos";
import { setter } from "../../../redux/slices/informes";
import { useEffect } from "react";

export default function InvInsumos() {
  const { storageInsumos } = useSelector((state) => state.informes);
  const columnStorageInsumos = [
    { title: "Código Loginteg", value: "codigo_loginteg" },
    { title: "Nombre", value: "nombre" },
    { title: "Cantidad", value: "cantidad" },
    // { title: "Lote", value: "lotes" },
    // { title: "F. Vencimiento", value: "vencimiento" },
    { title: "Detalles", value: "acciones_informes_insumos" },
  ];

  // console.log(storageInsumos);
  const [columns] = useDataGrid(columnStorageInsumos, () => {}, "CARGO");

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "storageInsumos", payload: [] }));
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <FilterInvInsumos />
      <DataGridComponent columns={columns} rows={storageInsumos} success={true} size="40px" />
    </div>
  );
}
