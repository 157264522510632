import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, MenuItem, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { createProvider } from "../../redux/slices/inventory";
import { validateRUT } from "validar-rut";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const validarRut = (value, isRutValidationRequired) => {
  if (isRutValidationRequired) {
    return validateRUT(value);
  } else {
    return true;
  }
};

export default function ProvidersCreate() {
  const [open, setOpen] = React.useState(false);
  const [isRutValidationRequired, setIsRutValidationRequired] = React.useState(true);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const switchRutValidation = (e) => {
    if (e.target.value === "SI") {
      setIsRutValidationRequired(true);
    } else {
      setIsRutValidationRequired(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const newItemType = async (value) => {
    value.nacional = value.nacional === "SI" ? true : false;
    dispatch(createProvider(value));
    reset();
    setOpen(false);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Registrar nuevo proveedor"
        color="primary"
      />

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newItemType)}>
              <div className="flex">
                <div className="w-full mr-5">
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      sx={{ mr: 2 }}
                      id={"descripcion"}
                      label={"Nombre"}
                      type={"text"}
                      {...register("descripcion", { required: true })}
                      error={Boolean(errors["descripcion"])}
                      helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      sx={{ mr: 2 }}
                      id={"correo"}
                      label={"Correo"}
                      type={"text"}
                      {...register("correo", { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ })}
                      error={Boolean(errors["correo"])}
                      helperText={
                        (errors.correo?.type === "required" && "El campo es requerido") ||
                        (errors.correo?.type === "pattern" && "El formato es invalido")
                      }
                      defaultValue={""}
                    />
                    <TextField
                      fullWidth
                      sx={{ mr: 2 }}
                      id={"rut"}
                      label={"RUT"}
                      type={"text"}
                      {...register("rut", {
                        required: true,
                        validate: {
                          validarRut: (val) => {
                            return validarRut(val, isRutValidationRequired);
                          },
                        },
                      })}
                      error={Boolean(errors["rut"])}
                      helperText={
                        (errors.rut?.type === "required" && "El campo es requerido") ||
                        (errors.rut?.type === "validarRut" && "El RUT es invalido")
                      }
                      defaultValue={""}
                    />
                  </div>
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      sx={{ mr: 2 }}
                      id={"contacto"}
                      label={"Contacto"}
                      type={"text"}
                      {...register("contacto", { required: true })}
                      error={Boolean(errors["contacto"])}
                      helperText={Boolean(errors["contacto"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                    <TextField
                      fullWidth
                      sx={{ mr: 2 }}
                      id={"nacional"}
                      label={"Es Nacional"}
                      type={"text"}
                      {...register("nacional", { required: true })}
                      onChange={switchRutValidation}
                      error={Boolean(errors["nacional"])}
                      helperText={Boolean(errors["nacional"]) && "El campo es requerido"}
                      defaultValue={""}
                      select
                    >
                      <MenuItem key={1} value={"SI"}>
                        SI
                      </MenuItem>
                      <MenuItem key={2} value={"NO"}>
                        NO
                      </MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
