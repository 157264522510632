import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function CustomInformSelect({ selectedOption, setSelectOption }) {
  const informs = [
    { id: 5, label: "Contactos" },
    { id: 8, label: "Detalles de OT" },
    { id: 4, label: "Elementos" },
    { id: 6, label: "Equipos Asignados" },
    { id: 1, label: "Equipos Bodega" },
    { id: 10, label: "Informe Rendición Gastos" },
    { id: 2, label: "Inventario Insumos" },
    { id: 9, label: "Informe Rendición" },
    { id: 3, label: "Inventario Repuestos" },
    { id: 11, label: "Listado de Rendiciones" },
    { id: 7, label: "OT Asignadas" },
  ];

  return (
    <div>
      <div className="w-full">
        <Autocomplete
          value={selectedOption || { id: 0, label: "" }}
          onChange={(option, value) => setSelectOption(value)}
          options={informs}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccionar Informe"
              size="small"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                },
              }}
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
