import React from "react";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { deletePieza, getAllPiezas, getAllTeamTypes } from "../redux/slices/inventory";
import PiezasModalCreate from "../components/modal/PiezasModalCreate";
import PiezasModalUpdate from "../components/modal/PiezasModalUpdate";

export default function Piezas() {
  const permitedRenderDataColumn = [
    { title: "ID", value: "id", hide: true },
    { title: "Equipo", value: "nombre_equipo" },
    { title: "Modelo", value: "nombre_modelo" },
    { title: "Version", value: "nombre_version" },
    { title: "Tipo de equipo", value: "nombre_item" },
    { title: "Cantidad", value: "cantidad" },
    { title: "Acciones", value: "acciones_inv" },
  ];
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const { piezas, success } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deletePieza(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Numero de elementos", false, setOpen);

  useEffect(() => {
    dispatch(getAllPiezas());
    dispatch(getAllTeamTypes());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Numero de elementos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Numero de elementos"} />
        </div>
        <div>
          <PiezasModalCreate />
          <PiezasModalUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={piezas} success={success} />
    </div>
  );
}
