import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { updateDocumentRecived } from "../../../../redux/slices/documents";
import { useDispatch } from "react-redux";

export default function FacturaRecibidaModal({ id, proyects, tipoGastos, tipoGastoId, proyectId }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "300px",
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const submit = (values) => {
    values.id = id;
    dispatch(updateDocumentRecived(values));
    handleClose();
  };

  React.useEffect(() => {
    reset({
      facturacion_proyectos: proyectId,
      facturacion_tipo_gastos: tipoGastoId,
    });
  }, [proyectId, tipoGastoId]);

  return (
    <div>
      <LibraryAddIcon sx={{ color: "green" }} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(submit)}>
            <div className="flex justify-center text-xl bg-[#2b2c84]/20 font-semibold p-2 mb-5">
              TIPO DE GASTO Y PROYECTO
            </div>
            <div className="h-[85px]">
              <Controller
                id="facturacion_proyectos"
                name="facturacion_proyectos"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    select
                    label={"Proyecto"}
                    onChange={onChange}
                    value={value || ""}
                    error={Boolean(errors["facturacion_proyectos"])}
                    helperText={errors?.facturacion_proyectos?.type === "required" && "* El campo es requerido"}
                  >
                    {proyects.length > 0 &&
                      proyects.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <div className="h-[85px]">
              <Controller
                id="facturacion_tipo_gastos"
                name="facturacion_tipo_gastos"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    select
                    onChange={onChange}
                    value={value || ""}
                    label={"Tipo de Gasto"}
                    error={Boolean(errors["facturacion_tipo_gastos"])}
                    helperText={errors?.facturacion_tipo_gastos?.type === "required" && "* El campo es requerido"}
                  >
                    {tipoGastos.length > 0 &&
                      tipoGastos.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.nombre}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <div>
              <Button type="submit" fullWidth variant="contained">
                ASIGNAR
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
