import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FormRDF from "./FormRDF";

export default function RowModal({
  onCreate,
  onUpdate,
  type = "create",
  updateSetter,
  openUpdate,
  data,
  rows,
  state,
  loadedData,
  valForm,
  ots,
  viewType,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    type === "create" ? setOpen(true) : updateSetter(true);
  };
  const handleClose = () => (type === "create" ? setOpen(false) : updateSetter(false));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
  };

  return (
    <div>
      {type === "create" && (
        <Button
          variant="outlined"
          endIcon={<AddCircleOutlineOutlinedIcon />}
          sx={{ color: "white", textTransform: "capitalize" }}
          onClick={handleOpen}
        >
          Registrar nuevo Gasto
        </Button>
      )}
      <Modal
        open={type === "create" ? open : openUpdate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(5px)" } } }}
      >
        <Box sx={style}>
          <div className="w-[450px] md:w-[550px] lg:w-[750px]">
            <FormRDF
              state={state}
              rows={rows}
              onCreate={onCreate}
              onUpdate={onUpdate}
              type={type}
              onClose={handleClose}
              data={data}
              loadedData={loadedData}
              valForm={valForm}
              ots={ots}
              viewType={viewType}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
