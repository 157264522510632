import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

export default function RolesForm({ submit, fields, permissions, type, activedPermissions }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <form className="flex flex-col justify-center items-center h-full" onSubmit={handleSubmit(submit)}>
      <div className="w-full ">
        {fields &&
          fields.map((field) => (
            <TextField
              size="small"
              required
              key={field.id}
              id={field.id}
              label={field.label}
              fullWidth
              type={field.type}
              {...register(field.id)}
              helperText={errors[field.id]?.message}
              error={Boolean(errors[field.id]?.message)}
              defaultValue={type === "edit" ? field.value : ""}
              sx={{ marginBottom: 2 }}
            />
          ))}
      </div>
      <div className="w-full mb-1 text-lg">Permisos del Rol</div>
      <div className="flex w-full h-auto justify-center">
        <TableContainer sx={{ width: "100%", height: 400 }}>
          <Table stickyHeader size="small" aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Nombre</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Descripcion</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions &&
                [...permissions]
                  .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                  .map((permission, index) => (
                    <TableRow key={index}>
                      <TableCell className="capitalize">{permission.descripcion.replace(/_/g, " ")}</TableCell>
                      <TableCell>{permission?.detalle || "S/D"}</TableCell>
                      <TableCell>
                        <Controller
                          control={control}
                          name={permission.descripcion}
                          defaultValue={
                            type === "edit" &&
                            Array.isArray(activedPermissions) &&
                            activedPermissions.some((ap) => ap.id_permiso === permission.id)
                          }
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              size="small"
                              checked={value}
                              onChange={(event) => onChange(event.target.checked)}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="flex w-full ml-3 mt-5">
        <Button sx={{ width: 200 }} type="submit" variant="contained">
          Enviar
        </Button>
      </div>
    </form>
  );
}
