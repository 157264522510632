import React from "react";
import useConstantCharges from "../components/modal/constants/useConstantCharges";
import { createNewCharge, deleteCharge } from "../redux/slices/charges";
import { getAllCharges } from "../redux/slices/charges";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { updateCharge } from "../redux/slices/charges";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";
import ModelModalCreate from "../components/modal/ModelModalCreate";
import { deleteModel, getAllModels } from "../redux/slices/inventory";
import ModelModalUpdate from "../components/modal/ModelModalUpdate";
import DeleteModal from "../components/deleteModal/DeleteModal";

export default function Model() {
  const permitedRenderDataColumn = [
    { title: "ID", value: "id", hide: true },
    { title: "Descripcion", value: "nombre" },
    { title: "Tipo de equipo", value: "tipo_de_equipo" },
    { title: "Acciones", value: "acciones_inv" },
  ];
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const { models, success } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteModel(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Modelo", false, setOpen);

  useEffect(() => {
    dispatch(getAllModels());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Modelos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Modelos"} />
        </div>
        <div>
          <ModelModalCreate />
          <ModelModalUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={models} success={success} />
    </div>
  );
}
