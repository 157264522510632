import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";

const ControlledTextField = ({
  id,
  name,
  control,
  value,
  label,
  disabled,
  errors,
  rules,
  type = "text",
  rows,
  formatDecimal,
}) => {
  const ListOfErrors = {
    required: "El campo es requerido",
    checkRut: "El RUT es invalido",
    checkName: "El nombre se encuentra en uso",
  };

  return (
    <div>
      <Controller
        id={id}
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              fullWidth
              type={type}
              size="small"
              onChange={(event) => onChange(event.target.value)}
              value={value || ""}
              label={label}
              disabled={disabled}
              multiline
              rows={rows}
              error={!!errors[name]}
              InputProps={
                errors[name]
                  ? {
                      endAdornment: (
                        <div className="absolute right-3 cursor-default">
                          <Tooltip title={ListOfErrors[errors[name].type]}>
                            <ErrorIcon fontSize="small" color="error" />
                          </Tooltip>
                        </div>
                      ),
                    }
                  : formatDecimal && {
                      startAdornment: <InputAdornment position="start">{"$"}</InputAdornment>,
                    }
              }
              placeholder=""
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#606060",
                },
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default ControlledTextField;

// helperText={errors[name] && }
