import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import UserInformation from "./UserInformation";
import ProofPayment from "./ProofPayment";
import Settlements from "./Settlements";
import dayjs from "dayjs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="min-h-[450px] max-h-[450px]"
    >
      {value === index && (
        <Box sx={{ py: 2, px: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RemunerationForm() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [year, setYear] = useState(dayjs().year());
  const [month, setMonth] = useState(dayjs().subtract(1, "month").month());

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab sx={{ width: "33%" }} label="Trabajador" {...a11yProps(0)} />
          <Tab sx={{ width: "33%" }} label="Comprobantes" {...a11yProps(1)} />
          <Tab sx={{ width: "33%" }} label="Liquidaciones" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserInformation />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ProofPayment year={year} setYear={setYear} month={month} setMonth={setMonth} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Settlements year={year} setYear={setYear} month={month} setMonth={setMonth} />
      </CustomTabPanel>
    </div>
  );
}
