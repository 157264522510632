import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { updateArticleMold } from "../../redux/slices/inventory";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "80%", // 600
    md: "40%", // 900
    lg: "40%", // 1200
    xl: "40%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function ArticleMoldUpdate({ open, data, setOpen, arr }) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(null);
  const { providersSelect } = useSelector((state) => state.inventory);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const updateNewArticle = async (value) => {
    const isDuplicateCodigoLoginteg = arr
      .filter((obj) => obj.id !== data.id)
      .some((obj) => obj.codigo_loginteg.toLowerCase() === value.codigo_loginteg.toLowerCase());

    const isDuplicateDescripcion = arr
      .filter((obj) => obj.id !== data.id)
      .some((obj) => obj.nombre.toLowerCase() === value.nombre.toLowerCase());

    let error = null;

    if (isDuplicateCodigoLoginteg && isDuplicateDescripcion) {
      error = "El Codigo Loginteg y Nombre del insumo ya existen";
    } else if (isDuplicateCodigoLoginteg) {
      error = "El Codigo Loginteg ya existe";
    } else if (isDuplicateDescripcion) {
      error = "El Nombre del insumo ya existe";
    }

    console.log(error);

    if (error) {
      setError(error);
      return;
    } else {
      setError(false);
    }

    dispatch(updateArticleMold({ value, id: data.id }));
    reset();
    setOpen({ status: false, data: null });
  };

  React.useEffect(() => {
    if (data) {
      reset({
        nombre: data.nombre,
        codigo_loginteg: data.codigo_loginteg,
        codigo_proveedor: data.codigo_proveedor,
      });
    }
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(updateNewArticle)}>
                <div className="flex">
                  <div className="w-full mr-5">
                    <div className={`flex mb-5`}>
                      <TextField
                        fullWidth
                        sx={{ mr: 2 }}
                        id={"codigo_loginteg"}
                        label={"Código Loginteg"}
                        size="small"
                        type={"text"}
                        {...register("codigo_loginteg", { required: true })}
                        error={Boolean(errors["codigo_loginteg"])}
                        helperText={Boolean(errors["codigo_loginteg"]) && "El campo es requerido"}
                      />
                      <TextField
                        fullWidth
                        id={"nombre"}
                        label={"Nombre"}
                        size="small"
                        type={"text"}
                        {...register("nombre", { required: true })}
                        error={Boolean(errors["nombre"])}
                        helperText={Boolean(errors["nombre"]) && "El campo es requerido"}
                      />
                    </div>
                    <div className="mb-5">
                      <TextField
                        fullWidth
                        id={"codigo_proveedor"}
                        label={"Código Proveedor"}
                        size="small"
                        type={"text"}
                        {...register("codigo_proveedor", { required: true })}
                        error={Boolean(errors["codigo_proveedor"])}
                        helperText={Boolean(errors["codigo_proveedor"]) && "El campo es requerido"}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Button type="submit" variant="contained" sx={{ width: "100%" }}>
                    Enviar formulario
                  </Button>
                  {error && (
                    <div className="w-full p-2 bg-red-100 rounded mt-2 text-red-500 flex gap-2 items-center mr-5">
                      <ErrorOutlineIcon />
                      {error}
                    </div>
                  )}
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
