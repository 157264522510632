import FacebookCircularProgress from "../FacebookLoading/FacebookLoading";

const FirstLoadSkeleton = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <FacebookCircularProgress />
    </div>
  );
};

export default FirstLoadSkeleton;
