import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const AlertMUI = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Variants = Error, Warning, Info, Success

const AlertCustom = ({ open, setOpen }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <AlertMUI onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Email enviado con éxito
        </AlertMUI>
      </Snackbar>
    </Stack>
  );
};

export default AlertCustom;
