import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { getAllChecks, getAllChecksPdf } from "../../redux/slices/checklist";
import { Button, Divider } from "@mui/material";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Svg, Path, Image, Font } from "@react-pdf/renderer";
import enviroments from "../../utils/enviroments";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%", // 0
    sm: "90%", // 600
    md: "70%", // 900
    lg: "70%", // 1200
    xl: "70%", // 1536
  },
  height: "750px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function PdfModal({ open, data, setOpen }) {
  const dispatch = useDispatch();
  const { checkMenu } = useSelector((state) => state.checkMenu);
  const [pdfView, setPdfView] = React.useState({ status: false, data: null });

  React.useEffect(() => {
    dispatch(getAllChecksPdf());
  }, [open]);

  const handleClose = () => setOpen({ open: false, data: null });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="w-full h-full overflow-auto">
              <PDFView
                status={pdfView.status}
                data={pdfView.data}
                setOpen={setPdfView}
                equipment={data?.equipment}
                order={data?.order}
              />
              {!pdfView.status &&
                data?.skilled?.map((person) => {
                  const personEquipment = data?.equipment.find((el) => el.equipo === person.equipment);
                  return (
                    <div>
                      <div className="flex justify-between items-center pr-10 max-sm:flex-col max-sm:pr-0 max-sm:items-start">
                        <div>
                          <div className="font-semibold">{person.name}</div>
                          <div>{person.rut}</div>
                          <div>Equipo {personEquipment?.inv_equipo.inv_tipo_equipo?.descripcion}</div>
                          <div>Modelo {personEquipment?.inv_equipo?.modelo}</div>
                        </div>
                        <div className="max-sm:mt-2">
                          <Button
                            onClick={() => setPdfView({ status: true, data: { person, checkMenu } })}
                            variant="contained"
                            size="small"
                            color="error"
                          >
                            Generar PDF
                          </Button>
                        </div>
                      </div>
                      <Divider sx={{ my: 1 }} />
                    </div>
                  );
                })}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

Font.register({
  family: "Open Sans",
  fonts: [
    { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
    { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf", fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    fontFamily: "Open Sans",
    marginVertical: 10,
  },
  section: {
    marginHorizontal: 10,
    // padding: 10,
    flexGrow: 1,
  },
});

const CheckSvg = () => {
  return (
    <Svg height="18" viewBox="0 -960 960 960" width="18">
      <Path
        fill="#000"
        d="m424-312 282-282-56-56-226 226-114-114-56 56 170 170ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
      />
    </Svg>
  );
};

const Uncheked = () => {
  return (
    <Svg height="18" viewBox="0 -960 960 960" width="18">
      <Path
        fill="#000"
        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Z"
      />
    </Svg>
  );
};

const PDFView = ({ data, status, setOpen, equipment, order }) => {
  if (status) {
    const { person, checkMenu } = data;
    const personEquipment = equipment?.find((el) => el.equipo === person.equipment)?.inv_equipo;
    const checkListPerson = person.checkListPerson;
    const client = order?.cliente_ot_clienteTocliente.nombre;
    const selectedMenu = person.checkListPerson
      .map(({ checkListId }) => {
        const finded = checkMenu.find((el) => el.checkList.some((check) => check.id === checkListId));
        return finded;
      })
      .filter((item, index, array) => {
        return array.findIndex((t) => t === item) === index;
      });
    return (
      <div className="w-full h-full">
        <Button onClick={() => setOpen({ status: false, data: null })}>Volver al listado</Button>
        <div className="h-full w-full max-h-[680px]">
          <PDFViewer width={"100%"} height={"100%"}>
            <Document>
              <Page scale={1.5} size="A4" style={styles.page}>
                <View style={{ width: "100%", padding: 10 }}>
                  <View style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Image
                      source={`${enviroments.img_endpoint}${"loginteg.png"}`}
                      style={{ width: "150px", height: "70px" }}
                    />
                  </View>
                  <Text style={{ marginBottom: 10, textAlign: "center", fontWeight: "bold", fontSize: 14 }}>
                    Capacitación {client}
                  </Text>
                  <View style={{ fontSize: "11px", border: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", gap: 5, borderBottom: 1 }}>
                      <Text style={{ borderRight: 1, minWidth: 90, paddingHorizontal: 5, fontWeight: "bold" }}>
                        Nombre:{" "}
                      </Text>
                      <Text>{person.name} </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", gap: 5, borderBottom: 1 }}>
                      <Text style={{ borderRight: 1, minWidth: 90, paddingHorizontal: 5, fontWeight: "bold" }}>
                        Rut:{" "}
                      </Text>
                      <Text>{person.rut} </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", gap: 5, borderBottom: 1 }}>
                      <Text style={{ borderRight: 1, minWidth: 90, paddingHorizontal: 5, fontWeight: "bold" }}>
                        Equipo:{" "}
                      </Text>
                      <Text>{personEquipment?.inv_tipo_equipo.descripcion} </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                      <Text style={{ borderRight: 1, minWidth: 90, paddingHorizontal: 5, fontWeight: "bold" }}>
                        Modelo:{" "}
                      </Text>
                      <Text>{personEquipment?.modelo} </Text>
                    </View>
                  </View>
                  <Text style={{ fontSize: 11, marginTop: 10, fontWeight: "bold" }}>TEMARIO DE CAPACITACIÓN</Text>
                  <View style={{ fontSize: "11px", marginVertical: 10 }}>
                    <View style={{ display: "flex", flexDirection: "row", border: 1 }}>
                      <Text
                        style={{
                          padding: 3,
                          borderRight: 1,
                          width: "30%",
                          maxWidth: "30%",
                          textTransform: "uppercase",
                          fontSize: 11,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Item Principal
                      </Text>
                      <Text
                        style={{
                          padding: 3,
                          borderRight: 1,
                          width: "60%",
                          maxWidth: "60%",
                          textTransform: "uppercase",
                          fontSize: 11,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Sub Item
                      </Text>
                      <Text
                        style={{
                          padding: 3,
                          fontSize: 11,
                          textTransform: "uppercase",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Estado
                      </Text>
                    </View>
                    {selectedMenu.map((selected, pageIndex) => {
                      const checkList = selected.checkList;
                      return (
                        <View style={{}}>
                          <Text style={{ padding: 3, marginVertical: 2, fontWeight: "bold", textAlign: "center" }}>
                            Menu: {selected.name}
                          </Text>
                          {checkList.map((check, idx) => {
                            const restData = checkListPerson.find((el) => el.checkListId === check.id);
                            return (
                              <View
                                wrap={false}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  border: 1,
                                  marginTop: -1,
                                }}
                              >
                                <Text
                                  style={{ padding: 3, borderRight: 1, width: "30%", maxWidth: "30%", lineHeight: 1.2 }}
                                >
                                  {check.item}
                                </Text>
                                <Text
                                  style={{ padding: 3, borderRight: 1, width: "60%", maxWidth: "60%", lineHeight: 1.2 }}
                                >
                                  {check.subItem}
                                </Text>
                                <View
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    padding: "0 0 0 16px",
                                  }}
                                >
                                  {!!restData ? <CheckSvg /> : <Uncheked />}
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      );
                    })}
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <View
                      style={{
                        paddingHorizontal: "10px",
                        width: "100%",
                        textAlign: "center",
                        height: "140px",
                      }}
                    >
                      <Image
                        source={`${enviroments.img_endpoint}${person.signature}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          marginVertical: "10px",
                          objectFit: "contain",
                        }}
                      />

                      <View
                        style={{
                          width: "100%",
                          borderBottom: "1px dotted black",
                          paddingHorizontal: "10px",
                        }}
                      ></View>
                    </View>
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </div>
      </div>
    );
  }
};
