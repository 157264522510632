import { Button, TextField, Chip, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { createNewCharge } from "../../redux/slices/charges";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { getAllBusinessName } from "../../redux/slices/businessName";
import { getAllPaises } from "../../redux/slices/paises";
import { getAllRegions, getCommuneByRegion } from "../../redux/slices/regions";
import axiosInstance from "../../utils/axiosInstance";
import { createNewClient, getAllClients } from "../../redux/slices/clients";
import { validateRUT } from "validar-rut";

export default function ClientDialog({ open, dialogValue, handleClose, setter }) {
  const dispatch = useDispatch();

  const { data: businessData } = useSelector((state) => state.businessName);
  const { paises } = useSelector((state) => state.paises);
  const { data: regionData, comunas } = useSelector((state) => state.regions);
  const { data: clients } = useSelector((state) => state.clients);

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    dispatch(getAllBusinessName());
    dispatch(getAllRegions());
    dispatch(getAllPaises());
    dispatch(getAllClients());
  }, []);

  useEffect(() => {
    setValue("nombre", dialogValue.nombre);
  }, [open]);

  const handle_submit = async (value) => {
    value.documento_identidad = String(value.documento_identidad);
    value.rut = String(value.rut);

    const client = await dispatch(createNewClient(value));
    setter(client);
    reset();
    handleClose();
  };

  const countryWatcher = watch("pais_id");
  const regionWatcher = watch("region");
  const sonWatcher = watch("hijo");
  const parentWatcher = watch("padre");

  const isChilean = paises.find((el) => el.id === countryWatcher)?.name === "Chile";

  const validateDu = async (val) => {
    const { data } = await axiosInstance.get(`/cliente/rut/validate?id=${null}&parentsRut=${parentWatcher || null}`);
    if (data?.arr) {
      return data.arr.includes(val) ? "El rut se encuentra en uso" : false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (paises?.length) {
      setValue("pais_id", paises.find((el) => el.name === "Chile")?.id);
    }
  }, [paises, open]);

  useEffect(() => {
    if (regionWatcher) {
      dispatch(getCommuneByRegion(regionWatcher));
    }
  }, [regionWatcher]);

  return (
    <form>
      <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-4">AÑADIR CLIENTE</div>
      <div className="max-xl:max-h-[520px] max-xl:overflow-y-autos w-full">
        <div className="w-full grid grid-cols-3 max-xl:grid-cols-1">
          <div className="col-span-3">
            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="nombre"
                  name="nombre"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Nombre"
                      onChange={onChange}
                      size="small"
                      value={value || ""}
                      error={errors.nombre}
                      helperText={errors.nombre?.message}
                    />
                  )}
                />
              </div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="tipo_razon_social"
                  name="tipo_razon_social"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      size="small"
                      onChange={onChange}
                      value={value || ""}
                      label="Razon Social (Nombre)"
                      error={errors.tipo_razon_social}
                      helperText={errors.tipo_razon_social?.message}
                      placeholder=""
                      sx={{
                        "& .MuiFormHelperText-root": {
                          marginLeft: 0,
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      {businessData?.map(
                        (option) =>
                          option.id && (
                            <MenuItem key={option.id} dense divider value={option.id}>
                              {option.descripcion}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="pais_id"
                  name="pais_id"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      size="small"
                      onChange={onChange}
                      value={value || ""}
                      label="Pais"
                      error={errors.pais_id}
                      helperText={errors.pais_id?.message}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          marginLeft: 0,
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      {paises?.map(
                        (option) =>
                          option.id && (
                            <MenuItem key={option.id} dense divider value={option.id}>
                              {option.name}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  )}
                />
              </div>
              <div className="min-h-[65px] mb-1">
                {isChilean ? (
                  <Controller
                    id="rut"
                    name="rut"
                    control={control}
                    rules={{
                      required: "* Campo requerido",
                      validate: {
                        validarRut: async (val) => {
                          const bool = validateRUT(val);
                          if (bool) {
                            const duplicated = await validateDu(val);

                            if (duplicated) {
                              return "El Rut se encuentra en uso";
                            } else {
                              return true;
                            }
                          } else {
                            return "Rut Invalido";
                          }
                        },
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        onChange={onChange}
                        value={value || ""}
                        label="RUT"
                        error={errors?.rut}
                        helperText={errors?.rut?.message}
                        placeholder=""
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    id="documento_identidad"
                    name="documento_identidad"
                    control={control}
                    rules={{
                      required: "* Campo requerido",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        onChange={onChange}
                        value={value || ""}
                        label="Documento de Identidad"
                        error={errors?.documento_identidad}
                        helperText={errors?.documento_identidad?.message}
                        placeholder=""
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            {isChilean && (
              <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="region"
                    name="region"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        onChange={onChange}
                        value={value || ""}
                        label="Región"
                        error={errors.region}
                        helperText={errors.region?.message}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          },
                        }}
                      >
                        {regionData?.map(
                          (option) =>
                            option.id && (
                              <MenuItem key={option.id} dense divider value={option.id}>
                                {option.descripcion}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    )}
                  />
                </div>
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="comuna"
                    name="comuna"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        onChange={onChange}
                        value={value || ""}
                        label="Comunas"
                        error={errors.comuna}
                        helperText={errors.comuna?.message}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          },
                        }}
                      >
                        {comunas?.map(
                          (option) =>
                            option.id && (
                              <MenuItem key={option.id} dense divider value={option.id}>
                                {option.descripcion}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    )}
                  />
                </div>
              </div>
            )}
            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="direccion"
                  name="direccion"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <TextField fullWidth label="Dirección" onChange={onChange} size="small" value={value || ""} />
                  )}
                />
              </div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="mail_contacto"
                  name="mail_contacto"
                  control={control}
                  rules={{
                    required: false,
                    validate: {
                      validarEmail: (val) => {
                        if (!!val) {
                          const bool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
                          return bool ? true : "Email Invalido";
                        }

                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Correo de contacto"
                      onChange={onChange}
                      size="small"
                      error={errors.mail_contacto}
                      helperText={errors.mail_contacto?.message}
                      value={value || ""}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={`grid ${
                sonWatcher === 1 ? "grid-cols-2" : "grid-cols-1"
              } max-xl:grid-cols-1 gap-5 max-xl:gap-0`}
            >
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="hijo"
                  name="hijo"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      size="small"
                      onChange={onChange}
                      value={value || ""}
                      label="Hijo"
                      error={errors.hijo}
                      helperText={errors.hijo?.message}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          marginLeft: 0,
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem dense divider value={1}>
                        SI
                      </MenuItem>
                      <MenuItem dense divider value={2}>
                        NO
                      </MenuItem>
                    </TextField>
                  )}
                />
              </div>
              {sonWatcher === 1 && (
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="padre"
                    name="padre"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        onChange={onChange}
                        value={value || ""}
                        label="Seleccionar Padre"
                        error={errors.padre}
                        helperText={errors.padre?.message}
                        sx={{
                          "& .MuiFormHelperText-root": {
                            marginLeft: 0,
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          },
                        }}
                      >
                        {clients?.map(
                          (option) =>
                            option.id && (
                              <MenuItem key={option.id} dense divider value={option.id}>
                                {option.nombre}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    )}
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="nombre_pago"
                  name="nombre_pago"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Nombre encargado de pago"
                      onChange={onChange}
                      size="small"
                      error={errors.nombre_pago}
                      helperText={errors.nombre_pago?.message}
                      value={value || ""}
                    />
                  )}
                />
              </div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="correo_pago"
                  name="correo_pago"
                  control={control}
                  rules={{
                    required: false,
                    validate: {
                      validarEmail: (val) => {
                        if (!!val) {
                          const bool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
                          return bool ? true : "Email Invalido";
                        }

                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Correo encargado de pago"
                      onChange={onChange}
                      size="small"
                      error={errors.correo_pago}
                      helperText={errors.correo_pago?.message}
                      value={value || ""}
                    />
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="tipo_empresa"
                  name="tipo_empresa"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      size="small"
                      onChange={onChange}
                      value={value || ""}
                      label="Tipo de Empresa"
                      error={errors.tipo_empresa}
                      helperText={errors.tipo_empresa?.message}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          marginLeft: 0,
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem dense divider value={1}>
                        Privada
                      </MenuItem>
                      <MenuItem dense divider value={2}>
                        Pública
                      </MenuItem>
                    </TextField>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center w-full justify-between max-xl:mt-5">
        <Button onClick={handleSubmit(handle_submit)} variant="outlined" fullWidth>
          Enviar formulario
        </Button>
      </div>
    </form>
  );
}
