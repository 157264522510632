import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, MenuItem, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { createItemType } from "../../redux/slices/inventory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function ItemTypeCreate() {
  const [open, setOpen] = React.useState(false);
  const { modelsSelect } = useSelector((state) => state.inventory);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const newItemType = async (value) => {
    dispatch(createItemType(value));
    reset();
    setOpen(false);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Agregar tipo de equipo"
        color="primary"
      />

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newItemType)}>
              <div className="flex">
                <div className="w-full mr-5">
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      sx={{ mr: 2 }}
                      id={"nombre"}
                      label={"Nombre"}
                      type={"text"}
                      {...register("nombre", { required: true })}
                      error={Boolean(errors["nombre"])}
                      helperText={Boolean(errors["nombre"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                    <TextField
                      fullWidth
                      id={"modelo"}
                      label={"Modelo"}
                      type={"text"}
                      {...register("modelo", { required: true, pattern: /^[0-9]*$/ })}
                      error={Boolean(errors["modelo"])}
                      helperText={Boolean(errors["modelo"]) && "El campo es requerido"}
                      defaultValue={""}
                      select
                    >
                      {modelsSelect.length &&
                        modelsSelect.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.nombre}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                  <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      id={"codigo"}
                      label={"Codigo"}
                      type={"text"}
                      {...register("codigo", { required: true })}
                      error={Boolean(errors["codigo"])}
                      helperText={Boolean(errors["codigo"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
