import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useForm } from "react-hook-form";
import {
  createNewRepairPart,
  getAllBrandsSelect,
  getAllStatusSelect,
  getAllTeamTypesSelect,
} from "../../redux/slices/inventory";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "90%", // 600
    md: "60%", // 900
    lg: "60%", // 1200
    xl: "60%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function RepairPartCreate({ setRepuestos, id, setId, arr }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [generic, setGeneric] = React.useState(false);
  const { brandsSelect, teamTypesSelect, providersSelect } = useSelector((state) => state.inventory);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    setError(null);
    reset();
  };

  const createRepairPart = async (values) => {
    if (!generic) {
      values.equipoText = values.tipo_equipo.descripcion;
    } else {
      delete values.tipo_equipo;
    }

    const isDuplicateCodigoLoginteg = arr.some(
      (obj) => obj.codigo_loginteg.toLowerCase() === values.codigo_loginteg.toLowerCase(),
    );

    const isDuplicateDescripcion = arr.some(
      (obj) => obj.descripcion.toLowerCase() === values.descripcion.toLowerCase(),
    );

    let error = null;

    if (isDuplicateCodigoLoginteg && isDuplicateDescripcion) {
      error = "El Codigo Loginteg y Nombre del repuesto ya existen";
    } else if (isDuplicateCodigoLoginteg) {
      error = "El Codigo Loginteg ya existe";
    } else if (isDuplicateDescripcion) {
      error = "El Nombre del repuesto ya existe";
    }

    if (error) {
      setError(error);
      return;
    } else {
      setError(null);
    }

    dispatch(createNewRepairPart(values));
    reset();
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllBrandsSelect());
    dispatch(getAllTeamTypesSelect());
    // dispatch(getAllStatusSelect());
  }, []);
  return (
    <div>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ textTransform: "none" }}>
        Registrar nuevo repuesto
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(createRepairPart)}>
              <div className="flex">
                <div className="w-full mr-5">
                  <div className={`flex mb-5 gap-4`}>
                    <TextField
                      fullWidth
                      id={"codigo_loginteg"}
                      label={"Codigo Loginteg"}
                      type={"text"}
                      {...register("codigo_loginteg", { required: true })}
                      error={Boolean(errors["codigo_loginteg"])}
                      helperText={Boolean(errors["codigo_loginteg"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                    <TextField
                      fullWidth
                      id={"nombre"}
                      label={"Nombre del Repuesto"}
                      type={"text"}
                      {...register("descripcion", { required: true })}
                      error={Boolean(errors["descripcion"])}
                      helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                  <div className="flex gap-4">
                    <TextField
                      fullWidth
                      id={"serie"}
                      label={"Código Proveedor"}
                      type={"text"}
                      {...register("serie")}
                      error={Boolean(errors["serie"])}
                      helperText={Boolean(errors["serie"]) && "El campo es requerido"}
                    />
                    <TextField
                      fullWidth
                      id={"marca"}
                      label={"Marca"}
                      type={"text"}
                      {...register("marca")}
                      error={Boolean(errors["marca"])}
                      helperText={Boolean(errors["marca"]) ? "El campo es requerido" : " "}
                      defaultValue={""}
                      select
                    >
                      {brandsSelect.length &&
                        brandsSelect
                          ?.filter((brand) => brand.repuesto)
                          .map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                    </TextField>
                  </div>

                  <div className={`flex  gap-4`}>
                    <TextField
                      fullWidth
                      id={"modelo"}
                      label={"Modelo del repuesto"}
                      type={"text"}
                      defaultValue={""}
                      {...register("modelo")}
                      helperText={Boolean(errors["modelo"]) ? "El campo es requerido" : " "}
                      error={Boolean(errors["modelo"])}
                    />
                    <TextField
                      fullWidth
                      id={"medidas"}
                      label={"Medidas"}
                      type={"text"}
                      {...register("medidas")}
                      error={Boolean(errors["medidas"])}
                      helperText={Boolean(errors["medidas"]) && "El campo es requerido"}
                      defaultValue={""}
                    />
                  </div>
                  <div className="flex">
                    <TextField
                      fullWidth
                      id={"generico"}
                      label={"Es Generico"}
                      type={"text"}
                      {...register("generico")}
                      helperText={Boolean(errors["generico"]) ? "El campo es requerido" : " "}
                      error={Boolean(errors["generico"])}
                      onChange={(e) => setGeneric(e.target.value)}
                      select
                      defaultValue={generic}
                      sx={!generic && { mr: 2 }}
                    >
                      <MenuItem key={"1"} value={true}>
                        {"SI"}
                      </MenuItem>
                      <MenuItem key={"2"} value={false}>
                        {"NO"}
                      </MenuItem>
                    </TextField>
                    {!generic && (
                      <TextField
                        fullWidth
                        id={"tipo_equipo"}
                        label={"Compatibilidad"}
                        type={"text"}
                        {...register("tipo_equipo")}
                        defaultValue={""}
                        select
                      >
                        {teamTypesSelect.length &&
                          teamTypesSelect.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  </div>
                  {/* <div className={`flex mb-5`}>
                    <TextField
                      fullWidth
                      id={"cantidad"}
                      label={"Cantidad"}
                      sx={{ mr: 2 }}
                      type={"text"}
                      {...register("cantidad", { required: true, pattern: /^[0-9]*$/ })}
                      error={Boolean(errors["cantidad"])}
                      helperText={
                        (Boolean(errors?.cantidad?.type === "required") && "El campo es requerido") ||
                        (Boolean(errors?.cantidad?.type === "pattern") && "El campo solo admite numeros")
                      }
                      defaultValue={""}
                    />
                    <TextField
                      fullWidth
                      id={"estado"}
                      label={"Estado"}
                      type={"text"}
                      {...register("estado", { required: true })}
                      error={Boolean(errors["estado"])}
                      helperText={Boolean(errors["estado"]) && "El campo es requerido"}
                      defaultValue={""}
                      select
                    >
                      {statusSelect.length &&
                        statusSelect.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.descripcion}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div> */}
                </div>
              </div>
              <div className="w-full pr-5">
                <Button fullWidth type="submit" variant="contained">
                  Enviar formulario
                </Button>

                {error && (
                  <div className="w-full p-2 bg-red-100 rounded mt-2 text-red-500 flex gap-2 items-center mr-5">
                    <ErrorOutlineIcon />
                    {error}
                  </div>
                )}
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
