import { useDispatch, useSelector } from "react-redux";
import ControlledDatePicker from "../../components/ControlledDatePicker";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { vacationRequest } from "../../redux/slices/remuneration";
import { setOpen } from "../../redux/slices/createModal";
import ControlledTextField from "../../components/ControlledTextField";
import { useEffect } from "react";
import { getAllFeriados } from "../../redux/slices/feriados";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const VacationRequest = () => {
  const dispatch = useDispatch();
  const { feriados } = useSelector((state) => state.feriados);
  const { data } = useSelector((state) => state.createModal);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  const initialWatcher = watch("initialDate");
  const finalWatcher = watch("finalDate");

  useEffect(() => {
    dispatch(getAllFeriados());
  }, []);

  const holidays = findHolidaysBetweenDates(feriados, initialWatcher, finalWatcher);
  const diffDays = dayjs(finalWatcher).diff(dayjs(initialWatcher), "days") + 1;
  const finDeSemana = countWeekendsBetweenDates(initialWatcher, finalWatcher);

  const submit = (val) => {
    dispatch(vacationRequest(val));
    dispatch(setOpen(false));
  };

  return (
    <form className="p-2" onSubmit={handleSubmit(submit)}>
      <div className="flex justify-center gap-5 mb-3 p-1 py-2 items-center  rounded">
        <div className="flex gap-2">
          <span className="bg-neutral-200 rounded-full p-1 px-2">Dias Restantes</span>
          <div className="p-1 bg-neutral-200 rounded-full px-2">
            <span
              className={`${
                parseFloat(data.totalWatcher - holidays - diffDays).toFixed(2) < 0 || data.totalWatcher < 0
                  ? "text-red-500"
                  : undefined
              }`}
            >
              {initialWatcher && finalWatcher
                ? `${parseFloat(data.totalWatcher - holidays - diffDays + finDeSemana).toFixed(2)}`
                : `${data.totalWatcher}`}
            </span>
          </div>
        </div>
        <div className="text-gray-500">|</div>
        <div className="flex gap-2">
          <span className="bg-neutral-200 rounded-full p-1 px-2">Dias Solicitados</span>
          <div className="p-1 bg-neutral-200 rounded-full px-2">
            <span>
              {initialWatcher && finalWatcher ? `${parseFloat(diffDays - holidays - finDeSemana).toFixed(2)}` : `${0}`}
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-3 mb-3">
        <div className="w-full">
          <ControlledDatePicker
            id="initialDate"
            rules={{ required: true }}
            name="initialDate"
            control={control}
            label="Fecha de inicio"
            disableWeekends
            disabled={false}
            errors={errors}
            minDate={dayjs()}
          />
        </div>
        <div className="w-full">
          <ControlledDatePicker
            id="finalDate"
            rules={{ required: true }}
            name="finalDate"
            control={control}
            label="Fecha de fin"
            disableWeekends
            disabled={false}
            errors={errors}
            minDate={initialWatcher}
          />
        </div>
      </div>
      <div className="w-full mb-3">
        <ControlledTextField
          id="userObservation"
          rules={{ required: true }}
          name="userObservation"
          control={control}
          label="Observación"
          disabled={false}
          errors={errors}
          rows={3}
        />
      </div>
      <Button type="submit" fullWidth variant="outlined">
        Solicitar
      </Button>
      <div className="text-center text-sm text-neutral-500 mt-2">
        Solo se tendran en cuenta los dias habiles entre las fechas seleccionadas
      </div>
    </form>
  );
};

function findHolidaysBetweenDates(holidays, initialDate, finalDate) {
  const startDate = dayjs(initialDate);
  const endDate = dayjs(finalDate);

  const holidaysBetweenDates = holidays.filter((holiday) => {
    const holidayDate = dayjs(holiday.fecha);
    return holidayDate.isBetween(startDate, endDate, null, "[]");
  });

  return holidaysBetweenDates?.length || 0;
}

const countWeekendsBetweenDates = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  let weekendCount = 0;
  let currentDate = start;
  while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
    const dayOfWeek = currentDate.day();

    if (dayOfWeek === 0 || dayOfWeek === 6) {
      weekendCount++;
    }

    currentDate = currentDate.add(1, "day");
  }

  return weekendCount;
};
