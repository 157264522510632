import React from "react";
import { getAllClients, deleteClient } from "../redux/slices/clients";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { getAllRegions } from "../redux/slices/regions";
import NewClientForm from "../components/modal/NewClientForm";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "nombre" },
  { title: "Razon Social", value: "tipo_razon_social" },
  { title: "RUT", value: "rutRender" },
  { title: "Direccion", value: "direccion" },
  { title: "Region", value: "region" },
  { title: "Comuna", value: "comuna" },
  { title: "Mail contacto", value: "mail_contacto" },
  { title: "Tipo cliente", value: "hijoRender" },
  { title: "Tipo Empresa", value: "tipoEmpresaRender" },
  { title: "Acciones", value: "acciones_cliente" },
];

export default function Client() {
  const { data, success } = useSelector((state) => state.clients);

  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteClient(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "CLIENTE", true, setOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.length) {
      dispatch(getAllClients());
    }

    dispatch(getAllRegions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Clientes</h1>
          <BreadCrumb origin={"Clientes"} current={"Cliente"} />
        </div>

        <div>
          <NewClientForm open_edit={open?.status} close_edit={setOpen} data_edit={open?.data} clients={data} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={true} />
    </div>
  );
}
