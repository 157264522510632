import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { updateArticle } from "../../redux/slices/inventory";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "80%", // 600
    md: "40%", // 900
    lg: "40%", // 1200
    xl: "40%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function ArticleUpdate({ open, data, setOpen }) {
  const { statusSelect, bodegas, providersSelect } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const updateNewArticle = async (value) => {
    value.cantidad = parseInt(value.cantidad);
    dispatch(updateArticle({ value, id: data.id }));
    reset();
    setOpen({ status: false, data: null });
  };

  React.useEffect(() => {
    if (data) {
      reset({
        nombre: data.nombre,
        cantidad: data.cantidad,
        lotes: data.lotes,
        fecha_vencimiento: dayjs(data.fecha_vencimiento),
        estado: data.estadoId,
        bodegaId: data.bodegaId,
        codigo_proveedor: data.codigo_proveedor,
      });
    }
  }, [data]);

  const stateWatcher = watch("estado");

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(updateNewArticle)}>
                <div className="flex">
                  <div className="w-full mr-5">
                    <div className={`flex mb-6 gap-5`}>
                      <TextField
                        fullWidth
                        id={"nombre"}
                        label={"Nombre"}
                        size="small"
                        type={"text"}
                        {...register("nombre", { required: true })}
                        error={Boolean(errors["nombre"])}
                        helperText={Boolean(errors["nombre"]) && "El campo es requerido"}
                      />
                      <TextField
                        fullWidth
                        id={"codigo_proveedor"}
                        label={"Código proveedor"}
                        size="small"
                        type={"text"}
                        {...register("codigo_proveedor")}
                        error={Boolean(errors["codigo_proveedor"])}
                        helperText={Boolean(errors["codigo_proveedor"]) && "El campo es requerido"}
                      />
                    </div>

                    <div className="flex gap-5 ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          id={"fecha_vencimiento"}
                          control={control}
                          rules={{ required: true }}
                          name={"fecha_vencimiento"}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              format="DD/MM/YYYY"
                              label="Fecha de vencimiento"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                  error: !!errors["fecha_vencimiento"],
                                },
                              }}
                              onChange={onChange}
                              value={value || null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Controller
                        id="lotes"
                        name="lotes"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            label="Lotes"
                            size="small"
                            onChange={onChange}
                            value={value || ""}
                            error={Boolean(errors["lotes"])}
                            helperText={Boolean(errors["lotes"]) ? "El campo es requerido" : " "}
                          />
                        )}
                      />
                    </div>
                    <div className="flex gap-5">
                      <TextField
                        fullWidth
                        id={"valueCost"}
                        size="small"
                        label={"Valor"}
                        type={"text"}
                        {...register("valueCost")}
                        error={Boolean(errors["valueCost"])}
                        helperText={Boolean(errors["valueCost"]) ? "El campo es requerido" : " "}
                        defaultValue={data && data?.valueCost ? data.valueCost : ""}
                      />
                      <TextField
                        fullWidth
                        id={"cantidad"}
                        label={"Cantidad"}
                        size="small"
                        type={"text"}
                        {...register("cantidad", { required: true, pattern: /^[0-9]*$/ })}
                        error={Boolean(errors["cantidad"])}
                        helperText={
                          errors?.cantidad?.type === "pattern"
                            ? "El campo es numerico"
                            : errors?.cantidad?.type === "required" && "El campo es requerido"
                        }
                      />
                    </div>
                    <div className="flex gap-5">
                      <Controller
                        id="estado"
                        name="estado"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            id={"estado"}
                            label={"Estado"}
                            type={"text"}
                            size="small"
                            value={value || ""}
                            onChange={onChange}
                            error={Boolean(errors["estado"])}
                            helperText={Boolean(errors["estado"]) && "El campo es requerido"}
                            select
                          >
                            {statusSelect.length &&
                              statusSelect.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.descripcion}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                      {stateWatcher === 4 && (
                        <Controller
                          id="bodegaId"
                          name="bodegaId"
                          rules={{ required: true }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              id={"bodegaId"}
                              label={"Bodega"}
                              type={"text"}
                              error={Boolean(errors["bodegaId"])}
                              size="small"
                              value={value || ""}
                              onChange={onChange}
                              helperText={
                                (Boolean(errors?.bodegaId?.type === "required") && "El campo es requerido") ||
                                (Boolean(errors?.bodegaId?.type === "pattern") && "El campo solo admite numeros")
                              }
                              select
                            >
                              {bodegas.length &&
                                bodegas?.map((option) => (
                                  <MenuItem key={option.id} value={option?.id || ""}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center w-full justify-between">
                  <Button type="submit" variant="contained" sx={{ mt: 2, width: "100%" }}>
                    Enviar formulario
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
