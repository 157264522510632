import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import Teams from "./Teams";
import ControlledDataGridComponent from "../components/tables/controlledDataGrid";
import {
  getAllProyectsBilling,
  getAllTypesExpensesBilling,
  removeProyectBilling,
  removeTypesExpensesBilling,
} from "../redux/slices/facturacion";
import ProyectBilling from "../components/modal/ProyectBilling";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "nombre" },
  { title: "Acciones", value: "acciones_inv" },
];

export const FacturacionTipoGastos = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const { facturacionTipo, success } = useSelector((state) => state.facturacion);
  const dispatch = useDispatch();
  const [handleElementClick, setHandleElementClick] = useState({ status: false });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(removeTypesExpensesBilling(id));
  };

  const [columns] = useDataGrid(
    permitedRenderDataColumn,
    handleDelete,
    "Facturacion Tipo",
    false,
    setHandleElementClick,
  );

  useEffect(() => {
    dispatch(getAllTypesExpensesBilling(paginationModel));
  }, [dispatch, paginationModel.page]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Facturación Tipo Gastos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Facturación Tipo Gastos"} />
        </div>
        <div>
          <ProyectBilling type={"create"} subtype={"expenses"} />
          <ProyectBilling
            type={"edit"}
            open={handleElementClick.status}
            data={handleElementClick.data}
            setOpen={setHandleElementClick}
            subtype={"expenses"}
          />
        </div>
      </div>
      <ControlledDataGridComponent
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        columns={columns}
        rows={facturacionTipo}
        success={success}
      />
    </div>
  );
};

export default FacturacionTipoGastos;
