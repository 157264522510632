import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteArticle,
  deleteRepairPartMold,
  getAllProvidersSelect,
  getAllRepairPartMold,
} from "../../redux/slices/inventory";
import DataGridComponent from "../../components/tables/DataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import useDataGrid from "../../hooks/useDataGrid";
import RepairPartCreate from "../../components/modal/RepairPartCreate";
import RepairPartUpdate from "../../components/modal/RepairPartUpdate";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Codigo Loginteg", value: "codigo_loginteg" },
  { title: "Descripcion", value: "descripcion" },
  { title: "Marca", value: "marcaText" },
  { title: "Modelo", value: "modelo" },
  { title: "Código Proveedor", value: "serie" },
  { title: "Compatibilidad", value: "equipoText" },
  { title: "Acciones", value: "acciones_inv" },
];

const RepairPartMaintainer = () => {
  const dispatch = useDispatch();
  const { repairPartsMold, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(deleteRepairPartMold(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "REPUESTO", false, setOpen);

  useEffect(() => {
    dispatch(getAllRepairPartMold());
    dispatch(getAllProvidersSelect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Repuestos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Repuestos"} />
        </div>
        <div>
          <RepairPartCreate arr={repairPartsMold} />
          <RepairPartUpdate arr={repairPartsMold} open={open.status} data={open.data} setOpen={setOpen} mold />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={repairPartsMold} success={success} />
    </div>
  );
};
export default RepairPartMaintainer;
