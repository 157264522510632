import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

const initialState = {
  documentsIssued: [],
  documentsRecived: [],
  proyects: [],
  tipoGastos: [],
  success: false,
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocumentsIssued: (state, action) => {
      state.documentsIssued = action.payload;
      state.success = true;
    },
    setDocumentsRecived: (state, action) => {
      state.documentsRecived = action.payload;
      state.success = true;
    },
    setProyects: (state, action) => {
      state.proyects = action.payload;
      state.success = true;
    },
    setTipoGastos: (state, action) => {
      state.tipoGastos = action.payload;
      state.success = true;
    },
    setUpdateDocumentsRecived: (state, action) => {
      const updated = state.documentsRecived.filter((documents) => {
        return documents.id !== action.payload.id;
      });
      const documentOrder = [...updated, action.payload];
      state.documentsRecived = documentOrder.sort((a, b) => a.id - b.id);
      state.success = true;
    },
    addDocument: (state, action) => {
      state.documents = [...state.documents, action.payload];
      state.success = true;
    },
    setUpdateDocument: (state, action) => {
      const updated = state.documents.filter((documents) => {
        return documents.id !== action.payload.id;
      });
      state.documents = [...updated, action.payload];
      state.success = true;
    },
    setRemoveDocument: (state, action) => {
      const updated = state.documents.filter((documents) => {
        return documents.id !== action.payload.id;
      });
      state.documents = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllDocumentsIssued = (f_inicio, f_fin) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get(`/documents/issued?f_inicio=${f_inicio}&f_fin=${f_fin}`);
    const newData = res.data.map((el) => {
      return {
        ...el,
        responsable: el.gen_usuario?.nombre || "-",
      };
    });
    dispatch(setDocumentsIssued(newData));
  } catch (error) {
    console.log(error);
  }
};

export const getAllDocumentsRecived = (f_inicio, f_fin) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get(`/documents/recived?f_inicio=${f_inicio}&f_fin=${f_fin}`);
    // const newData = res.data.map((el) => {
    //   return {
    //     ...el,
    //     proyecto: el.facturacion_proyectos ? el.facturacion_proyectos.nombre : "-",
    //     tipo_gasto: el.facturacion_tipo_gastos ? el.facturacion_tipo_gastos.nombre : "-",
    //     responsable: el.gen_usuario.nombre,
    //   };
    // });
    dispatch(setDocumentsRecived(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const updateDocumentRecived = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.put("/documents/recived/update", values);
    const newData = {
      ...res.data,
      proyecto: res.data.facturacion_proyectos.nombre,
      tipo_gasto: res.data.facturacion_tipo_gastos.nombre,
      responsable: res.data.gen_usuario.nombre,
    };
    dispatch(setUpdateDocumentsRecived(newData));
  } catch (error) {
    console.log(error);
  }
};

export const getProyects = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/facturacion/proyectos");
    dispatch(setProyects(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getTipoGastos = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/facturacion/tipo-gastos");
    dispatch(setTipoGastos(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const uploadDocuemntsIssued = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/documents/upload/issued", data);
    const newData = res.data.map((el) => {
      return {
        ...el,
        responsable: el.gen_usuario.nombre,
      };
    });
    dispatch(setDocumentsIssued(newData));
  } catch (error) {}
};

export const uploadDocuemntsreceived = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/documents/upload/recived", data);
    const newData = res.data.map((el) => {
      return {
        ...el,
        proyecto: el.facturacion_proyectos ? el.facturacion_proyectos.nombre : "-",
        tipo_gasto: el.facturacion_tipo_gastos ? el.facturacion_tipo_gastos.nombre : "-",
        responsable: el.gen_usuario.nombre,
      };
    });
    dispatch(setDocumentsRecived(newData));
  } catch (error) {}
};

export const {
  setDocumentsIssued,
  setDocumentsRecived,
  setUpdateDocumentsRecived,
  setProyects,
  setTipoGastos,
  addDocument,
  setUpdateDocument,
  setRemoveDocument,
  setSuccess,
} = documentsSlice.actions;

export default documentsSlice.reducer;
