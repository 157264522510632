import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCobranzasFiltered } from "../../redux/slices/cobranzas";

export default function TableInfoFacturacion({ totales }) {
  const dispatch = useDispatch();
  const [reProgramadas, setReProgramadas] = useState([]);
  const [vencidas, setVencidas] = useState([]);
  const [twoMonth, setTwoMonth] = useState([]);
  const [quince, setQuince] = useState([]);
  const [threeMonth, setThreeMonth] = useState([]);
  const [selectedButton, setSelectedButton] = useState("total");

  const today = dayjs().startOf("day");

  // console.log(-89 <= -60);
  // console.log(-89 >= -90);
  // console.log(twoMonth);

  const switchStates = (array) => {
    array.forEach((element) => {
      const fecha = dayjs(element.fecha_vencimiento, "DD/MM/YYYY");
      const diffDays = fecha.diff(today, "day");
      if (element.f_compromiso !== element.f_vencimiento) {
        setReProgramadas((prevState) => [...prevState, element]);
      } else {
        if (diffDays >= -60 && diffDays <= 0) {
          setVencidas((prevState) => [...prevState, element]);
        } else if (diffDays < -60 && diffDays >= -90) {
          setTwoMonth((prevState) => [...prevState, element]);
        } else if (diffDays < -90 && diffDays <= 0) {
          setThreeMonth((prevState) => [...prevState, element]);
        }
      }
    });
  };

  useEffect(() => {
    switchStates(totales);
  }, [totales]);

  const handleButtonClick = (buttonType, data) => {
    setSelectedButton(buttonType);
    dispatch(setCobranzasFiltered(data));
  };

  return (
    <div className="grid grid-cols-6 w-[800px] h-[80px] max-md:h-[56px] justify-between border-solid">
      <div className="flex flex-col">
        <div className="flex justify-center h-[30%] max-md:hidden">Total</div>
        <div
          className={`flex justify-center items-end h-[70%] max-md:h-[100%] ${
            selectedButton === "total" ? "text-5xl font-medium" : "text-4xl"
          }`}
        >
          <button className="cursor-pointer" onClick={() => handleButtonClick("total", totales)}>
            {totales.length}
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center h-[30%] max-md:hidden">Re Programada</div>
        <div
          className={`flex justify-center items-end h-[70%] max-md:h-[100%] text-green-700 ${
            selectedButton === "reprogramadas" ? "text-5xl font-medium" : "text-4xl"
          }`}
        >
          <button className="cursor-pointer" onClick={() => handleButtonClick("reprogramadas", reProgramadas)}>
            {reProgramadas.length}
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center h-[30%] max-md:hidden">Vencidas</div>
        <div
          className={`flex justify-center items-end h-[70%] max-md:h-[100%] text-red-600 ${
            selectedButton === "vencidas" ? "text-5xl font-medium" : "text-4xl"
          }`}
        >
          <button className="cursor-pointer" onClick={() => handleButtonClick("vencidas", vencidas)}>
            {vencidas.length}
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center h-[30%] max-md:hidden">+ 2 Meses</div>
        <div
          className={`flex justify-center items-end h-[70%] max-md:h-[100%] text-blue-700 ${
            selectedButton === "veinte" ? "text-5xl font-medium" : "text-4xl"
          }`}
        >
          <button className="cursor-pointer" onClick={() => handleButtonClick("veinte", twoMonth)}>
            {twoMonth.length}
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center h-[30%] max-md:hidden">+ 3 Meses</div>
        <div
          className={`flex justify-center items-end h-[70%] max-md:h-[100%] text-yellow-500 ${
            selectedButton === "siete" ? "text-5xl font-medium" : "text-4xl"
          }`}
        >
          <button className="cursor-pointer" onClick={() => handleButtonClick("siete", threeMonth)}>
            {threeMonth.length}
          </button>
        </div>
      </div>
    </div>
  );
}
