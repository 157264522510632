import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

const initialState = {
  checkMenu: [],
};

const checkMenu = createSlice({
  name: "checkMenu",
  initialState,
  reducers: {
    set: (state, { payload: { data, name } }) => {
      state[name] = data;
      state.success = true;
    },
    add: (state, { payload: { data, name } }) => {
      state[name].push(data);
      state.success = true;
    },
    update: (state, { payload: { data, name } }) => {
      state[name] = [...state[name].filter((worker) => worker.id !== data.id), data];
      state.success = true;
    },
    remove: (state, { payload: { name, data } }) => {
      const updated = state[name].filter((el) => el.id !== data);
      state[name] = updated;
    },
  },
});

export const getAllChecks = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/check-menu`);

    const format = data.map((el) => ({
      ...el,
      modelName: el.inv_modelo.nombre,
      itemCount: el._count.checkList,
    }));

    dispatch(set({ name: "checkMenu", data: format }));
  } catch (error) {
    console.log(error);
  }
};

export const getAllChecksPdf = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/check-menu/pdf`);

    const format = data.map((el) => ({
      ...el,
      modelName: el.inv_modelo.nombre,
      itemCount: el._count.checkList,
    }));

    dispatch(set({ name: "checkMenu", data: format }));
  } catch (error) {
    console.log(error);
  }
};

export const createCheckMenu = (val) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.post(`/check-menu`, val);

    const format = {
      ...data,
      modelName: data.inv_modelo.nombre,
      itemCount: data._count.checkList,
    };

    dispatch(add({ name: "checkMenu", data: format }));
  } catch (error) {
    console.log(error);
  }
};

export const editCheckMenu = (val) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.put(`/check-menu`, val);

    const format = {
      ...data,
      modelName: data.inv_modelo.nombre,
      itemCount: data._count.checkList,
    };

    dispatch(update({ name: "checkMenu", data: format }));
  } catch (error) {
    console.log(error);
  }
};

export const deleteCheckmenu = (val) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.put(`/check-menu/delete?id=${val}`);
    console.log(data);
    dispatch(remove({ name: "checkMenu", data: data.id }));
  } catch (error) {
    console.log(error);
  }
};

export const { set, add, remove, update } = checkMenu.actions;

export default checkMenu.reducer;
