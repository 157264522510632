import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/editModal";
import { useEffect, useState } from "react";
import AccordionEquipaments from "../accordion/AccordionEquipaments";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { addMultiples } from "../../redux/slices/invProducts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "700px",
  maxHeight: "700px",
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function EquipmentModal({ data, cliente_id }) {
  const { open } = useSelector((state) => state.editModal);

  const handleClose = () => dispatch(setOpen({ open: false }));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const dispatch = useDispatch();
  const [equipment, setEquipment] = useState(undefined);
  const [provider, setProvider] = useState(undefined);
  const [brand, setBrand] = useState(undefined);
  const [model, setModel] = useState(undefined);
  const [selected, setSelected] = useState([]);
  const [providers, setProviders] = useState([]);
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [arrData, setArrData] = useState(data);
  const [equipmentType, setEquipmentType] = useState([]);

  useEffect(() => {
    const dataFiltered = data.filter((data) => data.estado !== 5);
    const nameProviders = dataFiltered.map((objeto) => objeto.inv_proveedor.descripcion);
    const brandsName = dataFiltered.map((objeto) => objeto.inv_marca.descripcion);
    const modelName = dataFiltered.map((objeto) => objeto.modelo);
    const equipmentType = [...new Set(dataFiltered.map((objeto) => objeto.inv_tipo_equipo.descripcion))];

    const providers = [...new Set(nameProviders)];
    const brands = [...new Set(brandsName)];
    const models = [...new Set(modelName)];

    setArrData(dataFiltered);
    setEquipmentType(equipmentType);
    setProviders(providers);
    setBrands(brands);
    setModels(models);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  const handleFilter = (equipment = undefined, provider = undefined, brand = undefined, model = undefined) => {
    setEquipment(equipment);
    setProvider(provider);
    setBrand(brand);
    setModel(model);
    setCurrentPage(1);

    const dataFiltered = data.filter((data) => data.estado !== 5);

    setArrData(
      dataFiltered.filter((data) => {
        return (
          (!equipment || data.inv_tipo_equipo.descripcion === equipment) &&
          (!provider || data.inv_proveedor.descripcion === provider) &&
          (!brand || data.inv_marca.descripcion === brand) &&
          (!model || data.modelo === model)
        );
      }),
    );
  };

  const handleEquipment = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(item, provider, brand, model);
    } else {
      handleFilter(undefined, provider, brand, model);
    }
  };
  const handleProvider = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(equipment, item, brand, model);
    } else {
      handleFilter(equipment, undefined, brand, model);
    }
  };

  const handleBrand = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(equipment, provider, item, model);
    } else {
      handleFilter(equipment, provider, undefined, model);
    }
  };

  const handleModel = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(equipment, provider, brand, item);
    } else {
      handleFilter(equipment, provider, brand, undefined);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(arrData.filter((el) => el.estado === 4 || el.estado === 1).length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const submitMultiples = async () => {
    const status = await dispatch(addMultiples({ selected, id: cliente_id }));
    if (status === 201) {
      window.location.reload();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="mb-4 mt-2">
              {data && (
                <div className="grid grid-cols-5 gap-3">
                  <Autocomplete
                    key={"list"}
                    disablePortal
                    id="combo-box-demo"
                    options={equipmentType}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Filtrar Tipo Equipo" size="small" />}
                    onChange={(e, item, reason) => handleEquipment(item, reason)}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={brands}
                    fullWidth
                    onChange={(e, item, reason) => handleBrand(item, reason)}
                    renderInput={(params) => <TextField {...params} label="Filtrar Marca" size="small" />}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={models}
                    fullWidth
                    onChange={(e, item, reason) => handleModel(item, reason)}
                    renderInput={(params) => <TextField {...params} label="Filtrar Modelo" size="small" />}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={providers}
                    fullWidth
                    onChange={(e, item, reason) => handleProvider(item, reason)}
                    renderInput={(params) => <TextField {...params} label="Filtrar Proveedor" size="small" />}
                  />
                  {!selected.length ? null : (
                    <Button onClick={submitMultiples} variant="contained" size="small">
                      Añadir multiples
                    </Button>
                  )}
                </div>
              )}
            </div>
            {arrData.length ? (
              [...arrData]
                .filter((el) => el.estado === 4 || el.estado === 1)
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((equipo) => (
                  <AccordionEquipaments
                    setSelected={setSelected}
                    selected={selected}
                    equipo={equipo}
                    key={equipo.id}
                    idCliente={cliente_id}
                  />
                ))
            ) : (
              <div className="bg-slate-200 p-5 rounded text-center">No se encuentran equipos</div>
            )}
            <div className="w-full flex justify-center">Página {currentPage}</div>
            <div className="w-full flex justify-center gap-2">
              <Button onClick={handlePrevPage}>Atras</Button>
              <Button onClick={handleNextPage}>Siguiente</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
