import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import HistoryIcon from "@mui/icons-material/History";
import { useSelector } from "react-redux";
import { setError } from "../../redux/slices/roles";
import { useDispatch } from "react-redux";
import { getCobranzaById, setCobranzaId } from "../../redux/slices/cobranzas";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CobranzasTable from "../tables/CobranzasTable/CobranzasTable";
import MenuBookIcon from "@mui/icons-material/MenuBook";

export default function HistorialCobranzaModal({ params }) {
  const [loading, setLoading] = useState(false);
  const { cobranzaId } = useSelector((state) => state.cobranzas);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 600,
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    setOpen(true);
    await dispatch(getCobranzaById(params));
    setLoading(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(setError(false));
    dispatch(setCobranzaId({}));
    setLoading(false);
  };

  return (
    <div>
      <HistoryIcon onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loading === true ? (
            <div className="h-full">
              <div className="flex justify-center items-center bg-[#2B2C84]/20 text-xl font-medium h-[10%]">
                HISTORIAL
              </div>
              <div className="h-[90%]">
                {cobranzaId.cobranza_obs.length > 0 ? (
                  <div className=" mt-2 max-h-[100%] overflow-y-auto">
                    <CobranzasTable rows={cobranzaId.cobranza_obs} />
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                    <div className="">
                      <HistoryIcon sx={{ width: "100px", height: "100px" }} />
                    </div>
                    <div className="text-xl mt-5 font-medium">SIN HISTORIAL PREVIO</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex justify-center h-full items-center">
              <CircularProgress />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
