import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import formatDataModal from "../../../utils/formatDataModal";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  success: false,
};

const chargesSlice = createSlice({
  name: "charges",
  initialState,
  reducers: {
    setCharges: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    addCharge: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateCharge: (state, action) => {
      const updated = state.data.filter((charge) => {
        return charge.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveCharge: (state, action) => {
      const updated = state.data.filter((charge) => {
        return charge.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllCharges = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/cargo");

    const filtrado = res.data.filter((charge) => charge.estado === true);

    const charges = filtrado.map((charge) => ({
      ...charge,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: charge.f_creacion.split("T")[0],
    }));
    dispatch(setCharges(charges));
  } catch (error) {
    console.log(error);
  }
};

export const createNewCharge = (data) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/cargo", data);
    const charge = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };
    dispatch(addCharge(charge));
    dispatch(
      setNotification({
        status: "success",
        message: "Cargo creado con éxito",
        open: true,
      }),
    );

    return charge;
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear cargo",
        open: true,
      }),
    );
    return error;
  }
};

export const updateCharge = (data) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/cargo", data);

    const charge = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };

    dispatch(setUpdateCharge(charge));
    dispatch(
      setNotification({
        status: "success",
        message: "Cargo editado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar cargo",
        open: true,
      }),
    );
    return console.log(error);
  }
};

export const deleteCharge = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/cargo/${id}`);
    dispatch(setRemoveCharge(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Cargo eliminado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.message,
        open: true,
      }),
    );
  }
};

export const { setCharges, addCharge, setUpdateCharge, setRemoveCharge, setSuccess } = chargesSlice.actions;

export default chargesSlice.reducer;
