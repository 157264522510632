import { useDispatch, useSelector } from "react-redux";
import ControlledTextfield from "../ControlledTextField";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ControlledDatePicker from "../ControlledDatePicker";
import { Button, Chip, Divider } from "@mui/material";
import dayjs from "dayjs";
import { updateWorkerVacation } from "../../redux/slices/remuneration";
import { setOpen } from "../../redux/slices/createModal";

export default function UserInformation() {
  const { data } = useSelector((state) => state.createModal);
  const [loading, setLoading] = useState(false);
  const dispatchEvent = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  const initialDateWatcher = watch("initialDate");
  const finalDateWatcher = watch("finalDate");
  const usedDaysWatcher = watch("usedDays");
  const availableDays = watch("availableDays");

  useEffect(() => {
    if (initialDateWatcher) {
      if (finalDateWatcher) {
        setValue(
          "availableDays",
          parseFloat(dayjs(finalDateWatcher).diff(initialDateWatcher, "days") * 0.0410958904109589).toFixed(2),
        );
        return;
      }

      setValue("availableDays", parseFloat(dayjs().diff(initialDateWatcher, "days") * 0.0410958904109589).toFixed(2));
    }
  }, [initialDateWatcher, finalDateWatcher]);

  useEffect(() => {
    if (data) {
      reset({
        name: data.nombre,
        rut: data.rut,
        cargo: data.gen_cargo.descripcion,
        department: data.gen_departamento.descripcion,
        initialDate: dayjs(data.Vacation?.initialDate),
        finalDate: data.Vacation?.finalDate ? dayjs(data.Vacation?.finalDate) : null,
        usedDays: data.Vacation?.takenDays,
      });
    }
  }, [data]);

  useEffect(() => {
    if (availableDays && !isNaN(availableDays - usedDaysWatcher)) {
      setValue("total", parseFloat(availableDays - (usedDaysWatcher || 0)).toFixed(2));
    }
  }, [usedDaysWatcher, availableDays]);

  const submit = (values) => {
    setLoading(true);
    values.id = data.id;
    dispatchEvent(updateWorkerVacation(values)).then(() => {
      dispatchEvent(setOpen(false));
      setLoading(false);
    });
  };

  return (
    <form className="" onSubmit={handleSubmit(submit)}>
      <Divider textAlign="center" sx={{ mb: 2 }}>
        <Chip color="primary" label="INFORMACIÓN" />
      </Divider>
      <div className="mb-3 flex gap-3">
        <div className="w-full">
          <ControlledTextfield
            id="name"
            rules={{ required: true }}
            name="name"
            control={control}
            label="Nombre"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledTextfield
            id="rut"
            rules={{ required: true }}
            name="rut"
            control={control}
            label="RUT"
            disabled={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="flex mb-3 gap-3">
        <div className="w-full">
          <ControlledTextfield
            id="department"
            rules={{ required: false }}
            name="department"
            control={control}
            label="Departamento"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledTextfield
            id="cargo"
            rules={{ required: false }}
            name="cargo"
            control={control}
            label="Cargo"
            disabled={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-full">
          <ControlledDatePicker
            id="initialDate"
            rules={{ required: true }}
            name="initialDate"
            control={control}
            label="Fecha de inicio"
            disabled={false}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledDatePicker
            id="finalDate"
            rules={{ required: false }}
            name="finalDate"
            control={control}
            label="Fecha de fin"
            disabled={false}
            errors={errors}
          />
        </div>
      </div>
      <Divider textAlign="center" sx={{ my: 3 }}>
        <Chip color="primary" label="VACACIONES" />
      </Divider>
      <div className="mb-3 flex gap-3">
        <div className="w-full">
          <ControlledTextfield
            id="availableDays"
            rules={{ required: false }}
            name="availableDays"
            control={control}
            label="Numero de dias"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledTextfield
            id="usedDays"
            rules={{ required: false, pattern: /^[1-9]\d*$/ }}
            name="usedDays"
            control={control}
            label="Dias tomados"
            disabled={false}
            errors={errors}
          />
        </div>
      </div>
      <div className="mb-3">
        <ControlledTextfield
          id="total"
          rules={{ required: false }}
          name="total"
          control={control}
          label="Total de dias disponibles"
          disabled={true}
          errors={errors}
        />
      </div>
      <Button disabled={loading} fullWidth variant="outlined" type="submit">
        Guardar
      </Button>
    </form>
  );
}
