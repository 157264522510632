import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCharges } from "../../../redux/slices/charges";

export default function useConstantDepartament(data) {
  const dispatch = useDispatch();

  const { data: chargesData } = useSelector((state) => state.charges);
  const { id } = useSelector((state) => state.editModal);
  const [editValues, setEditValues] = useState({});

  useEffect(() => {
    if (!chargesData.length) {
      dispatch(getAllCharges());
    }

    if (id && data.length) {
      const filtered = data.filter((department) => department.id === id);
      setEditValues(filtered[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data.length, dispatch, data]);

  const chargesOptions = [];

  chargesData &&
    chargesData.forEach((charge) => {
      let option = {
        id: charge.id,
        label: charge.descripcion,
        value: charge.id,
      };
      chargesOptions.push(option);
    });

  const fields = [
    {
      id: "descripcion",
      label: "Nombre de Departamento",
      type: "text",
      value: editValues?.descripcion || "",
      required: true,
    },
    {
      id: "jefatura",
      label: "Jefatura",
      type: "select",
      required: true,
      value: editValues?.jefatura || "",
      options: chargesOptions,
    },
  ];

  const element = {
    button: "Añadir Departamento",
  };
  return [fields, element];
}
