import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPortalData } from "../../redux/slices/remuneration";
import { Box, Tab, Tabs, Button } from "@mui/material";
import PortalInformation from "./PortalInformation";
import PortalSettlement from "./PortalSettlement";
import PortalProof from "./PortalProof";
import Signature from "../../components/Signature/Signature";
import UserSignature from "../../components/UserSignature";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="min-h-[450px] max-h-[450px]"
    >
      {value === index && props.loading === "false" && (
        <Box sx={{ py: 2, px: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Portal = () => {
  const [loading, setLoading] = useState(true);
  const [loadings, setTechnicalSignature] = useState(true);
  const [value, setValue] = useState(0);
  const dispatchEvent = useDispatch();

  useEffect(() => {
    dispatchEvent(getPortalData()).then(() => setLoading(false));
  }, [dispatchEvent]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs sx={{ width: "100%" }} value={value} onChange={handleChange} centered>
          <Tab sx={{ width: "33%" }} label="Trabajador" {...a11yProps(0)} />
          <Tab sx={{ width: "33%" }} label="Comprobantes" {...a11yProps(1)} />
          <Tab sx={{ width: "33%" }} label="Liquidaciones" {...a11yProps(2)} />
        </Tabs>
      </Box>
      {value === 2 ? (
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div></div>
          <UserSignature type={"tecnico"} />
        </div>
      ) : null}
      <CustomTabPanel loading={loading.toString()} value={value} index={0}>
        <PortalInformation />
      </CustomTabPanel>
      <CustomTabPanel loading={loading.toString()} value={value} index={1}>
        <PortalProof />
      </CustomTabPanel>
      <CustomTabPanel loading={loading.toString()} value={value} index={2}>
        <PortalSettlement />
      </CustomTabPanel>
    </div>
  );
};

export default Portal;
