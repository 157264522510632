import { TextField, MenuItem, IconButton, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllProvidersSelect, getAllTypesOfDocuments } from "../redux/slices/inventory";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import dayjs from "dayjs";
import enviroments from "../utils/enviroments";

const InventoryEntry = ({ setInventoryEntry, setIsDisabled, data, view }) => {
  const dispatch = useDispatch();
  const { providersSelect, typeOfDocument } = useSelector((state) => state.inventory);
  const [file, setFile] = useState(null);
  const [factura, setFactura] = useState(null);

  useEffect(() => {
    dispatch(getAllProvidersSelect());
    dispatch(getAllTypesOfDocuments());
  }, [dispatch]);

  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChangeFileFactura = (e) => {
    setFactura(e.target.files[0]);
  };

  const {
    watch,
    formState: { errors },
    getValues,
    control,
    reset,
  } = useForm({ mode: "all" });

  const tipo_documento = watch("tipo_documento");
  const numero_documento = watch("numero_documento");
  const proveedor = watch("proveedor");
  const observaciones = watch("observaciones");
  const ocNumber = watch("oc_numero");
  const facturaNumber = watch("factura_numero");
  const date = watch("fecha_ingreso");
  const dateProveedor = watch("fecha_factura_proveedor");

  useEffect(() => {
    if (data?.id) {
      reset({
        fecha_ingreso: dayjs(data.fecha_ingreso).add(1, "day"),
        fecha_factura_proveedor: dayjs(data?.fecha_factura_proveedor).add(1, "day"),
        numero_documento: data.numero_documento,
        observaciones: data.observaciones,
        oc_numero: data.oc_numero,
        proveedor: data.proveedor,
        tipo_documento: data.tipo_documento,
        factura_numero: data.factura_numero,
      });
    } else {
      reset({ fecha_ingreso: dayjs(), fecha_factura_proveedor: dayjs() });
    }
  }, [data]);

  useEffect(() => {
    const values = getValues();
    setInventoryEntry({ ...values, file, factura });

    if (tipo_documento && numero_documento && observaciones && proveedor && date && dateProveedor) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    tipo_documento,
    numero_documento,
    proveedor,
    observaciones,
    ocNumber,
    file,
    date,
    factura,
    facturaNumber,
    dateProveedor,
  ]);

  return (
    <div className="w-full">
      <div className="mb-2 rounded capitalize p-2 w-full">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full flex">
            <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">
              Tipo de Documento:
            </div>
            <Controller
              id="tipo_documento"
              name="tipo_documento"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mr: "20px" }}
                  fullWidth
                  size="small"
                  select
                  disabled={view}
                  onChange={onChange}
                  value={value || ""}
                  error={Boolean(errors["tipo_documento"])}
                  helperText={Boolean(errors["tipo_documento"]) ? "El campo es requerido" : " "}
                >
                  {typeOfDocument?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
          <div className="w-full flex">
            <span className="font-bold uppercase tracking-wider	text-sm mr-5  min-w-[100px] w-[100px]">
              Numero de Documento:
            </span>
            <Controller
              id="numero_documento"
              name="numero_documento"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mr: "20px" }}
                  fullWidth
                  size="small"
                  disabled={view}
                  onChange={onChange}
                  value={value || ""}
                  error={Boolean(errors["numero_documento"])}
                  helperText={
                    errors?.numero_documento?.type === "required"
                      ? "El campo es requerido"
                      : errors?.numero_documento?.type === "pattern"
                      ? "Este campo es numerico"
                      : " "
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="w-full flex">
            <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">Proveedor:</div>
            <Controller
              id="proveedor"
              name="proveedor"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mr: "20px" }}
                  fullWidth
                  size="small"
                  select
                  disabled={view}
                  onChange={onChange}
                  value={value || ""}
                  error={Boolean(errors["proveedor"])}
                  helperText={Boolean(errors["proveedor"]) ? "El campo es requerido" : " "}
                >
                  {providersSelect?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>

          <div className="w-full flex">
            <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">
              F. Factura Proveedor:
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                id={"fecha_factura_proveedor"}
                control={control}
                rules={{ required: true }}
                name={"fecha_factura_proveedor"}
                // defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    disabled={view}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: { mr: "20px" },
                        error: !!errors["fecha_factura_proveedor"],
                        // helperText: errors['fecha_ingreso'] && ListOfErrors[errors['fecha_ingreso'].type],
                      },
                    }}
                    onChange={onChange}
                    value={value || null}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="w-full flex">
            <span className="font-bold uppercase tracking-wider	text-sm mr-5  min-w-[100px] w-[100px]">
              Observacion:
            </span>
            <Controller
              id="observaciones"
              name="observaciones"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mr: "20px" }}
                  fullWidth
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors["observaciones"])}
                  helperText={Boolean(errors["observaciones"]) ? "El campo es requerido" : " "}
                />
              )}
            />
          </div>
          <div className="w-full flex">
            <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">
              Fecha de Ingreso:
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                id={"fecha_ingreso"}
                control={control}
                rules={{ required: true }}
                name={"fecha_ingreso"}
                // defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    disabled={view}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: { mr: "20px" },
                        error: !!errors["fecha_ingreso"],
                        // helperText: errors['fecha_ingreso'] && ListOfErrors[errors['fecha_ingreso'].type],
                      },
                    }}
                    onChange={onChange}
                    value={value || null}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="w-full flex">
            <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">Numero OC:</div>
            <Controller
              id="oc_numero"
              name="oc_numero"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ mr: "20px" }}
                  fullWidth
                  size="small"
                  onChange={onChange}
                  value={value || ""}
                  error={Boolean(errors["oc_numero"])}
                  helperText={Boolean(errors["oc_numero"]) ? "El campo es requerido" : " "}
                  InputProps={{
                    endAdornment: (
                      <IconButton size="small" component="label">
                        <AttachFileIcon />
                        <input
                          hidden
                          accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                          type="file"
                          onChange={handleChangeFile}
                        />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="w-full flex">
            <div className="w-full flex">
              <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">
                Numero de Factura:
              </div>
              <Controller
                id="factura_numero"
                name="factura_numero"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ mr: "20px" }}
                    fullWidth
                    size="small"
                    onChange={onChange}
                    value={value || ""}
                    error={Boolean(errors["factura_numero"])}
                    helperText={Boolean(errors["factura_numero"]) ? "El campo es requerido" : " "}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" component="label">
                          <AttachFileIcon />
                          <input
                            hidden
                            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                            type="file"
                            onChange={handleChangeFileFactura}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex w-full sm:flex-row">
            {(file?.name || data?.oc_adjunto) && (
              <div className="w-full flex">
                <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">
                  Adjunto OC:{" "}
                </div>
                <div className="w-full p-2 border-neutral-400/60 border-[1px] rounded mr-[20px] flex justify-between items-center">
                  {file?.name || data?.oc_adjunto}
                  {file?.name ? (
                    <InsertDriveFileIcon sx={{ color: "gray", mr: 1 }} />
                  ) : (
                    <a
                      href={`${enviroments.img_endpoint}${data?.oc_adjunto}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      download="proposed_file_name"
                      // className="w-[70px] h-[19px] p-3 rounded-[20px] ml-2 bg-red-500 text-white flex justify-center items-center text-sm"
                    >
                      <InsertDriveFileIcon sx={{ color: "gray", mr: 1, cursor: "pointer" }} />
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full sm:flex-row">
            {(factura?.name || data?.factura_adjunto) && (
              <div className="w-full flex">
                <div className="font-bold uppercase tracking-wider text-sm mr-5  min-w-[100px] w-[100px]">
                  Adjunto Factura:{" "}
                </div>
                <div className="w-full p-2 border-neutral-400/60 border-[1px] rounded mr-[20px] flex justify-between items-center">
                  {factura?.name || data?.factura_adjunto}
                  {factura?.name ? (
                    <InsertDriveFileIcon sx={{ color: "gray", mr: 1 }} />
                  ) : (
                    <a
                      href={`${enviroments.img_endpoint}${data?.factura_adjunto}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      download="proposed_file_name"
                      // className="w-[70px] h-[19px] p-3 rounded-[20px] ml-2 bg-red-500 text-white flex justify-center items-center text-sm"
                    >
                      <InsertDriveFileIcon sx={{ color: "gray", mr: 1, cursor: "pointer" }} />
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryEntry;
