import React from "react";
import DataGridComponent from "../../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useDataGrid from "../../hooks/useDataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { deleteStorage, getAllStorages } from "../../redux/slices/inventory";
import StorageForm from "../../components/modal/StorageModal";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre de Bodega", value: "name" },
  { title: "Dirección", value: "adress" },
  { title: "País", value: "country" },
  { title: "Acciones", value: "acciones_bodegas" },
];

export default function Bodegas() {
  const { bodegas, success } = useSelector((state) => state.inventory);

  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    await dispatch(deleteStorage(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Bodega", false, setOpen);

  useEffect(() => {
    dispatch(getAllStorages());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Bodegas</h1>
          <BreadCrumb origin={"Inventario"} current={"Bodegas"} />
        </div>
        <div>
          <StorageForm open_edit={open?.status} close_edit={setOpen} data_edit={open?.data} bodegas={bodegas} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={bodegas} success={success} />
    </div>
  );
}
