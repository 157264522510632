import { useSelector } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import CustomSelectClient from "../components/CustomSelectClient";
import { useEffect, useState } from "react";
import DataGridComponent from "../components/tables/DataGrid";
import useDataGrid from "../hooks/useDataGrid";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import thousandSeparator from "../utils/thousandSeparator";

export default function FacturacionCliente() {
  const { cobranzasClientes, success } = useSelector((state) => state.cobranzas);
  const [loading, setLoading] = useState(false);

  const [arrayToRender, setArrayToRender] = useState([]);

  const permitedRenderDataColumn = [
    { title: "ID", value: "id", hide: true },
    { title: "Nro Factura", value: "nro_factura" },
    { title: "Cliente", value: "cliente" },
    { title: "Fecha", value: "fecha" },
    { title: "Neto", value: "neto" },
    { title: "IVA", value: "iva" },
    { title: "Monto", value: "monto" },
    { title: "Nro OC.", value: "nro_oc" },
    { title: "Fecha OC.", value: "f_oc" },
    { title: "Hijo", value: "hijo" },
    { title: "Detalle", value: "detalle" },
    { title: "Descripcion", value: "item_name" },
    { title: "Modelo", value: "modelo_name" },
    { title: "Serie", value: "serie_name" },
    { title: "Marca", value: "marca_name" },
    { title: "Cantidad", value: "cantidad" },
  ];

  useEffect(() => {
    if (cobranzasClientes.nombre) {
      const rowsToRender = [];
      let base = { cliente: cobranzasClientes.nombre, nro_oc: cobranzasClientes.nro_oc };
      let id = 0;
      if (cobranzasClientes.facturacion_facturacion_clienteTocliente.length) {
        cobranzasClientes.facturacion_facturacion_clienteTocliente.forEach((el) => {
          if (el.cobranza.length) {
            el.cobranza.forEach((el) => {
              let base2 = {
                fecha: dayjs(el.f_facturacion).format("DD/MM/YYYY"),
                monto: "$" + Math.round(el.monto).toLocaleString(), // TIENE IVA
                iva: "$" + Math.round((el.monto / 1.19) * 0.19).toLocaleString(), // IVA
                neto: "$" + Math.round(el.monto / 1.19).toLocaleString(), // SIN IVA
                nro_factura: el.n_factura,
              };
              if (el.facturacion.facturacion_articulos.length) {
                el.facturacion.facturacion_articulos.forEach((item) => {
                  id = id + 1;
                  rowsToRender.push({
                    ...base,
                    ...base2,
                    nro_oc: el.facturacion.nro_oc,
                    hijo: "NO",
                    f_oc: dayjs(el.facturacion.fecha_oc).format("DD/MM/YYYY"),
                    item_name: item.inv_articulos.nombre,
                    modelo_name: "S/D",
                    serie_name: "S/D",
                    marca_name: "S/D",
                    cantidad: item.cantidad,
                    id: id,
                    detalle: "Insumo",
                  });
                });
              }
              if (el.facturacion.facturacion_repuestos.length) {
                el.facturacion.facturacion_repuestos.forEach((item) => {
                  id = id + 1;
                  rowsToRender.push({
                    ...base,
                    ...base2,
                    nro_oc: el.facturacion.nro_oc,
                    hijo: "NO",
                    f_oc: dayjs(el.fecha_oc).format("DD/MM/YYYY"),
                    item_name: item.inv_repuesto.descripcion,
                    modelo_name: item.inv_repuesto.modelo,
                    serie_name: item.inv_repuesto.serie,
                    marca_name: "S/D",
                    cantidad: "S/D",
                    detalle: "Repuesto",
                    id: id,
                  });
                });
              }
              if (el.facturacion.facturacion_equipo.length) {
                el.facturacion.facturacion_equipo.forEach((item) => {
                  id = id + 1;
                  rowsToRender.push({
                    ...base,
                    ...base2,
                    nro_oc: el.facturacion.nro_oc,
                    hijo: "NO",
                    f_oc: dayjs(el.fecha_oc).format("DD/MM/YYYY"),
                    item_name: item.inv_equipo?.inv_tipo_equipo?.descripcion,
                    modelo_name: item.inv_equipo.modelo,
                    serie_name: item.inv_equipo.serie,
                    marca_name: item.inv_equipo.inv_marca.descripcion,
                    detalle: "Equipo",
                    cantidad: "S/D",
                    id: id,
                  });
                });
              }
              if (el.facturacion.facturacion_servicios.length) {
                el.facturacion.facturacion_servicios.forEach((item) => {
                  id = id + 1;
                  rowsToRender.push({
                    ...base,
                    ...base2,
                    nro_oc: el.facturacion.nro_oc,
                    hijo: "NO",
                    f_oc: dayjs(el.fecha_oc).format("DD/MM/YYYY"),
                    item_name: item.servicios.nombre,
                    cantidad: item.cantidad,
                    modelo_name: "S/D",
                    serie_name: "S/D",
                    marca_name: "S/D",
                    detalle: "Servicio",
                    id: id,
                  });
                });
              }
            });
          }
        });
      }
      if (cobranzasClientes.other_cliente.length) {
        cobranzasClientes.other_cliente.forEach((el) => {
          if (el.facturacion_facturacion_clienteTocliente.length) {
            let base4 = { cliente: el.nombre };
            el.facturacion_facturacion_clienteTocliente.forEach((el) => {
              if (el.cobranza.length) {
                el.cobranza.forEach((el) => {
                  let base3 = {
                    fecha: dayjs(el.f_facturacion).format("DD/MM/YYYY"),
                    monto: "$ " + el.monto.toLocaleString(),
                    nro_factura: el.n_factura,
                  };
                  if (el.facturacion.facturacion_articulos.length) {
                    el.facturacion.facturacion_articulos.forEach((item) => {
                      id = id + 1;
                      rowsToRender.push({
                        ...base4,
                        ...base3,
                        nro_oc: el.facturacion.nro_oc,
                        hijo: "SI",
                        f_oc: dayjs(el.facturacion.fecha_oc).format("DD/MM/YYYY"),
                        item_name: item.inv_articulos.nombre,
                        cantidad: item.cantidad,
                        modelo_name: "S/D",
                        serie_name: "S/D",
                        marca_name: "S/D",
                        id: id,
                        detalle: "Insumo",
                      });
                    });
                  }
                  if (el.facturacion.facturacion_repuestos.length) {
                    el.facturacion.facturacion_repuestos.forEach((item) => {
                      id = id + 1;
                      rowsToRender.push({
                        ...base4,
                        ...base3,
                        nro_oc: el.facturacion.nro_oc,
                        hijo: "SI",
                        f_oc: dayjs(el.facturacion.fecha_oc).format("DD/MM/YYYY"),
                        item_name: item.inv_repuesto.descripcion,
                        modelo_name: item.inv_repuesto.modelo,
                        serie_name: item.inv_repuesto.serie,
                        marca_name: "S/D",
                        detalle: "Repuesto",
                        cantidad: "S/D",
                        id: id,
                      });
                    });
                  }
                  if (el.facturacion.facturacion_equipo.length) {
                    el.facturacion.facturacion_equipo.forEach((item) => {
                      id = id + 1;
                      rowsToRender.push({
                        ...base4,
                        ...base3,
                        nro_oc: el.facturacion.nro_oc,
                        hijo: "SI",
                        f_oc: dayjs(el.facturacion.fecha_oc).format("DD/MM/YYYY"),
                        item_name: item.inv_equipo?.inv_tipo_equipo?.descripcion,
                        modelo_name: item.inv_equipo.modelo,
                        serie_name: item.inv_equipo.serie,
                        marca_name: item.inv_equipo.inv_marca.descripcion,
                        cantidad: "S/D",
                        detalle: "Equipo",
                        id: id,
                      });
                    });
                  }
                  if (el.facturacion.facturacion_servicios.length) {
                    el.facturacion.facturacion_servicios.forEach((item) => {
                      id = id + 1;
                      rowsToRender.push({
                        ...base4,
                        ...base3,
                        nro_oc: el.facturacion.nro_oc,
                        hijo: "SI",
                        f_oc: dayjs(el.facturacion.fecha_oc).format("DD/MM/YYYY"),
                        item_name: item.servicios.nombre,
                        cantidad: item.cantidad,
                        detalle: "Servicio",
                        modelo_name: "S/D",
                        serie_name: "S/D",
                        marca_name: "S/D",
                        id: id,
                      });
                    });
                  }
                });
              }
            });
          }
        });
      }
      setArrayToRender(rowsToRender);
    }
  }, [cobranzasClientes]);
  const handleDelete = () => {};
  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "TIPO DE RAZON SOCIAL");

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Facturación clientes</h1>
          <BreadCrumb origin={"Informes Cobranzas"} current={"Facturación clientes"} />
        </div>
        <div>
          <div className="w-[500px] mr-[20px]">
            <CustomSelectClient setLoading={setLoading} />
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <DataGridComponent columns={columns} rows={arrayToRender} success={success} />
        )}
      </div>
    </div>
  );
}
