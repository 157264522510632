import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  data: null,
  form: null,
};

const createModalSlice = createSlice({
  name: "createModal",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload.open || action.payload;
      // state.open = action.payload.open !== null ? action.payload : action.payload.open;
      state.data = action.payload.data;
      state.form = action.payload.form;
    },
  },
});

export const { setOpen } = createModalSlice.actions;

export default createModalSlice.reducer;
