import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/editModal";
import CircularProgress from "@mui/material/CircularProgress";
import AccordionHistoryAssign from "../accordion/AccordionHistoryAssign";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "90%", // 600
    md: "70%", // 900
    lg: "70%", // 1200
    xl: "70%", // 1536
  },
  height: "700px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function HistoryModal() {
  const { open } = useSelector((state) => state.editModal);
  const { client_ots, success } = useSelector((state) => state.workOrders);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setOpen({ open: false }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {client_ots.length && success ? (
              <div className="h-full overflow-y-auto">
                <div className="mb-3 text-lg font-semibold">Historial cliente</div>
                {[...client_ots]
                  .sort((a, b) => (dayjs(a.f_ot).isAfter(dayjs(b.f_ot)) ? 1 : -1))
                  .map((ot, index) => (
                    <AccordionHistoryAssign key={index} order={ot} />
                  ))}
              </div>
            ) : !client_ots.length && success ? (
              <div className="w-full ">
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline font-semibold">No tiene ordenes de trabajo asignadas</span>
                </div>
              </div>
            ) : (
              <div className="w-full h-[700px] flex justify-center items-center">
                <CircularProgress />
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
