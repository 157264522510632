const dev_enviroments = {
  img_endpoint: process.env.REACT_APP_DEV_IMAGES_DIR,
  base_url: process.env.REACT_APP_DEV_API_URL,
};

const prod_env = {
  img_endpoint: process.env.REACT_APP_PROD_IMAGES_DIR,
  base_url: process.env.REACT_APP_PROD_API_URL,
};

const enviroments = process.env.NODE_ENV === "production" ? prod_env : dev_enviroments;

export default enviroments;
