import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import FilterInvInsumos from "./filter";
import { setter } from "../../../redux/slices/informes";
import { useEffect } from "react";

export default function RendicionGastos() {
  const { rendicionGastos } = useSelector((state) => state.informes);

  const columnStorageInsumos = [
    { title: "ID", value: "id", hidden: true },
    { title: "Colaborador", value: "colaboradorInforme" },
    { title: "Fecha", value: "fecha" },
    { title: "Tipo documento", value: "tipo_documento" },
    { title: "Nombre Empresa", value: "nombre_empresa" },
    { title: "Rut Empresa", value: "rut_empresa" },
    { title: "Monto", value: "monto" },
    { title: "Estado", value: "estado" },
    { title: "Documento", value: "documento_informe" },
  ];

  // console.log(storageInsumos);
  const [columns] = useDataGrid(columnStorageInsumos, () => {}, "CARGO");

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "storageInsumos", payload: [] }));
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <FilterInvInsumos />
      <DataGridComponent columns={columns} rows={rendicionGastos} success={true} size="40px" />
    </div>
  );
}
