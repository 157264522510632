import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../tables/DataGrid";
import useDataGrid from "../../../hooks/useDataGrid";
import FilterInvRepuestos from "./FilterInvRepuestos";
import { useEffect } from "react";
import { setter } from "../../../redux/slices/informes";

export default function InvRepuestos() {
  const { storageRepuestos } = useSelector((state) => state.informes);
  const columnStorageRepuestos = [
    { title: "Código Loginteg", value: "codigo_loginteg" },
    { title: "Descripción", value: "descripcion" },
    { title: "Marca", value: "marca" },
    { title: "Modelo", value: "modelo" },
    { title: "Código Proveedor", value: "serie" },
    { title: "Cantidad", value: "cantidad" },
  ];

  const [columns] = useDataGrid(columnStorageRepuestos, () => {}, "CARGO");

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setter({ setter: "storageRepuestos", payload: [] }));
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <FilterInvRepuestos />
      <DataGridComponent columns={columns} rows={storageRepuestos} success={true} size="40px" />
    </div>
  );
}
