import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { updateFeriado } from "../../redux/slices/feriados";
import { updateArea } from "../../redux/slices/area";
import { getAllUsers } from "../../redux/slices/users";
import { createTelegram, getAllUsersEnvios } from "../../redux/slices/gestionEnvios";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { values } from "pdf-lib";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function GestionEnviosTelegram({ open, data, setOpen }) {
  const { users } = useSelector((state) => state.gestionEnvios);
  const dispatch = useDispatch();
  const [usersTelegram, setUsersTelegram] = React.useState([]);

  const handleClose = () => {
    setOpen({ status: false, data: null });
  };

  const handle_submit = async () => {
    dispatch(createTelegram(data.id, usersTelegram));
    setOpen({ status: false, data: null });
  };

  const changeUsersTelegram = (e) => {
    setUsersTelegram((prevState) => [...prevState, e.target.value]);
  };

  const removeUsersTelegram = (id) => {
    setUsersTelegram((prevState) => prevState.filter((el) => el.id !== id));
  };

  React.useEffect(() => {
    setUsersTelegram(
      data?.envioTelegramUsuario?.map((el) => ({ id: el.gen_usuario.id, nombre: el.gen_usuario.nombre }))
    );
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handle_submit}>
                <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">{data.name}</div>
                <div className="mb-2">
                  <TextField
                    sx={{ mr: "20px" }}
                    fullWidth
                    size="small"
                    label="Usuarios"
                    select
                    onChange={changeUsersTelegram}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {users?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option}
                        disabled={
                          usersTelegram.length && usersTelegram.some((user) => user.id === option.id) ? true : false
                        }
                      >
                        {option.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="max-h-[300px] overflow-auto mb-2">
                  {usersTelegram.map((el) => (
                    <div
                      className="bg-[#2b2c84]/20 px-5 py-2 rounded font-semibold mb-1 flex justify-between"
                      key={el.id}
                    >
                      <div>{el.nombre}</div>
                      <div className="cursor-pointer" onClick={() => removeUsersTelegram(el.id)}>
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center w-full justify-between">
                  <Button type="submit" variant="outlined" fullWidth>
                    Enviar formulario
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
