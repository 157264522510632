import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { Button, TextField, Chip, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { createArea } from "../../redux/slices/area";
import { createNewCharge } from "../../redux/slices/charges";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function PositionCreate() {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handle_submit = async (value) => {
    dispatch(createNewCharge(value));
    reset();
    setOpen(false);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Añadir Cargo"
        color="primary"
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(handle_submit)}>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR CARGO</div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="descripcion"
                  name="descripcion"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Descripción"
                      onChange={onChange}
                      size="small"
                      value={value || ""}
                      error={errors.descripcion}
                      helperText={errors.descripcion?.message}
                    />
                  )}
                />
              </div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="tipo"
                  name="tipo"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      label="Tipo"
                      onChange={onChange}
                      size="small"
                      value={value || ""}
                      error={errors.tipo}
                      helperText={errors.tipo?.message}
                    >
                      <MenuItem key={1} dense divider value={"Contacto"}>
                        Contacto
                      </MenuItem>
                      <MenuItem key={1} dense divider value={"Usuario"}>
                        Usuario
                      </MenuItem>
                    </TextField>
                  )}
                />
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="outlined" fullWidth>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
