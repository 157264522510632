import DataGridComponent from "../components/tables/DataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogTitle, MenuItem, TextField, Tooltip } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { getAllModels } from "../redux/slices/inventory";
import { createCheckMenu, deleteCheckmenu, editCheckMenu, getAllChecks } from "../redux/slices/checklist";

export const dataColumns = [
  { value: "id", title: "id", minWidth: 170 },
  { value: "name", title: "Nombre", minWidth: 170 },
  { value: "modelName", title: "Modelo", minWidth: 170 },
  { value: "itemCount", title: "Cantidad de Items", minWidth: 170 },
  { value: "capacitacion_actions", title: "Acciones", minWidth: 100 },
];

export default function MenuMantencion() {
  const [items, setItems] = useState([]);
  const [item, setItem] = useState("");
  const [subItem, setSubItem] = useState("");
  const [open, setOpen] = useState({
    open: false,
    data: null,
  });

  const { models } = useSelector((state) => state.inventory);
  const { checkMenu } = useSelector((state) => state.checkMenu);
  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({ mode: "all" });

  const onClose = () => {
    setItems([]);
    setItem("");
    setSubItem("");
    reset({ name: "", model: "" });
    setOpen({ open: false, data: null });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllModels());
    dispatch(getAllChecks());
  }, [dispatch]);

  useEffect(() => {
    if (open.data) {
      reset({ name: open.data.name, model: open.data.inv_modeloId });
      setItems(open.data.checkList);
    }
  }, [open.data]);

  const handleDelete = (event) => {
    dispatch(deleteCheckmenu(event.id));
  };

  const [column] = useDataGrid(dataColumns, handleDelete, "", false, setOpen);

  const submit = async (val) => {
    if (!items.length) return;
    const send = { ...val, items };

    if (open.data) {
      send.id = open.data.id;
      dispatch(editCheckMenu(send));
    } else {
      dispatch(createCheckMenu(send));
    }

    onClose();
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">CheckList Capacitación</h1>
          <BreadCrumb origin={"Mantenedores"} current={"CheckList Capacitación"} />
        </div>
        <div>
          <Button variant="contained" onClick={() => setOpen({ open: true, data: null })}>
            Crear CheckList
          </Button>
        </div>
      </div>
      <DataGridComponent columns={column} rows={checkMenu} success={true} />
      <Dialog
        open={open.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {open.data ? "Editar CheckList" : "Creación de CheckList para capacitación"}
        </DialogTitle>
        <form onSubmit={handleSubmit(submit)} className="p-5 pt-1 w-[600px]">
          <div className="flex gap-3">
            <div className="mb-3 w-full">
              <Controller
                id="name"
                name="name"
                rules={{ required: "* El campo es requerido" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    id={"name"}
                    label={"Nombre del CheckList"}
                    value={value || ""}
                    onChange={onChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    type={"text"}
                    error={errors?.name?.message}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </div>
            <div className="mb-3 w-full">
              <Controller
                id="model"
                name="model"
                rules={{ required: "* El campo es requerido" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    id={"model"}
                    label={"Modelo"}
                    value={value || ""}
                    onChange={onChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    type={"text"}
                    error={errors?.model?.message}
                    select
                    helperText={errors?.model?.message}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {models?.map((el) => {
                      return (
                        <MenuItem value={el.id} dense>
                          {el.nombre}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <TextField
              value={item}
              onChange={(e) => setItem(e.target.value)}
              label="Item Principal"
              size="small"
              fullWidth
            />
            <TextField
              value={subItem}
              onChange={(e) => setSubItem(e.target.value)}
              label="Sub Item"
              size="small"
              fullWidth
            />
          </div>
          <div className="mt-3 w-full flex gap-5 items-center">
            <div className="w-full h-[1px] bg-neutral-400" />

            <Button
              onClick={() => {
                if (subItem === "" && item === "") return;
                setItems((prev) => [...prev, { item: item || " ", subItem: subItem || " " }]);
                setItem("");
                setSubItem("");
              }}
              size="small"
              variant="outlined"
            >
              Agregar
            </Button>
          </div>
          <div className="w-full max-h-[300px] h-[300px] overflow-auto mt-3">
            {items
              .filter((el) => !el.deleted)
              .map(({ item, subItem }) => (
                <div
                  onClick={() => {
                    const finded = { ...items.find((el) => el.subItem === subItem), deleted: true };

                    setItems((prev) => [...prev.filter((el) => el.subItem !== subItem), finded]);
                  }}
                  className="grid grid-cols-3 cursor-pointer items-center p-1 gap-4 px-2 bg-blue-100 transition-all hover:bg-blue-200 border mb-2 border-blue-200 rounded"
                >
                  <div className="font-bold col-span-1 line-clamp-2">{item}</div>
                  <Tooltip title={subItem}>
                    <div className="col-span-2 line-clamp-2 text-neutral-600">{subItem}</div>
                  </Tooltip>
                </div>
              ))}
          </div>
          <DialogActions>
            <Button onClick={onClose}>Cerrar</Button>
            <Button type="submit" autoFocus>
              {open.data ? "editar" : "crear"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
