import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getAllProducts } from "../../redux/slices/invProducts";
import AccordionEquipaments from "../accordion/AccordionEquipaments";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FacebookCircularProgress from "../FacebookLoading/FacebookLoading";
import EquipmentModal from "../modal/EquipmentModal";
import { setOpen } from "../../redux/slices/editModal";

export default function EquipamentList() {
  const { data, success } = useSelector((state) => state.invProducts);
  const [params, setParams] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [equipment, setEquipment] = useState(undefined);
  const [provider, setProvider] = useState(undefined);
  const [brand, setBrand] = useState(undefined);
  const [model, setModel] = useState(undefined);

  const [providers, setProviders] = useState([]);
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [equipmentType, setEquipmentType] = useState([]);
  const [arrData, setArrData] = useState(data);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    if (data?.length) {
      const dataFiltered = data.filter(
        (data) => data.cliente_equipo.find((el) => el.cliente === parseInt(id))?.cliente && data.estado === 5,
      );

      const nameProviders = dataFiltered.map((objeto) => objeto.inv_proveedor.descripcion);
      const brandsName = dataFiltered.map((objeto) => objeto.inv_marca.descripcion);
      const modelName = dataFiltered.map((objeto) => objeto.modelo);
      const equipmentType = [...new Set(dataFiltered.map((objeto) => objeto.inv_tipo_equipo.descripcion))];

      const providers = [...new Set(nameProviders)];
      const brands = [...new Set(brandsName)];
      const models = [...new Set(modelName)];

      setArrData(dataFiltered);
      setEquipmentType(equipmentType);
      setProviders(providers);
      setBrands(brands);
      setModels(models);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rut = queryParams.get("rut");
    const client = queryParams.get("client");

    if (!rut || !client) return;
    setParams({ rut, client });
  }, []);

  const handleFilter = (equipment = undefined, provider = undefined, brand = undefined, model = undefined) => {
    setEquipment(equipment);
    setProvider(provider);
    setBrand(brand);
    setModel(model);

    const dataFiltered = data.filter(
      (data) => data.cliente_equipo.find((el) => el.cliente === parseInt(id))?.cliente && data.estado === 5,
    );
    setArrData(
      dataFiltered.filter((data) => {
        return (
          (!equipment || data.inv_tipo_equipo.descripcion === equipment) &&
          (!provider || data.inv_proveedor.descripcion === provider) &&
          (!brand || data.inv_marca.descripcion === brand) &&
          (!model || data.modelo === model)
        );
      }),
    );
  };

  const handleEquipment = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(item, provider, brand, model);
    } else {
      handleFilter(undefined, provider, brand, model);
    }
  };

  const handleProvider = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(equipment, item, brand, model);
    } else {
      handleFilter(equipment, undefined, brand, model);
    }
  };

  const handleBrand = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(equipment, provider, item, model);
    } else {
      handleFilter(equipment, provider, undefined, model);
    }
  };

  const handleModel = (item, reason) => {
    if (reason === "selectOption") {
      handleFilter(equipment, provider, brand, item);
    } else {
      handleFilter(equipment, provider, brand, undefined);
    }
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Asignar Equipos</h1>
          <BreadCrumb origin={"Servicio técnico"} current={"Asignación"} />
        </div>
        <EquipmentModal data={data} cliente_id={id} />
      </div>
      <div className="border-container calculated-height-assign overflow-y-auto">
        <div className="my-1 p-2 text-xl font-semibold">
          {params?.client} - {params?.rut}
        </div>
        <div className="mb-4 mt-2">
          {data && (
            <div className="grid grid-cols-5 gap-3">
              <Autocomplete
                key={"list"}
                disablePortal
                id="combo-box-demo"
                options={equipmentType}
                fullWidth
                renderInput={(params) => {
                  return <TextField {...params} label="Filtrar Tipo Equipo" size="small" />;
                }}
                onChange={(e, item, reason) => handleEquipment(item, reason)}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={brands}
                fullWidth
                onChange={(e, item, reason) => handleBrand(item, reason)}
                renderInput={(params, props) => (
                  <TextField {...params} key={props} label="Filtrar Marca" size="small" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={models}
                fullWidth
                onChange={(e, item, reason) => handleModel(item, reason)}
                renderInput={(params, idx) => <TextField {...params} key={idx} label="Filtrar Modelo" size="small" />}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={providers}
                fullWidth
                onChange={(e, item, reason) => handleProvider(item, reason)}
                renderInput={(params, idx) => (
                  <TextField {...params} key={idx} label="Filtrar Proveedor" size="small" />
                )}
              />
              <Button onClick={(e) => dispatch(setOpen({ open: true }))} variant="contained">
                Asignar Equipo
              </Button>
            </div>
          )}
        </div>
        {arrData.length ? (
          [...arrData]
            ?.filter(
              (data) => data.cliente_equipo.find((el) => el.cliente === parseInt(id))?.cliente && data.estado === 5,
            )
            .map((equipo, idx) => <AccordionEquipaments equipo={equipo} idx={idx} idCliente={id} />)
        ) : !success ? (
          <div className="w-full calculated-height-equipment flex justify-center items-center">
            <FacebookCircularProgress />
          </div>
        ) : (
          <div className="bg-slate-200 p-5 rounded text-center">No se encuentran equipos</div>
        )}
      </div>
    </div>
  );
}
