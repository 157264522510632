import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useForm, Controller } from "react-hook-form";
import { setError } from "../../redux/slices/roles";
import { useDispatch, useSelector } from "react-redux";
import { Chip, TextField } from "@mui/material";
import uploadFiles from "../../utils/UploadFiles";
import { registrarPago } from "../../redux/slices/cobranzas";
import FileUploader from "../../utils/FileUploader";
import Typography from "@mui/material/Typography";

export default function NotaDeCreditoModal({ handleDelete, params, name }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const [file, setFile] = React.useState({});

  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 380,
    borderRadius: 2,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setError(false));
    setFile({});
    reset();
  };
  const handleFileChange = (event) => setFile((prevState) => ({ ...prevState, contract: event.target.files[0] }));

  const submit = async (data) => {
    if (file?.contract?.name) {
      const uploaded = await uploadFiles("docs", file.contract);

      const formToSend = {
        ...data,
        id: params.row.id,
        facturacionId: params.row.facturacion.id,
        responsable: currentUser.id,
        f_compromiso: params.row.f_compromiso,
        comprobante: uploaded.data,
        observaciones: `Se anula factura con nota de crédito número: ${data.numero_nota_credito}`,
        obs_nota_credito: data.obs_nota_credito,
        numero_nota_credito: data.numero_nota_credito,
        monto_pago: params.row.saldo_pendiente,
        estado: true,
      };
      dispatch(registrarPago(formToSend));
    }
  };

  return (
    <div>
      <Chip color="warning" label="Registar" onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(submit)}>
            <div className="flex justify-center text-xl bg-[#2b2c84]/20 font-semibold p-2 mb-3">
              REGISTRAR NOTA DE CRÉDITO
            </div>
            <div className="flex items-center mb-3">
              <Controller
                name="numero_nota_credito"
                control={control}
                rules={{ required: "Campo requerido" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    error={errors.numero_nota_credito}
                    label={
                      errors.numero_nota_credito ? `* ${errors.numero_nota_credito.message}` : "Número Nota de Credito"
                    }
                    sx={{ height: 40 }}
                  />
                )}
              />
            </div>
            <div className="flex items-center mb-3">
              <Controller
                name="obs_nota_credito"
                control={control}
                rules={{ required: "Campo requerido" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    error={errors.obs_nota_credito}
                    label={errors.obs_nota_credito ? `* ${errors.obs_nota_credito.message}` : "Observación"}
                    sx={{ height: 40 }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col min-h-[92px]">
              <div className="flex flex-col w-full ">
                <FileUploader value={file} keyword={"contract"} to={"pdf"} handler={handleFileChange} size={"40px"} />
              </div>
              {Object.keys(file).length > 0 && (
                <div className="h-[40px] mt-3 border-[1px] border-[#bdbdbd] text-gray-500 w-full rounded flex justify-between items-center px-3">
                  {file?.contract.name.length < 30 ? file?.contract?.name : file.contract?.name.slice(0, 30) + "..."}
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <Typography id="modal-modal-description" sx={{ textAlign: "center", mt: 1, mb: 2 }}>
                ¿Seguro que deseas registrar nota de crédito?
              </Typography>
              <div className="flex justify-center">
                <Button sx={{ marginRight: 2 }} variant="contained" type="submit">
                  SI
                </Button>
                <Button variant="contained" onClick={handleClose}>
                  NO
                </Button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

//   return (
//     <div>
//       <Chip color="warning" label="Registar" onClick={handleOpen} />
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <form onSubmit={submit}>
//             <div className="">
//               <div className="flex justify-center text-xl bg-[#2b2c84]/20 font-semibold p-2 mb-5">
//                 REGISTRAR NOTA DE CREDITO
//               </div>
//               <div className="flex items-center">
//                 <div className="pr-2">* Número Nota de Credito:</div>
//                 <Controller
//                   name="numero_nota_credito"
//                   control={control}
//                   rules={{ required: "Campo requerido" }}
//                   render={({ field }) => (
//                     <TextField
//                       {...field}
//                       size="small"
//                       error={errors.numero_nota_credito}
//                       label={errors.numero_nota_credito ? `* ${errors.numero_nota_credito.message}` : ""}
//                       sx={{ height: 40 }}
//                     />
//                   )}
//                 />
//               </div>
//               <div className="w-full flex mt-5">
//                 <div className="w-[20%]">
//                   <Button variant="contained" component="label" size="small">
//                     Subir pdf
//                     <input
//                       hidden
//                       accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
//                       type="file"
//                       onChange={handleChangeFile}
//                     />
//                   </Button>
//                 </div>
//                 <div className="flex items-end w-[85%] ">
//                   {file.name ? (
//                     <div className="2 pl-2 max-w-[100%] flex-wrap overflow-hidden text-ellipsis">{file.name}</div>
//                   ) : (
//                     <div className="text-red-500 pl-2">
//                       <p> * Campo Requerido</p>
//                     </div>
//                   )}
//                 </div>
//               </div>
//               <div className="flex flex-col mt-5 bg-[#2b2c84]/20 p-5">
//                 <div className="text-xl flex justify-center">¿Seguro que deseas registrar nota de crédito?</div>
//                 <div className="mt-5 flex justify-around">
//                   <div>
//                     <Button variant="outlined" type="submit">
//                       SI
//                     </Button>
//                   </div>
//                   <div>
//                     <Button variant="outlined" onClick={handleClose}>
//                       NO
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </Box>
//       </Modal>
//     </div>
//   );
// }
