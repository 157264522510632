import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import InventoryEntry from "../components/InventoryEntry";
import ArticleAccordion from "../components/accordion/IncorporationAccordions/ArticlesAccordion";
import RepairPartsAccordion from "../components/accordion/IncorporationAccordions/RepairPartsAccordion";
import EquipmentAccordion from "../components/accordion/IncorporationAccordions/EquipmentAccordion";
import axiosInstance from "../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { setNotification } from "../redux/slices/notification";
import { useNavigate } from "react-router-dom";
import uploadFiles from "../utils/UploadFiles";

const InventoryIncorporationView = ({ data, setter }) => {
  const [inventoryEntry, setInventoryEntry] = useState({});
  const [equipos, setEquipos] = useState([]);
  const [repuestos, setRepuestos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    delete inventoryEntry.fecha_ingreso;
    delete inventoryEntry.numero_documento;
    delete inventoryEntry.proveedor;
    delete inventoryEntry.tipo_documento;

    if (inventoryEntry?.file) {
      const { data } = await uploadFiles("docs", inventoryEntry.file);
      inventoryEntry.file = data;
    }
    if (inventoryEntry?.factura) {
      const { data } = await uploadFiles("docs", inventoryEntry.factura);
      inventoryEntry.factura = data;
    }

    const res = await axiosInstance.put(`/inventory/incorporation?id=${data.id}`, { inventoryEntry });
    if (res.status === 201) {
      setLoading(false);
      dispatch(
        setNotification({
          status: "success",
          message: "Incorporacion creada exitosamente",
          open: true,
        }),
      );

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setLoading(false);
      dispatch(
        setNotification({
          status: "Error",
          message: "Error al crear incorporacion",
          open: true,
        }),
      );
    }
  };

  useEffect(() => {
    const newInvEquipo = data.inv_equipo.map((equipo) => ({
      ...equipo,
      inv_marca: equipo.inv_marca.descripcion,
      inv_tipo_equipo: equipo.inv_tipo_equipo.descripcion,
      inv_version: equipo.inv_version.descripcion,
      inv_estado: equipo.bodega ? equipo.bodega.name : equipo.inv_estado.descripcion,
    }));

    const newInvRepuesto = data.inv_repuesto_inv_incorporacion.map((equipo) => ({
      ...equipo.inv_repuesto,
      inv_marca: equipo.inv_repuesto.inv_marca.descripcion,
      inv_tipo_equipo: equipo?.inv_repuesto?.inv_tipo_equipo?.descripcion || "S/D",
      inv_estado: equipo.inv_repuesto.bodega
        ? equipo.inv_repuesto.bodega.name
        : equipo.inv_repuesto.inv_estado.descripcion,
      cantidad: equipo.cantidad,
    }));

    const newInvArticulo = data.inv_articulos_inv_incorporacion.map((equipo) => ({
      ...equipo.inv_articulos,
      cantidad: equipo.cantidad,
    }));

    setEquipos(newInvEquipo);
    setRepuestos(newInvRepuesto);
    setArticulos(newInvArticulo);
  }, [data]);

  return (
    <div>
      <div className="flex justify-between items-center border-gray p-0 sm:p-5 rounded">
        <div className="w-full">
          <div className="flex gap-4 items-center">
            <Button
              onClick={() =>
                setter({
                  status: false,
                  data: null,
                })
              }
              variant="contained"
            >
              Volver
            </Button>
            <h1 className="font-semibold text-[#1E293B] text-2xl">Incorporacion Inventario</h1>
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full my-5 flex justify-center rounded">
              <div className="w-full">
                <InventoryEntry view data={data} setIsDisabled={setIsDisabled} setInventoryEntry={setInventoryEntry} />
              </div>
            </div>
            <div className="w-full flex-col flex justify-center p-5 rounded">
              <div>
                <EquipmentAccordion edit equipos={equipos} setEquipos={setEquipos} />
              </div>
            </div>
            <div className=" flex-col w-full flex justify-center p-5 rounded">
              <RepairPartsAccordion repuestos={repuestos} edit setRepuestos={setRepuestos} />
            </div>
            <div className="w-full flex-col flex justify-center p-5 rounded">
              <ArticleAccordion edit articulos={articulos} setArticulos={setArticulos} />
            </div>
            <Button disabled={loading} sx={{ mt: 5 }} variant="contained" fullWidth onClick={handleSubmit}>
              Enviar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryIncorporationView;
