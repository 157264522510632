import { useDispatch, useSelector } from "react-redux";
import enviroments from "../../utils/enviroments";
import React, { useEffect, useRef, useState } from "react";
import { Chip, Divider, IconButton } from "@mui/material";
import { PDFDocument } from "pdf-lib";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import uploadFiles from "../../utils/UploadFiles";
import { handleCodeSuccess } from "../../redux/slices/remuneration";
import axiosInstance from "../../utils/axiosInstance";
import { Document, Page, pdfjs } from "react-pdf";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

function generateOTP() {
  const min = 100000;
  const max = 999999;
  const otp = Math.floor(Math.random() * (max - min + 1)) + min;
  return otp.toString();
}

const PortalSettlement = () => {
  const { portal } = useSelector((state) => state.remuneration);
  const { currentUser } = useSelector((state) => state.auth);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [realCode, setRealCode] = useState();
  const [activeOTPInput, setActiveOTPInput] = useState(false);
  const files = portal.Settlement;
  const [selectedFile, setSelectedFile] = useState({ id: null, fileName: null });
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePDF = (id, fileName) => {
    setSelectedFile({ id, fileName });
    setPageNumber(1);
  };

  const sendCode = async () => {
    try {
      setActiveOTPInput(true);
      const otpCode = generateOTP();
      setRealCode(otpCode);
      await axiosInstance.post("/remuneration/send-otp", { otp: otpCode });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSuccess = async () => {
    const pdf = await modifyPdf(`${enviroments.img_endpoint}${selectedFile.fileName}`);
    const blob = new Blob([pdf], { type: "application/pdf" });
    blob.name = selectedFile.fileName.split("-")[0];
    const { data } = await uploadFiles(null, blob);
    const res = await dispatch(handleCodeSuccess({ id: selectedFile.id, fileName: data }));
    setSelectedFile({ id: selectedFile.id, fileName: res.fileName });
  };

  useEffect(() => {
    if (otp.join("") === realCode) {
      setActiveOTPInput(false);
      setOtp(new Array(6).fill(""));
      handleSuccess();
    }
  }, [otp]);

  async function modifyPdf(url) {
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    firstPage.drawText(
      `Firmado por ${portal.nombre} el ${dayjs().format("DD-MM-YYYY")} a las ${dayjs().format("HH:mm:ss")} hrs`,
      {
        x: 50,
        y: 15,
        size: 13,
      }
    );

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  }

  async function modifyPdf(url) {
    // Load the existing PDF
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Load the user's signature image
    const imgUrl = `${enviroments.img_endpoint}${currentUser?.firma_usuario}`; // Using your image URL
    const imageBytes = await fetch(imgUrl).then((res) => res.arrayBuffer());
    const image = await pdfDoc.embedPng(imageBytes); // If the image is in PNG format

    // Set the image size
    const { width, height } = image.scale(0.3); // Adjust the scale as needed

    // Calculate the X position to place the image on the right side
    const pageWidth = firstPage.getWidth();
    const imageX = pageWidth - width - 50; // 50px margin from the right edge
    const imageY = 50; // Y position on the page

    // Draw the image on the first page
    firstPage.drawImage(image, {
      x: imageX,
      y: imageY,
      width: width,
      height: height,
    });

    // Add the signature text to the right of the image
    const textX = imageX; // Align text with image horizontally
    const textY = imageY - 20; // Position text slightly below the image
    const signatureText = `${dayjs().format("DD-MM-YYYY")} a las ${dayjs().format("HH:mm:ss")} hrs`;

    firstPage.drawText(signatureText, {
      x: textX,
      y: textY,
      size: 13,
    });

    // Save the modified PDF
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  }

  return (
    <div className="flex gap-5">
      <div className=" pr-2 rounded w-[20%] max-h-[80vh] overflow-auto relative">
        {files.length ? (
          files.map((file) => {
            return (
              <div key={file.id}>
                <div
                  id={file.id}
                  onClick={() => handlePDF(file.id, file.fileName)}
                  className={`${
                    selectedFile.id === file.id ? "bg-blue-200" : undefined
                  } rounded transition-all flex cursor-pointer hover:bg-neutral-100 p-2 border-l-[3px] bg-neutral-100/50 border-l-blue-950 items-center w-full justify-between px-2`}
                >
                  <div>
                    <div className="uppercase pointer-events-none line-clamp-1 w-full text-neutral-700 text-sm ml-1 tracking-wider">
                      Liquidación
                    </div>
                    <div className="uppercase pointer-events-none w-full text-neutral-700 text-sm ml-1 tracking-wider">{`${
                      months[file.month].label
                    } - ${file.year}`}</div>
                  </div>
                  <Chip color={file.signed ? "success" : "error"} label={file.signed ? "FIRMADO" : "SIN FIRMAR"} />
                </div>
                <Divider sx={{ my: 1 }} />
              </div>
            );
          })
        ) : (
          <div className="text-neutral-500 w-full text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            No se encontraron liquidaciones
          </div>
        )}
      </div>
      <div className="w-[80%] h-[80vh] object-contain ">
        {selectedFile?.fileName ? (
          <div className="relative z-20  min-h-[80vh] max-h-[80vh] w-full flex justify-center items-center bg-[#58545c] rounded">
            <Document
              loading="Cargando"
              noData="Cargando.."
              onLoadSuccess={onDocumentLoadSuccess}
              className={"max-h-[78vh] overflow-y-auto overflow-x-hidden rounded"}
              file={`${enviroments.img_endpoint}${selectedFile.fileName}`}
            >
              <Page
                width={600}
                noData="No hay datos"
                loading={"Cargando.."}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={pageNumber}
              />
            </Document>
            <div className="absolute bottom-5 bg-[#58545c] rounded left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex text-white items-center gap-5 px-2">
                <IconButton
                  color="inherit"
                  disabled={pageNumber <= 1}
                  onClick={() => {
                    setPageNumber((prev) => prev - 1);
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <span>{pageNumber}</span>
                <IconButton
                  color="inherit"
                  disabled={pageNumber >= numPages}
                  onClick={() => {
                    setPageNumber((prev) => prev + 1);
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </div>
            {!files.find((el) => el.id === selectedFile.id)?.signed ? (
              <Chip
                onClick={sendCode}
                color="success"
                label="Enviar Solicitud"
                disabled={!currentUser.firma_usuario}
                sx={{ position: "absolute", right: 40, top: 15, px: 2 }}
              />
            ) : (
              <a
                href={`${enviroments.img_endpoint}${selectedFile.fileName}`}
                target="_blank"
                rel="noreferrer noopener"
                download="proposed_file_name"
                className="cursor-pointer absolute top-4 right-12 px-4 bg-red-400 rounded-full text-white py-2"
              >
                Descargar PDF
              </a>
            )}
            <AnimatePresence>
              <motion.div
                className="box"
                variants={variants}
                initial="initial"
                animate={activeOTPInput ? "animate" : "initial"}
              >
                <InputOTP otp={otp} setOtp={setOtp} />
              </motion.div>
            </AnimatePresence>
          </div>
        ) : (
          <div className="bg-[#58545c] w-full h-full rounded"></div>
        )}
      </div>
    </div>
  );
};

let currentOtpIndex;
const InputOTP = ({ otp, setOtp }) => {
  const [activeOtp, setActiveOTP] = useState(0);
  const inputRef = useRef(null);

  const handleChange = ({ target }) => {
    const { value } = target;
    const newOTP = [...otp];
    newOTP[currentOtpIndex] = value.substring(value.length - 1);
    if (!value) setActiveOTP(currentOtpIndex - 1);
    else setActiveOTP(currentOtpIndex + 1);
    setOtp(newOTP);
  };

  const handleOnKeyDown = ({ key }, index) => {
    currentOtpIndex = index;
    if (key === "Backspace") {
      setActiveOTP(currentOtpIndex - 1);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtp]);

  return (
    <div className="absolute min-w-[354px] top-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-neutral-200 border-[1px] shadow rounded p-2">
      <div className="text-center text-neutral-500 mb-1">Codigo Recibido via Email</div>
      {otp.map((_, index) => {
        return (
          <React.Fragment key={index}>
            <input
              ref={index === activeOtp ? inputRef : null}
              key={index}
              type="number"
              onChange={(e) => handleChange(e)}
              value={otp[index]}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              className="w-12 h-12 border-[1px] mx-1 rounded bg-white outline-none text-center font-semibold text-xl border-[#2B2C84]/70 focus:border-[#2B2C84] focus:text-[#2B2C84] text-neutral-400 transition spin-button-none"
            />
            {index === otp.length - 1 ? null : <span className="w-2 py-0.5 bg-gray-400" />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const months = [
  { id: 0, label: "Enero" },
  { id: 1, label: "Febrero" },
  { id: 2, label: "Marzo" },
  { id: 3, label: "Abril" },
  { id: 4, label: "Mayo" },
  { id: 5, label: "Junio" },
  { id: 6, label: "Julio" },
  { id: 7, label: "Agosto" },
  { id: 8, label: "Septiembre" },
  { id: 9, label: "Octubre" },
  { id: 10, label: "Noviembre" },
  { id: 11, label: "Diciembre" },
];

export default PortalSettlement;
