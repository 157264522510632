import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";

const initialState = {
  data: [],
  success: false,
};

const communaSlice = createSlice({
  name: "communa",
  initialState,
  reducers: {
    setCommuna: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllComunas = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/comuna");
    dispatch(setCommuna(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const { setCommuna, addCommuna, setSuccess } = communaSlice.actions;

export default communaSlice.reducer;
