import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { updateOtType } from "../../redux/slices/workOrders";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function OtTypeEditModal({ open, data, setOpen }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const updateOtTypes = async (value) => {
    dispatch(updateOtType({ ...value, id: data.id }));
    setOpen({ status: false, data: null });
    reset();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(updateOtTypes)}>
                <div className="flex flex-col">
                  <div className="w-full mr-5">
                    <div className={`flex mb-5`}>
                      <TextField
                        fullWidth
                        id={"descripcion"}
                        label={"Descripcion"}
                        type={"text"}
                        {...register("descripcion", { required: true })}
                        error={Boolean(errors["descripcion"])}
                        helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                        defaultValue={data.descripcion}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center w-full justify-center">
                  <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Enviar formulario
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
