import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useConstantContactMeans(data) {
  const { id } = useSelector((state) => state.editModal);
  const [editValues, setEditValues] = useState({});

  useEffect(() => {
    if (id && data.length) {
      const filtered = data.filter((contactMean) => contactMean.id === id);
      setEditValues(filtered[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data.length, data]);

  const fields = [
    {
      id: "descripcion",
      label: "Nombre de medio de contacto",
      type: "text",
      value: editValues?.descripcion || "",
      required: true,
    },
  ];

  const element = {
    button: "Agregar medio de contacto",
  };

  return [fields, element];
}
