import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import CustomSelectMonthYear from "../components/CustomSelectMonthYear";
import { getFacturacionByDate, setFacturacion } from "../redux/slices/facturacion";
import { useEffect, useState } from "react";
import ReporteFacturacion from "../components/ReporteFacturacion";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import thousandSeparator from "../utils/thousandSeparator";

export default function InformeFacturacion() {
  const { facturacion, success } = useSelector((state) => state.facturacion);
  const [loading, setLoading] = useState(false);
  const [arrayToRender, setArrayToRender] = useState([]);
  const [searched, setSearched] = useState(false);
  const dispatch = useDispatch();

  const getReport = (values) => {
    setSearched(true);
    setLoading(true);
    dispatch(getFacturacionByDate(values)).then(() => setLoading(false));
  };

  useEffect(() => {
    return () => dispatch(setFacturacion([]));
  }, [dispatch]);

  const permitedRenderDataColumn = [
    { title: "ID", value: "id", hide: true },
    { title: "Nro Factura.", value: "n_factura" },
    { title: "Cliente", value: "nombre_cliente" },
    { title: "Fecha", value: "fecha" },
    { title: "Neto", value: "neto" },
    { title: "IVA", value: "iva" },
    { title: "Monto", value: "monto" },
    { title: "Nro OC.", value: "nro_oc" },
    { title: "Detalle", value: "detalle" },
    { title: "Descripcion", value: "item_name" },
    { title: "Modelo", value: "modelo_name" },
    { title: "Serie", value: "serie_name" },
    { title: "Marca", value: "marca_name" },
    { title: "Cantidad", value: "cantidad" },
  ];

  const handleDelete = () => {
    console.log("delete");
  };

  useEffect(() => {
    const array = [];
    let id = 0;
    const cobranza = [...facturacion];
    // console.log(facturacion);

    if (searched) {
      if (cobranza.length) {
        cobranza.map((el, idx) => {
          let data = {
            nombre_cliente: el.facturacion.cliente_facturacion_clienteTocliente.nombre,
            n_factura: el?.n_factura || "S/D",
            nro_oc: el.facturacion.nro_oc,
            fecha: dayjs(el.f_facturacion).format("DD/MM/YYYY"),
            monto: "$" + Math.round(el.monto).toLocaleString(), // TIENE IVA
            iva: "$" + Math.round((el.monto / 1.19) * 0.19).toLocaleString(), // IVA
            neto: "$" + Math.round(el.monto / 1.19).toLocaleString(), // SIN IVA
          };

          // ARTICULOS
          if (el.facturacion.facturacion_articulos.length) {
            el.facturacion.facturacion_articulos.forEach((item) => {
              id = id + 1;
              array.push({
                ...data,
                item_name: item.inv_articulos.nombre,
                cantidad: item.cantidad || 1,
                modelo_name: "S/D",
                serie_name: "S/D",
                marca_name: "S/D",
                id: id,
                detalle: "Insumo",
              });
            });
          }

          // REPUESTOS
          if (el.facturacion.facturacion_repuestos.length) {
            el.facturacion.facturacion_repuestos.forEach((item) => {
              id = id + 1;
              array.push({
                ...data,
                item_name: item.inv_repuesto.descripcion,
                modelo_name: item.inv_repuesto.modelo,
                serie_name: item.inv_repuesto.serie,
                marca_name: "S/D",
                detalle: "Repuesto",
                cantidad: item.cantidad || 1,
                id: id,
              });
            });
          }

          // EQUIPOS
          if (el.facturacion.facturacion_equipo.length) {
            el.facturacion.facturacion_equipo.forEach((item) => {
              id = id + 1;
              array.push({
                ...data,
                item_name: item.inv_equipo?.inv_tipo_equipo?.descripcion,
                modelo_name: item.inv_equipo.modelo,
                serie_name: item.inv_equipo.serie,
                marca_name: item.inv_equipo.inv_marca.descripcion,
                detalle: "Equipo",
                cantidad: 1,
                id: id,
              });
            });
          }

          // SERVICIOS
          if (el.facturacion.facturacion_servicios.length) {
            el.facturacion.facturacion_servicios.forEach((item) => {
              id = id + 1;
              array.push({
                ...data,
                item_name: item.servicios.nombre,
                cantidad: item.cantidad || 1,
                detalle: "Servicio",
                modelo_name: "S/D",
                serie_name: "S/D",
                marca_name: "S/D",
                id: id,
              });
            });
          }
        });
      }
    }

    // if (searched) {
    //   if (facturacion.length) {
    //     facturacion.map((el) => {
    //       if (!el.cobranza.length) {
    //         let data = {
    //           nombre_cliente: el.cliente_facturacion_clienteTocliente.nombre,
    //           n_factura: el.n_factura ? String(el.n_factura) : "S/D",
    //           nro_oc: el.nro_oc,
    //           monto: "$ " + el.monto.toLocaleString(),
    //           fecha: dayjs(el.fecha_oc).format("DD/MM/YYYY"),
    //         };
    //         if (el.facturacion_articulos.length) {
    //           el.facturacion_articulos.forEach((item) => {
    //             id = id + 1;
    //             array.push({
    //               ...data,
    //               item_name: item.inv_articulos.nombre,
    //               cantidad: item.cantidad,
    //               modelo_name: "S/D",
    //               serie_name: "S/D",
    //               marca_name: "S/D",
    //               cantidad: 1,
    //               id: id,
    //               detalle: "Insumo",
    //             });
    //           });
    //         }
    //         if (el.facturacion_repuestos.length) {
    //           el.facturacion_repuestos.forEach((item) => {
    //             id = id + 1;
    //             array.push({
    //               ...data,
    //               item_name: item.inv_repuesto.descripcion,
    //               modelo_name: item.inv_repuesto.modelo,
    //               serie_name: item.inv_repuesto.serie,
    //               marca_name: "S/D",
    //               detalle: "Repuesto",
    //               cantidad: 1,
    //               id: id,
    //             });
    //           });
    //         }
    //         if (el.facturacion_equipo.length) {
    //           el.facturacion_equipo.forEach((item) => {
    //             id = id + 1;
    //             array.push({
    //               ...data,
    //               item_name: item.inv_equipo?.inv_tipo_equipo?.descripcion,
    //               modelo_name: item.inv_equipo.modelo,
    //               serie_name: item.inv_equipo.serie,
    //               marca_name: item.inv_equipo.inv_marca.descripcion,
    //               detalle: "Equipo",
    //               cantidad: 1,
    //               id: id,
    //             });
    //           });
    //         }
    //         if (el.facturacion_servicios.length) {
    //           el.facturacion_servicios.forEach((item) => {
    //             id = id + 1;
    //             array.push({
    //               ...data,
    //               item_name: item.servicios.nombre,
    //               cantidad: item.cantidad,
    //               detalle: "Servicio",
    //               modelo_name: "S/D",
    //               serie_name: "S/D",
    //               marca_name: "S/D",
    //               id: id,
    //               cantidad: 1,
    //             });
    //           });
    //         }
    //       } else {
    //         el.cobranza.map((cobranza) => {
    //           let data = {
    //             nombre_cliente: el.cliente_facturacion_clienteTocliente.nombre,
    //             n_factura: cobranza.n_factura,
    //             fecha: dayjs(cobranza.f_facturacion).format("DD/MM/YYYY"),
    //             nro_oc: el.nro_oc,
    //             monto: "$ " + cobranza.monto.toLocaleString(),
    //           };
    //           if (el.facturacion_articulos.length) {
    //             el.facturacion_articulos.forEach((item) => {
    //               id = id + 1;
    //               array.push({
    //                 ...data,
    //                 item_name: item.inv_articulos.nombre,
    //                 cantidad: item.cantidad,
    //                 modelo_name: "S/D",
    //                 serie_name: "S/D",
    //                 marca_name: "S/D",
    //                 id: id,
    //                 detalle: "Insumo",
    //                 cantidad: 1,
    //               });
    //             });
    //           }
    //           if (el.facturacion_repuestos.length) {
    //             el.facturacion_repuestos.forEach((item) => {
    //               id = id + 1;
    //               array.push({
    //                 ...data,
    //                 item_name: item.inv_repuesto.descripcion,
    //                 modelo_name: item.inv_repuesto.modelo,
    //                 serie_name: item.inv_repuesto.serie,
    //                 marca_name: "S/D",
    //                 detalle: "Repuesto",
    //                 id: id,
    //                 cantidad: 1,
    //               });
    //             });
    //           }
    //           if (el.facturacion_equipo.length) {
    //             el.facturacion_equipo.forEach((item) => {
    //               id = id + 1;
    //               array.push({
    //                 ...data,
    //                 item_name: item.inv_equipo?.inv_tipo_equipo?.descripcion,
    //                 modelo_name: item.inv_equipo.modelo,
    //                 serie_name: item.inv_equipo.serie,
    //                 marca_name: item.inv_equipo.inv_marca.descripcion,
    //                 detalle: "Equipo",
    //                 id: id,
    //                 cantidad: 1,
    //               });
    //             });
    //           }
    //           if (el.facturacion_servicios.length) {
    //             el.facturacion_servicios.forEach((item) => {
    //               id = id + 1;
    //               array.push({
    //                 ...data,
    //                 item_name: item.servicios.nombre,
    //                 cantidad: item.cantidad,
    //                 detalle: "Servicio",
    //                 modelo_name: "S/D",
    //                 serie_name: "S/D",
    //                 marca_name: "S/D",
    //                 id: id,
    //               });
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
    // }
    setArrayToRender(array);
  }, [facturacion]);

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "TIPO DE RAZON SOCIAL");

  return (
    <div>
      <div className="grid grid-cols-2 justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Facturación</h1>
          <BreadCrumb origin={"Informes Cobranzas"} current={"Facturación"} />
        </div>
        <div>
          <CustomSelectMonthYear handleSubmitFunction={getReport} />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="h-[calc(100vh_-_65px_-_32px_-_80px_-_24px)] flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <DataGridComponent columns={columns} rows={arrayToRender} success={success} />
        )}
      </div>
    </div>
  );
}
