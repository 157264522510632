import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useConstantRoles() {
  const { id } = useSelector((state) => state.editModal);
  const [editValues, setEditValues] = useState({});
  const { data } = useSelector((state) => state.roles);
  const [actived, setActived] = useState({});

  useEffect(() => {
    if (id && data.length) {
      const filtered = data.filter((role) => role.id === id);
      setEditValues(filtered[0]);
      setActived(filtered[0].gen_permiso_rol);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data, data.length]);

  const fields = [
    {
      id: "role_name",
      label: "Nombre del rol",
      type: "text",
      required: true,
      value: editValues?.descripcion || "",
    },
  ];

  return [fields, actived];
}
