import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import formatDataModal from "../../../utils/formatDataModal";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  success: false,
};

const contactMeansSlice = createSlice({
  name: "contactMeans",
  initialState,
  reducers: {
    setContactMeans: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    addContactMean: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateContactMean: (state, action) => {
      const updated = state.data.filter((contactMean) => {
        return contactMean.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveContactMean: (state, action) => {
      const updated = state.data.filter((contactMean) => {
        return contactMean.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllContactMeans = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/medio-contacto");
    const filtrado = res.data.filter((contactMean) => contactMean.estado === true);
    const contactMeans = filtrado.map((contactMean) => ({
      ...contactMean,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: contactMean.f_creacion.split("T")[0],
    }));
    dispatch(setContactMeans(contactMeans));
  } catch (error) {
    console.log(error);
  }
};

export const createNewContactMean = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/medio-contacto", formatedValues);
    const contactMean = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };
    dispatch(addContactMean(contactMean));
    dispatch(
      setNotification({
        status: "success",
        message: "Medio de contacto creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear medio de contacto",
        open: true,
      })
    );
    return error;
  }
};

export const updateContactMean = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/medio-contacto", formatedValues);

    const contactMean = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };

    dispatch(setUpdateContactMean(contactMean));
    dispatch(
      setNotification({
        status: "success",
        message: "Medio de editado creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar medio de contacto",
        open: true,
      })
    );
    return console.log(error);
  }
};

export const deleteContactMean = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/medio-contacto/${id}`);
    dispatch(setRemoveContactMean(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Medio de contacto eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.message,
        open: true,
      })
    );
  }
};

export const { setContactMeans, addContactMean, setUpdateContactMean, setRemoveContactMean, setSuccess } =
  contactMeansSlice.actions;

export default contactMeansSlice.reducer;
