import React from "react";
import useConstantContactMeans from "../components/modal/constants/useConstantContactMeans";
import TransitionsModal from "../components/modal/TransitionsModal";
import contactMeansSchema from "../resolvers/contact_means.resolver";
import { createNewContactMean, deleteContactMean } from "../redux/slices/contactMeans";
import { getAllContactMeans } from "../redux/slices/contactMeans";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import EditModal from "../components/modal/EditModal";
import { updateContactMean } from "../redux/slices/contactMeans";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Fecha de creacion", value: "f_creacion" },
  { title: "Acciones", value: "acciones" },
];

export default function BusinessName() {
  const { data, success } = useSelector((state) => state.contactMeans);
  const [fields, element] = useConstantContactMeans(data);
  const { id } = useSelector((state) => state.editModal);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteContactMean(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "MEDIO DE CONTACTO");

  const newContactMeans = async (values) => {
    dispatch(createNewContactMean(values));
    dispatch(setOpen(false));
  };

  const handleUpdate = async (values) => {
    dispatch(updateContactMean({ ...values, id }));
    dispatch(setOpenEdit({ open: false }));
  };

  useEffect(() => {
    dispatch(getAllContactMeans());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.length]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Medios de contacto</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Medio Contacto"} />
        </div>
        <div>
          <TransitionsModal
            data={element}
            fields={fields}
            schema={contactMeansSchema}
            submit={newContactMeans}
            type={"create"}
            size="500px"
          />
          <EditModal fields={fields} schema={contactMeansSchema} submit={handleUpdate} type={"edit"} size="500px" />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={data} success={success} />
    </div>
  );
}
