import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col items-center ">
        <div className="flex flex-col items-center ">
          <div className="flex flex-col items-center  text-image rounded-lg p-20">
            <div className="font-semibold text-9xl text-white">404</div>
            <div className="text-white text-xl">No se encuentra la ruta</div>
            <div className="mt-2 font-semibold text-white text-xl">{location.pathname}</div>
          </div>
          <Button sx={{ marginTop: 5 }} fullWidth variant="contained" onClick={() => navigate("/")}>
            Volver
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
