import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import dayjs from "dayjs";

const initialState = {
  data: [],
  clienteProduct: [],
  maintenances: [],
  openMaintenance: false,
  maintenance: null,
  success: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    setProductClient: (state, action) => {
      state.clienteProduct = action.payload;
    },
    setMaintenances: (state, action) => {
      state.success = true;
      state.maintenances = action.payload;
    },
    setOpenMaintenance: (state, action) => {
      state.openMaintenance = action.payload.openMaintenance;
      state.maintenance = action.payload.maintenance;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllProducts = () => async (dispatch) => {
  try {
    setSuccess(false);
    const res = await axiosInstance.get("/equipo");
    dispatch(setProducts(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getProductCliente = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/equipo/cliente/${id}`);
    dispatch(setProductClient(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const addProduct = (equipo, cliente) => async (dispatch) => {
  try {
    await axiosInstance.post("/equipo", { equipo, cliente });
  } catch (error) {
    console.log(error);
  }
};

export const addMultiples = (values) => async (dispatch) => {
  try {
    const { status } = await axiosInstance.post("/equipo/multiple", values);
    return status;
  } catch (error) {
    console.log(error);
  }
};

export const removeProduct = (idDelete) => async (dispatch) => {
  try {
    await axiosInstance.delete(`/equipo/${idDelete}`);
  } catch (error) {
    console.log(error);
  }
};

export const editProduct = (values) => async (dispatch) => {
  await axiosInstance.put("/equipo", values);
};

export const removeOtProgramation = (id) => async (dispatch) => {
  await axiosInstance.delete(`/ot/removeOtProgramation/${id}`);
};

export const getAllMaintenances = () => async (dispatch) => {
  try {
    setSuccess(false);

    const res = await axiosInstance.get("/equipo/mantencion");

    const renderData = res.data.map((data, index) => {
      const maintenanceDates = data.inv_equipo.ot_programacion.map((date) => date.f_programacion);
      const closestMaintenance = maintenanceDates
        .filter((date) => dayjs(date.slice(0, -2)).isAfter(dayjs()) || dayjs(date.slice(0, -2)).isSame(dayjs()))
        .sort((a, b) => dayjs(a.slice(0, -2)).diff(dayjs()) - dayjs(b.slice(0, -2)).diff(dayjs()))
        .shift();

      return {
        ...data,
        descripcion: data.inv_equipo.descripcion,
        serie: data.inv_equipo.serie,
        modelo: data.inv_equipo.modelo,
        cliente: data.cliente_cliente_equipo_clienteTocliente.nombre,
        mantenciones: data.inv_equipo.ot_programacion.length,
        closestMaintenance:
          closestMaintenance !== undefined && dayjs(closestMaintenance?.slice(0, -2)).format("DD/MM/YYYY"),
      };
    });
    dispatch(setMaintenances(renderData));
  } catch (error) {
    console.log(error);
  }
};

export const { setProducts, setProductClient, setMaintenances, setOpenMaintenance, setSuccess } = productsSlice.actions;

export default productsSlice.reducer;
