import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  permissions: [],
  success: false,
  error: false,
};

const roleSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
      state.success = true;
    },
    addRole: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateRole: (state, action) => {
      const updated = state.data.filter((role) => {
        return role.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const getAllRoles = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/rol");
    const roles = res.data.map((role) => ({
      ...role,
      estado: role.estado === true ? "Activo" : "Inactivo",
      f_creacion: role.f_creacion.split("T")[0],
    }));
    dispatch(setRole(roles));
  } catch (error) {
    console.log(error);
  }
};
export const deleteRole = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put(`/rol/delete/${id}`);
    const roles = res.data.map((role) => ({
      ...role,
      estado: role.estado === true ? "Activo" : "Inactivo",
      f_creacion: role.f_creacion.split("T")[0],
    }));
    dispatch(setRole(roles));
    dispatch(
      setNotification({
        status: "success",
        message: "Rol borrado con exito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(setSuccess(false));
    if (error.response.data.error === "Hay usuarios asociados a ese rol") {
      dispatch(setError(true));
      dispatch(setSuccess(true));
    } else {
      dispatch(
        setNotification({
          status: "error",
          message: "Error al eliminar este rol",
          open: true,
        }),
      );
    }
  }
};

export const getAllPermissions = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/rol/permissions");
    dispatch(setPermissions(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const createNewRol = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("rol/create", values);
    const newRol = {
      ...res.data,
      estado: res.data.estado === true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };

    dispatch(addRole(newRol));
    dispatch(
      setNotification({
        status: "success",
        message: "Rol creado con éxito",
        open: true,
      }),
    );

    return newRol;
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear rol",
        open: true,
      }),
    );
    console.log(error);
  }
};

export const updateRole = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put(`rol/update/${values.id}`, values);
    const updatedRol = {
      ...res.data,
      estado: res.data.estado === true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };

    dispatch(setUpdateRole(updatedRol));
    dispatch(
      setNotification({
        status: "success",
        message: "Rol editado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar rol",
        open: true,
      }),
    );
    console.log(error);
  }
};

export const { setRole, setPermissions, addRole, setUpdateRole, setSuccess, setError } = roleSlice.actions;

export default roleSlice.reducer;
