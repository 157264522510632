import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import RolesForm from "../form/RolesForm";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/editModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

export default function EditRoleModal({ submit, fields, permissions, type, activedPermissions }) {
  const { open, id } = useSelector((state) => state.editModal);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setOpen({ open: false }));
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {id && permissions && activedPermissions && (
              <RolesForm
                key={JSON.stringify(activedPermissions)}
                submit={submit}
                fields={fields}
                permissions={permissions}
                type={type}
                activedPermissions={activedPermissions}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
