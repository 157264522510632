import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { updateNewBrand } from "../../redux/slices/inventory";
import { Controller } from "react-hook-form";
import { updateServices } from "../../redux/slices/services";
import { updateGanancias } from "../../redux/slices/ganancias";
import { updateRiesgo } from "../../redux/slices/riesgo";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function RiesgoUpdate({ open, data, setOpen }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const handle_submit = async (value) => {
    dispatch(updateRiesgo({ ...value, id: data.id }));
    reset();
    setOpen({ status: false, data: null });
  };

  React.useEffect(() => {
    if (data?.id) {
      reset({
        name: data.name,
        desde: data.desde,
        hasta: data.hasta,
      });
    }
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(handle_submit)}>
                <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">
                  EDITAR PORCENTAJE
                </div>
                <div className="min-h-[65px] mb-1">
                  <Controller
                    id="name"
                    name="name"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Nombre"
                        disabled
                        onChange={onChange}
                        size="small"
                        value={value || ""}
                        error={errors.nombre}
                        helperText={errors.nombre?.message}
                      />
                    )}
                  />
                </div>
                <div className="min-h-[65px]">
                  <Controller
                    id="desde"
                    name="desde"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Desde"
                        onChange={onChange}
                        size="small"
                        value={value || ""}
                        error={errors.desde}
                        helperText={errors.codigo?.desde}
                      />
                    )}
                  />
                </div>
                <div className="min-h-[65px]">
                  <Controller
                    id="hasta"
                    name="hasta"
                    control={control}
                    rules={{ required: "* Campo requerido" }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label="Hasta"
                        onChange={onChange}
                        size="small"
                        value={value || ""}
                        error={errors.hasta}
                        helperText={errors.codigo?.hasta}
                      />
                    )}
                  />
                </div>
                <div className="flex items-center w-full justify-between">
                  <Button type="submit" variant="outlined" fullWidth>
                    Enviar formulario
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
