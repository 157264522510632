import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Avatar from "@mui/material/Avatar";
import enviroments from "../../utils/enviroments";

export default function NewForm({ fields, schema, submit, type, client, img, image, defaultImage, setParentRuts }) {
  const [children, setChildren] = useState(client && fields.find((field) => field.id === "hijo").value);
  const [defaultRegion, setDefaultRegion] = useState(fields.filter((field) => field.id === "region")[0]?.value);
  const [defaultComuna, setDefaultComuna] = useState(fields.filter((field) => field.id === "comuna")[0]?.value);
  const { data: regionData } = useSelector((state) => state.regions);
  const [comunas, setComunas] = useState({ id: 0, value: 0 });
  const [changed, setChanged] = useState(false);
  const [url, setUrl] = useState();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: { ...(client && { hijo: fields.find((field) => field.id === "hijo").value }) },
  });

  const { data } = useSelector((state) => state.clients);
  const region = watch("region");

  useEffect(() => {
    if (client && !children) {
      setValue("padre", null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    if (regionData) {
      const comunasByRegion = regionData
        .filter((regions) => regions.id === region?.value ?? defaultRegion)
        .flatMap((regions) => regions.gen_provincia)
        .filter((provincia) => provincia.gen_comuna)
        .flatMap((provincia) => provincia.gen_comuna);

      const comunasArr = comunasByRegion.map((comuna) => ({
        ...comuna,
        label: comuna.descripcion,
        value: comuna.id,
      }));
      comunasArr.sort((a, b) => a.label.localeCompare(b.label));
      setComunas(comunasArr);

      if (region?.value) {
        if (changed && defaultRegion === region.value) {
          setValue("comuna", comunasArr[comunasArr.findIndex((comuna) => comuna.id === defaultComuna)]);
        } else if (defaultRegion !== region.value) {
          setChanged(true);
          setValue("comuna", comunasArr[0]);
        }
      }
    }
  }, [regionData, region]);

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    image(e.target.files[0]);
  };

  const handleRegion = (e) => {
    if (e.target.name === "hijo") {
      setChildren(e.target.value);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex  overflow-y-auto xl:overflow-visible max-h-[70vh] flex-col justify-center xl:flex-row"
    >
      {img === true && (
        <div className="xl:w-[25%] xl:h-[70vh] flex xl:flex-col justify-center items-center min-h-[20vh]">
          <div className="mr-10 xl:mr-0">
            <Avatar
              src={(url && url) || (defaultImage && `${enviroments.img_endpoint}${defaultImage}`)}
              sx={{ height: 150, width: 150, marginBottom: 2 }}
            />
          </div>
          <label htmlFor="file-upload" className="custom-file-upload">
            <FileUploadIcon sx={{ mr: 1 }} />
            Subir Imagen
          </label>
          <input id="file-upload" type="file" accept="image/*" onChange={uploadImage} />
        </div>
      )}
      <div className="xl:w-[90%] flex flex-col justify-center overflow-y-auto xl:overflow-visible">
        <div className="flex flex-col xl:flex-row xl:flex-wrap items-center xl:justify-start  overflow-y-auto  pt-6 xl:pt-2">
          {fields.length > 1 &&
            fields.map((field) =>
              field.type === "text" || field.type === "password" || field.type === "checkbox" ? (
                <div key={field.id} className="xl:w-[50%] w-[80%] px-1">
                  {field.type !== "checkbox" ? (
                    <TextField
                      fullWidth
                      id={field.id}
                      label={field.label}
                      type={field.type}
                      disabled={field?.disabled && type === "edit" ? true : false}
                      {...register(`${field.id}`)}
                      helperText={errors[field.id]?.message || " "}
                      error={Boolean(errors[field.id]?.message)}
                      onChange={field.handler ?? field.handler}
                      defaultValue={type === "edit" ? field.value : ""}
                      autoComplete="off"
                    />
                  ) : (
                    <div className="flex items-center">
                      <Controller
                        control={control}
                        name={field.id}
                        defaultValue={field.value}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox size="small" checked={value} onChange={(event) => onChange(event.target.checked)} />
                        )}
                      />
                      <div className="ml-1">{field.label}</div>
                    </div>
                  )}
                </div>
              ) : field.id !== "padre" && field.id !== "hijo" ? (
                <div key={field.id} className="xl:w-[50%] w-[80%] px-1">
                  <Controller
                    id={field.id}
                    control={control}
                    defaultValue={
                      type === "edit"
                        ? field.options?.filter((option) =>
                            typeof option.value === "boolean" ? option.value === field.value : option.id === field.value
                          )[0]
                        : { label: "" }
                    }
                    name={field.id}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        freeSolo
                        options={field.id === "comuna" ? comunas : field.options}
                        getOptionLabel={(item) => item.label}
                        onChange={(event, item) => onChange(item)}
                        value={value || { label: "" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={errors[field.id]?.message || errors[field.id]?.label.message || " "}
                            error={!!Boolean(errors[field.id])}
                            label={field.label}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </div>
              ) : field.id === "hijo" ? (
                <div key={field.id} className="xl:w-[50%] w-[80%] px-1">
                  <TextField
                    fullWidth
                    id={field.id}
                    label={field.label}
                    {...register(`${field.id}`)}
                    helperText={errors[field.id]?.message || " "}
                    error={Boolean(errors[field.id]?.message) || ""}
                    select
                    defaultValue={type === "edit" ? field.value : ""}
                    onChange={handleRegion}
                  >
                    {field.options?.map((option) => (
                      <MenuItem key={option.id} value={option.value ?? ""}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ) : (
                children && (
                  <div key={field.id} className="xl:w-[50%] w-[80%] px-1">
                    <TextField
                      fullWidth
                      {...register(`${field.id}`)}
                      select
                      id={field.id}
                      required={children}
                      onChange={(e) => setParentRuts && setParentRuts(e.target.value)}
                      error={Boolean(errors[field.id]?.message)}
                      helperText={errors[field.id]?.message || " "}
                      label={field.label}
                      defaultValue={type === "edit" ? field.value : ""}
                    >
                      {field.options?.map((option, index) => (
                        <MenuItem key={index} value={option.id ?? ""}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )
              )
            )}
          {fields.length === 1 &&
            fields.map((field) => (
              <TextField
                fullWidth
                sx={{ marginTop: 2 }}
                id={field.id}
                label={field.label}
                type={field.type}
                disabled={field?.disabled && type === "edit" ? true : false}
                {...register(`${field.id}`)}
                helperText={errors[field.id]?.message || " "}
                error={Boolean(errors[field.id]?.message)}
                onChange={field.handler ?? field.handler}
                defaultValue={type === "edit" ? field.value : ""}
                autoComplete="off"
              />
            ))}
        </div>
        <div className="flex justify-center">
          <Button type="submit" variant="contained" sx={{ minWidth: 200, marginTop: 2 }}>
            Enviar
          </Button>
        </div>
      </div>
    </form>
  );
}
