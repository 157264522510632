import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { updateTeams } from "../../redux/slices/inventory";
import Avatar from "@mui/material/Avatar";
import enviroments from "../../utils/enviroments";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axiosInstance from "../../utils/axiosInstance";
import Chip from "@mui/material/Chip";
import { element } from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "90%", // 600
    md: "60%", // 900
    lg: "60%", // 1200
    xl: "60%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.3)",
    outline: "1px solid slategrey",
  },
};

export default function TeamModalEdit({ open, data, setOpen, permission }) {
  const {
    brandsSelect,
    providersSelect: providers,
    teamTypesSelect,
    modelsSelect,
    statusSelect,
    bodegas,
  } = useSelector((state) => state.inventory);
  const [accesories, setAccesories] = React.useState([]);
  const [url, setUrl] = React.useState();
  const [image, setImage] = React.useState();
  const [showRoom, setShowRoom] = React.useState(false);
  const [versionsByModel, setVersionsByModel] = React.useState([]);
  const [handledVersion, setHandledVersion] = React.useState(false);
  const [newElements, setNewElements] = React.useState([]);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const statusWatcher = watch("estado");

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
    setUrl();
    setImage();
    setAccesories([]);
    setNewElements([]);
  };

  const handleChangeElements = (e, value) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue !== value.cantidad_actual && inputValue !== "") {
      const updatedElement = {
        id: value.id,
        cantidad: inputValue,
      };
      setNewElements((prevState) => {
        const updatedElements = prevState.filter((element) => element.id !== value.id);
        return [...updatedElements, updatedElement];
      });
    } else {
      setNewElements((prevState) => prevState.filter((element) => element.id !== value.id));
    }
  };

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  React.useEffect(() => {
    if (modelsSelect && data?.id) {
      const model = modelsSelect.filter((model) => model.nombre === data.modelo);
      setVersionsByModel(model[0]?.inv_version || []);
    }
  }, [modelsSelect, data]);

  const updateTeam = async (value) => {
    value.marca = parseInt(value.marca);
    value.proveedor = parseInt(value.proveedor);
    value.version = parseInt(value.version);
    value.tipo = parseInt(value.tipo);
    value.estado = parseInt(value.estado);
    value.newElements = newElements;
    value.valueCost = parseInt(value.valueCost);

    if (value.estado !== 3 && value.estado !== 7 && value.observacion) {
      value.observacion = null;
    }

    if (Boolean(image)) {
      const formData = new FormData();
      formData.append("image", image);
      const res = await axiosInstance.post(`/images`, formData);
      value.imagen = res.data;
    }
    await dispatch(updateTeams(value, data.id));
    handleClose();
  };

  const state = watch("estado");

  const handleVersionByModel = (e) => {
    setHandledVersion(true);

    setVersionsByModel(modelsSelect.find((model) => model.nombre === e.target.value).inv_version);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(updateTeam)}>
                <div className="flex flex-col sm:flex-row">
                  <div className="w-[100%] sm:w-[60%] mr-5">
                    <div className="flex flex-col items-center sm:flex-row w-full">
                      <div className="mr-5 relative">
                        <Avatar
                          variant="rounded"
                          src={(url && url) || (data?.imagen && `${enviroments.img_endpoint}${data?.imagen}`)}
                          onClick={() => setShowRoom(!showRoom)}
                          sx={{
                            height: 134,
                            width: 170,
                            marginBottom: 2,
                            transition: "all 0.3s ease-in-out",
                            cursor: "pointer",
                            "&:hover": { padding: 0.3 },
                          }}
                        />
                        <div className="absolute bottom-4  w-full">
                          <label
                            htmlFor="file-upload"
                            className="text-sm w-full flex justify-center font-bold items-center p-[6px] bg-white/60 cursor-pointer"
                          >
                            <FileUploadIcon sx={{ mr: 0.5, fontSize: 16 }} />
                            Subir Imagen
                          </label>
                          <input id="file-upload" type="file" accept="image/*" onChange={uploadImage} />
                        </div>
                      </div>
                      <div className="w-full">
                        <h1 className="font-semibold w-full text-gray-500 rounded hidden sm:block border-[#c5c4c8] border-[1px] uppercase h-min p-[15px] mb-5">
                          Editar Equipo
                        </h1>
                        <TextField
                          sx={{ mr: 2 }}
                          fullWidth
                          id={"descripcion"}
                          label={"Descripción"}
                          type={"text"}
                          {...register("descripcion")}
                          error={Boolean(errors["descripcion"])}
                          helperText={Boolean(errors["descripcion"]) ? "El campo es requerido" : " "}
                          defaultValue={data && data?.descripcion ? data.descripcion : ""}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <TextField
                        fullWidth
                        id={"marca"}
                        sx={{ mr: 2 }}
                        label={"Marca"}
                        type={"text"}
                        {...register("marca")}
                        error={Boolean(errors["marca"])}
                        helperText={Boolean(errors["marca"]) ? "El campo es requerido" : " "}
                        defaultValue={data && data?.marca ? data.marca : ""}
                        select
                      >
                        {brandsSelect.length &&
                          brandsSelect
                            ?.filter((brand) => brand.equipo)
                            .map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.descripcion}
                              </MenuItem>
                            ))}
                      </TextField>
                      <TextField
                        fullWidth
                        id={"equipo"}
                        label={"Tipo de equipo"}
                        type={"text"}
                        {...register("tipo")}
                        helperText={Boolean(errors["tipo"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["tipo"])}
                        select
                        defaultValue={data && data?.tipo ? data.tipo : ""}
                      >
                        {teamTypesSelect.length &&
                          teamTypesSelect?.map((option) => (
                            <MenuItem key={option.id} value={option?.id || ""}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="flex">
                      <TextField
                        fullWidth
                        id={"modelo"}
                        label={"Modelo"}
                        sx={{ mr: 2 }}
                        type={"text"}
                        defaultValue={data && data?.modelo ? data.modelo : ""}
                        {...register("modelo")}
                        onChange={handleVersionByModel}
                        helperText={Boolean(errors["modelo"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["modelo"])}
                        select
                      >
                        {modelsSelect.length &&
                          modelsSelect?.map((option) => (
                            <MenuItem key={option.id} value={option.nombre || ""}>
                              {option.nombre}
                            </MenuItem>
                          ))}
                      </TextField>
                      <TextField
                        fullWidth
                        id={"version"}
                        label={"Version"}
                        type={"text"}
                        {...register("version")}
                        helperText={Boolean(errors["version"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["version"])}
                        select
                        defaultValue={data && data?.version ? data.version : ""}
                      >
                        {versionsByModel.length ? (
                          versionsByModel?.map((option) => (
                            <MenuItem key={option.id} value={option.id || ""}>
                              {option.descripcion}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={1} disabled>
                            {!handledVersion ? "Selecciona un modelo" : "No hay versiones para este modelo"}
                          </MenuItem>
                        )}
                      </TextField>
                    </div>
                    <div className="flex">
                      <TextField
                        fullWidth
                        id={"serie"}
                        label={"Serie"}
                        type={"text"}
                        defaultValue={data && data?.serie ? data.serie : ""}
                        {...register("serie")}
                        helperText={Boolean(errors["serie"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["serie"])}
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        fullWidth
                        id={"proveedor"}
                        label={"Proveedor"}
                        type={"text"}
                        {...register("proveedor")}
                        error={Boolean(errors["proveedor"])}
                        helperText={Boolean(errors["proveedor"]) ? "El campo es requerido" : " "}
                        select
                        defaultValue={data && data?.proveedor ? data.proveedor : ""}
                      >
                        {providers.length &&
                          providers?.map((option) => (
                            <MenuItem key={option.id} value={option?.id || ""}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="flex gap-4">
                      <TextField
                        fullWidth
                        id={"estado"}
                        label={"Estado"}
                        type={"text"}
                        {...register("estado")}
                        helperText={Boolean(errors["estado"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["proveedor"])}
                        select
                        defaultValue={data && data?.estado ? data.estado : ""}
                      >
                        {statusSelect.length &&
                          statusSelect?.map((option) => (
                            <MenuItem key={option.id} value={option?.id || ""}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                      </TextField>
                      {(statusWatcher === 4 || data.bodegaId) && (
                        <TextField
                          fullWidth
                          id={"bodegaId"}
                          label={"Bodega"}
                          type={"text"}
                          {...register("bodegaId")}
                          helperText={Boolean(errors["bodegaId"]) ? "El campo es requerido" : " "}
                          error={Boolean(errors["proveedor"])}
                          select
                          defaultValue={data && data?.bodegaId ? data.bodegaId : ""}
                        >
                          {bodegas.length &&
                            bodegas?.map((option) => (
                              <MenuItem key={option.id} value={option?.id || ""}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </div>
                    <div>
                      <div>
                        <TextField
                          sx={{ mr: 2 }}
                          disabled={
                            data?.inv_estado?.descripcion === "RESERVA" ||
                            data?.inv_estado?.descripcion === "BAJA" ||
                            state === 3 ||
                            state === 7
                              ? false
                              : true
                          }
                          fullWidth
                          id={"observacion"}
                          label={"Observación"}
                          type={"text"}
                          {...register("observacion")}
                          error={Boolean(errors["observacion"])}
                          helperText={Boolean(errors["observacion"]) ? "El campo es requerido" : " "}
                          defaultValue={data && data?.observacion ? data.observacion : ""}
                        />
                      </div>
                    </div>
                    <div>
                      <TextField
                        sx={{ mr: 2 }}
                        fullWidth
                        id={"valueCost"}
                        label={"Valor"}
                        type={"text"}
                        {...register("valueCost")}
                        error={Boolean(errors["valueCost"])}
                        helperText={Boolean(errors["valueCost"]) ? "El campo es requerido" : " "}
                        defaultValue={data && data?.valueCost ? data.valueCost : ""}
                      />
                    </div>
                  </div>
                  <div className="w-[100%] sm:w-[40%]">
                    <div className=" rounded border-[#c8c4c4] border-[1px] h-full max-h-[470px] flex flex-col px-5 ">
                      <div className="h-[15%] flex  items-center">
                        <div className="text-black uppercase font-semibold mt-3 gap-2 flex flex-row items-center">
                          <h1>Elementos</h1>
                          {data?.inv_accesorios.length > 0 ? (
                            data?.inv_accesorios.every((acc) => acc.cantidad_actual === acc.cantidad_requerida) ? (
                              <Chip
                                size="small"
                                label="Completo"
                                sx={{ textTransform: "capitalize", marginRight: "20px", minWidth: "85px" }}
                                color="success"
                              />
                            ) : (
                              <Chip
                                size="small"
                                label="Incompleto"
                                sx={{ textTransform: "capitalize", marginRight: "20px", minWidth: "85px" }}
                                color="warning"
                              />
                            )
                          ) : null}
                        </div>
                      </div>
                      {data?.inv_accesorios.length > 0 ? (
                        <div className="overflow-y-auto max-h-[85%]">
                          {permission === 1 ? (
                            <div>
                              <div className="flex w-full bg-slate-300 mb-3">
                                <div className="w-[50%] border-left border-r-2 pl-5">ELEMENTO</div>
                                <div className="w-[25%] flex justify-center border-r-2">Cantidad</div>
                                <div className="w-[25%] flex justify-center">Actual</div>
                              </div>
                              {data?.inv_accesorios.map((pieza, index) => (
                                <div
                                  className={`w-full flex ${
                                    index % 2 === 0 ? "bg-slate-100" : "bg-slate-200"
                                  } pt-2 pb-2`}
                                >
                                  <div className="w-[50%] pl-5 flex items-center">
                                    {pieza.inv_pieza_equipos.inv_tipo_item_equipo.nombre}
                                  </div>
                                  <div className="w-[25%] flex justify-center items-center">
                                    {pieza.cantidad_requerida}
                                  </div>
                                  <TextField
                                    size="small"
                                    sx={{ width: "25%" }}
                                    defaultValue={pieza.cantidad_actual}
                                    onChange={(e) => handleChangeElements(e, pieza)}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <div className="flex w-full bg-slate-300 mb-3">
                                <div className="w-[50%] border-left border-r-2 pl-5">ELEMENTO</div>
                                <div className="w-[25%] flex justify-center border-r-2">Cantidad</div>
                                <div className="w-[25%] flex justify-center">Actual</div>
                              </div>
                              {data?.inv_accesorios.map((pieza, index) => (
                                <div
                                  className={`w-full flex ${
                                    index % 2 === 0 ? "bg-slate-100" : "bg-slate-200"
                                  } pt-2 pb-2`}
                                >
                                  <div className="w-[50%] pl-5">
                                    {pieza.inv_pieza_equipos.inv_tipo_item_equipo.nombre}
                                  </div>
                                  <div className="w-[25%] flex justify-center">{pieza.cantidad_requerida}</div>
                                  <div className="w-[25%] flex justify-center">{pieza.cantidad_actual}</div>
                                </div>
                              ))}
                            </div>
                          )}
                          {/* <div className="flex w-full bg-slate-300 mb-3">
                            <div className="w-[50%] border-left border-r-2 pl-5">ELEMENTO</div>
                            <div className="w-[25%] flex justify-center border-r-2">Cantidad</div>
                            <div className="w-[25%] flex justify-center">Actual</div>
                          </div>
                          {data?.inv_accesorios.map((pieza, index) => (
                            <div
                              className={`w-full flex ${index % 2 === 0 ? "bg-slate-100" : "bg-slate-200"} pt-2 pb-2`}
                            >
                              <div className="w-[50%] pl-5">{pieza.inv_pieza_equipos.inv_tipo_item_equipo.nombre}</div>
                              <div className="w-[25%] flex justify-center">{pieza.cantidad_requerida}</div>
                              <div className="w-[25%] flex justify-center">{pieza.cantidad_actual}</div>
                            </div>
                          ))} */}
                        </div>
                      ) : (
                        <div className="flex justify-center mt-5 mb-5">No hay elementos asociados</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Modal
                      open={showRoom}
                      onClose={() => setShowRoom(false)}
                      aria-labelledby="child-modal-title"
                      slotProps={{
                        backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(8px)" } },
                      }}
                      aria-describedby="child-modal-description"
                    >
                      <Box sx={{ ...style, width: "auto", padding: 1, maxWidth: "600px" }}>
                        <img src={(url && url) || (data?.imagen && `${enviroments.img_endpoint}${data?.imagen}`)} />
                      </Box>
                    </Modal>
                  </div>
                </div>
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Enviar formulario
                </Button>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
