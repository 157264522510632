import React from "react";
import { deleteUser, recoverUser } from "../redux/slices/users";
import { getAllUsers, getAllDeletedUsers } from "../redux/slices/users";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import NewUserForm from "../components/modal/newUserForm";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "nombre" },
  { title: "Identificador", value: "rut" },
  { title: "Cargo", value: "cargo" },
  { title: "Rol", value: "rol" },
  { title: "Direccion", value: "direccion" },
  { title: "Telefono", value: "telefono" },
  { title: "Departamento", value: "departamento" },
  { title: "Acciones", value: "acciones_usuario" },
];
const inactiveUsersColumns = [
  { title: "ID", value: "id", hide: true },
  { title: "Nombre", value: "nombre" },
  { title: "Identificador", value: "rut" },
  { title: "Cargo", value: "cargo" },
  { title: "Rol", value: "rol" },
  { title: "Direccion", value: "direccion" },
  { title: "Telefono", value: "telefono" },
  { title: "Departamento", value: "departamento" },
  { title: "Acciones", value: "recover" },
];

export default function Users() {
  const [select, setSelect] = useState(0);
  const { data, success } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  useEffect(() => {
    if (select === 0) dispatch(getAllUsers());
    if (select === 1) dispatch(getAllDeletedUsers());
  }, [dispatch, data.length, select]);

  const handleChangeSelect = (e) => {
    setSelect(e.target.value);
  };

  const handleDelete = async (params) => {
    const id = params.id;
    dispatch(deleteUser(id));
    dispatch(setOpen(false));
  };

  const handleRecover = async (params) => {
    const id = params.id;
    dispatch(recoverUser(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(
    select === 0 ? permitedRenderDataColumn : inactiveUsersColumns,
    select === 0 ? handleDelete : handleRecover,
    "USUARIO",
    false,
    setOpen,
  );

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Usuarios</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Usuarios"} />
        </div>
        <div>
          <NewUserForm open_edit={open?.status} close_edit={setOpen} data_edit={open?.data} />
        </div>
      </div>
      <DataGridComponent
        columns={columns}
        rows={data}
        success={success}
        logicalDelete={true}
        logicalDeleteSetter={handleChangeSelect}
        selectorValue={select}
      />
    </div>
  );
}
