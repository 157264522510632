import { TextField, Button, Menu, MenuItem } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBrandsSelect,
  getAllModelsSelect,
  getAllProvidersSelect,
  getAllStatusSelect,
  getAllTeamTypesSelect,
  getAllVersionsSelect,
  setBrands,
} from "../../redux/slices/inventory";

const FiltrosEquipos = ({ rows, setEquipos }) => {
  const dispatch = useDispatch();
  const { teamTypesSelect, brandsSelect, providersSelect, modelsSelect, statusSelect, versionSelect, success } =
    useSelector((state) => state.inventory);

  const [team, setTeam] = useState(null);
  const [brand, setbrand] = useState(null);
  const [version, setVersion] = useState(null);
  const [models, setModels] = useState(null);
  const [status, setStatus] = useState(null);
  const [arrData, setArrData] = useState(rows);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useEffect(() => {
    dispatch(getAllTeamTypesSelect());
    dispatch(getAllBrandsSelect());
    dispatch(getAllProvidersSelect());
    dispatch(getAllModelsSelect());
    dispatch(getAllStatusSelect());
    dispatch(getAllVersionsSelect());
  }, []);
  //   console.log(rows[30].modelo === models);
  //   console.log(rows[30].inv_tipo_equipo.id === team);

  useEffect(() => {
    if (status === null) {
      setEquipos(rows.filter((data) => data.inv_estado.id === 4));
    } else {
      setEquipos(rows);
    }
  }, [rows]);

  const handleFilter = () => {
    setEquipos(
      rows.filter((data) => {
        return (
          (!team || data.inv_tipo_equipo.id === team) &&
          (!brand || data.inv_marca.id === brand) &&
          (!version || data.version === version) &&
          (!models || data.modelo === models) &&
          (!status || data.inv_estado.id === status)
        );
      })
    );
  };

  return (
    <div className="absolute right-5">
      <Button onClick={handleOpenUserMenu} startIcon={<FilterAltIcon />} sx={{ fontSize: "13px" }}>
        Filtrar Tarea
      </Button>
      <Menu
        sx={{ mt: "35px", p: 2 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <div className="w-[250px] px-2">
          <TextField
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            select
            defaultValue={0}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem dense={true} onClick={() => setTeam(null)} value={0}>
              Seleccionar Equipo
            </MenuItem>
            {teamTypesSelect.map((team) => (
              <MenuItem
                divider
                dense={true}
                key={team.descripcion}
                value={team.id}
                onClick={() => setTeam(team.id)}
                sx={{ width: "234px" }}
              >
                {team.descripcion}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="w-[250px] px-2">
          <TextField
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            select
            defaultValue={0}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem dense={true} onClick={() => setModels(null)} value={0}>
              Seleccionar Modelo
            </MenuItem>
            {modelsSelect.map((model) => (
              <MenuItem
                divider
                dense={true}
                key={model.nombre}
                value={model.id}
                onClick={() => setModels(model.nombre)}
                sx={{ width: "234px" }}
              >
                {model.nombre}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="w-[250px] px-2">
          <TextField
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            select
            defaultValue={0}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem dense={true} onClick={() => setVersion(null)} value={0}>
              Seleccionar Version
            </MenuItem>
            {versionSelect.map((version) => (
              <MenuItem
                divider
                dense={true}
                key={version.descripcion}
                value={version.id}
                onClick={() => setVersion(version.id)}
                sx={{ width: "234px" }}
              >
                {version.descripcion}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="w-[250px] px-2">
          <TextField
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            defaultValue={0}
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem dense={true} onClick={() => setbrand(null)} value={0}>
              Seleccionar Marca
            </MenuItem>
            {brandsSelect.map((brand) => (
              <MenuItem
                divider
                dense={true}
                key={brand.descripcion}
                value={brand.id}
                onClick={() => setbrand(brand.id)}
                sx={{ width: "234px" }}
              >
                {brand.descripcion}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="w-[250px] px-2">
          <TextField
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            select
            defaultValue={0}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem dense={true} onClick={() => setStatus(null)} value={0}>
              Seleccionar Estado
            </MenuItem>
            {statusSelect.map((status) => (
              <MenuItem
                divider
                dense={true}
                key={status.descripcion}
                value={status.id}
                onClick={() => setStatus(status.id)}
                sx={{ width: "234px" }}
              >
                {status.descripcion}
              </MenuItem>
            ))}
          </TextField>
          <div className="pb-2">
            <Button fullWidth variant="outlined" onClick={handleFilter}>
              FILTRAR
            </Button>
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default FiltrosEquipos;
