import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: "10px",
    width: "20%",
    borderBottom: "3px solid black",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
    width: "20%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    borderBottom: "1px solid #8c8c8c",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
}));
export default function PreFacturaRepuestoTable({ rows, setAsignados }) {
  const handleValueCostChange = (id, value) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, valueCost: value === "" ? 0 : parseInt(value) } : row
    );
    setAsignados(updatedRows);
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>CÓDIGO LOGINTEG</StyledTableCell>
            <StyledTableCell align="left">DETALLE</StyledTableCell>
            <StyledTableCell align="left">CANTIDAD</StyledTableCell>
            <StyledTableCell align="left">MODELO</StyledTableCell>
            <StyledTableCell align="left">SERIE</StyledTableCell>
            <StyledTableCell align="left">TOTAL</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{row.codigo_loginteg || "S/D"}</StyledTableCell>
              <StyledTableCell align="left">{row.descripcion}</StyledTableCell>
              <StyledTableCell align="left">{row.cantidad_solic}</StyledTableCell>
              <StyledTableCell align="left">{row.modelo}</StyledTableCell>
              <StyledTableCell align="left">{row.serie}</StyledTableCell>
              <StyledTableCell align="left">
                <TextField
                  sx={{ width: 150 }}
                  value={row?.valueCost}
                  onChange={(e) => handleValueCostChange(row.id, e.target.value)}
                  type="number"
                  variant="outlined"
                  size="small"
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
