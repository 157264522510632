import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataGridComponent from "../components/tables/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import { getAllMails, getAllTelegrams, getAllUsersEnvios } from "../redux/slices/gestionEnvios";
import useDataGrid from "../hooks/useDataGrid";
import GestionEnviosTelegram from "../components/modal/GestionEnviosTelegram";
import GestionEnvioMails from "../components/modal/GestionEnvioMails";

export default function GestionDeEnvios() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState({
    status: false,
    data: null,
  });
  const [openMail, setOpenMail] = React.useState({
    status: false,
    data: null,
  });
  const { telegrams, users, mails } = useSelector((state) => state.gestionEnvios);
  const dispatch = useDispatch();
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(getAllTelegrams());
    dispatch(getAllUsersEnvios());
    dispatch(getAllMails());
  }, []);

  const permitedRenderDataColumnTelegram = [
    { title: "Etapa", value: "name" },
    { title: "Acciones", value: "acciones_gestion_envios" },
  ];

  const permitedRenderDataColumnMails = [
    { title: "Etapa", value: "name" },
    { title: "Acciones", value: "acciones_gestion_envios" },
  ];

  const [columns] = useDataGrid(permitedRenderDataColumnTelegram, () => {}, "AREA", false, setOpen);
  const [columnsMail] = useDataGrid(permitedRenderDataColumnTelegram, () => {}, "AREA", false, setOpenMail);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Gestión de Envios</h1>
          <BreadCrumb origin={"Gestión Web"} current={"Gestión de Envios"} />
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Envios de Telegram" {...a11yProps(0)} />
            <Tab label="Envios de Correos" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div>
            <div>
              <GestionEnviosTelegram open={open.status} data={open.data} setOpen={setOpen} />
            </div>
            <DataGridComponent columns={columns} rows={telegrams} success={true} />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div>
            <div>
              <GestionEnvioMails open={openMail.status} data={openMail.data} setOpen={setOpenMail} />
            </div>
            <DataGridComponent columns={columnsMail} rows={mails} success={true} />
          </div>
        </CustomTabPanel>
      </Box>
    </div>
  );
}
