import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getCobranzaById, setCobranzaId, updateRegistroCobranza } from "../../../redux/slices/cobranzas";
import { Button, TextField, MenuItem } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import dayjs from "dayjs";
import CobranzasTable from "../../../components/tables/CobranzasTable/CobranzasTable";
import CircularProgress from "@mui/material/CircularProgress";
import * as utc from "dayjs/plugin/utc";
import { getAllContactMeans } from "../../../redux/slices/contactMeans";
dayjs.extend(utc);

export default function CobranzaId() {
  const { cobranzaId } = useSelector((state) => state.cobranzas);
  const { data } = useSelector((state) => state.contactMeans);
  const { currentUser } = useSelector((state) => state.auth);
  const [fecha, setFecha] = useState("");
  const [contactMeans, setContactsMeans] = useState("");
  const [obs, setObs] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCobranzaById(id));

    return () => dispatch(setCobranzaId({}));
  }, []);

  const principal =
    cobranzaId.facturacion?.cliente_facturacion_clienteTocliente.contacto_contacto_clienteTocliente.filter(
      (cliente) => cliente.principal === true && cliente.estado === true
    );

  const handleChange = (e) => {
    setFecha(dayjs(e).format("YYYY-MM-DD"));
  };

  const handleObservaciones = (e) => {
    setObs(e.target.value);
  };

  useEffect(() => {
    setFecha(dayjs(cobranzaId?.f_compromiso).add(1, "day").format("YYYY-MM-DD"));
    dispatch(getAllContactMeans());
  }, [cobranzaId]);

  const handleSubmit = async () => {
    const formulario = {
      id,
      id_facturacion: cobranzaId.id_facturacion,
      f_compromiso: fecha,
      responsable: currentUser.id,
      medio_contacto: contactMeans,
      observaciones: obs,
    };
    await dispatch(updateRegistroCobranza(formulario));
    // navigate("/cobranza");
  };

  return (
    <div>
      {Object.keys(cobranzaId).length > 0 ? (
        <div>
          <div className="mt-5 bg-[#2b2c84]/20 px-5 py-2 rounded flex justify-center mb-5">REGISTAR COBRANZA</div>
          <div className="pl-5 grid xl:grid-cols-2 2md:grid-cols-1 gap-x-5">
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">RUT: </label>
              <TextField
                size="small"
                disabled
                id="filled-disabled"
                value={cobranzaId?.facturacion?.cliente_facturacion_clienteTocliente.rut}
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "250px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Nombre: </label>
              <TextField
                size="small"
                fullWidth
                disabled
                id="filled-disabled"
                value={cobranzaId?.facturacion?.cliente_facturacion_clienteTocliente.nombre}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "250px",
                }}
              />
            </div>

            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Numero de Factura: </label>
              <TextField
                size="small"
                fullWidth
                disabled
                id="filled-disabled"
                value={cobranzaId?.n_factura}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "250px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Monto: </label>
              <TextField
                size="small"
                fullWidth
                disabled
                id="filled-disabled"
                value={cobranzaId?.monto}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "250px",
                }}
              />
            </div>
            <div className="flex items-center mb-2 max-movil:flex-col max-movil:items-start">
              <label className="min-w-[150px]">Contactos: </label>
              <TextField
                size="small"
                fullWidth
                disabled
                id="filled-disabled"
                value={principal.length > 0 ? `${principal[0]?.telefono1} - ${principal[0]?.telefono2}` : "Sin asignar"}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#707070",
                  },
                  textAlign: "center",
                  backgroundColor: "#F1F5F9",
                  // width: "250px",
                }}
              />
            </div>
          </div>
          <div className="lg:grid grid-cols-3 mt-5 pl-5 ">
            <div className="flex justify-center items-start flex-col">
              <label className="w-[230px]">Compromiso de pago previo: </label>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={dayjs(cobranzaId?.f_compromiso).add(1, "day")}
                    disabled
                    id="filled-disabled"
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                      textAlign: "center",
                      width: "250px",
                      backgroundColor: "#F1F5F9",
                    }}
                    format={"DD/MM/YYYY"}
                    disablePast
                    slotProps={{ textField: { size: "small" } }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="flex justify-center items-start flex-col">
              <label className="w-[230px]">Nuevo compromiso de pago: </label>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ width: "250px" }}
                    onChange={handleChange}
                    format={"DD/MM/YYYY"}
                    defaultValue={dayjs(cobranzaId?.f_compromiso).add(1, "day")}
                    disablePast
                    slotProps={{ textField: { size: "small" } }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="flex justify-center items-start flex-col">
              <label className="w-[230px]">Medio de contacto: </label>
              <TextField id={"contact_means"} select size="small" sx={{ width: "250px" }}>
                {data.length > 0 &&
                  data.map((option) => (
                    <MenuItem key={option.id} value={option.id} onClick={() => setContactsMeans(option.id)}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </div>
          <div>
            <div className="bg-[#2b2c84]/20 px-5 py-2 rounded flex justify-center my-5">
              <label className="">OBSERVACIONES </label>
            </div>
            <TextField
              required
              id="outlined-multiline-static"
              multiline
              rows={4}
              fullWidth
              placeholder="* AGREGAR OBSERVACION"
              onChange={handleObservaciones}
            />
            <Button sx={{ marginTop: 1 }} fullWidth variant="contained" onClick={handleSubmit}>
              Ingresar
            </Button>
            <div>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded mt-5 flex justify-center">
                <label className="">HISTORIAL </label>
              </div>
              <CobranzasTable rows={cobranzaId?.cobranza_obs} />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[100%] h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
