import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import ReadCSV from "../../components/ReadCSV/ReadCSV";
import { getAllDocumentsIssued, uploadDocuemntsIssued } from "../../redux/slices/documents";
import { useEffect, useState } from "react";
import DataGridComponent from "../../components/tables/DataGrid";
import useDataGrid from "../../hooks/useDataGrid";
import FacturasEmitidasTable from "../../components/tables/FacturasEmitidasTable/FacturasEmitidasTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Button, MenuItem, TextField } from "@mui/material";

export default function FactrurasEmitidas() {
  const { currentUser } = useSelector((state) => state.auth);
  const { documentsIssued, success } = useSelector((state) => state.document);
  const [cliente, setCliente] = useState([]);
  const [docFiltered, setDocFiltered] = useState([]);
  const dispatch = useDispatch();

  const handleSumbit = (e) => {
    e.forEach((element) => {
      delete element.nro;
      element.responsable_carga = currentUser.id;
    });
    dispatch(uploadDocuemntsIssued(e));
  };

  const handle_filter = async (values) => {
    const f_inicio = dayjs(values.f_inicio).format("DD-MM-YYYY");
    const f_fin = dayjs(values.f_fin).format("DD-MM-YYYY");
    await dispatch(getAllDocumentsIssued(f_inicio, f_fin));
  };

  useEffect(() => {
    if (documentsIssued) {
      setDocFiltered(documentsIssued);
    }
  }, [documentsIssued]);

  useEffect(() => {
    const cliente = [];
    if (documentsIssued) {
      documentsIssued.forEach((el) => {
        let findCliente = cliente.find((element) => element === el.razon_social);
        if (!findCliente) {
          cliente.push(el.razon_social);
        }
      });
    }
    setCliente(cliente);
  }, [documentsIssued]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleCliente = (e) => {
    if (e === 0) {
      setDocFiltered(documentsIssued);
    } else {
      const filter = documentsIssued.filter((el) => el.razon_social === e);
      setDocFiltered(filter);
    }
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">SII</h1>
          <BreadCrumb origin={"SII"} current={"Facturas Emitidas"} />
        </div>
        <div>
          <ReadCSV uploader={handleSumbit} />
        </div>
      </div>
      <form onSubmit={handleSubmit(handle_filter)} className="flex gap-5 my-5">
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              rules={{ required: true }}
              name={"f_inicio"}
              defaultValue={dayjs()}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  label={"Fecha Inicio"}
                  onChange={onChange}
                  value={value || null}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              rules={{ required: true }}
              name={"f_fin"}
              defaultValue={dayjs()}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  label={"Fecha Fin"}
                  onChange={onChange}
                  value={value || null}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <Button variant="outlined" type="submit">
          BUSCAR
        </Button>
        <div className="w-[300px]">
          <TextField
            size="small"
            className="w-[80%] md:w-[90%]"
            label="Clientes"
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
          >
            <MenuItem key={0} value={0} onClick={() => handleCliente(0)}>
              TODOS
            </MenuItem>
            {cliente?.map((cli) => (
              <MenuItem key={cli} value={cli} onClick={() => handleCliente(cli)}>
                {cli}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </form>
      <FacturasEmitidasTable rows={docFiltered} />
    </div>
  );
}
