import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { getProyects, getTipoGastos } from "../../../redux/slices/documents";
import FacturaRecibidaModal from "./FacturaRecibidaModal/FacturaRecibidaModal";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "",
    color: "#000000",
    padding: 10,
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function FacturasRecibidasTable({ rows }) {
  const dispatch = useDispatch();
  const { proyects, tipoGastos } = useSelector((state) => state.document);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" sx={{ minWidth: "80px" }}>
              Acciones
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tipo Gasto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Proyecto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "80px" }}>
              Tipo Doc
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tipo Compra
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              RUT Proveedor
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "350px" }}>
              Razon Social
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Folio
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha Docto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha Recepcion
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha Acuse
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Monto Exento
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Monto Neto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Monto IVA Recuperable
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Monto Iva No Recuperable
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Codigo IVA No Rec.
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Monto Total
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Monto Neto Activo Fijo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              IVA Activo Fijo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              IVA uso Comun
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Impto. Sin Derecho a Credito
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              IVA No Retenido
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tabacos Puros
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tabacos Cigarrillos
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tabacos Elaborados
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              NCE o NDE sobre Fact. de Compra
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Codigo Otro Impuesto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Valor Otro Impuesto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tasa Otro Impuesto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha Carga
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Responsable Carga
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="left">
                <FacturaRecibidaModal
                  id={row.id}
                  proyectId={row.id_proyecto}
                  tipoGastoId={row.id_tipo_gasto}
                  proyects={proyects}
                  tipoGastos={tipoGastos}
                />
              </StyledTableCell>
              <StyledTableCell align="left">{row.tipo_gasto}</StyledTableCell>
              <StyledTableCell align="left">{row.proyecto}</StyledTableCell>
              <StyledTableCell align="left">{row.tipo_doc}</StyledTableCell>
              <StyledTableCell align="left">{row.tipo_compra}</StyledTableCell>
              <StyledTableCell align="left">{row.rut_proveedor}</StyledTableCell>
              <StyledTableCell align="left">{row.razon_social}</StyledTableCell>
              <StyledTableCell align="left">{row.folio}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_docto}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_recepcion}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_acuse}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_exento}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_neto}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_iva_recuperable}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_iva_no_recuperable}</StyledTableCell>
              <StyledTableCell align="left">{row.codigo_iva_no_rec_}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_total}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_neto_activo_fijo}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_activo_fijo}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_uso_comun}</StyledTableCell>
              <StyledTableCell align="left">{row.impto__sin_derecho_a_credito}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_no_retenido}</StyledTableCell>
              <StyledTableCell align="left">{row.tabacos_puros}</StyledTableCell>
              <StyledTableCell align="left">{row.tabacos_cigarrillos}</StyledTableCell>
              <StyledTableCell align="left">{row.tabacos_elaborados}</StyledTableCell>
              <StyledTableCell align="left">{row.nce_o_nde_sobre_fact__de_compra}</StyledTableCell>
              <StyledTableCell align="left">{row.codigo_otro_impuesto}</StyledTableCell>
              <StyledTableCell align="left">{row.valor_otro_impuesto}</StyledTableCell>
              <StyledTableCell align="left">{row.tasa_otro_impuesto}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_carga}</StyledTableCell>
              <StyledTableCell align="left">{row.responsable}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
