import DataGridComponent from "../components/tables/DataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllWorkOrders, reabrirOT } from "../redux/slices/workOrders";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

export const dataColumns = [
  { value: "ot_id", title: "Nro OT", minWidth: 170 },
  { value: "cliente", title: "Cliente", minWidth: 170 },
  { value: "ot_estado", title: "Estado", minWidth: 170 },
  { value: "reabrir_actions", title: "Acciones", minWidth: 100 },
];

export default function ReabrirOT() {
  const [render, setRender] = useState([]);
  const [open, setOpen] = useState({
    open: false,
    data: null,
  });

  const onClose = () => {
    setObservation("");
    setOpen({ open: false, data: null });
  };

  const dispatch = useDispatch();
  const { data: ots } = useSelector((state) => state.workOrders);
  const [observation, setObservation] = useState("");
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (ots?.length) {
      const format = ots
        .map((el) => ({
          ...el,
          ot_id: el.id,
          cliente: el.cliente_ot_clienteTocliente.nombre,
          ot_estado: el.finalizada ? "Finalizada" : el.cerrada && "Cerrada",
        }))
        .filter((el) => el.ot_estado)
        .sort((a, b) => b.ot_id - a.ot_id);

      setRender(format);
    }
  }, [ots]);

  useEffect(() => {
    dispatch(getAllWorkOrders());
  }, [dispatch, currentUser.id]);

  const [column] = useDataGrid(dataColumns, null, "", false, setOpen);

  const handleSubmit = async () => {
    const id = await dispatch(reabrirOT({ id: open.data.id, observation }));
    setRender((prev) => prev.filter((el) => el.ot_id !== id));
    onClose();
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Reabrir OT</h1>
          <BreadCrumb origin={"Rendicion de gastos"} current={"Reabrir OT"} />
        </div>
      </div>
      <DataGridComponent columns={column} rows={render} success={true} />
      <Dialog
        open={open.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">"Procedimiento para reabrir la OT Nro {open?.data?.id}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              Previo a la reapertura de la Orden de Trabajo (OT), se requiere proporcionar un comentario adicional. Esto
              permitirá una comprensión clara del motivo de la reapertura al momento de su ejecución.
            </span>
            <div className="mt-1">Ten en cuenta que esto eliminará las firmas del cliente y del técnico.</div>
          </DialogContentText>
          <TextField
            label="Motivo"
            onChange={(e) => setObservation(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
            multiline
            rows={4}
            value={observation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cerrar</Button>
          <Button onClick={handleSubmit} autoFocus>
            Reabrir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
