import { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import DataGridComponent from "../../components/tables/DataGrid";
import useDataGrid from "../../hooks/useDataGrid";
import axiosInstance from "../../utils/axiosInstance";
import dayjs from "dayjs";
import ApproveRejectDialog from "../Rendicion/ApproveRejectDialog";
import { acceptRejectVacationRequest } from "../../redux/slices/remuneration";
import { useDispatch } from "react-redux";
import uploadFiles from "../../utils/UploadFiles";

const permitedRenderDataColumn = [
  { title: "Colaborador", value: "user" },
  { title: "Fecha inicial", value: "dateOne" },
  { title: "Fecha final", value: "dateTwo" },
  { title: "Documento", value: "signedFile" },
  { title: "Estado", value: "statePending" },
];

const PendingVacationList = () => {
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState({ state: false, id: null, sign: null, data: null });
  const [columns] = useDataGrid(permitedRenderDataColumn, null, "AREA", false, setOpen);

  const dispatch = useDispatch();

  const submit = async (state, val, file, isFirstStep) => {
    const submitData = { id: open.id };

    if (isFirstStep) {
      submitData.state = state ? "ACEPTADA" : "RECHAZADA";
      submitData.approverObservation = val;
    } else {
      submitData.finalApprove = state;
      submitData.finalObs = val;
    }

    if (file) {
      const res = await uploadFiles("docs", file);
      submitData.file = res.data;
    }

    const data = await dispatch(acceptRejectVacationRequest(submitData));

    setOpen({ state: false, id: null });
    window.location.reload();
  };

  useEffect(() => {
    const get = async () => {
      try {
        const { data } = await axiosInstance.get("/remuneration/pending-vacation");
        const formatted = data.map((el) => ({
          ...el,
          user: el.gen_usuario.nombre,
          dateOne: dayjs(el.initialDate).format("DD/MM/YYYY"),
          dateTwo: dayjs(el.finalDate).format("DD/MM/YYYY"),
          statePending: "PENDIENTE DE VALIDACIÓN",
        }));
        setRow(formatted);
      } catch (error) {
        setRow([]);
      }
    };

    get();
  }, []);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Pendientes de validación</h1>
          <BreadCrumb origin={"RRHH"} current={"Pendientes de validación"} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={row} success={true} />
      <ApproveRejectDialog
        open={open.state}
        isFirstStep={!!!open?.sign}
        setOpen={setOpen}
        data={open?.data}
        handleSubmit={submit}
      />
    </div>
  );
};

export default PendingVacationList;
