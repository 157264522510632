import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Chip, MenuItem, Avatar, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { createArea, getAllAreas } from "../../redux/slices/area";
import { useEffect } from "react";
import { getAllCharges } from "../../redux/slices/charges";
import { getAllClients } from "../../redux/slices/clients";
import { getAllContactMeans } from "../../redux/slices/contactMeans";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import enviroments from "../../utils/enviroments";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { createNewContact } from "../../redux/slices/contact";
import { getAllPaises } from "../../redux/slices/paises";
import FreeSoloCreateOptionDialog from "../FreeSoloCreateOptionDialog";
import ChargeDialog from "../FreeSoloCreateOptionDialog/ChargeDialog";
import ClientDialog from "../FreeSoloCreateOptionDialog/ClientDialog";
import AreaDialog from "../FreeSoloCreateOptionDialog/AreaDialog";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: "30%",
  height: "670px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function ContactCreate() {
  const [open, setOpen] = React.useState(false);
  const { data: position } = useSelector((state) => state.charges);
  const { data: clients } = useSelector((state) => state.clients);
  const { data: contactMeans } = useSelector((state) => state.contactMeans);
  const { area } = useSelector((state) => state.area);
  const { paises } = useSelector((state) => state.paises);
  const [url, setUrl] = React.useState();
  const [image, setImage] = React.useState(null);

  const [autocompleteErrors, setAutocompleteErrors] = React.useState({
    charge: false,
    client: false,
    area: false,
  });

  const [chargeSelected, setChargeSelected] = React.useState(null);
  const [clientSelected, setClientSelected] = React.useState(null);
  const [areaSelected, setAreaSelected] = React.useState(null);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setChargeSelected(null);
    setClientSelected(null);
    setAreaSelected(null);
    setAutocompleteErrors({});
    setOpen(false);
    setUrl("");
    setImage(null);
    reset();
  };

  const handle_submit = async (value) => {
    if (!!!clientSelected || !!!chargeSelected || !!!areaSelected) return;

    if (image !== null) {
      const formData = new FormData();
      formData.append("image", image);
      const res = await axios.post(`${enviroments.base_url}/images`, formData);
      value.imagen = res.data;
    } else {
      value.imagen = null;
    }
    value.cliente = clientSelected?.id;
    value.areaId = areaSelected?.id;
    value.cargo = chargeSelected?.id;

    dispatch(createNewContact(value));

    handleClose();
  };

  useEffect(() => {
    dispatch(getAllCharges());
    dispatch(getAllClients());
    dispatch(getAllContactMeans());
    dispatch(getAllAreas());
    dispatch(getAllPaises());
  }, []);

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Añadir contacto"
        color="primary"
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR CONTACTO</div>
              <div className="max-xl:max-h-[520px] max-xl:overflow-y-auto max-xl:w-[350px] w-[800px]">
                <div className="w-full grid grid-cols-4 max-xl:grid-cols-1">
                  <div className="flex items-center flex-col justify-center">
                    <Avatar alt="Remy Sharp" src={url && url} sx={{ width: 100, height: 100 }} />
                    <label htmlFor="file-upload" className={`custom-file-upload mx-10`}>
                      <FileUploadIcon sx={{ mr: 1 }} />
                      Imagen
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      {...register("imagen")}
                      onChange={uploadImage}
                    />
                  </div>
                  <div className="col-span-3">
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="nombre"
                          name="nombre"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Nombre"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.nombre}
                              helperText={errors.nombre?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1 ">
                        <FreeSoloCreateOptionDialog
                          name="cargo"
                          label={"Cargo"}
                          Form={ChargeDialog}
                          renderOption="descripcion"
                          options={position?.filter((el) => el.tipo === "Contacto")}
                          setValue={setChargeSelected}
                          value={chargeSelected}
                          error={autocompleteErrors.charge}
                          onChange={(newValue, toggleOpen, setDialogValue) => {
                            if (typeof newValue === "string") {
                              setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                  descripcion: newValue,
                                });
                              });
                            } else if (newValue && newValue.inputValue) {
                              toggleOpen(true);
                              setDialogValue({
                                descripcion: newValue.inputValue,
                              });
                            } else {
                              setChargeSelected(newValue);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="telefono1"
                          name="telefono1"
                          control={control}
                          rules={{ pattern: /^[0-9]+$/ }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Telefono 1"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.telefono1}
                              helperText={errors.telefono1 && "* Solo caracteres numéricos"}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="telefono2"
                          name="telefono2"
                          control={control}
                          rules={{ pattern: /^[0-9]+$/ }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Telefono 2"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.telefono2}
                              helperText={errors.telefono2 && "* Solo caracteres numéricos"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="correo1"
                          name="correo1"
                          control={control}
                          rules={{ pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Correo Institucional"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.correo1}
                              helperText={errors.correo1 && "* Ingrese un correo válido"}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="correo2"
                          name="correo2"
                          control={control}
                          rules={{ pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Correo Personal"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.correo2}
                              helperText={errors.correo2 && "* Ingrese un correo válido"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <FreeSoloCreateOptionDialog
                          name="cliente"
                          label={"Cliente"}
                          Form={ClientDialog}
                          options={clients}
                          renderOption="nombre"
                          setValue={setClientSelected}
                          value={clientSelected}
                          error={autocompleteErrors.client}
                          onChange={(newValue, toggleOpen, setDialogValue) => {
                            if (typeof newValue === "string") {
                              setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                  nombre: newValue,
                                });
                              });
                            } else if (newValue && newValue.inputValue) {
                              toggleOpen(true);
                              setDialogValue({
                                nombre: newValue.inputValue,
                              });
                            } else {
                              setClientSelected(newValue);
                            }
                          }}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="medio_contacto"
                          name="medio_contacto"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Medios de Contacto"
                              error={errors["medio_contacto"]}
                              helperText={errors.medio_contacto?.message}
                              placeholder=""
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {contactMeans?.map(
                                (option, idx) =>
                                  option.id && (
                                    <MenuItem key={idx} dense divider value={option.id}>
                                      {option.descripcion}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller
                            id={"f_nacimiento"}
                            control={control}
                            name={"f_nacimiento"}
                            render={({ field: { onChange, value } }) => (
                              <DatePicker
                                label="Fecha de Nacimiento"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    fullWidth: true,
                                    error: !!errors["f_nacimiento"],
                                  },
                                }}
                                onChange={onChange}
                                value={value || null}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="instagram"
                          name="instagram"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Instagram"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.instagram}
                              helperText={errors.instagram?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="linkedin"
                          name="linkedin"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="LinkedIn"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.linkedin}
                              helperText={errors.linkedin?.message}
                            />
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="observaciones"
                          name="observaciones"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Observaciones"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.observaciones}
                              helperText={errors.observaciones?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <div className="min-h-[65px] mb-1">
                          <FreeSoloCreateOptionDialog
                            name="areaId"
                            label={"Area"}
                            Form={AreaDialog}
                            options={area}
                            renderOption="name"
                            setValue={setAreaSelected}
                            value={areaSelected}
                            error={autocompleteErrors.area}
                            onChange={(newValue, toggleOpen, setDialogValue) => {
                              if (typeof newValue === "string") {
                                setTimeout(() => {
                                  toggleOpen(true);
                                  setDialogValue({
                                    name: newValue,
                                  });
                                });
                              } else if (newValue && newValue.inputValue) {
                                toggleOpen(true);
                                setDialogValue({
                                  name: newValue.inputValue,
                                });
                              } else {
                                setAreaSelected(newValue);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="principal"
                          name="principal"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Principal"
                              error={errors["principal"]}
                              helperText={errors.principal && "* Campo requerido"}
                              placeholder=""
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              <MenuItem key={1} dense divider value={1}>
                                SI
                              </MenuItem>
                              <MenuItem key={2} dense divider value={2}>
                                NO
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-5 max-xl:gap-0">
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="paisId"
                          name="paisId"
                          control={control}
                          rules={{ required: "* Campo requerido" }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              select
                              size="small"
                              onChange={onChange}
                              value={value || ""}
                              label="Pais"
                              error={errors["paisId"]}
                              helperText={errors.paisId?.message}
                              placeholder=""
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  marginLeft: 0,
                                },
                              }}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300,
                                    },
                                  },
                                },
                              }}
                            >
                              {paises?.map(
                                (option) =>
                                  option.id && (
                                    <MenuItem key={option.id} dense divider value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  )
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                      <div className="min-h-[65px] mb-1">
                        <Controller
                          id="rut"
                          name="rut"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              label="Rut / Documento"
                              onChange={onChange}
                              size="small"
                              value={value || ""}
                              error={errors.telefono2}
                              helperText={errors.telefono2 && "* Solo caracteres numéricos"}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center w-full justify-between max-xl:mt-5">
                <Button
                  onClick={(e) => {
                    const errors_tmp = {};

                    if (!!!clientSelected) errors_tmp.client = true;
                    if (!!!chargeSelected) errors_tmp.charge = true;
                    if (!!!areaSelected) errors_tmp.area = true;

                    setAutocompleteErrors(errors_tmp);

                    return handleSubmit(handle_submit)(e);
                  }}
                  variant="outlined"
                  fullWidth
                >
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
