import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { getAllTeamTypes, updatePieza, updateVersion } from "../../redux/slices/inventory";
import { useEffect } from "react";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function VersionEdit({ open, data, setOpen }) {
  const { teamTypes } = useSelector((state) => state.inventory);
  const [models, setModales] = useState([]);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const updateNewModel = async (value) => {
    dispatch(updatePieza({ ...value, id: data.id }));
    setOpen({ status: false, data: null });
    reset();
  };

  const submitVersion = async (values) => {
    values.id = data.id;
    dispatch(updateVersion(values));
    // dispatch(createVersion(values));
    // dispatch(createProvider(value));
    reset();
    setOpen(false);
  };

  const handleChangeTeam = (team) => {
    setModales(team.inv_modelo);
  };

  useEffect(() => {
    dispatch(getAllTeamTypes());
    setModales(data?.inv_modelo.inv_tipo_equipo.inv_modelo);
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(submitVersion)}>
                <div className="flex flex-col">
                  <div className="w-full mb-5">
                    <TextField
                      fullWidth
                      id={"descripcion"}
                      label={"Descripcion"}
                      type={"text"}
                      {...register("descripcion", { required: true })}
                      error={Boolean(errors["descripcion"])}
                      helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                      defaultValue={data.descripcion}
                    />
                  </div>
                  <div className="w-full mb-5">
                    <TextField
                      fullWidth
                      id="tipo_equipo"
                      label="Equipo"
                      select
                      defaultValue={data.inv_modelo.inv_tipo_equipo.id}
                    >
                      {teamTypes?.map((team) => (
                        <MenuItem key={team.id} value={team.id} onClick={() => handleChangeTeam(team)}>
                          {team.descripcion}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="w-full mb-5">
                    <TextField
                      fullWidth
                      id="modelo"
                      label="Modelo"
                      select
                      defaultValue={data.modelo}
                      {...register("modelo", { required: true })}
                      error={Boolean(errors["modelo"])}
                      helperText={Boolean(errors["modelo"]) && "El campo es requerido"}
                    >
                      {models?.map((model) => (
                        <MenuItem key={model.id} value={model.id}>
                          {model.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="flex mb-5 w-full justify-between">
                    <div className="w-[48%]">
                      <TextField
                        fullWidth
                        id={"altura"}
                        label={"Altura"}
                        type={"text"}
                        {...register("altura")}
                        defaultValue={data.altura}
                      />
                    </div>
                    <div className="w-[48%]">
                      <TextField
                        fullWidth
                        id={"ancho"}
                        label={"Ancho"}
                        type={"text"}
                        {...register("ancho")}
                        defaultValue={data.ancho}
                      />
                    </div>
                  </div>
                  <div className="flex mb-5 w-full justify-between">
                    <div className="w-[48%]">
                      <TextField
                        fullWidth
                        id={"profundidad"}
                        label={"Profundidad"}
                        type={"text"}
                        {...register("profundidad")}
                        defaultValue={data.profundidad}
                      />
                    </div>
                    <div className="w-[48%]">
                      <TextField
                        fullWidth
                        id={"observacion"}
                        label={"Observación"}
                        type={"text"}
                        {...register("configuracion")}
                        defaultValue={data.configuracion}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center w-full justify-between">
                  <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
                    Enviar formulario
                  </Button>
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
