const Unauthorized = () => {
  return (
    <div className="w-full  calculated-height flex justify-center items-center">
      <div className="flex flex-col items-center ">
        <div className="flex flex-col items-center ">
          <div className="flex flex-col items-center border-4 border-red-500/80 rounded-lg p-20">
            <div className="font-semibold text-9xl text-red-500/80">401</div>
            <div className=" text-xl">Acceso no autorizado</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
