import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearAccessToken, setCurrentUser, clearCurrentUser } from "../redux/slices/auth";
import { setAccessRoutes } from "../redux/slices/sidebar";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Wrapper from "./Wrapper";
import axiosInstance from "../utils/axiosInstance";
import FirstLoadSkeleton from "../components/firstLoadSkeleton/FirstLoadSkeleton";
import Unauthorized from "../components/Unauthorized/Unauthorized";

const PrivateRoutes = () => {
  const [isValid, setIsValid] = useState({
    bool: false,
    type: "",
  });
  const { accessToken } = useSelector((state) => state.auth);
  const { accessRoutes } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const resolveToken = async () => {
      if (accessToken) {
        try {
          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          const user = await axiosInstance.get("/auth/verify");
          dispatch(setCurrentUser(user.data));

          if (!accessRoutes?.clientsLinks.length && !accessRoutes?.maintainersLinks.length) {
            const routes = await axiosInstance.get("/routes-links");
            const clientsLinks = routes.data.clientsLinks.map((client) => client.link);
            const mainteiners = routes.data.maintainersLinks.map((client) => client.link);
            const technicalService = routes.data.servicioTecnico.map((client) => client.link);
            const inventoryLinks = routes.data.inventoryLinks.map((client) => client.link);
            const billingLinks = routes.data.billingLinks.map((client) => client.link);
            const cobranzaLinks = routes.data.cobranzaLinks.map((client) => client.link);
            const siiLinks = routes.data.siiLinks.map((client) => client.link);
            const rrhhLinks = routes.data.rrhhLinks.map((client) => client.link);
            const informesCobranzasLinks = routes.data.informesCobranzasLinks.map((client) => client.link);
            const rendicionsLinks = routes.data.rendicionLinks.map((client) => client.link);
            const informesLinks = routes.data.informesLinks.map((client) => client.link);
            const managmentLinks = routes.data.managmentLinks.map((client) => client.link);
            const accessLinks = clientsLinks
              .concat(mainteiners)
              .concat(technicalService)
              .concat(inventoryLinks)
              .concat(billingLinks)
              .concat(cobranzaLinks)
              .concat(siiLinks)
              .concat(informesCobranzasLinks)
              .concat(rrhhLinks)
              .concat(rendicionsLinks)
              .concat(managmentLinks)
              .concat("/ot-view")
              .concat("/orden-de-compra-edit");
            dispatch(setAccessRoutes(routes.data));

            if (location.pathname !== "/" && location.pathname !== "/profile" && location.pathname !== "/img") {
              if (!accessLinks.includes(`/${location.pathname.split("/")[1]}`)) {
                return setIsValid({ bool: false, type: "role" });
              } else {
                return setIsValid({ bool: true });
              }
            }
          } else {
            const clientsLinks = accessRoutes.clientsLinks.map((client) => client.link);
            const mainteiners = accessRoutes.maintainersLinks.map((client) => client.link);
            const technicalService = accessRoutes.servicioTecnico.map((client) => client.link);
            const inventoryLinks = accessRoutes.inventoryLinks.map((client) => client.link);
            const billingLinks = accessRoutes.billingLinks.map((client) => client.link);
            const cobranzaLinks = accessRoutes.cobranzaLinks.map((client) => client.link);
            const siiLinks = accessRoutes.siiLinks.map((client) => client.link);
            const rrhhLinks = accessRoutes.rrhhLinks.map((client) => client.link);
            const rendicionLinks = accessRoutes.rendicionLinks.map((client) => client.link);
            const managmentLinks = accessRoutes.managmentLinks.map((client) => client.link);

            const informesCobranzasLinks = accessRoutes.informesCobranzasLinks.map((client) => client.link);
            const informesLinks = accessRoutes.informesLinks.map((client) => client.link);

            const accessLinks = clientsLinks
              .concat(mainteiners)
              .concat(technicalService)
              .concat(inventoryLinks)
              .concat(billingLinks)
              .concat(cobranzaLinks)
              .concat(informesCobranzasLinks)
              .concat(informesLinks)
              .concat(siiLinks)
              .concat(rrhhLinks)
              .concat(rendicionLinks)
              .concat(managmentLinks)
              .concat("/ot-view")
              .concat("/orden-de-compra-edit");
            if (location.pathname !== "/" && location.pathname !== "/profile") {
              if (!accessLinks.includes(`/${location.pathname.split("/")[1]}`)) {
                return setIsValid({ bool: false, type: "role" });
              } else {
                return setIsValid({ bool: true });
              }
            }
          }

          setIsValid({ bool: true });
        } catch (error) {
          dispatch(clearCurrentUser());
          dispatch(clearAccessToken());
          setIsValid({ bool: true, type: "token" });
          window.location.reload();
        }
      }
    };

    resolveToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dispatch, location.pathname]);

  if (!isValid.bool && isValid.type === "role") {
    return (
      <Wrapper>
        <Unauthorized />
      </Wrapper>
    );
  }

  if (isValid.bool) {
    return (
      <Wrapper>
        <Outlet />
      </Wrapper>
    );
  } else if (accessToken) {
    return <FirstLoadSkeleton />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
