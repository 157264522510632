import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import NewForm from "../form/NewForm";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../redux/slices/editModal";

export default function EditModal({
  fields,
  schema,
  submit,
  type,
  client = false,
  img = false,
  image,
  defaultImage,
  setParentRuts,
  size = "70%",
}) {
  const { open, id } = useSelector((state) => state.editModal);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: size,
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => dispatch(setOpen({ open: false }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {id && (
              <NewForm
                key={JSON.stringify(fields)}
                type={type}
                fields={fields}
                schema={schema}
                submit={submit}
                setParentRuts={setParentRuts}
                client={client}
                img={img}
                image={image}
                defaultImage={defaultImage}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
