import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { List, ListItemButton, ListItemText, capitalize } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: "1px solid #b0b0b0",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: "start",
  paddingLeft: "20px",
  fontSize: "15px",
}));

export default function CustomizedAccordions({
  name,
  links,
  color,
  handleColor,
  panel,
  expanded,
  handleChange,
}) {
  return (
    <div>
      <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="font-medium tracking-wider text-xs uppercase text-[#3a3a3a]">
            {name}
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{ paddingLeft: "13px", marginRight: "5px" }}
          className="lastChild"
        >
          <List disablePadding={true}>
            {links &&
              links.map((el, index) => {
                const capitalizedName = capitalizeFirstLetter(el.name);
                const directoryName =
                  capitalizedName === "Ots pendientes"
                    ? "OTs pendientes"
                    : capitalizedName.includes("ot")
                      ? capitalizedName.split("ot").join("OT")
                      : capitalizedName.includes("2")
                        ? capitalizedName.slice(0, capitalizedName.length - 1)
                        : capitalizedName;

                return (
                  <Link
                    key={index}
                    to={el.link}
                    onClick={() => handleColor(index, name)}
                    sx={{ display: "flex" }}
                  >
                    <div
                      className={`flex items-center py-2 mb-1 hover:bg-gray-200 rounded transition hover:duration-150 duration-150 ${
                        color?.index === index && color?.name === name
                          ? color.style
                          : null
                      }`}
                    >
                      <KeyboardArrowRightIcon
                        sx={{ fontSize: "20px", mr: 0.2, color: "#2b2c84" }}
                      />
                      <div className="font-medium  font-roboto text-sm text-[#3a3a3a]">
                        {directoryName}
                      </div>
                    </div>
                  </Link>
                );
              })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
