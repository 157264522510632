export default function validateChileanRut(rut) {
  if (!rut) return false;

  rut = rut.replace(/[.-]/g, "").toUpperCase();

  if (!/^[0-9]+[0-9K]$/.test(rut)) {
    return false;
  }

  const verificationDigit = rut.slice(-1);
  const rutWithoutDigit = rut.slice(0, -1);
  const reversedRut = [...rutWithoutDigit].reverse().join("");
  const multipliers = [2, 3, 4, 5, 6, 7, 2, 3, 4, 5, 6, 7];

  const sum = reversedRut.split("").reduce((acc, val, index) => {
    const product = parseInt(val) * multipliers[index];
    return acc + product;
  }, 0);

  const remainder = sum % 11;
  const expectedDigit = remainder === 0 ? "0" : remainder === 1 ? "K" : (11 - remainder).toString();

  return verificationDigit === expectedDigit;
}
