import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import formatDataModal from "../../../utils/formatDataModal";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  client: {},
  success: false,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    setClientById: (state, action) => {
      state.client = action.payload;
      state.success = true;
    },
    resetClientById: (state, action) => {
      state.client = initialState.client;
      state.success = true;
    },
    addClient: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateClient: (state, action) => {
      const updated = state.data.filter((client) => {
        return client.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveClient: (state, action) => {
      const updated = state.data.filter((client) => {
        return client.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllClients = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/cliente");
    const filtrado = res.data.filter((client) => client.estado === true);
    const clients = filtrado.map((client) => ({
      ...client,
      tipo_razon_social: client.gen_tipo_razon_social.descripcion,
      region: client?.gen_region?.descripcion,
      comuna: client?.gen_comuna?.descripcion,
      hijoRender: client.hijo ? "Hijo" : "Padre",
      tipoEmpresaRender: client.tipo_empresa,
      rutRender: client.rut,
    }));
    dispatch(setClients(clients));
  } catch (error) {
    console.log(error);
  }
};

export const getClientById = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const clientById = await axiosInstance.get(`/cliente/${id}`);
    const clientByIdFormated = {
      ...clientById.data,
      tipo_razon_social: clientById?.data.gen_tipo_razon_social?.descripcion || "S/D",
      region: clientById.data?.gen_region?.descripcion || "S/D",
      comuna: clientById.data?.gen_comuna?.descripcion || "S/D",
      hijoRender: clientById.data.hijo ? "Hijo" : "Padre",
      tipoEmpresaRender: clientById?.data?.tipo_empresa || "S/D",
      rutRender: clientById?.data?.rut || "S/D",
    };

    dispatch(setClientById(clientByIdFormated));
  } catch (error) {
    console.log(error);
  }
};

export const createNewClient = (data) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/cliente/register", data);

    const client = {
      ...res.data,
      tipo_razon_social: res.data.gen_tipo_razon_social.descripcion,
      region: res.data?.gen_region?.descripcion,
      comuna: res.data?.gen_comuna?.descripcion,
      hijoRender: res.data.hijo ? "Hijo" : "Padre",
      tipoEmpresaRender: res.data.tipo_empresa,
      rutRender: res.data.rut,
    };

    dispatch(addClient(client));
    dispatch(
      setNotification({
        status: "success",
        message: "Cliente creado con éxito",
        open: true,
      }),
    );

    return client;
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al crear cliente",
        open: true,
      }),
    );
    return console.log(error);
  }
};

export const updateClient = (data) => async (dispatch) => {
  dispatch(setSuccess(false));
  const padre = data.padre;
  const formatedValues = formatDataModal(data);

  formatedValues.padre = padre;

  try {
    const res = await axiosInstance.put("/cliente/update", formatedValues);
    const client = {
      ...res.data,
      tipo_razon_social: res.data.gen_tipo_razon_social.descripcion,
      region: res.data?.gen_region?.descripcion,
      comuna: res.data?.gen_comuna?.descripcion,
      hijoRender: res.data.hijo ? "Hijo" : "Padre",
      tipoEmpresaRender: res.data.tipo_empresa,
      rutRender: res.data.rut,
    };

    dispatch(setUpdateClient(client));
    dispatch(
      setNotification({
        status: "success",
        message: "Cliente editado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.error || "Error al editar cliente",
        open: true,
      }),
    );
    return console.log(error);
  }
};

export const deleteClient = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/cliente/${id}`);
    dispatch(setRemoveClient(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Cliente eliminado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar cliente",
        open: true,
      }),
    );
    return console.log("error al eliminado contacto");
  }
};

export const {
  setClients,
  addClient,
  setUpdateClient,
  setRemoveClient,
  setUpdateParent,
  setClientById,
  resetClientById,
  setSuccess,
} = clientsSlice.actions;

export default clientsSlice.reducer;
