import * as Yup from "yup";

const departamentSchema = Yup.object().shape({
  descripcion: Yup.string().required("Nombre del departamento es requerido"),

  jefatura: Yup.object()
    .shape({
      label: Yup.string().required("Jefatura es requerido"),
      id: Yup.number().required("requerido"),
    })
    .required("Jefatura es requerido"),
});

export default departamentSchema;
