import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import formatDataModal from "../../../utils/formatDataModal";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  success: false,
};

const businessNameSlice = createSlice({
  name: "businessName",
  initialState,
  reducers: {
    setBusinessName: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    addBusinessName: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateBusinessName: (state, action) => {
      const updated = state.data.filter((businessName) => {
        return businessName.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveBusinessName: (state, action) => {
      const updated = state.data.filter((BusinessName) => {
        return BusinessName.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
      state.success = true;
    },
  },
});

export const getAllBusinessName = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/tipo-razon-social");

    const filtrado = res.data.filter((businessName) => businessName.estado === true);

    const businessNames = filtrado.map((businessName) => ({
      ...businessName,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: businessName.f_creacion.split("T")[0],
    }));
    dispatch(setBusinessName(businessNames));
  } catch (error) {
    console.log(error);
  }
};

export const createNewBusinessName = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/tipo-razon-social", formatedValues);
    const businessName = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };
    dispatch(addBusinessName(businessName));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo de razon social creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear Tipo de razon social ",
        open: true,
      })
    );
    return error;
  }
};

export const updateBusinessName = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/tipo-razon-social", formatedValues);

    const businessName = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };

    dispatch(setUpdateBusinessName(businessName));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo de razon social editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar Tipo de razon social ",
        open: true,
      })
    );
    return console.log(error);
  }
};

export const deleteBusinessName = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/tipo-razon-social/${id}`);
    dispatch(setRemoveBusinessName(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo de razon social eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.message,
        open: true,
      })
    );
  }
};

export const { setBusinessName, addBusinessName, setUpdateBusinessName, setRemoveBusinessName, setSuccess } =
  businessNameSlice.actions;

export default businessNameSlice.reducer;
