import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";

const initialState = {
  paises: [],
  success: false,
  status: "idle",
};

const paisesSlice = createSlice({
  name: "paises",
  initialState,
  reducers: {
    setPaises: (state, action) => {
      state.paises = action.payload;
      state.status = true;
      state.success = true;
    },
    create: (state, action) => {
      state.paises.push(action.payload);
      state.success = true;
    },
    remove: (state, action) => {
      const updated = state.paises.filter((el) => el.id !== action.payload);
      state.paises = updated;
      state.success = true;
    },
    update: (state, action) => {
      const updated = state.paises.filter((el) => el.id !== action.payload.id);
      state.paises = [...updated, action.payload];
      state.success = true;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getAllPaises = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/paises");
    dispatch(setPaises(response.data));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const { setPaises, create, remove, update, setStatus } = paisesSlice.actions;

export default paisesSlice.reducer;
