import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "",
    color: "#000000",
    padding: 10,
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function FacturasEmitidasTable({ rows }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Tipo Doc
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Tipo Venta
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Rut cliente
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "350px" }}>
              Razon Social
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Folio
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Fecha Docto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha Recepcion
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Fecha Acuse Recibo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha Reclamo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Monto Exento
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Monto Neto
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Monto IVA
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Monto total"
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              IVA Retenido Total
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              IVA Retenido Parcial
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              IVA no retenido
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              IVA propio
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              IVA Terceros
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              RUT Emisor Liquid. Factura
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Neto Comision Liquid. Factura
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Exento Comision Liquid. Factura
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              IVA Comision Liquid. Factura
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              IVA fuera de plazo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Tipo Docto. Referencia
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Folio Docto. Referencia
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Num. Ident. Receptor Extranjero
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Nacionalidad Receptor Extranjero
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Credito empresa constructora
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Impto. Zona Franca (Ley 18211)
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Garantia Dep. Envases
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Indicador Venta sin Costo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Indicador Servicio Periodico
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
              Monto No facturable
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Total Monto Periodo
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              Venta Pasajes Transporte Nacional
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "300px" }}>
              Venta Pasajes Transporte Internacional
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Numero Interno
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Codigo Sucursal
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "250px" }}>
              NCE o NDE sobre Fact. de Compra
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Codigo Otro Imp.
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Valor Otro Imp.
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Tasa Otro Imp.
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
              Fecha de Carga
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ minWidth: "100px" }}>
              Responsable
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="left">{row.tipo_doc}</StyledTableCell>
              <StyledTableCell align="left">{row.tipo_venta}</StyledTableCell>
              <StyledTableCell align="left">{row.rut_cliente}</StyledTableCell>
              <StyledTableCell align="left">{row.razon_social}</StyledTableCell>
              <StyledTableCell align="left">{row.folio}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_docto}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_recepcion}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_acuse_recibo}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_reclamo}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_exento}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_neto}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_iva}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_total}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_retenido_total}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_retenido_parcial}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_no_retenido}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_propio}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_terceros}</StyledTableCell>
              <StyledTableCell align="left">{row.rut_emisor_liquid__factura}</StyledTableCell>
              <StyledTableCell align="left">{row.neto_comision_liquid__factura}</StyledTableCell>
              <StyledTableCell align="left">{row.exento_comision_liquid__factura}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_comision_liquid__factura}</StyledTableCell>
              <StyledTableCell align="left">{row.iva_fuera_de_plazo}</StyledTableCell>
              <StyledTableCell align="left">{row.tipo_docto__referencia}</StyledTableCell>
              <StyledTableCell align="left">{row.folio_docto__referencia}</StyledTableCell>
              <StyledTableCell align="left">{row.num__ident__receptor_extranjero}</StyledTableCell>
              <StyledTableCell align="left">{row.nacionalidad_receptor_extranjero}</StyledTableCell>
              <StyledTableCell align="left">{row.credito_empresa_constructora}</StyledTableCell>
              <StyledTableCell align="left">{row.impto__zona_franca__ley_18211_}</StyledTableCell>
              <StyledTableCell align="left">{row.garantia_dep__envases}</StyledTableCell>
              <StyledTableCell align="left">{row.indicador_venta_sin_costo}</StyledTableCell>
              <StyledTableCell align="left">{row.indicador_servicio_periodico}</StyledTableCell>
              <StyledTableCell align="left">{row.monto_no_facturable}</StyledTableCell>
              <StyledTableCell align="left">{row.total_monto_periodo}</StyledTableCell>
              <StyledTableCell align="left">{row.venta_pasajes_transporte_nacional}</StyledTableCell>
              <StyledTableCell align="left">{row.venta_pasajes_transporte_internacional}</StyledTableCell>
              <StyledTableCell align="left">{row.numero_interno}</StyledTableCell>
              <StyledTableCell align="left">{row.codigo_sucursal}</StyledTableCell>
              <StyledTableCell align="left">{row.nce_o_nde_sobre_fact__de_compra}</StyledTableCell>
              <StyledTableCell align="left">{row.codigo_otro_imp_}</StyledTableCell>
              <StyledTableCell align="left">{row.valor_otro_imp_}</StyledTableCell>
              <StyledTableCell align="left">{row.tasa_otro_imp_}</StyledTableCell>
              <StyledTableCell align="left">{row.fecha_carga}</StyledTableCell>
              <StyledTableCell align="left">{row.responsable}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
