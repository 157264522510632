import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGridComponent from "../components/tables/DataGrid";
import { deleteItemType, getAllItemTypes, getAllModelsSelect } from "../redux/slices/inventory";
import ItemTypeCreate from "../components/modal/ItemTypeCreate";
import ItemTypeUpdate from "../components/modal/ItemTypeUpdate";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { value: "nombre", title: "Nombre", minWidth: 170 },
  { value: "codigo", title: "Codigo", minWidth: 170 },
  { value: "modeloText", title: "Modelo", minWidth: 100 },
  {
    value: "acciones_inv",
    title: "Acciones",
    minWidth: 170,
    align: "center",
  },
];

export default function ItemType() {
  const dispatch = useDispatch();
  const { itemType, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(deleteItemType(id));
    setOpen({ status: false, data: null });
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Tipo de equipo", false, setOpen);

  useEffect(() => {
    dispatch(getAllItemTypes());
    dispatch(getAllModelsSelect());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Elementos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Elementos"} />
        </div>
        <div>
          <ItemTypeCreate />
          <ItemTypeUpdate open={open.status} data={open.data} setOpen={setOpen} />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={itemType} success={success} />
    </div>
  );
}
