import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import DataPicker from "../dataPicker/DataPicker";
import { useDispatch } from "react-redux";
import { addProduct, editProduct } from "../../redux/slices/invProducts";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AssignmentModal({ idProduct, idCliente, action, f_contrato, f_garantia, f_instalacion }) {
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setGarantia("");
    setGarantiaError("");
  };
  const [garantia, setGarantia] = useState("");
  const [garantiaError, setGarantiaError] = useState();
  const [contrato, setContrato] = useState(null);
  const [instalacion, setInstalacion] = useState("");

  const handleGarantia = (date) => {
    setGarantia(date);
  };

  const handleContrato = (date) => {
    setContrato(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Object.keys(garantia).length) {
      setGarantiaError("* Ingresar fecha de garantía");
    } else {
      if (action === "edit") {
        setDisabled(true);
        await dispatch(
          editProduct({
            id: idProduct,
            f_garantia: garantia,
            f_contrato: contrato,
            f_actualizacion: dayjs(),
            f_instalacion: instalacion,
          })
        );
        window.location.reload();
      }
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" sx={{ width: "100px", height: "35px", borderRadius: 2 }}>
        {action === "add" ? "Añadir" : "Editar"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(5px)" } } }}
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label>Fecha de instalación:</label>
              <DataPicker onChange={(date) => setInstalacion(date)} action={action} fecha={f_instalacion} />
            </div>
            <div className="mb-5 ">
              <label>* Finalización de Garantia:</label>
              <DataPicker onChange={handleGarantia} action={action} fecha={f_garantia} />
              {garantiaError && <p className="text-[red] text-sm pt-2">{garantiaError}</p>}
            </div>
            <div>
              <label>Finalizacion de Contrato de Mantención:</label>
              <DataPicker onChange={handleContrato} action={action} fecha={f_contrato} />
            </div>
            <div className="flex justify-center mt-5">
              <Button variant="contained" sx={{ width: "100px", height: "35px" }} type="submit" disabled={disabled}>
                Añadir
                {disabled === true && (
                  <div className="w-[15px] h-[100%] flex items-center ml-2">
                    <CircularProgress style={{ width: "15px", height: "15px" }} />
                  </div>
                )}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
