import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import { Button, Chip, Divider, IconButton } from "@mui/material";
import dayjs from "dayjs";
import ControlledDatePicker from "../../components/ControlledDatePicker";
import NewModal from "../../components/newModal";
import { setOpen } from "../../redux/slices/createModal";
import { VacationRequest } from "./VacationRequest";
import CommentIcon from "@mui/icons-material/Comment";
import enviroments from "../../utils/enviroments";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { signVacation } from "../../redux/slices/remuneration";
import uploadFiles from "../../utils/UploadFiles";

export default function PortalInformation() {
  const { portal } = useSelector((state) => state.remuneration);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (portal) {
      reset({
        name: portal.nombre,
        rut: portal.rut,
        cargo: portal.gen_cargo.descripcion,
        department: portal.gen_departamento.descripcion,
        initialDate: dayjs(portal.Vacation?.initialDate),
        finalDate: portal.Vacation?.finalDate ? dayjs(portal.Vacation?.finalDate) : null,
        usedDays: portal.Vacation?.takenDays,
      });
    }
  }, [portal]);

  const initialDateWatcher = watch("initialDate");
  const finalDateWatcher = watch("finalDate");
  const usedDaysWatcher = watch("usedDays");
  const availableDays = watch("availableDays");
  const totalWatcher = watch("total");

  const handleCommentary = (obs, final) => {
    dispatch(
      setOpen({
        open: true,
        data: null,
        form: (
          <div className="p-2 min-h-40">
            {final && (
              <>
                <div className="mb-2 p-1 bg-blue-100 rounded text-xl uppercase font-bold ">Observación Final</div>
                <div className="p-2 rounded bg-neutral-100 mb-2">
                  <span className="px-1 text-gray-600">{final || "Sin Observación   "}</span>
                </div>
              </>
            )}
            <div className="mb-2 p-1 bg-blue-100 rounded text-xl uppercase font-bold">Observación</div>
            <div className="p-2 rounded bg-neutral-100">
              <span className="px-1 text-gray-600">{obs || "Sin Observación   "}</span>
            </div>
          </div>
        ),
      }),
    );
  };

  useEffect(() => {
    if (initialDateWatcher) {
      if (finalDateWatcher) {
        setValue(
          "availableDays",
          parseFloat(dayjs(finalDateWatcher).diff(initialDateWatcher, "days") * 0.0410958904109589).toFixed(2),
        );
        return;
      }

      setValue("availableDays", parseFloat(dayjs().diff(initialDateWatcher, "days") * 0.0410958904109589).toFixed(2));
    }
  }, [initialDateWatcher, finalDateWatcher]);

  useEffect(() => {
    if (availableDays && !isNaN(availableDays - usedDaysWatcher)) {
      setValue("total", parseFloat(availableDays - (usedDaysWatcher || 0)).toFixed(2));
    }
  }, [usedDaysWatcher, availableDays]);

  const handleClick = (file) => {
    dispatch(
      setOpen({
        open: true,
        data: null,
        form: <SignVacation file={file} />,
      }),
    );
  };

  return (
    <div>
      <Divider textAlign="center" sx={{ mb: 2 }}>
        <Chip sx={{ minWidth: "140px" }} color="primary" label="INFORMACIÓN" />
      </Divider>
      <div className="mb-3 flex gap-3">
        <div className="w-full">
          <ControlledTextField
            id="name"
            rules={{ required: true }}
            name="name"
            control={control}
            label="Nombre"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledTextField
            id="rut"
            rules={{ required: true }}
            name="rut"
            control={control}
            label="RUT"
            disabled={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="flex mb-3 gap-3">
        <div className="w-full">
          <ControlledTextField
            id="department"
            rules={{ required: false }}
            name="department"
            control={control}
            label="Departamento"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledTextField
            id="cargo"
            rules={{ required: false }}
            name="cargo"
            control={control}
            label="Cargo"
            disabled={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-full">
          <ControlledDatePicker
            id="initialDate"
            rules={{ required: true }}
            name="initialDate"
            control={control}
            label="Fecha de inicio"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledDatePicker
            id="finalDate"
            rules={{ required: false }}
            name="finalDate"
            control={control}
            label="Fecha de fin"
            disabled={true}
            errors={errors}
          />
        </div>
      </div>
      <Divider textAlign="center" sx={{ my: 3 }}>
        <Chip sx={{ minWidth: "140px" }} color="primary" label="VACACIONES" />
      </Divider>
      <div className="mb-3 flex gap-3">
        <div className="w-full">
          <ControlledTextField
            id="availableDays"
            rules={{ required: false }}
            name="availableDays"
            control={control}
            label="Numero de dias"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <ControlledTextField
            id="usedDays"
            rules={{ required: false, pattern: /^[1-9]\d*$/ }}
            name="usedDays"
            control={control}
            label="Dias tomados"
            disabled={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="mb-3 flex gap-3">
        <div className="w-full">
          <ControlledTextField
            id="total"
            rules={{ required: false }}
            name="total"
            control={control}
            label="Total de dias disponibles"
            disabled={true}
            errors={errors}
          />
        </div>
        <div className="w-full">
          <Button
            onClick={() => {
              dispatch(setOpen({ open: true, data: { totalWatcher }, form: <VacationRequest /> }));
            }}
            variant="outlined"
            color="success"
            fullWidth
          >
            Solicitar Vacaciones
          </Button>
        </div>
      </div>
      <Divider textAlign="center" sx={{ my: 3 }}>
        <Chip sx={{ minWidth: "140px" }} color="primary" label="HISTORIAL" />
      </Divider>
      <div
        className={`max-h-[295px] flex ${
          !portal.VacationRequest.length ? "justify-center" : undefined
        }  items-center flex-col w-full min-h-[295px] rounded overflow-auto my-4 pr-2`}
      >
        {portal.VacationRequest.length ? (
          [...portal.VacationRequest]
            .sort((a, b) => a.id - b.id)
            .map((file, index) => (
              <div
                key={index}
                className={`mb-2 rounded p-2 border-[1px] items-center w-full flex justify-between border-neutral-300`}
              >
                <div className="uppercase line-clamp-1 w-full text-neutral-700 text-sm ml-1 tracking-wider">
                  Solicitud {dayjs(file.initialDate).format("DD/MM/YYYY")} al{" "}
                  {dayjs(file.finalDate).format("DD/MM/YYYY")}
                </div>
                <div className="uppercase w-full text-neutral-700 text-sm text-center tracking-wider">
                  {file.totalDays} Días
                </div>
                <div className="uppercase w-full text-neutral-700 text-sm flex items-center gap-2 justify-center tracking-wider">
                  <Chip
                    label={
                      !file.signedFile && file.state === "ACEPTADA"
                        ? "DOCUMENTO PENDIENTE"
                        : file.state === "ACEPTADA" && !file.finalApprove
                        ? "VALIDACIÓN PENDIENTE"
                        : file.state
                    }
                    size="small"
                    clickable
                    onClick={() => {
                      if (!file.signedFile && file.state === "ACEPTADA") handleClick(file);
                    }}
                    sx={{ minWidth: 200 }}
                    color={
                      file.state === "ACEPTADA" && !file.signedFile
                        ? "warning"
                        : file.state === "PENDIENTE"
                        ? "primary"
                        : file.state === "ACEPTADA"
                        ? "success"
                        : "error"
                    }
                    variant="outlined"
                  />
                  <IconButton
                    onClick={() => {
                      handleCommentary(file.approverObservation, file.finalObservation);
                    }}
                    color={file.approverObservation ? "info" : "default"}
                  >
                    <CommentIcon />
                  </IconButton>
                </div>
              </div>
            ))
        ) : (
          <span className="w-full flex justify-center items-center h-[295px] text-neutral-500">
            No se encontraron solicitudes
          </span>
        )}
      </div>
      <NewModal />
    </div>
  );
}

const SignVacation = ({ file }) => {
  const [document, setDocument] = useState(null);

  const dispatch = useDispatch();

  const handleDocumentSend = async () => {
    if (document) {
      const res = await uploadFiles("docs", document);
      await dispatch(signVacation({ document: res.data, id: file.id }));
      dispatch(setOpen(false));
    }
  };

  return (
    <div className="w-full p-2">
      <div className="flex w-full gap-2 mb-4">
        <Button fullWidth variant="contained" component="label">
          <a href={`${enviroments.img_endpoint}${file.file}`} target="_blank" download>
            Descargar Comprobante para firmar
          </a>
        </Button>
        <Button
          color={!!document ? "success" : "primary"}
          variant={!!document ? "contained" : "outlined"}
          fullWidth
          startIcon={<AttachFileIcon />}
          component="label"
        >
          Elegir Documento Firmado
          <input
            hidden
            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
            type="file"
            onChange={(e) => setDocument(e.target.files[0])}
          />
        </Button>
      </div>
      <Button onClick={handleDocumentSend} fullWidth variant="outlined">
        Enviar Documento
      </Button>
    </div>
  );
};

const months = [
  { id: 0, label: "Enero" },
  { id: 1, label: "Febrero" },
  { id: 2, label: "Marzo" },
  { id: 3, label: "Abril" },
  { id: 4, label: "Mayo" },
  { id: 5, label: "Junio" },
  { id: 6, label: "Julio" },
  { id: 7, label: "Agosto" },
  { id: 8, label: "Septiembre" },
  { id: 9, label: "Octubre" },
  { id: 10, label: "Noviembre" },
  { id: 11, label: "Diciembre" },
];
