import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { updateRepairPart, updateRepairPartMold } from "../../redux/slices/inventory";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "90%", // 600
    md: "60%", // 900
    lg: "60%", // 1200
    xl: "60%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.3)",
    outline: "1px solid slategrey",
  },
};

export default function RepairPartUpdate({ open, data, setOpen, mold, arr }) {
  const { brandsSelect, teamTypesSelect, statusSelect, bodegas, providersSelect } = useSelector(
    (state) => state.inventory,
  );
  const [generic, setGeneric] = React.useState();
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const handleClose = () => {
    setOpen({ status: false, data: null });
    reset();
  };

  const submit = async (values) => {
    values.id = data.id;
    values.generico = generic;
    if (generic) {
      values.tipo_equipo = null;
    }

    if (mold) {
      const isDuplicateCodigoLoginteg = arr
        .filter((obj) => obj.id !== data.id)
        .some((obj) => obj.codigo_loginteg.toLowerCase() === values.codigo_loginteg.toLowerCase());

      const isDuplicateDescripcion = arr
        .filter((obj) => obj.id !== data.id)
        .some((obj) => obj.descripcion.toLowerCase() === values.descripcion.toLowerCase());

      let error = null;

      if (isDuplicateCodigoLoginteg && isDuplicateDescripcion) {
        error = "El Codigo Loginteg y Nombre del repuesto ya existen";
      } else if (isDuplicateCodigoLoginteg) {
        error = "El Codigo Loginteg ya existe";
      } else if (isDuplicateDescripcion) {
        error = "El Nombre del repuesto ya existe";
      }

      if (error) {
        setError(error);
        return;
      } else {
        setError(null);
        dispatch(updateRepairPartMold(values));
      }
    } else {
      dispatch(updateRepairPart(data.id, values.estado, values.cantidad, values.valueCost, values.bodegaId));
    }
    reset();
    handleClose();
  };

  React.useEffect(() => {
    if (data?.id) {
      setGeneric(data.generico);
    }
  }, [data?.id]);

  const statusWatcher = watch("estado");
  const defaultStorage = bodegas.find((el) => el.main);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {data?.id && (
              <form onSubmit={handleSubmit(submit)}>
                <div className="flex">
                  <div className="w-full mr-5">
                    <div className={`flex mb-5 gap-4`}>
                      <TextField
                        fullWidth
                        disabled={!mold}
                        id={"codigo_loginteg"}
                        label={"Codigo Loginteg"}
                        type={"text"}
                        {...register("codigo_loginteg", { required: mold ? true : false })}
                        error={Boolean(errors["codigo_loginteg"])}
                        helperText={Boolean(errors["codigo_loginteg"]) && "El campo es requerido"}
                        defaultValue={data && data?.codigo_loginteg ? data.codigo_loginteg : ""}
                      />
                      <TextField
                        fullWidth
                        id={"descripcion"}
                        label={"Nombre del Repuesto"}
                        disabled={!mold}
                        type={"text"}
                        {...register("descripcion", { required: mold ? true : false })}
                        error={Boolean(errors["descripcion"])}
                        helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                        defaultValue={data && data?.descripcion ? data.descripcion : ""}
                      />
                    </div>
                    <div className="flex gap-4">
                      <TextField
                        fullWidth
                        id={"serie"}
                        disabled={!mold}
                        label={"Código Proveedor"}
                        type={"text"}
                        {...register("serie")}
                        error={Boolean(errors["serie"])}
                        helperText={Boolean(errors["serie"]) && "El campo es requerido"}
                        defaultValue={data && data?.serie ? data.serie : ""}
                      />
                      <TextField
                        fullWidth
                        id={"marca"}
                        label={"Marca"}
                        disabled={!mold}
                        type={"text"}
                        {...register("marca")}
                        error={Boolean(errors["marca"])}
                        helperText={Boolean(errors["marca"]) ? "El campo es requerido" : " "}
                        defaultValue={data && data?.marca ? data.marca : ""}
                        select
                      >
                        {brandsSelect.length &&
                          brandsSelect
                            ?.filter((brand) => brand.repuesto)
                            .map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.descripcion}
                              </MenuItem>
                            ))}
                      </TextField>
                    </div>

                    <div className={`flex gap-4`}>
                      <TextField
                        fullWidth
                        id={"modelo"}
                        label={"Modelo"}
                        disabled={!mold}
                        type={"text"}
                        defaultValue={data && data?.modelo ? data.modelo : ""}
                        {...register("modelo")}
                        helperText={Boolean(errors["modelo"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["modelo"])}
                      />
                      <TextField
                        fullWidth
                        id={"medidas"}
                        disabled={!mold}
                        label={"Medidas"}
                        type={"text"}
                        {...register("medidas", { required: mold ? true : false })}
                        error={Boolean(errors["medidas"])}
                        helperText={Boolean(errors["medidas"]) && "El campo es requerido"}
                        defaultValue={data && data?.medidas ? data.medidas : ""}
                      />
                    </div>
                    <div className="flex">
                      <TextField
                        fullWidth
                        id={"generico"}
                        disabled={!mold}
                        label={"Es Generico"}
                        type={"text"}
                        defaultValue={data && data?.generico ? data.generico : false}
                        {...register("generico")}
                        helperText={Boolean(errors["generico"]) ? "El campo es requerido" : " "}
                        error={Boolean(errors["generico"])}
                        onChange={(e) => setGeneric(e.target.value)}
                        select
                        sx={!generic && { mr: 2 }}
                      >
                        <MenuItem key={1} value={true}>
                          {"SI"}
                        </MenuItem>
                        <MenuItem key={2} value={false}>
                          {"NO"}
                        </MenuItem>
                      </TextField>
                      {!generic && (
                        <TextField
                          fullWidth
                          id={"tipo_equipo"}
                          label={"Compatibilidad"}
                          disabled={!mold}
                          type={"text"}
                          {...register("tipo_equipo")}
                          defaultValue={data && data?.tipo_equipo ? data.tipo_equipo : ""}
                          select
                        >
                          {teamTypesSelect.length &&
                            teamTypesSelect.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.descripcion}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </div>
                    {!mold && (
                      <div className={`flex mb-5`}>
                        <TextField
                          fullWidth
                          id={"cantidad"}
                          label={"Cantidad"}
                          sx={{ mr: 2 }}
                          type={"text"}
                          {...register("cantidad", { required: true, pattern: /^[0-9]*$/ })}
                          error={Boolean(errors["cantidad"])}
                          helperText={
                            (Boolean(errors?.cantidad?.type === "required") && "El campo es requerido") ||
                            (Boolean(errors?.cantidad?.type === "pattern") && "El campo solo admite numeros")
                          }
                          defaultValue={data && data?.cantidad ? data.cantidad : ""}
                        />
                        <TextField
                          fullWidth
                          id={"estado"}
                          label={"Estado"}
                          type={"text"}
                          {...register("estado", { required: mold ? true : false })}
                          error={Boolean(errors["estado"])}
                          helperText={Boolean(errors["estado"]) && "El campo es requerido"}
                          defaultValue={data && data?.estado ? data.estado : ""}
                          select
                        >
                          {statusSelect.length &&
                            statusSelect.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.descripcion}
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                    )}
                    <div className="flex">
                      {!mold && (
                        <TextField
                          sx={{ mr: 2 }}
                          fullWidth
                          id={"valueCost"}
                          label={"Valor"}
                          type={"text"}
                          {...register("valueCost", { required: true })}
                          error={Boolean(errors["valueCost"])}
                          helperText={Boolean(errors["valueCost"]) ? "El campo es requerido" : " "}
                          defaultValue={data && data?.valueCost ? data.valueCost : ""}
                        />
                      )}
                      {(statusWatcher === "4" || statusWatcher === 4 || data.bodegaId) && (
                        <TextField
                          fullWidth
                          id={"bodegaId"}
                          label={"Bodega"}
                          type={"text"}
                          {...register("bodegaId")}
                          helperText={Boolean(errors["bodegaId"]) ? "El campo es requerido" : " "}
                          error={Boolean(errors["proveedor"])}
                          select
                          defaultValue={data && data?.bodegaId ? data.bodegaId : ""}
                        >
                          {bodegas.length &&
                            bodegas?.map((option) => (
                              <MenuItem key={option.id} value={option?.id || ""}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Button fullWidth type="submit" variant="contained">
                    Enviar formulario
                  </Button>
                  {error && (
                    <div className="w-full p-2 bg-red-100 rounded mt-2 text-red-500 flex gap-2 items-center mr-5">
                      <ErrorOutlineIcon />
                      {error}
                    </div>
                  )}
                </div>
              </form>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
