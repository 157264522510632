import React from "react";
import TransitionsModal from "../components/modal/TransitionsModal";
import TeamTypeSchema from "../resolvers/teamType.resolver";
import useConstantTeamTypes from "../components/modal/constants/useConstantTeamTypes";
import DataGridComponent from "../components/tables/DataGrid";
import { useSelector, useDispatch } from "react-redux";
import useDataGrid from "../hooks/useDataGrid";
import { useEffect } from "react";
import EditModal from "../components/modal/EditModal";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { setOpen } from "../redux/slices/createModal";
import { setOpen as setOpenEdit } from "../redux/slices/editModal";
import { createTeamType, getAllTeamTypes, updateTeamType, deleteTeamType } from "../redux/slices/inventory";

const permitedRenderDataColumn = [
  { title: "ID", value: "id", hide: true },
  { title: "Descripcion", value: "descripcion" },
  { title: "Acciones", value: "acciones" },
];

export default function TeamTypes() {
  const { teamTypes, success } = useSelector((state) => state.inventory);
  const { id } = useSelector((state) => state.editModal);
  const [fields, element] = useConstantTeamTypes(teamTypes);
  const dispatch = useDispatch();

  const handleDelete = async (params) => {
    const id = params.id;

    dispatch(deleteTeamType(id));
    dispatch(setOpen(false));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Tipo de equipo");

  const newTeamtype = async (value) => {
    dispatch(createTeamType(value));
    dispatch(setOpen(false));
  };

  const handleUpdate = async (values) => {
    dispatch(updateTeamType({ ...values, id }));
    dispatch(setOpenEdit({ open: false }));
  };

  useEffect(() => {
    dispatch(getAllTeamTypes());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Tipos de Equipos</h1>
          <BreadCrumb origin={"Mantenedores"} current={"Tipos de Equipo"} />
        </div>
        <div>
          <TransitionsModal
            size="500px"
            data={element}
            fields={fields}
            schema={TeamTypeSchema}
            submit={newTeamtype}
            type={"create"}
          />
          <EditModal fields={fields} schema={TeamTypeSchema} submit={handleUpdate} type={"edit"} size="500px" />
        </div>
      </div>
      <DataGridComponent columns={columns} rows={teamTypes} success={success} />
    </div>
  );
}
