import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch } from "react-redux";
import { Button, TextField, Chip } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { createServices } from "../../redux/slices/services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function ServicesCreate() {
  const [open, setOpen] = React.useState(false);
  const [equipo, setEquipo] = React.useState(false);
  const [repuesto, setRepuesto] = React.useState(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handle_submit = async (value) => {
    dispatch(createServices(value));
    reset();
    setOpen(false);
  };

  return (
    <div>
      <Chip
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ textTransform: "none" }}
        label="Añadir Servicio"
        color="primary"
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(handle_submit)}>
              <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR SERVICIO</div>
              <div className="min-h-[65px] mb-1">
                <Controller
                  id="nombre"
                  name="nombre"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Nombre"
                      onChange={onChange}
                      size="small"
                      value={value || ""}
                      error={errors.nombre}
                      helperText={errors.nombre?.message}
                    />
                  )}
                />
              </div>
              <div className="min-h-[65px]">
                <Controller
                  id="codigo"
                  name="codigo"
                  control={control}
                  rules={{ required: "* Campo requerido" }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      label="Código"
                      onChange={onChange}
                      size="small"
                      value={value || ""}
                      error={errors.codigo}
                      helperText={errors.codigo?.message}
                    />
                  )}
                />
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="outlined" fullWidth>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
