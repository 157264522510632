import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const ApproveRejectDialog = ({ open, setOpen, handleSubmit, isFirstStep, data }) => {
  const [showObs, setShowObs] = useState({ open: false });
  const [file, setFile] = useState(null);
  const [val, setVal] = useState("");

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setShowObs({ open: false });
        setVal("");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ width: 400, height: "auto" }}>
        <div className="w-full p-2">
          {isFirstStep && showObs?.state === "accept" && (
            <div className="mb-3">
              <Button
                color={!!file ? "success" : "primary"}
                variant={!!file ? "contained" : "outlined"}
                fullWidth
                startIcon={<AttachFileIcon />}
                size="small"
                component="label"
                sx={{ mb: 1 }}
              >
                Subir Documento
                <input
                  hidden
                  accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Button>
            </div>
          )}
          {(showObs?.state === "reject" || (showObs?.state === "accept" && isFirstStep)) && (
            <>
              <TextField
                fullWidth
                label="Observación"
                value={val}
                onChange={(e) => setVal(e.target.value)}
                rows={4}
                multiline
                sx={{ mb: 2 }}
              />
            </>
          )}
          {(!showObs?.state || showObs.state === "accept") && (
            <Button
              onClick={() => {
                if (!showObs?.state && isFirstStep) {
                  setShowObs({ open: true, state: "accept" });
                } else {
                  handleSubmit(true, val, file, isFirstStep);
                }
              }}
              sx={{ mb: 2 }}
              variant="outlined"
              color="success"
              fullWidth
            >
              Aceptar
            </Button>
          )}
          {(!showObs?.state || showObs.state === "reject") && (
            <Button
              onClick={() => {
                if (!showObs?.state) {
                  setShowObs({ open: true, state: "reject", isFirstStep });
                } else {
                  handleSubmit(false, val);
                }
              }}
              variant="outlined"
              color="error"
              fullWidth
            >
              Rechazar
            </Button>
          )}
          {isFirstStep ? (
            <div className="text-gray-500 mt-3 text-center">
              Esta solicitud de vacaciones se encuentra en su primera etapa, habiendo sido solicitada.
            </div>
          ) : (
            <div className="text-gray-500 mt-3 text-center">
              Esta solicitud de vacaciones se encuentra en su segunda etapa, habiendo sido adjuntado el documento
              firmado por el solicitante.
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ApproveRejectDialog;
