import React from "react";
import { Page, Text, View, Document, StyleSheet, Font, pdf, Image, PDFViewer } from "@react-pdf/renderer";
import enviroments from "../../utils/enviroments";
import RobotoBold from "../../assets/Roboto-Bold.ttf";
import RobotoRegular from "../../assets/Roboto-Regular.ttf";
import dateFormatter from "../../utils/dateFormatter";
import dayjs from "dayjs";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

const styles = StyleSheet.create({
  borderedView: { border: "2px solid black", height: "100%" },
  titleHeaderBlue: {
    backgroundColor: "#2B2C84",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "14pt",
  },
  formTitle: { fontSize: "9pt", fontWeight: "bold", minWidth: "40px", marginRight: "5px" },
  formTitleMedium: { fontSize: "9pt", fontWeight: "bold", minWidth: "65px", marginRight: "5px" },
  formTitleLarge: { fontSize: "9pt", fontWeight: "bold", minWidth: "80px", marginRight: "5px" },
  formTitleXLarge: { fontSize: "9pt", fontWeight: "bold", minWidth: "100px", marginRight: "5px" },
  formTitleTiny: { fontSize: "9pt", fontWeight: "bold", minWidth: "10px" },
  formData: {
    fontSize: "9pt",
    borderBottom: "2px solid black",
    marginLeft: "10px",
    marginRight: "10px",
    width: "100%",
  },
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
  ],
});

const fullCapitalize = (str) => {
  if (!str) {
    return "S/D";
  }

  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const PDFComponent = ({ data, current, current_rut }) => {
  const totalPages = Math.ceil(data.ot_imagen_ot_imagen_otToot.length / 4);
  const totalPagesSkilled = Math.ceil(data.skilledPerson.length / 8);
  const result = groupByEquipment(data.skilledPerson);

  const principalContact = data.cliente_ot_clienteTocliente.contacto_contacto_clienteTocliente.filter(
    (contacto) => contacto.principal,
  );

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: 10,
          display: "flex",
          fontFamily: "Roboto",
        }}
      >
        <View style={styles.borderedView}>
          <View
            style={{ borderBottom: "2px solid black", paddingBottom: "5px", display: "flex", flexDirection: "row" }}
          >
            <Image
              source={`${enviroments.img_endpoint}${"loginteg.png"}`}
              style={{ width: "200px", height: "100px" }}
            />
            <View style={{ display: "flex", justifyContent: "center", alignItems: "start", marginLeft: "10px" }}>
              <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>LOGISTICA INTEGRAL SpA</Text>
              <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>SERVICIO TECNICO AUTORIZADO</Text>
              <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>INSTALACIONES - SALUD</Text>
              <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
                Sede Comercial: Los canteros 8781, La Reina, Santiago de Chile
              </Text>
              <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
                E-mail: instaladores@loginteg.cl; pcaro@loginteg.cl
              </Text>
              <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>Cel: +56931454472</Text>
            </View>
            <View>
              <View style={{ flexDirection: "row", margin: "5px" }}>
                <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>No.</Text>
                <Text style={{ width: "90px", borderBottom: "2px solid black", fontSize: "9pt" }}>{data.id}</Text>
              </View>
              <View style={{ flexDirection: "row", margin: "5px" }}>
                <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>Fecha.</Text>
                <Text style={{ width: "75px", borderBottom: "2px solid black", fontSize: "9pt" }}>
                  {dateFormatter(data?.f_ot, "DD/MM/YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ borderBottom: "2px solid black", padding: "5px", paddingHorizontal: "20px" }}>
            <Text style={{ fontSize: "9pt", textAlign: "center", fontWeight: "bold" }}>
              SI LA VISITA ES POR MOTIVOS AJENOS A LA LOGISTICA INTEGRAL O PRODUCTOS POR FUERA DE GARANTIA, ESTE
              SERVICIO SERA FACTURADO
            </Text>
          </View>
          <View style={{ borderBottom: "2px solid black" }}>
            <Text style={styles.titleHeaderBlue}>INFORMACIÓN DEL CLIENTE</Text>
            <View style={{ marginTop: "10px", marginHorizontal: "10px" }}>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "10px" }}>
                <View style={{ width: "70%", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>CLIENTE :</Text>
                  <Text style={styles.formData}>{capitalizeFirstLetter(data?.cliente_ot_clienteTocliente.nombre)}</Text>
                </View>
                <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                  <Text style={styles.formTitleTiny}>RUT :</Text>
                  <Text style={styles.formData}>{data?.cliente_ot_clienteTocliente.rut}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "10px" }}>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>SERVICIO :</Text>
                  <Text style={styles.formData}>{capitalizeFirstLetter(data?.servicio)}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "10px" }}>
                <View style={{ width: "60%", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>DIRECCIÓN :</Text>
                  <Text style={styles.formData}>
                    {capitalizeFirstLetter(data?.cliente_ot_clienteTocliente.direccion)}
                  </Text>
                </View>
                <View style={{ width: "40%", display: "flex", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>TELÉFONO :</Text>
                  <Text style={styles.formData}>{principalContact[0] ? principalContact[0].telefono1 : "S/D"}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "10px" }}>
                <View style={{ width: "60%", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>REGIÓN :</Text>
                  <Text style={styles.formData}>
                    {capitalizeFirstLetter(data?.cliente_ot_clienteTocliente.gen_region.descripcion)}
                  </Text>
                </View>
                <View style={{ width: "40%", display: "flex", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>COMUNA :</Text>
                  <Text style={styles.formData}>
                    {capitalizeFirstLetter(data?.cliente_ot_clienteTocliente.gen_comuna.descripcion)}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "10px" }}>
                <View style={{ width: "60%", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>CONTACTO :</Text>
                  <Text style={styles.formData}>{principalContact[0] ? principalContact[0].nombre : "S/D"}</Text>
                </View>
                <View style={{ width: "40%", display: "flex", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>CELULAR :</Text>
                  <Text style={styles.formData}>{principalContact[0] ? principalContact[0].telefono2 : "S/D"}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "10px" }}>
                <View style={{ width: "60%", flexDirection: "row" }}>
                  <Text style={styles.formTitleLarge}>HORARIO ATENCIÓN :</Text>
                  <Text style={styles.formData}>{data?.horario_atencion ? data?.horario_atencion : "S/D"}</Text>
                </View>
                <View style={{ width: "40%", display: "flex", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>E-MAIL :</Text>
                  <Text style={styles.formData}>{principalContact[0] && principalContact[0].correo1}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", display: "flex", width: "100%", marginBottom: "7px" }}>
                <View style={{ width: "40%", flexDirection: "row" }}>
                  <Text style={styles.formTitle}>GARANTIA :</Text>
                  <View style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                    <Text style={{ fontSize: "10pt", marginRight: "10px", marginLeft: "10px" }}>SI</Text>
                    <View
                      style={{
                        width: "25px",
                        height: "17px",
                        border: "2px solid black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontSize: "10px" }}>{data?.garantia && "X"}</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                    <Text style={{ fontSize: "10pt", marginRight: "10px", marginLeft: "10px" }}>NO</Text>
                    <View
                      style={{
                        width: "25px",
                        height: "17px",
                        border: "2px solid black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontSize: "10px" }}>{!data?.garantia && "X"}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.titleHeaderBlue}>EQUIPOS</Text>
            {/* tabla */}
            <View style={{ width: "100%", position: "relative", maxHeight: "150px", borderBottom: "2px solid black" }}>
              <View
                style={{
                  position: "absolute",
                  left: "25%",
                  width: "2px",
                  height: "100%",
                  backgroundColor: "black",
                }}
              ></View>
              <View
                style={{
                  position: "absolute",
                  left: "50%",
                  width: "2px",
                  height: "100%",
                  backgroundColor: "black",
                }}
              ></View>
              <View
                style={{
                  position: "absolute",
                  right: "24.7%",
                  width: "2px",
                  height: "100%",
                  backgroundColor: "black",
                }}
              ></View>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Text
                  style={{
                    width: "25%",
                    fontSize: "10pt",
                    padding: "2px",
                    borderBottom: "2px solid black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  NOMBRE
                </Text>
                <Text
                  style={{
                    width: "25%",
                    borderBottom: "2px solid black",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    padding: "2px",
                    textAlign: "center",
                  }}
                >
                  MODELO
                </Text>
                <Text
                  style={{
                    width: "25%",
                    borderBottom: "2px solid black",
                    fontSize: "10pt",
                    padding: "2px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  SERIE
                </Text>
                <Text
                  style={{
                    width: "25%",
                    borderBottom: "2px solid black",
                    fontSize: "10pt",
                    padding: "2px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  FECHA INSTALACIÓN
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  height: "100%",
                }}
              >
                {data?.ot_equipo_ot_equipo_otToot?.map((equipment, index) => {
                  const equipmentData = equipment.inv_equipo;
                  return (
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                      }}
                      key={index}
                    >
                      <View
                        style={{
                          width: "25%",
                          fontSize: "10pt",
                        }}
                      >
                        <Text style={{ fontSize: "9pt", margin: "2px 0px 1px 5px" }}>
                          {capitalizeFirstLetter(equipmentData.descripcion)}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "25%",
                          fontSize: "10pt",
                        }}
                      >
                        <Text style={{ fontSize: "9pt", margin: "2px 0px 1px 5px" }}>
                          {capitalizeFirstLetter(equipmentData.modelo)}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "25%",
                          fontSize: "10pt",
                        }}
                      >
                        <Text style={{ fontSize: "9pt", margin: "2px 0px 1px 5px" }}>
                          {capitalizeFirstLetter(equipmentData.serie)}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "25%",
                          fontSize: "10pt",
                        }}
                      >
                        <Text style={{ fontSize: "9pt", margin: "2px 0px 1px 5px" }}>
                          {dateFormatter(equipmentData?.f_instalacion, "DD/MM/YYYY")}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          <View style={{}}>
            <Text style={styles.titleHeaderBlue}>OBSERVACIONES</Text>
            <View
              style={{
                // height: "90px",
                maxHeight: "300px",
                // borderBottom: "2px solid black",
                padding: "10px",
                display: "flex",
                // justifyContent: "center",
              }}
            >
              <View style={{ fontSize: "10pt", width: "100%", height: "100%" }}>
                {data?.ot_observacion_ot_observacion_otToot.map((obs, index) => (
                  <View
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      maxWidth: "85%",
                      wordWrap: "break-word",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ marginRight: "5px" }}>{dateFormatter(obs.f_creaci_n)}</Text>
                    <Text style={{ wordWrap: "break-word" }}>{capitalizeFirstLetter(obs.descripcion)}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>

      {Array.from({ length: totalPages }, (_, pageIndex) => {
        const startIndex = pageIndex * 4;
        const endIndex = startIndex + 4;
        const pageItems = data.ot_imagen_ot_imagen_otToot.slice(startIndex, endIndex);

        return (
          <Page
            style={{
              padding: 10,
              display: "flex",
              fontFamily: "Roboto",
            }}
            key={pageIndex}
            size="A4"
          >
            <View style={styles.borderedView}>
              <View
                style={{
                  borderBottom: "2px solid black",
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Image
                  source={`${enviroments.img_endpoint}${"loginteg.png"}`}
                  style={{ width: "200px", height: "100px" }}
                />
                <View style={{ display: "flex", justifyContent: "center", alignItems: "start", marginLeft: "10px" }}>
                  <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>LOGISTICA INTEGRAL SpA</Text>
                  <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>
                    SERVICIO TECNICO AUTORIZADO
                  </Text>
                  <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>INSTALACIONES - SALUD</Text>
                  <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
                    Sede Comercial: Los canteros 8781, La Reina, Santiago de Chile
                  </Text>
                  <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
                    E-mail: pcaro@loginteg.cl;agarcia@loginteg.cl
                  </Text>
                  <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>Cel: 82865649 - 91288370</Text>
                </View>
                <View>
                  <View style={{ flexDirection: "row", margin: "5px" }}>
                    <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>No.</Text>
                    <Text style={{ width: "90px", borderBottom: "2px solid black", fontSize: "9pt" }}>{data.id}</Text>
                  </View>
                  <View style={{ flexDirection: "row", margin: "5px" }}>
                    <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>Fecha.</Text>
                    <Text style={{ width: "75px", borderBottom: "2px solid black", fontSize: "9pt" }}>
                      {dateFormatter(data?.f_ot, "DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={styles.titleHeaderBlue}>INFORME SITUACIÓN</Text>
              <View style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", height: "100%" }}>
                {pageItems.map((item, index) => (
                  <View
                    key={index}
                    style={
                      index % 2 === 0
                        ? { width: "50%", height: "50%", borderRight: "1px solid black" }
                        : { width: "50%", height: "50%", borderLeft: "1px solid black" }
                    }
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontSize: "10pt",
                        fontWeight: "bold",
                        textAlign: "center",
                        backgroundColor: "#cec6c6",
                        borderTop: "2px solid black",
                        padding: "2px",
                      }}
                    >
                      IMAGEN # {index + 1}
                    </Text>
                    <View style={{ width: "100%", padding: "10px", height: "70%" }}>
                      <Image
                        source={`${enviroments.img_endpoint}${item.img}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                    </View>
                    <Text
                      style={{
                        fontSize: "8pt",
                        width: "100%",
                        textAlign: "center",
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        paddingTop: "4px",
                      }}
                    >
                      NOTAS
                    </Text>
                    <View style={{ height: "75px", padding: "5px", borderBottom: "2px solid black" }}>
                      <Text style={{ fontSize: "9pt" }}>
                        <Text style={{ fontSize: "9pt", fontWeight: "bold" }}>TECNICO</Text>:
                        {capitalizeFirstLetter(item.gen_usuario.nombre)}
                      </Text>
                      <Text style={{ fontSize: "9pt" }}>
                        <Text style={{ fontSize: "9pt", fontWeight: "bold" }}>FECHA</Text>:
                        {dateFormatter(item.f_creacion)}
                      </Text>
                      <Text style={{ fontSize: "9pt", maxHeight: "50px" }}>
                        <Text style={{ fontSize: "9pt", fontWeight: "bold" }}>OBSERVACIÓN</Text>:
                        {capitalizeFirstLetter(item.descripcion)}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </Page>
        );
      })}
      {/* {!result.length
        ? null
        : result.map((dataArray, index) => {
            return <React.Fragment key={index}>{renderPDFPages(dataArray, data, index)}</React.Fragment>;
          })} */}
      <Page
        size="A4"
        style={{
          padding: 10,
          display: "flex",
          fontFamily: "Roboto",
        }}
      >
        <View style={styles.borderedView}>
          <View style={{ borderBottom: "2px solid black" }}>
            <View
              style={{
                borderBottom: "2px solid black",
                paddingBottom: "5px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Image
                source={`${enviroments.img_endpoint}${"loginteg.png"}`}
                style={{ width: "200px", height: "100px" }}
              />
              <View style={{ display: "flex", justifyContent: "center", alignItems: "start", marginLeft: "10px" }}>
                <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>LOGISTICA INTEGRAL SpA</Text>
                <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>SERVICIO TECNICO AUTORIZADO</Text>
                <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>INSTALACIONES - SALUD</Text>
                <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
                  Sede Comercial: Los canteros 8781, La Reina, Santiago de Chile
                </Text>
                <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
                  E-mail: pcaro@loginteg.cl;agarcia@loginteg.cl
                </Text>
                <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>Cel: 82865649 - 91288370</Text>
              </View>
              <View>
                <View style={{ flexDirection: "row", margin: "5px" }}>
                  <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>No.</Text>
                  <Text style={{ width: "90px", borderBottom: "2px solid black", fontSize: "9pt" }}>{data.id}</Text>
                </View>
                <View style={{ flexDirection: "row", margin: "5px" }}>
                  <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>Fecha.</Text>
                  <Text style={{ width: "75px", borderBottom: "2px solid black", fontSize: "9pt" }}>
                    {dateFormatter(data?.f_ot, "DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.titleHeaderBlue}>CUMPLIMIENTO DE LA VISITA</Text>
            <View
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                paddingHorizontal: "10px",
                paddingVertical: "10px",
              }}
            >
              <View style={{ width: "50%", flexDirection: "row" }}>
                <Text style={styles.formTitleMedium}>FECHA INICIAL :</Text>
                <Text style={styles.formData}>{dateFormatter(data?.f_ot, "DD/MM/YYYY")}</Text>
              </View>
              <View style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                <Text style={styles.formTitleMedium}>FECHA TERMINO :</Text>
                <Text style={styles.formData}>{dayjs().format("DD/MM/YYYY")}</Text>
              </View>
            </View>
          </View>
          <View
            style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", paddingVertical: "10px" }}
          >
            <View style={{ marginHorizontal: "10px", width: "200px", textAlign: "center", height: "120px" }}>
              <Text style={{ fontSize: "10pt", fontWeight: "bold" }}>REPRESENTANTE TECNICO</Text>
              <Image
                source={`${enviroments.img_endpoint}${data?.firma_tecnico}`}
                style={{
                  maxWidth: "100%",
                  height: "100%",
                  marginVertical: "10px",
                  marginHorizontal: "10px",
                  objectFit: "contain",
                }}
              />
              <View style={{ width: "100%", borderBottom: "1px dotted black" }}></View>
              <View style={{ width: "100%", textAlign: "start" }}>
                <Text style={{ fontSize: "9pt", marginTop: "2px" }}>
                  <Text style={{ fontWeight: "bold" }}>NOMBRE</Text>:{fullCapitalize(current)}
                </Text>
                <Text style={{ fontSize: "9pt", marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>RUT</Text>: {current_rut}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginHorizontal: "10px",
                width: "200px",
                textAlign: "center",
                height: "120px",
              }}
            >
              <Text
                style={{
                  fontSize: "10pt",
                  fontWeight: "bold",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                CLIENTE
              </Text>
              <Image
                source={`${enviroments.img_endpoint}${data?.firma_cliente}`}
                style={{
                  width: "100%",
                  height: "100%",
                  marginVertical: "10px",
                  marginHorizontal: "10px",
                  objectFit: "contain",
                }}
              />

              <View style={{ width: "100%", borderBottom: "1px dotted black" }}></View>
              <View style={{ width: "100%", textAlign: "start" }}>
                <Text style={{ fontSize: "9pt", marginTop: "2px" }}>
                  <Text style={{ fontWeight: "bold" }}>NOMBRE</Text>: {fullCapitalize(data?.img_cliente)}
                </Text>
                <Text style={{ fontSize: "9pt", marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>RUT</Text>: {data?.rut_cliente}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              borderTop: "2px solid black",
              width: "100%",
              marginTop: "10px",
              textAlign: "center",
              paddingTop: "2px",
            }}
          >
            <Text style={{ fontSize: "9pt", fontWeight: "bold" }}>
              LA FIRMA DE ESTE DOCUMENTO CONSTATA LA RECEPCIÓN CONFORME POR PARTE DEL CLIENTE
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const renderPDFPages = (data, ot) => {
  const itemsPerPage = 19;
  const totalPages = Math.ceil(data.flat().length / itemsPerPage);

  const pages = [];

  for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
    const startIndex = pageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedData = data.flat().slice(startIndex, endIndex);

    const page = renderPDFPage(slicedData, ot, pageIndex);
    pages.push(page);
  }

  return pages;
};

const renderPDFPage = (data, ot, index) => {
  const team = ot?.ot_equipo_ot_equipo_otToot?.find((el) => el.equipo === data[index].equipment)?.inv_equipo;

  return (
    <Page
      key={index}
      style={{
        padding: 10,
        display: "flex",
        fontFamily: "Roboto",
      }}
      size="A4"
    >
      <View style={styles.borderedView}>
        <View
          style={{
            borderBottom: "2px solid black",
            paddingBottom: "5px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Image source={`${enviroments.img_endpoint}${"loginteg.png"}`} style={{ width: "200px", height: "100px" }} />
          <View style={{ display: "flex", justifyContent: "center", alignItems: "start", marginLeft: "10px" }}>
            <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>LOGISTICA INTEGRAL SpA</Text>
            <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>SERVICIO TECNICO AUTORIZADO</Text>
            <Text style={{ fontSize: "10pt", fontWeight: "bold", marginTop: 5 }}>INSTALACIONES - SALUD</Text>
            <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
              Sede Comercial: Los canteros 8781, La Reina, Santiago de Chile
            </Text>
            <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>
              E-mail: pcaro@loginteg.cl;agarcia@loginteg.cl
            </Text>
            <Text style={{ fontSize: "9pt", fontWeight: "bold", marginTop: 5 }}>Cel: 82865649 - 91288370</Text>
          </View>
          <View>
            <View style={{ flexDirection: "row", margin: "5px" }}>
              <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>No.</Text>
              <Text style={{ width: "90px", borderBottom: "2px solid black", fontSize: "9pt" }}>{ot.id}</Text>
            </View>
            <View style={{ flexDirection: "row", margin: "5px" }}>
              <Text style={{ fontSize: "9pt", marginRight: 5, fontWeight: "bold" }}>Fecha.</Text>
              <Text style={{ width: "75px", borderBottom: "2px solid black", fontSize: "9pt" }}>
                {dateFormatter(ot?.f_ot, "DD/MM/YYYY")}
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.titleHeaderBlue}>CAPACITACIONES {team.descripcion.toUpperCase()}</Text>
        <View
          style={{
            width: "100%",
            display: "flex",
            height: "100%",
          }}
        >
          <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid black" }}>
            <Text
              style={{
                width: "40%",
                borderRight: "1px solid black",
                fontSize: 11,
                fontWeight: "semibold",
                textAlign: "center",
              }}
            >
              Nombre
            </Text>
            <Text
              style={{
                width: "30%",
                borderRight: "1px solid black",
                fontSize: 11,
                fontWeight: "semibold",
                textAlign: "center",
              }}
            >
              RUT
            </Text>
            <Text style={{ width: "30%", fontSize: 11, fontWeight: "semibold", textAlign: "center" }}>FIRMA</Text>
          </View>
          {data.map((el) => {
            return (
              <View wrap={false} style={{ borderBottom: "1px solid black" }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "40%",
                      borderRight: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {el.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30%",
                      borderRight: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {el.rut}
                    </Text>
                  </View>
                  <View style={{ height: "40px", width: "30%", padding: 2 }}>
                    <Image
                      source={`${enviroments.img_endpoint}${el.signature}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        marginVertical: "5px",
                        objectFit: "contain",
                      }}
                    />
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </Page>
  );
};

function groupByEquipment(arr) {
  const grouped = {};
  arr.forEach((obj) => {
    const equipment = obj.equipment;
    if (!grouped[equipment]) {
      grouped[equipment] = [];
    }
    grouped[equipment].push(obj);
  });
  return Object.values(grouped);
}

export const generateAndSendPDF = async (data, current, current_rut) => {
  try {
    const pdfBlob = await pdf(<PDFComponent data={data} current={current} current_rut={current_rut} />).toBlob();
    return pdfBlob;
  } catch (error) {
    console.log(error);
  }
};

export default PDFComponent;
