import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import formatDataModal from "../../../utils/formatDataModal";
import { setNotification } from "../notification";

const initialState = {
  data: [],
  success: false,
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    setDepartments: (state, action) => {
      state.data = action.payload;
      state.success = true;
    },
    addDepartment: (state, action) => {
      state.data = [...state.data, action.payload];
      state.success = true;
    },
    setUpdateDepartment: (state, action) => {
      const updated = state.data.filter((department) => {
        return department.id !== action.payload.id;
      });
      state.data = [...updated, action.payload];
      state.success = true;
    },
    setRemoveDepartment: (state, action) => {
      const updated = state.data.filter((department) => {
        return department.id !== action.payload.id;
      });
      state.data = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const getAllDepartments = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/departamento");
    const filtrado = res.data.filter((department) => department.estado === true);
    const departments = filtrado.map((department) => ({
      ...department,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: department.f_creacion.split("T")[0],
    }));
    dispatch(setDepartments(departments));
  } catch (error) {
    console.log(error);
  }
};

export const createNewDepartment = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/departamento", formatedValues);
    const department = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };
    dispatch(addDepartment(department));
    dispatch(
      setNotification({
        status: "success",
        message: "Departamento creado con éxito",
        open: true,
      }),
    );
    return department;
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear departamento",
        open: true,
      }),
    );
    return error;
  }
};

export const updateDepartment = (data) => async (dispatch) => {
  const formatedValues = formatDataModal(data);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/departamento", formatedValues);

    const department = {
      ...res.data,
      estado: true ? "Activo" : "Inactivo",
      f_creacion: res.data.f_creacion.split("T")[0],
    };

    dispatch(setUpdateDepartment(department));
    dispatch(
      setNotification({
        status: "success",
        message: "Departamento editado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar departamento",
        open: true,
      }),
    );
    return console.log(error);
  }
};

export const deleteDepartment = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const deleted = await axiosInstance.put(`/departamento/${id}`);
    dispatch(setRemoveDepartment(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Departamento eliminado con éxito",
        open: true,
      }),
    );
  } catch (error) {
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data.message,
        open: true,
      }),
    );
  }
};

export const { setDepartments, addDepartment, setUpdateDepartment, setRemoveDepartment, setSuccess } =
  departmentSlice.actions;

export default departmentSlice.reducer;
