import * as Yup from "yup";

const profileSchema = Yup.object().shape({
  nombre: Yup.string().min(1, "Debe contener informacion"),

  direccion: Yup.string().min(1, "Debe contener informacion"),

  telefono: Yup.string()
    .min(1, "Debe contener informacion")
    .matches(/^[0-9]+$/, "Formato de telefono invalido"),

  mail_personal: Yup.string().min(1, "Debe contener informacion").email("Ingrese un formato valido"),

  clave: Yup.string(),

  confirmar_clave: Yup.string().oneOf([Yup.ref("clave")], "Las claves no coinciden"),
});

export default profileSchema;
