import { Button, TextField, Chip, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createNewDepartment } from "../../redux/slices/deparments";
import { getAllCharges } from "../../redux/slices/charges";

export default function DepartmentDialog({ open, dialogValue, handleClose, setter }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: chargesData } = useSelector((state) => state.charges);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(getAllCharges());
    setValue("descripcion", dialogValue.descripcion);
  }, [open]);

  const handle_submit = async (value) => {
    const department = await dispatch(createNewDepartment(value));
    setter(department);
    reset();
    handleClose();
  };

  return (
    <form>
      <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR DEPARTAMENTO</div>
      <div className="min-h-[65px] mb-1">
        <Controller
          id="descripcion"
          name="descripcion"
          control={control}
          rules={{ required: "* Campo requerido" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label="Descripción"
              onChange={onChange}
              size="small"
              value={value || ""}
              error={errors.descripcion}
              helperText={errors.descripcion?.message}
            />
          )}
        />
      </div>
      <div className="min-h-[65px] mb-1">
        <Controller
          id="jefatura"
          name="jefatura"
          control={control}
          rules={{ required: "* Campo requerido" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              select
              size="small"
              onChange={onChange}
              value={value || ""}
              label="Jefatura"
              error={errors.jefatura}
              helperText={errors.jefatura?.message}
              sx={{
                "& .MuiFormHelperText-root": {
                  marginLeft: 0,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                },
              }}
            >
              {chargesData?.map(
                (option) =>
                  option.id && (
                    <MenuItem key={option.id} dense divider value={option.id}>
                      {option.descripcion}
                    </MenuItem>
                  ),
              )}
            </TextField>
          )}
        />
      </div>
      <div className="flex items-center w-full justify-between">
        <Button onClick={handleSubmit(handle_submit)} variant="outlined" fullWidth>
          Enviar formulario
        </Button>
      </div>
    </form>
  );
}
