import { Button, TextField, Chip, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { createNewCharge } from "../../redux/slices/charges";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ChargeDialog({ open, dialogValue, handleClose, setter }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("descripcion", dialogValue.descripcion);
  }, [open]);

  const handle_submit = async (value) => {
    const charge = await dispatch(createNewCharge(value));
    setter(charge);
    reset();
    handleClose();
  };

  return (
    <form>
      <div className="bg-[#2b2c84]/20 px-5 py-2 rounded  text-center font-semibold mb-2">AÑADIR CARGO</div>
      <div className="min-h-[65px] mb-1">
        <Controller
          id="descripcion"
          name="descripcion"
          control={control}
          rules={{ required: "* Campo requerido" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label="Descripción"
              onChange={onChange}
              size="small"
              value={value || ""}
              error={errors.descripcion}
              helperText={errors.descripcion?.message}
            />
          )}
        />
      </div>
      <div className="min-h-[65px] mb-1">
        <Controller
          id="tipo"
          name="tipo"
          control={control}
          rules={{ required: "* Campo requerido" }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              select
              label="Tipo"
              onChange={onChange}
              size="small"
              value={value || ""}
              error={errors.tipo}
              helperText={errors.tipo?.message}
            >
              {location.pathname === "/users" ? (
                <MenuItem key={1} dense divider value={"Usuario"}>
                  Usuario
                </MenuItem>
              ) : (
                <MenuItem key={1} dense divider value={"Contacto"}>
                  Contacto
                </MenuItem>
              )}
            </TextField>
          )}
        />
      </div>
      <div className="flex items-center w-full justify-between">
        <Button onClick={handleSubmit(handle_submit)} variant="outlined" fullWidth>
          Enviar formulario
        </Button>
      </div>
    </form>
  );
}
